import type { IsConnected } from 'operations/IsConnected'

import { gql, useQuery } from '@apollo/client'
import { isEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'

const Q_GET_INVITATIONS = gql`
  query IsConnected($fromProfileId: Int!, $toProfileId: Int!) {
    invitations(
      where: {
        _or: [
          {
            from_profile_id: { _eq: $fromProfileId }
            to_profile_id: { _eq: $toProfileId }
          }
          {
            to_profile_id: { _eq: $fromProfileId }
            from_profile_id: { _eq: $toProfileId }
          }
        ]
      }
      limit: 1
    ) {
      id
      from_profile_id
      to_profile_id
      sent_at
      accepted_at
      created_at
      token
      updated_at
    }
  }
`

enum ConnectionState {
  PENDING = 'pending',
  CONNECT = 'connect',
  CONNECTED = 'connected',
}

const useConnectionState = (fromProfileId: number, toProfileId: number) => {
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.CONNECT,
  )

  const { loading, error, data } = useQuery<IsConnected>(Q_GET_INVITATIONS, {
    variables: { fromProfileId, toProfileId },
    skip: fromProfileId == undefined,
  })

  const getConnectionState = () => {
    if (data) {
      const { invitations } = data
      if (isEmpty(invitations)) {
        setConnectionState(ConnectionState.CONNECT)
      } else {
        if (invitations?.[0]) {
          const invitation = invitations?.[0]
          if (!invitation.accepted_at) {
            setConnectionState(ConnectionState.PENDING)
          }
          if (invitation.accepted_at) {
            setConnectionState(ConnectionState.CONNECTED)
          }
        }
      }
    }
  }

  useEffect(getConnectionState, [data, loading, fromProfileId, toProfileId])

  return connectionState
}

export default useConnectionState
