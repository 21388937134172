import clsx from 'clsx'
import { FunctionComponent, MouseEventHandler } from 'react'

interface Props {
  className?: string
  handleClick?: MouseEventHandler<SVGSVGElement>
}

const CheckCircleIcon: FunctionComponent<Props> = ({
  className = '',
  handleClick,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={clsx(className, 'h-5 w-5')}
    onClick={handleClick}
  >
    <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
  </svg>
)

export default CheckCircleIcon
