import InformationIcon from '@/components/common/information_icon'
import { Tooltip } from '@/components/ui/tooltip'
import { newReferralData } from '@/lib/reactiveVariables'
import Link from 'next/link'
import { FunctionComponent, useContext, useReducer, useState } from 'react'

import { Checkbox, NewReferralStep, Text } from 'components/ui'

import useUpsertReferral, {
  StepType,
} from '../../../edit_referral/hooks/useUpsertReferral'
import { CreateReferralContext } from '../../step_controller/step_controller'

interface IStepSix extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}
enum ActionType {
  Urgent = 'Urgent',
  Share = 'Share',
  Assistance = 'Assistance',
  Public = 'Public',
}

interface PublishingData {
  urgent: boolean
  shareReferral: boolean
  assistanceNeeded: boolean
  publicDisplay: boolean
}

export type StepSixSeekingTherapistsData = PublishingData & {
  _type: 'StepSixSeekingTherapists'
}

const StepSix: FunctionComponent<IStepSix> = () => {
  const {
    shareReferral: initialShareReferral,
    urgent: initialUrgent,
    assistanceNeeded: initialAssistanceNeeded,
    publicDisplay: initialPublicDisplay,
  } = newReferralData()

  const { goToPreviousStep, goToNextStep } = useContext(CreateReferralContext)

  const { upsertReferral } = useUpsertReferral()

  const textContent = {
    stepNumber: '6/6',
    stepDescription: 'Distribution, Visibility & Publishing',
    title: 'Distribution & Publishing',
    description:
      "That's it. We've got all the information needed to share your referral with the Belongly community and get the client connected with a qualified therapist. Just a few more questions and we'll get your referral published.",
  }

  const checkboxItems = [
    {
      label: (
        <Text className="text-[#c91111]">
          My referral is urgent and time sensitive
        </Text>
      ),
      selected: 'urgent',
      onChange: () => handleToggleOption(ActionType.Urgent),
    },
    {
      label:
        'Please distribute my referral via email to the Belongly referal network',
      tooltip:
        'When you request to have your referral distributed via email. Belongly will distribute the content, to our certified network of therapists in the specific location where the client is seeking help. Your identity will remain private and is only exposed to approved and logged in members of the Belongly platform. If the location is in multiple states or in a PSYPACT state, therapists in all relevant locations will be invited to respond. This greatly increases the probability of quality respones.',
      selected: 'shareReferral',
      onChange: () => handleToggleOption(ActionType.Share),
    },
    {
      label: "I'd love assitance from a Belongly referral coordinator",
      selected: 'assistanceNeeded',
      onChange: () => handleToggleOption(ActionType.Assistance),
    },
    {
      label: 'Display this referral publicly',
      tooltip:
        'Including this referral in our public display will greatly increase the exposure of this referral and the likelhood of a high-quality response from a vetted Belongly therapist. No personal information about you (the author) or the client will ever be communicated in our public display. This is an opportunity to increase visibility about a situation in need and bring more vetted therapists into the platform to participate in this and all referrals.',
      selected: 'publicDisplay',
      onChange: () => handleToggleOption(ActionType.Public),
    },
  ]

  const updateStateProperty = (
    state: PublishingData,
    property: keyof PublishingData,
  ) => {
    return { ...state, [property]: !state[property] }
  }

  const reducer = (state: PublishingData, action: { type: ActionType }) => {
    switch (action.type) {
      case ActionType.Urgent:
        return updateStateProperty(state, 'urgent')
      case ActionType.Assistance:
        return updateStateProperty(state, 'assistanceNeeded')
      case ActionType.Share:
        return updateStateProperty(state, 'shareReferral')
      case ActionType.Public:
        return updateStateProperty(state, 'publicDisplay')
      default:
        return state
    }
  }

  const intialPublishingData = {
    urgent: initialUrgent || false,
    shareReferral: initialShareReferral,
    assistanceNeeded: initialAssistanceNeeded || false,
    publicDisplay: initialPublicDisplay || false,
  }

  const [publishingData, dispatch] = useReducer(reducer, intialPublishingData)
  const [termsNConditions, toggleTermsNConditions] = useState(false)
  const [clicked, setClicked] = useState(false)

  const handleToggleOption = (option: ActionType) => {
    dispatch({ type: option })
  }

  const handleToggleTermsNConditions = () =>
    toggleTermsNConditions((current) => !current)

  const handleContinue = () => {
    upsertReferral({
      ...publishingData,
      _type: StepType.STEP_SIX_SEEKING_THERAPISTS,
    })
    goToNextStep()
  }

  const handleClick = async () => {
    if (!clicked) {
      setClicked(true)
      handleContinue()
    }
  }

  return (
    <NewReferralStep
      stepNumber={textContent.stepNumber}
      stepDescription={textContent.stepDescription}
      formTitle="Let's get your referral published"
      title={textContent.title}
      positiveLabel="Publish"
      onPositive={handleClick}
      onNegative={goToPreviousStep}
      description={textContent.description}
      isPositiveButtonDisabled={!termsNConditions}
      formPositionHigh
    >
      <>
        {/* <hr className="text-[#cfcfcf] h-[1px]" /> */}
        <Text size="xl" weight="bold">
          Additional options:
        </Text>
        <section className="flex flex-col gap-14">
          <div className="flex flex-col gap-4">
            {checkboxItems.map((item, i) => (
              <div key={i} className="flex items-center">
                <Checkbox
                  size="md"
                  className="gap-4 items-center"
                  label={item.label}
                  uncheckedClassName={i == 0 ? 'fill-[#c91111]' : ''}
                  selected={
                    publishingData[item.selected as keyof PublishingData]
                  }
                  onChange={item.onChange}
                />
                {item.tooltip && (
                  <Tooltip content={item.tooltip}>
                    <InformationIcon className="mt-3 ml-1" />
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
          <div className="flex pt-5 w-11/12">
            <Checkbox
              className="gap-4 items-start"
              label=""
              size="md"
              selected={termsNConditions}
              onChange={handleToggleTermsNConditions}
            />
            <Text>
              By posting this referral to Belongly, I hereby agree to the
              specific{' '}
              <Link
                href="https://www.belongly.com/terms-of-service/"
                target="_blank"
                className="text-[#7a8fce]"
              >
                terms & conditions
              </Link>{' '}
              related to referrals.
            </Text>
          </div>
        </section>
      </>
    </NewReferralStep>
  )
}

export default StepSix
