import { gql } from '@apollo/client'

import { BusinessProfile, ProfileFields } from './posts'

export const S_MESSAGE_GROUP_LIST = gql`
  ${BusinessProfile}
  ${ProfileFields}
  subscription MessageGroupList($profileId: Int!, $beeProfileId: Int!) {
    message_group(
      order_by: { updated_at: desc }
      where: {
        _and: {
          profiles: { from_profile_id: { _eq: $profileId } }
          owner_profile_id: { _neq: $beeProfileId }
          messages: { content: { _is_null: false } }
        }
      }
    ) {
      id
      profiles(
        where: {
          _or: [
            { from_profile_id: { _neq: $profileId } }
            { from_business_profile_id: { _neq: $profileId } }
          ]
        }
      ) {
        from_profile {
          ...ProfileFields
        }
        from_business_profile {
          ...BusinessProfile
        }
      }
      profile_seen: profiles(where: { from_profile_id: { _eq: $profileId } }) {
        last_seen
      }
      unread_messages: profiles(
        where: { from_profile_id: { _eq: $profileId } }
      ) {
        unread_messages_count
      }
      messages(order_by: { created_at: desc }, limit: 1) {
        id
        created_at
        content
        message_group_id
        business_profile {
          ...BusinessProfile
        }
        profile {
          ...ProfileFields
        }
      }
    }
  }
`
export const S_BEE_MESSAGE_GROUP_LIST = gql`
  ${BusinessProfile}
  ${ProfileFields}
  subscription BeeMessageGroupList($profileId: Int!, $beeProfileId: Int!) {
    message_group(
      order_by: { created_at: asc }
      where: {
        _and: {
          profiles: { from_profile_id: { _eq: $profileId } }
          owner_profile_id: { _eq: $beeProfileId }
        }
      }
    ) {
      id
      profiles(
        where: {
          _or: [
            { from_profile_id: { _neq: $profileId } }
            { from_business_profile_id: { _neq: $profileId } }
          ]
        }
      ) {
        from_profile {
          ...ProfileFields
        }
      }
      profile_seen: profiles(where: { from_profile_id: { _eq: $profileId } }) {
        last_seen
      }
      unread_messages: profiles(
        where: { from_profile_id: { _eq: $profileId } }
      ) {
        unread_messages_count
      }
      messages(order_by: { created_at: desc }, limit: 1) {
        id
        created_at
        content
        message_group_id
        profile {
          ...ProfileFields
        }
      }
    }
  }
`

export const Q_BEE_MESSAGES = gql`
  query BeeMessages($profileId: Int!, $beeProfileId: Int!) {
    messages(
      where: {
        _and: {
          profile_id: { _eq: $profileId }
          message_group: { owner_profile_id: { _eq: $beeProfileId } }
        }
      }
    ) {
      id
      content
    }
  }
`

export const S_BUSINESS_PROFILE_CONVERSATIONS = gql`
  ${BusinessProfile}
  subscription BusinessProfileConversations($profileId: Int!) {
    message_group(
      where: {
        business_profile: {
          _or: [
            { administrators: { profile_id: { _eq: $profileId } } }
            { owner_id: { _eq: $profileId } }
          ]
        }
      }
    ) {
      id
      profiles(where: { from_business_profile_id: { _is_null: false } }) {
        unread_messages_count
        from_business_profile_id
      }
    }
  }
`

export const S_BUSINESS_MESSAGE_GROUP_LIST = gql`
  ${BusinessProfile}
  ${ProfileFields}
  subscription BusinessMessageGroupList($profileId: Int!) {
    message_group(
      order_by: { updated_at: desc }
      where: {
        _and: {
          profiles: { from_business_profile_id: { _eq: $profileId } }
          messages: { content: { _is_null: false } }
        }
      }
    ) {
      id
      profiles(
        where: {
          _or: [
            { from_profile_id: { _neq: $profileId } }
            { from_business_profile_id: { _neq: $profileId } }
          ]
        }
      ) {
        from_profile {
          ...ProfileFields
        }
        from_business_profile {
          ...BusinessProfile
        }
      }
      business_profile {
        ...BusinessProfile
      }
      profile_seen: profiles(where: { from_profile_id: { _eq: $profileId } }) {
        last_seen
      }
      unread_messages: profiles(
        where: { from_business_profile_id: { _eq: $profileId } }
      ) {
        unread_messages_count
      }
      messages(order_by: { created_at: desc }, limit: 1) {
        id
        created_at
        content
        message_group_id
        business_profile {
          ...BusinessProfile
        }
        profile {
          ...ProfileFields
        }
      }
    }
  }
`

export const Q_MESSAGE_GROUP_PROFILE_TO_PROFILE = gql`
  query MessageGroupProfileToProfile($fromProfileId: Int!, $toProfileId: Int!) {
    message_group(
      where: {
        _and: [
          { profiles: { from_profile_id: { _eq: $fromProfileId } } }
          { profiles: { from_profile_id: { _eq: $toProfileId } } }
        ]
      }
    ) {
      id
      profiles(where: { from_profile_id: { _neq: $fromProfileId } }) {
        id
        from_profile {
          id
          first_name
          last_name
          avatar
          user_status {
            is_online
          }
        }
      }
    }
  }
`
export const Q_MESSAGE_GROUP_PROFILE_TO_BUSINESS_PROFILE = gql`
  ${BusinessProfile}
  query MessageGroupProfileToBusinessProfile(
    $fromProfileId: Int!
    $toProfileId: Int!
  ) {
    message_group(
      where: {
        _and: [
          { profiles: { from_profile_id: { _eq: $fromProfileId } } }
          { business_profile: { id: { _eq: $toProfileId } } }
        ]
      }
    ) {
      id
      business_profile {
        ...BusinessProfile
      }
    }
  }
`
export const Q_MESSAGE_GROUP_BUSINESS_PROFILE_TO_PROFILE = gql`
  query MessageGroupBusinessProfileToProfile(
    $fromProfileId: Int!
    $toProfileId: Int!
  ) {
    message_group(
      where: {
        _and: [
          { business_profile: { id: { _eq: $fromProfileId } } }
          { profiles: { from_profile_id: { _eq: $toProfileId } } }
        ]
      }
    ) {
      id
      profiles(where: { from_profile_id: { _neq: $fromProfileId } }) {
        id
        from_profile {
          id
          first_name
          last_name
          avatar
          user_status {
            is_online
          }
        }
      }
    }
  }
`

export const Q_MESSAGE_GROUP_BUSINESS_PROFILE_TO_BUSINESS_PROFILE = gql`
  ${BusinessProfile}
  query MessageGroupBusinessProfileToBusinessProfile(
    $fromProfileId: Int!
    $toProfileId: Int!
  ) {
    message_group(
      where: {
        _and: [
          { profiles: { from_business_profile_id: { _eq: $fromProfileId } } }
          { profiles: { from_business_profile_id: { _eq: $toProfileId } } }
        ]
      }
    ) {
      id
      profiles(where: { from_business_profile_id: { _neq: $fromProfileId } }) {
        id
        from_business_profile {
          ...BusinessProfile
        }
      }
    }
  }
`

export const S_MESSAGES_BY_GROUP_ID = gql`
  ${BusinessProfile}
  subscription Messages($messageGroupId: Int!, $ownerProfileId: Int!) {
    messages(
      where: { message_group_id: { _eq: $messageGroupId } }
      order_by: { created_at: asc }
    ) {
      id
      content
      created_at
      profile {
        id
        avatar
        first_name
        last_name
        user_status {
          is_online
        }
      }
      business_profile {
        ...BusinessProfile
      }
      message_group {
        profiles(where: { from_profile_id: { _neq: $ownerProfileId } }) {
          from_profile {
            user_status {
              is_online
            }
          }
        }
      }
    }
  }
`

export const Q_MESSAGE_GROUP_BY_ID = gql`
  ${BusinessProfile}
  ${ProfileFields}
  query MessageGroupById($messageGroupId: Int!) {
    message_group(where: { id: { _eq: $messageGroupId } }) {
      id
      profiles {
        from_business_profile {
          ...BusinessProfile
        }
        from_profile {
          ...ProfileFields
        }
      }
      owner_profile_id
    }
  }
`

export const Q_LAST_SEEN = gql`
  query LastSeenByMessageGroupId($messageGroupId: Int!, $fromProfileId: Int!) {
    message_group_profiles(
      where: {
        message_group_id: { _eq: $messageGroupId }
        from_profile_id: { _eq: $fromProfileId }
      }
      limit: 1
    ) {
      last_seen
    }
  }
`

export const Q_BUSINESS_LAST_SEEN = gql`
  query BusinessLastSeenByMessageGroupId(
    $messageGroupId: Int!
    $fromProfileId: Int!
  ) {
    message_group_profiles(
      where: {
        message_group_id: { _eq: $messageGroupId }
        from_business_profile_id: { _eq: $fromProfileId }
      }
      limit: 1
    ) {
      last_seen
    }
  }
`
