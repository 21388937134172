import clsx from 'clsx'
import { TextareaHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { Text, TextCharsRemaining } from 'components/ui'

interface ITextArea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  background?: 'white' | 'page'
  rows?: number
  variant?: 'regular'
  inputStyle?: string
  error?: string | undefined
  containerClassname?: string
  footNote?: string
  minLimit?: number
  maxLimit?: number
  resize?: boolean
}

const TextArea = forwardRef<HTMLTextAreaElement, ITextArea>(
  (
    {
      className,
      containerClassname,
      background = 'page',
      placeholder,
      variant = 'regular',
      error,
      rows,
      minLimit,
      maxLimit,
      inputStyle,
      footNote,
      resize = false,
      ...props
    }: ITextArea,
    ref,
  ) => {
    const backgroundIsWhite = background === 'white'
    const { value } = props

    return (
      <div className={twMerge(containerClassname, 'flex flex-col gap-1')}>
        <div
          className={twMerge(
            'flex w-full rounded border text-body outline-none focus:outline-0 align-center',
            backgroundIsWhite ? 'border-dark-border' : 'border-light-border',
            className,
          )}
        >
          <textarea
            {...props}
            style={{ resize: resize ? 'both' : 'none' }}
            maxLength={maxLimit}
            placeholder={placeholder}
            ref={ref}
            rows={rows}
            className={clsx(
              'w-full px-4 py-6 align-center rounded',
              backgroundIsWhite ? 'bg-background' : 'bg-white',
              inputStyle,
            )}
          />
        </div>
        {footNote && (
          <Text variant="body" detectLink={false}>
            {footNote}
          </Text>
        )}
        {minLimit && typeof value == 'string' && (
          <TextCharsRemaining
            minLimit={minLimit}
            value={value}
            maxLimit={maxLimit}
          />
        )}
        {error && <Text className="mt-2 text-error">{error}</Text>}
      </div>
    )
  },
)

TextArea.displayName = 'TextArea'

export default TextArea
