import {
  M_INSERT_REFERRAL,
  M_UPDATE_REFERRAL,
} from 'lib/mutations/opportunities'
import { Q_GET_REFERRALS } from 'lib/queries/opportunities'
import {
  initialReferralData,
  newReferralData,
  referralEditedId,
} from 'lib/reactiveVariables'

import {
  InsertOneReferral,
  InsertOneReferralVariables,
} from '@/operations/InsertOneReferral'
import {
  UpdateReferralById,
  UpdateReferralByIdVariables,
} from '@/operations/UpdateReferralById'
import { useMutation } from '@apollo/client'
import * as linkify from 'linkifyjs'
import { isNil } from 'ramda'
import { useContext, useMemo } from 'react'
import { parseReferralTitle, whether } from 'utils'

import { useProfile } from 'components/hooks'
import usePostAs from 'components/hooks/usePostAs'
import useLinkPreview from 'components/hooks/usePreviewLink'

import { StepFiveSeekingClientsData } from '../../create_new_referral/seeking_clients_steps/step_five/step_five'
import { StepSixSeekingTherapistsData } from '../../create_new_referral/seeking_therapists_steps/step_six/step_six'
import { CreateReferralContext } from '../../create_new_referral/step_controller/step_controller'
import { getFinalReferralData } from '../../utils/referrals'

export enum StepType {
  STEP_FIVE_SEEKING_CLIENTS = 'StepFiveSeekingClients',
  STEP_SIX_SEEKING_THERAPISTS = 'StepSixSeekingTherapists',
}
const useUpsertReferral = () => {
  const { profile: ownerProfile } = useProfile()
  const { getProfileType, removeProfileType } = usePostAs({})

  //Create insert and update mutation

  const [
    insertReferralMutation,
    {
      data: dataReferralInsert,
      loading: loadingReferralInsert,
      error: errorReferralInsert,
    },
  ] = useMutation<InsertOneReferral, InsertOneReferralVariables>(
    M_INSERT_REFERRAL,
    {
      refetchQueries: [{ query: Q_GET_REFERRALS }, 'GetReferrals'],
    },
  )
  const [
    updateReferralMutation,
    {
      data: dataReferralUpdate,
      loading: loadingReferralUpdate,
      error: errorReferralUpdate,
    },
  ] = useMutation<UpdateReferralById, UpdateReferralByIdVariables>(
    M_UPDATE_REFERRAL,
    {
      refetchQueries: [{ query: Q_GET_REFERRALS }, 'GetReferrals'],
    },
  )

  const { setReferralLink } = useContext(CreateReferralContext)

  // Get the referral data that's going to be saved
  const allReferralData = newReferralData()

  // Find out if the referral is being created or edited
  const referralId = referralEditedId()
  const isEditOperation = !isNil(referralId) && referralId != -1

  enum Category {
    Referral = 'Referral',
  }
  //linkpreview
  const linkPreviewUrl = useMemo(() => {
    const results = linkify.find(allReferralData.referralDescription || '')
    return whether(!isNil(results) && results.length > 0, results[0]?.href)
  }, [allReferralData])

  const linkPreview = useLinkPreview(linkPreviewUrl)

  //Create the function for both purposes: creation or updating

  const upsertReferral = async (
    finalStepData: StepFiveSeekingClientsData | StepSixSeekingTherapistsData,
  ) => {
    // Get all the final referral data from function created to extract the data depending on wether its:
    // A) Last step of seeking clients
    // B) Last step of seeking Therapists
    // Then the data is destructured and passed to the variable object (the object sent to the mutation query)
    // DO NOT REMOVE!
    const {
      seekingInfo,
      urgent,
      shareReferral,
      assistanceNeeded,
      publicDisplay,
    } = getFinalReferralData({ finalStepData, allReferralData })

    if (!ownerProfile) return
    const profilePostingInfo = await getProfileType()

    const author_key: 'author_profile_id' | 'business_id' =
      profilePostingInfo.businessId ? 'business_id' : 'author_profile_id'

    //Common variables between insert and update
    const commonVariables = {
      category: Category.Referral,
      subcategory: allReferralData.referralType,
      content: allReferralData.referralDescription,
      title: allReferralData.referralTitle,
      seeking_info: allReferralData.referralSeekingInfo,
      link_preview: linkPreview,
      urgent,
      share_referral: shareReferral,
      assistance_needed: assistanceNeeded,
      public_display: publicDisplay,
    }

    //variables in case its an update
    const updateVariables = {
      id: referralId,
      ...commonVariables,
    }

    //variables in case its an insert
    const insertVariables = {
      [author_key]:
        author_key === 'author_profile_id'
          ? ownerProfile?.id
          : profilePostingInfo.businessId,
      reason: allReferralData.reason,
      ...commonVariables,
    }

    //Executing either one of the operations depending on the type (edit or update)
    const { data: referral } = isEditOperation
      ? await updateReferralMutation({ variables: updateVariables })
      : await insertReferralMutation({ variables: insertVariables })
    newReferralData(initialReferralData)
    referralEditedId(-1)

    //Getting the uuid and title for the referral link creation
    const uuid = referral
      ? 'update_opportunities_by_pk' in referral
        ? referral.update_opportunities_by_pk?.uuid || -1
        : referral?.insert_opportunities_one?.uuid || -1
      : -1

    const title = referral
      ? 'update_opportunities_by_pk' in referral
        ? referral.update_opportunities_by_pk?.title || ''
        : referral?.insert_opportunities_one?.title || ''
      : ''

    removeProfileType()

    setReferralLink(
      `/opportunities/referrals/${uuid}/${parseReferralTitle(title)}`,
    )
  }

  const loadingReferralUpsert = isEditOperation
    ? loadingReferralUpdate
    : loadingReferralInsert
  const dataReferralUpsert = isEditOperation
    ? dataReferralUpdate?.update_opportunities_by_pk
    : dataReferralInsert?.insert_opportunities_one
  const errorReferralUpsert = isEditOperation
    ? errorReferralUpdate
    : errorReferralInsert

  const referralLink = dataReferralUpsert
    ? `/opportunities/referrals/${
        dataReferralUpsert?.uuid
      }/${parseReferralTitle(dataReferralUpsert?.title)}`
    : undefined

  return {
    upsertReferral,
    loadingReferralUpsert,
    dataReferralUpsert,
    errorReferralUpsert,
    referralLink,
  }
}

export default useUpsertReferral
