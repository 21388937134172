import React from 'react'

function CloseButton({ onClick }: { onClick: () => void }) {
  return (
    <div className="text-xl" onClick={onClick}>
      &times;
    </div>
  )
}

export default CloseButton
