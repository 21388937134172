import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { FunctionComponent } from 'react'

import { InfoLabel, Text } from 'components/ui'

interface IRadioGroup {
  label?: string
  options: string[]
  horizontal?: boolean
  optionRoundedShape?: boolean
  value?: string
  textSize?: 'xs' | 'sm' | 'md' | 'lg'
  tooltip?: string
  onSelect: (option: string) => void
  className?: string
  labelClassName?: string
  defaultValue?: string
  variant?: 'rounded' | 'square'
}

const RadioGroup: FunctionComponent<IRadioGroup> = ({
  label,
  className,
  labelClassName,
  options,
  horizontal = false,
  variant = 'rounded',
  value,
  textSize = 'sm',
  tooltip,
  onSelect,
  defaultValue,
  ...props
}: IRadioGroup) => {
  const isRounded = variant === 'rounded'

  return (
    <div
      {...props}
      className={clsx(className, 'flex relative justify-center items-center')}
    >
      <div className="flex-1">
        <HeadlessRadioGroup
          value={value}
          onChange={onSelect}
          defaultValue={defaultValue}
        >
          {label && (
            <HeadlessRadioGroup.Label
              className={clsx('mb-4 flex', labelClassName)}
            >
              {label}
            </HeadlessRadioGroup.Label>
          )}
          <div className={clsx(horizontal && 'flex')}>
            {options.map((option) => (
              <HeadlessRadioGroup.Option
                value={option}
                key={option}
                className={clsx(horizontal ? 'px-3' : 'py-3')}
              >
                {({ checked }) => (
                  <div className="flex cursor-pointer">
                    <div
                      className={clsx(
                        'flex justify-center items-center mr-3 w-4 h-4 bg-white border border-gray-500 shrink-0',
                        isRounded && 'rounded-full',
                      )}
                    >
                      {checked && (
                        <span
                          className={clsx(
                            'flex w-2 h-2 bg-primary',
                            isRounded && 'rounded-full',
                          )}
                        />
                      )}
                    </div>
                    <Text size={textSize} capitalize>
                      {option}
                    </Text>
                  </div>
                )}
              </HeadlessRadioGroup.Option>
            ))}
          </div>
        </HeadlessRadioGroup>
      </div>
      {tooltip && (
        <div className="absolute -right-7">
          <InfoLabel tooltip={tooltip} />
        </div>
      )}
    </div>
  )
}

export default RadioGroup
