import clsx from 'clsx'
import { FunctionComponent } from 'react'

interface IStepper extends React.HTMLAttributes<HTMLDivElement> {
  numberOfSteps?: number
  currentStep?: number
}

const Stepper: FunctionComponent<IStepper> = ({
  numberOfSteps = 3,
  currentStep = 0,
  className,
  ...props
}: IStepper) => {
  const steps = new Array(numberOfSteps)
  steps.fill(1)

  const isCompleted = (index: number) => index <= currentStep

  return (
    <div {...props} className={clsx(className, 'flex gap-2 w-full h-1')}>
      {steps.map((_, i) => (
        <div
          className={clsx(
            'rounded-full flex-1 h-full',
            isCompleted(i) ? 'bg-secondary' : 'bg-gray-300',
          )}
          key={`${i}`}
        />
      ))}
    </div>
  )
}

export default Stepper
