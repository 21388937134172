import { INewReferralData } from '@/lib/reactiveVariables'

import { StepFiveSeekingClientsData } from '../create_new_referral/seeking_clients_steps/step_five/step_five'
import { StepSixSeekingTherapistsData } from '../create_new_referral/seeking_therapists_steps/step_six/step_six'
import { StepType } from '../edit_referral/hooks/useUpsertReferral'

//Function used to extract all the final data to pass as to the object sent as a varabiel to the query depending on:
// A) Last step of seeking clients
// B) Last step of seeking Therapists

export const getFinalReferralData = ({
  finalStepData,
  allReferralData,
}: {
  finalStepData: StepFiveSeekingClientsData | StepSixSeekingTherapistsData
  allReferralData: INewReferralData
}) => {
  const seekingInfo =
    finalStepData._type === StepType.STEP_FIVE_SEEKING_CLIENTS
      ? { ...allReferralData.referralSeekingInfo, finalStepData }
      : allReferralData.referralSeekingInfo

  const urgent =
    finalStepData._type === StepType.STEP_SIX_SEEKING_THERAPISTS
      ? finalStepData.urgent
      : allReferralData.urgent || false
  const shareReferral =
    finalStepData._type === StepType.STEP_SIX_SEEKING_THERAPISTS
      ? finalStepData.shareReferral
      : allReferralData.shareReferral
  const assistanceNeeded =
    finalStepData._type === StepType.STEP_SIX_SEEKING_THERAPISTS
      ? finalStepData.assistanceNeeded
      : allReferralData.assistanceNeeded || false
  const publicDisplay =
    finalStepData._type === StepType.STEP_SIX_SEEKING_THERAPISTS
      ? finalStepData.publicDisplay
      : allReferralData.publicDisplay || false

  return {
    seekingInfo,
    urgent,
    shareReferral,
    assistanceNeeded,
    publicDisplay,
  }
}
