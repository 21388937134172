import { useRouter } from 'next/router'
import { isNil } from 'ramda'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import { OrganizationRole } from 'components/business_profiles/business_profiles.types'
import { useProfile } from 'components/hooks'
import useBusinessProfile from 'components/hooks/useBusinessProfile'

import { activeConversations, activeProfileOnChat } from '../types/types'
import ChatContext from './chat_context'

interface ChatProviderProps {
  children: ReactNode
}

function ChatProvider(props: ChatProviderProps) {
  const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false)

  const [isBusinessProfileActive, setIsBusinessProfileActive] =
    useState<boolean>(false)

  const [activeProfileOnChat, setActiveProfileOnChat] =
    useState<activeProfileOnChat>()

  const { profile, id: myId } = useProfile()

  const { businessProfile } = useBusinessProfile()

  const adminUser = businessProfile?.administrators.find(
    (admin) => admin.profile_id === myId,
  )
  const userPermissions = useMemo(() => {
    const isBusinessPageOwner = businessProfile?.owner_id === myId

    if (isBusinessPageOwner) {
      return { role: OrganizationRole.SuperAdmin }
    }
    if (adminUser) {
      return { role: adminUser.permissions[0] }
    }
    return { role: OrganizationRole.Guest }
  }, [businessProfile, adminUser, myId])

  const isManagedByMe = userPermissions.role != OrganizationRole.Guest

  useEffect(() => {
    if (isManagedByMe && !isNil(businessProfile)) {
      setActiveProfileOnChat(businessProfile)
      setIsBusinessProfileActive(true)
    } else {
      setActiveProfileOnChat(profile)
      setIsBusinessProfileActive(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, businessProfile])

  const [activeConversations, setActiveConversations] =
    useState<activeConversations>([])

  const value = useMemo(
    () => ({
      hasUnreadMessages,
      setHasUnreadMessages,
      isBusinessProfileActive,
      setIsBusinessProfileActive,
      activeProfileOnChat,
      activeConversations,
      setActiveConversations,
      setActiveProfileOnChat,
    }),
    [
      hasUnreadMessages,
      setHasUnreadMessages,
      isBusinessProfileActive,
      setIsBusinessProfileActive,
      activeProfileOnChat,
      activeConversations,
      setActiveConversations,
      setActiveProfileOnChat,
      ,
    ],
  )

  return (
    <ChatContext.Provider value={value}>{props.children}</ChatContext.Provider>
  )
}

export default ChatProvider
