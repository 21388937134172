import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { FunctionComponent, useEffect, useState } from 'react'

import useConnectionState from 'components/hooks/useConnectionState'
import { Button } from 'components/ui'

import { PermissionWrapper } from './hocs'

const M_INSERT_INVITATION = gql`
  mutation InsertOneInvite($fromProfileId: Int!, $toProfileId: Int!) {
    insert_invitations_one(
      object: { from_profile_id: $fromProfileId, to_profile_id: $toProfileId }
    ) {
      id
      from_profile_id
      to_profile_id
    }
  }
`

interface ButtonProps {
  className?: string
  children?: React.ReactNode
  fromProfileId: number
  toProfileId: number
}

enum ConnectionState {
  PENDING = 'pending',
  CONNECT = 'connect',
  CONNECTED = 'connected',
}

const ConnectButton: FunctionComponent<ButtonProps> = ({
  className,
  fromProfileId,
  toProfileId,
}) => {
  const connectionState = useConnectionState(fromProfileId, toProfileId)

  const [buttonState, setButtonState] = useState<ConnectionState>(
    ConnectionState.CONNECT,
  )

  const [createInvitation] = useMutation(M_INSERT_INVITATION)

  const buttonDisabled = buttonState != ConnectionState.CONNECT

  const updateButtonState = () => {
    const state = {
      connected: () => setButtonState(ConnectionState.CONNECTED),
      connect: () => setButtonState(ConnectionState.CONNECT),
      pending: () => setButtonState(ConnectionState.PENDING),
    }
    state[connectionState]()
  }

  useEffect(updateButtonState, [connectionState])

  if (fromProfileId === toProfileId) {
    return null
  }

  const insertInvitation = () => {
    createInvitation({ variables: { fromProfileId, toProfileId } })
    setButtonState(ConnectionState.PENDING)
  }

  return (
    <PermissionWrapper
      action="Connect"
      behavior="Disable"
      tooltip="You don't have the permissions to connect with someone yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly."
    >
      <Button
        className={clsx('capitalize rounded-buttons w-full', className)}
        variant={buttonDisabled ? 'third' : 'primary'}
        onClick={insertInvitation.bind(null)}
        disabled={buttonDisabled}
      >
        {buttonState}
      </Button>
    </PermissionWrapper>
  )
}

export default ConnectButton
