import { FindLocations_locations } from 'operations/FindLocations'
import { GetReferrals_opportunities } from 'operations/GetReferrals'

import { makeVar } from '@apollo/client'

import {
  OrganizationAddress,
  OrganizationAdmins,
  OrganizationSize,
  OrganizationType,
} from 'components/business_profiles/business_profiles.types'
import { ImageCrop } from 'components/upload'

export const referralDataVar = makeVar<GetReferrals_opportunities | undefined>(
  undefined,
)
export const isBusinessProfile = makeVar<boolean>(false)
export const switchProfileInfo = makeVar({
  businessId: -1,
})

interface IOnboardingData {
  firstName: string
  lastName: string
  professionalDesignations: string[]
  zipCode: string
  location: FindLocations_locations
  recentJobs: string[]
  employmentType: string
  referralPreferences: {
    acceptingPatients: boolean
    psypact: boolean
    acceptInsurance: boolean
    modality: string
    insurances: string[]
    languages: string[]
  }
  contactInfo: {
    bookingMethod: string
    email: string
    phoneNumber: string
    website?: string
    schedulingLink?: string
    coordinatorFirstName?: string
    coordinatorLastName?: string
  }
  specialities: string[]
  treatments: string[]
  ageGroups: string[]
  sexualOrientations: string[]
  avatar: ImageCrop
  id: number
}

export const onboardingData = makeVar<IOnboardingData>({
  id: -1,
  firstName: '',
  lastName: '',
  professionalDesignations: [],
  zipCode: '',
  location: {
    __typename: 'locations',
    id: -1,
    city: null,
    state: null,
    state_code: null,
  },
  recentJobs: [],
  employmentType: '',
  referralPreferences: {
    acceptingPatients: false,
    psypact: false,
    acceptInsurance: false,
    modality: '',
    insurances: [],
    languages: [],
  },
  contactInfo: {
    bookingMethod: '',
    email: '',
    phoneNumber: '',
    website: '',
    schedulingLink: '',
    coordinatorFirstName: '',
    coordinatorLastName: '',
  },
  specialities: [],
  treatments: [],
  ageGroups: [],
  sexualOrientations: [],
  avatar: { image: '', crop: {} },
})

export interface INotificationsSettings {
  locations: any[]
  PSYPACT: boolean
  email: boolean
  mobile: boolean
  web: boolean
}

export const notificationsSettings = makeVar<INotificationsSettings>({
  locations: [],
  PSYPACT: false,
  email: true,
  mobile: true,
  web: true,
})

export interface ILocation {
  __typename: 'locations'
  id: number
  city: string | null
  state: string | null
  state_code: string | null
}

export interface INewReferralData {
  referralType: string
  referralTitle: string
  referralDescription: string
  referralSeekingInfo: {
    locations: ILocation[]
    typeOfClients: string[]
    genders: string[]
    ageGroups: string[]
    communities: string[]
    insurances: string[]
    sessionModality: string
    languages: string[]
    mainSpecialties: string[]
    interventions: string[]
    issues: string[]
  }
  shareReferral: boolean
  urgent?: boolean
  assistanceNeeded?: boolean
  publicDisplay?: boolean
  reason: number | null
}
export const initialReferralData: INewReferralData = {
  referralType: '',
  referralTitle: '',
  referralDescription: '',
  referralSeekingInfo: {
    locations: [],
    typeOfClients: [],
    genders: [],
    ageGroups: [],
    communities: [],
    insurances: [],
    sessionModality: '',
    languages: [],
    mainSpecialties: [],
    interventions: [],
    issues: [],
  },
  shareReferral: false,
  urgent: false,
  assistanceNeeded: false,
  publicDisplay: false,
  reason: null,
}

export const newReferralData = makeVar<INewReferralData>({
  ...initialReferralData,
})

export const referralEditedId = makeVar<number>(-1)

export interface INewBusinessProfile {
  owner_id?: number
  organization_name: string
  organization_username: string
  organization_type: OrganizationType
  organization_subtype: string
  organization_size: OrganizationSize
  logo?: ImageCrop
  tagline?: string
  description: string
  has_right_to_act: boolean
  scheduling_link?: string
  year_founded?: string
  addresses?: OrganizationAddress[]
  people?: OrganizationAdmins[]
  website?: string
}

export const initialBusinessProfileData: INewBusinessProfile = {
  owner_id: undefined,
  organization_name: '',
  organization_username: '',
  organization_type: OrganizationType.BusinessPage,
  organization_subtype: '',
  organization_size: OrganizationSize.Small,
  logo: undefined,
  tagline: undefined,
  description: '',
  has_right_to_act: false,
  scheduling_link: undefined,
  year_founded: undefined,
  addresses: undefined,
  website: '',
  people: undefined,
}

export const newBusinessProfile = makeVar<INewBusinessProfile>({
  ...initialBusinessProfileData,
})

export const businessEditedId = makeVar<number>(-1)

export const deviceToken = makeVar<string>('')

export const redirectUrl = makeVar<string>('')
