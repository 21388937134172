import {
  CheckIcon,
  MinusSmIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/react/outline'
import clsx from 'clsx'

import { Availabilities } from 'components/profile/user_basic_details/availability_badge'

const AcceptingClientsIcon = ({
  className,
  availability,
  showExtraIcons = false,
}: {
  className?: string
  availability?: string
  showExtraIcons?: boolean
}) => {
  if (showExtraIcons) {
    return (
      <div className="relative px-3">
        <UserGroupIcon className={clsx(className, 'h-6 w-6')} />
        {showExtraIcons && (
          <>
            {availability === Availabilities.accepting && (
              <CheckIcon
                className={clsx(className, 'h-4 w-4 absolute -top-2 right-1')}
              />
            )}
            {availability === Availabilities.notAccepting && (
              <XIcon
                className={clsx(className, 'h-4 w-4 absolute -top-2 right-1')}
              />
            )}
            {availability === Availabilities.waitlist && (
              <MinusSmIcon
                className={clsx(className, 'h-4 w-4 absolute -top-2 right-1')}
              />
            )}
          </>
        )}
      </div>
    )
  }
  return <UserGroupIcon className={clsx(className, 'h-5 w-5')} />
}

export default AcceptingClientsIcon
