import { gql } from '@apollo/client'

export const Q_GET_OPPORTUNITY_REASONS = gql`
  query GetOpportunityReasons($search: String) {
    opportunity_reasons(where: { new_description: { _ilike: $search } }) {
      description
      id
      title
      new_description
    }
  }
`
