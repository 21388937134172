import { gql } from '@apollo/client'

export const M_FOLLOW_SOURCE = gql`
  mutation FollowSource($sourceId: Int!, $profileId: Int!) {
    insert_followed_sources(
      objects: { source_id: $sourceId, from_profile_id: $profileId }
    ) {
      affected_rows
    }
  }
`

export const M_UNFOLLOW_SOURCE = gql`
  mutation UnFollowSource($sourceId: Int!, $profileId: Int!) {
    delete_followed_sources(
      where: {
        from_profile_id: { _eq: $profileId }
        source_id: { _eq: $sourceId }
      }
    ) {
      affected_rows
    }
  }
`
