import clsx from 'clsx'
import { FunctionComponent } from 'react'

interface ILine extends React.HTMLAttributes<HTMLDivElement> {
  background?: 'page' | 'white'
}

const Line: FunctionComponent<ILine> = ({
  className,
  background = 'page',
  ...props
}: ILine) => {
  const backgroundIsWhite = background === 'white'
  return (
    <div
      {...props}
      className={clsx(
        'flex h-[1px] w-full',
        backgroundIsWhite ? 'bg-dark-border' : 'bg-light-border',
        className,
      )}
    />
  )
}

export default Line
