import { newReferralData } from 'lib/reactiveVariables'

import clsx from 'clsx'
import { FunctionComponent, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { NewReferralStep, Text, TextArea } from 'components/ui'

import { CreateReferralContext } from '../../step_controller/step_controller'
import StepThreeForm from './step_three_form'

interface IStepThree {
  className?: string
}

const StepThree: FunctionComponent<IStepThree> = () => {
  const textContent = {
    stepNumber: '3/6',
    stepDescription: 'Description',
    title: 'Describe yourself and your practice focus.',
    description:
      'This is how therapists with client referrals figure out who might be a good match by reading about your philosophy, treatment focus, experience and your background.',
  }

  const { goToPreviousStep, goToNextStep } = useContext(CreateReferralContext)

  const { referralDescription } = newReferralData()

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      description: referralDescription || '',
    },
  })
  const description = watch('description')
  const descriptionLength = description ? description.length : 0
  const maxDescriptionLength = 5000
  const minDescriptionLength = 100

  useEffect(() => {
    reset({
      description: referralDescription,
    })
  }, [reset, referralDescription])

  const handleContinue = ({ description }: { description: string }) => {
    const allData = newReferralData()
    newReferralData({
      ...allData,
      referralDescription: description,
    })
    goToNextStep()
  }

  return (
    <NewReferralStep
      stepNumber={textContent.stepNumber}
      stepDescription={textContent.stepDescription}
      title={textContent.title}
      description={textContent.description}
      formTitle="Include a detailed background about yourself"
      onNegative={goToPreviousStep}
      onPositive={handleSubmit(handleContinue)}
    >
      <section className="flex flex-col gap-4">
        <Controller
          control={control}
          name="description"
          rules={{
            required: 'You must provide a description.',
            validate: {
              description: (desc) =>
                desc.length >= minDescriptionLength ||
                `The description must have at least ${minDescriptionLength} characters.`,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <TextArea
              placeholder="Already have the description written up? Paste it here."
              background="white"
              rows={10}
              onChange={onChange}
              value={value}
              maxLength={maxDescriptionLength}
              error={errors.description?.message}
            />
          )}
        />
        <Text variant="body" className="flex justify-end">{`${
          maxDescriptionLength - descriptionLength
        } characters left`}</Text>
      </section>
    </NewReferralStep>
  )
}

export default StepThree
