import { FindCities, FindCities_locations } from 'operations/FindCities'
import { FindLocations_locations } from 'operations/FindLocations'

import { isNil } from 'ramda'

export const getBoxFormat = (
  locations: FindLocations_locations[] | undefined,
) => {
  if (!locations) return []
  return locations?.map((item) => getLocationFormat(item)) || []
}

export const getLocationFormat = (location: FindLocations_locations) => {
  const locationFormat = !isNil(location.city)
    ? `${location.city && `${location.city},`} ${location.state}` || ''
    : location.state || ''
  return {
    title: locationFormat,
    value: JSON.stringify(location),
  }
}

export const getOptionFormat = (item: string) => {
  return { title: item, value: item }
}

export const removeDuplicatedCities = (cities: FindCities_locations[]) => {
  if (cities.length > 0) {
    const citiesWithouType = [...cities].map((city) => city.city)
    return [...new Set(citiesWithouType)]
  }
  return []
}
