import { GetReferrals_opportunities } from 'operations/GetReferrals'

import { isBefore } from 'date-fns'
import { useRouter } from 'next/router'
import { useReducer } from 'react'

type State = {
  lastSeenDate: string
  isRead: boolean
}

type Action = {
  type: 'SET_LAST_SEEN_DATE' | 'READ'
  value: string | boolean
}

const initialState: State = {
  lastSeenDate: '',
  isRead: false,
}

const reducer = (state: State, action: Action) => {
  let newState = { ...state }
  switch (action.type) {
    case 'SET_LAST_SEEN_DATE':
      newState = { ...newState, lastSeenDate: action.value as string }
      return newState
    case 'READ':
      newState = { ...newState, isRead: action.value as boolean }
      return newState
    default:
      return state
  }
}

const useUnreadReferrals = () => {
  const [referralState, dispatch] = useReducer(reducer, initialState)
  const { pathname } = useRouter()

  const checkReadReferrals = (lastReferral: GetReferrals_opportunities) => {
    const isReadByUser = isBefore(
      new Date(referralState?.lastSeenDate),
      new Date(lastReferral?.created_at),
    )

    if (isReadByUser || referralState.lastSeenDate === '') {
      dispatch({ type: 'SET_LAST_SEEN_DATE', value: lastReferral?.created_at })
      dispatch({ type: 'READ', value: false })
      return
    }

    if (pathname.includes('opportunities')) {
      dispatch({ type: 'READ', value: true })
      return
    }
  }

  return {
    referralState,
    checkReadReferrals,
  }
}

export default useUnreadReferrals
