import { Q_FUZZY_FIND_PROFILES } from 'lib/queries/profiles'

import { FindProfiles, FindProfiles_profiles } from '@/operations/FindProfiles'
import { useLazyQuery } from '@apollo/client'
import { isEmpty } from 'ramda'
import { FunctionComponent } from 'react'
import { Mention, MentionsInput as ReactMentionsInput } from 'react-mentions'

import { Avatar } from 'components/ui'

interface IMentionInput {
  className?: string
  onChange?: (text: string) => void
  onClick?: () => void
  placeholder?: string
  rows?: number
  content?: string
  hasBorder?: boolean
  disabled?: boolean
  autoFocus?: boolean
  preSelectedProfiles?: Partial<FindProfiles_profiles>[]
}

const MentionsInput: FunctionComponent<IMentionInput> = ({
  className,
  onChange,
  onClick,
  content,
  hasBorder = true,
  preSelectedProfiles,
  ...props
}) => {
  const [getSuggestions] = useLazyQuery<FindProfiles>(Q_FUZZY_FIND_PROFILES, {
    fetchPolicy: 'network-only',
  })

  const handleChange = (text: string) => {
    if (onChange) {
      onChange(text)
    }
  }

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  const fetchSuggestions = async (query: string, callback: any) => {
    const { data: suggestions } = await getSuggestions({
      variables: {
        fullname: query,
      },
    })

    if (!suggestions) {
      // eslint-disable-next-line
      console.error('No suggestion where found')
      callback([])
      return
    }

    const preSelectedProfilesId = new Set(
      preSelectedProfiles?.map((profile) => profile.id),
    )
    const mappedSuggestions = suggestions.profiles
      .filter((profile) => !preSelectedProfilesId.has(profile.id))
      .map((suggestion) => ({
        display: `${suggestion.first_name} ${suggestion.last_name}`,
        id: `${suggestion.id}`,
        avatar: suggestion.avatar,
      }))
    const mappedPreSelectedProfiles =
      !isEmpty(preSelectedProfiles) && preSelectedProfiles
        ? preSelectedProfiles.map((profile) => ({
            display: `${profile.first_name} ${profile.last_name}`,
            id: `${profile.id}`,
            avatar: profile.avatar,
          }))
        : []

    callback([...mappedPreSelectedProfiles, ...mappedSuggestions])
  }

  return (
    <ReactMentionsInput
      onClick={handleClick}
      {...props}
      className={hasBorder ? 'mentions-input' : 'mentions-input-without-border'}
      style={{ width: '100%' }}
      value={content}
      onChange={({ target }) => handleChange(target.value)}
      allowSpaceInQuery
    >
      <Mention
        style={{ position: 'relative', zIndex: 10, top: 0, color: '#42337A' }}
        trigger="@"
        appendSpaceOnAdd
        renderSuggestion={(props: any) => {
          return (
            <div className="flex items-center p-1">
              <div>
                <Avatar
                  objectFit="cover"
                  layout="fill"
                  className="inline object-cover rounded-full"
                  profile={{ avatar: props.avatar }}
                  alt="Profile image"
                  size={8}
                />
              </div>
              {props.display}
            </div>
          )
        }}
        data={fetchSuggestions}
      />
    </ReactMentionsInput>
  )
}

export default MentionsInput
