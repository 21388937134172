import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

import { LinkifyWrapper } from 'components/common/hocs'

interface IText extends React.HTMLAttributes<HTMLParagraphElement> {
  size?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl'
  titleSize?: number
  weight?: 'bold' | 'semibold' | 'regular' | 'light' | 'extralight'
  variant?:
    | 'title'
    | 'heading'
    | 'subheading'
    | 'body'
    | 'small'
    | 'extra-small'
    | 'none'
  color?:
    | 'primary'
    | 'secondary'
    | 'gray'
    | 'gray-light'
    | 'gray-500'
    | 'black'
    | 'white'
    | 'fourth'
    | 'red'
  capitalize?: boolean
  uppercase?: boolean
  isLink?: boolean
  detectLink?: boolean
}

const Text: FunctionComponent<IText> = ({
  className,
  size,
  titleSize = 1,
  weight,
  capitalize = false,
  uppercase = false,
  isLink = false,
  variant = 'none',
  color,
  detectLink = true,
  children,
  ...props
}: IText) => {
  const isTitle = variant === 'title'
  const isHeading = variant === 'heading'
  const isSubheading = variant === 'subheading'
  const isBody = variant === 'body'
  const isSmall = variant === 'small'
  const isExtraSmall = variant === 'extra-small'
  const noVariant = variant === 'none'

  return (
    <p
      {...props}
      className={twMerge(
        [
          noVariant && `font-${weight} text-${size}`,
          isTitle && `text-xl  ${!weight && 'font-semibold'} text-title-light`,
          isHeading &&
            `text-lg ${!weight && 'font-semibold'} text-title-light leading-6`,
          isSubheading &&
            `text-base ${!weight && 'font-medium'} text-title-light`,
          isBody && `text-base ${!weight && 'font-extralight'} text-gray-700`,
          isSmall && `text-sm ${!weight && 'font-light'} text-text-small`,
          isExtraSmall && `text-xs ${!weight && 'font-light'} text-gray`,
          capitalize && 'capitalize',
          uppercase && 'uppercase',
          isLink && 'underline text-fourth',
          weight && `font-${weight}`,
          size && `text-${size}`,
          color && `text-${color}`,
        ],
        className,
      )}
    >
      {detectLink ? <LinkifyWrapper>{children}</LinkifyWrapper> : children}
    </p>
  )
}

export default Text
