import { Text } from '@/components/ui'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import NewTag from '../links/new_tag'

interface NavLinkProps extends LinkProps {
  parentRef?: React.LegacyRef<HTMLLIElement>
  className?: string
  isLink?: boolean
  isMobile?: boolean
  isChild?: boolean
  isHidden?: boolean
  isNew?: boolean
  regularMenuHeight?: boolean
  title: string
  subtitle?: string
  hasNotification?: boolean
  onClick?: () => void
}

function NavLink({
  href,
  as,
  className,
  isMobile,
  title,
  isNew = false,
  subtitle,
  parentRef,
  hasNotification,
  regularMenuHeight = true,
  isLink = true,
  isChild = false,
  isHidden = false,
  onClick,
  ...props
}: NavLinkProps) {
  const { asPath } = useRouter()
  const urlRootPath = asPath.toString().split('/')[1]
  const hrefRootPath = href.toString().split('/')[1]
  const isSelected = urlRootPath === hrefRootPath

  return (
    <li ref={parentRef} className={twMerge(isHidden && 'md:hidden')}>
      {isLink ? (
        <Link href={href} as={as} {...props} legacyBehavior>
          <a
            className={twMerge(
              'flex flex-col gap-1.5  items-start justify-center py-4 px-2 w-full transition duration-300 border-transparent border-l-2 group',
              isSelected && 'border-secondary',
              regularMenuHeight ? 'h-16' : 'h-12',
            )}
            onClick={onClick}
          >
            <p className="flex gap-2 items-center">
              <Text
                weight="semibold"
                className="text-sm text-black group-hover:text-[#7A8FCE]"
              >
                {title}
              </Text>
              {isNew && <NewTag />}
              {hasNotification && (
                <div className="w-2 h-2 align-middle rounded-full bg-fourth" />
              )}
            </p>

            {subtitle && (
              <Text className="text-sm font-extralight leading-none text-gray-500">
                {subtitle}
              </Text>
            )}
          </a>
        </Link>
      ) : (
        <>
          <a
            className={twMerge(
              'flex flex-col gap-1.5  items-start justify-center py-4 px-2 w-full transition duration-300 border-transparent border-l-2 group',
              isSelected && 'border-secondary',
              regularMenuHeight ? 'h-16' : 'h-12',
            )}
            onClick={onClick}
          >
            <p className="flex gap-2 items-center">
              <Text
                weight="semibold"
                className="text-sm text-black group-hover:text-[#7A8FCE]"
              >
                {title}
              </Text>
              {hasNotification && (
                <div className="w-2 h-2 align-middle rounded-full bg-fourth" />
              )}
            </p>

            {subtitle && (
              <Text className="text-sm font-extralight leading-none text-gray-500">
                {subtitle}
              </Text>
            )}
          </a>
          {hasNotification && (
            <div className="w-2 h-2 align-middle rounded-full bg-fourth" />
          )}
        </>
      )}
    </li>
  )
}

export default NavLink
