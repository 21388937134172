import { gql } from '@apollo/client'

import {
  BusinessProfile,
  Comments,
  CommonFields,
  LikedProfiles,
  ProfileFields,
} from './posts'

export const ProfileDataFragment = gql`
  fragment ProfileData on profiles {
    verified_at
    first_name
    last_name
    id
    referral_information
    availability
    modality
    created_at
    services
    user_status {
      is_online
    }
    owner {
      id
      roles
    }
    is_verified
    address
    owner_id
    office_info
    degree
    educations
    current_job_new
    avatar
    experiences
    is_ambassor
    onboarded
    username
    notifications_settings
    experiences_search(order_by: { end_year: desc, end_month: desc }) {
      title
      organization
    }
    connections_from_aggregate {
      aggregate {
        count(columns: accepted_at)
      }
    }
    connections_to_aggregate {
      aggregate {
        count(columns: accepted_at)
      }
    }
    connections_to(order_by: { created_at: desc }) {
      id
      created_at
      from_profile {
        avatar
        first_name
        id
        last_name
        license
        username
        degree
        experiences
        current_job_new
      }
    }
  }
`

export const Q_GET_PROFILE_BY_USERNAME = gql`
  ${ProfileDataFragment}
  query GetProfileByUsername($username: String!) {
    profiles(where: { username: { _eq: $username } }, limit: 1) {
      ...ProfileData
      research
      biography
      license
      header_url
      press
      contact_info
      office_hours
      services
      module_properties
    }
  }
`

export const Q_GET_PROFILE = gql`
  query GetProfileByOwnerId($ownerId: bpchar) {
    profiles(where: { owner_id: { _eq: $ownerId } }, limit: 1) {
      verified_at
      first_name
      last_name
      id
      referral_information
      availability
      modality
      user_status {
        is_online
      }
      created_at
      services
      owner {
        id
        roles
      }
      is_verified
      address
      owner_id
      office_info
      degree
      educations
      current_job_new
      avatar
      experiences
      research
      onboarded
      username
      notifications_settings
      is_ambassor
      experiences_search(order_by: { end_year: desc, end_month: desc }) {
        title
        organization
      }
      connections_from_aggregate {
        aggregate {
          count(columns: accepted_at)
        }
      }
      connections_to_aggregate {
        aggregate {
          count(columns: accepted_at)
        }
      }
      connections_to(order_by: { created_at: desc }) {
        id
        created_at
        from_profile {
          avatar
          first_name
          id
          last_name
          license
          username
          degree
          experiences
          current_job_new
        }
      }
    }
  }
`
export const Q_GET_CONNECTIONS_PROFILE = gql`
  query GetConnectionsByProfileOwnerId(
    $ownerId: bpchar
    $order_by: [connections_order_by!] = { created_at: desc }
  ) {
    profiles(where: { owner_id: { _eq: $ownerId } }, limit: 1) {
      first_name
      last_name
      id
      avatar
      username
      experiences
      current_job_new
      connections_to_aggregate {
        aggregate {
          count(columns: accepted_at)
        }
      }
      connections_to(order_by: $order_by) {
        id
        created_at
        from_profile {
          avatar
          first_name
          username
          id
          last_name
          license
          degree
          experiences
          current_job_new
        }
      }
    }
  }
`

export const Q_GET_FULL_PROFILE = gql`
  ${ProfileDataFragment}
  query GetFullProfile($profileId: Int) {
    profiles(where: { id: { _eq: $profileId } }, limit: 1) {
      ...ProfileData
      license
      contact_info
    }
  }
`

export const Q_GET_FULL_BUSINESS_PROFILE = gql`
  ${BusinessProfile}
  query GetFullBusinessProfile($profileId: Int) {
    business_profiles(where: { id: { _eq: $profileId } }, limit: 1) {
      ...BusinessProfile
    }
  }
`

export const U_PROFILES_PK = gql`
  mutation UpdateProfilesPk($_set: profiles_set_input, $profileId: Int!) {
    update_profiles_by_pk(pk_columns: { id: $profileId }, _set: $_set) {
      id
    }
  }
`

export const U_PROFILE_MODULE_PROPERTIES = gql`
  mutation UpdateProfilesModuleProperties(
    $profileId: Int!
    $moduleProperties: jsonb
  ) {
    update_profiles(
      where: { id: { _eq: $profileId } }
      _append: { module_properties: $moduleProperties }
    ) {
      affected_rows
    }
  }
`

export const Q_GET_NAME_BY_ID = gql`
  query GetNameById($profileId: Int!) {
    profiles(where: { id: { _eq: $profileId } }) {
      first_name
      last_name
      username
      avatar
    }
  }
`

export const Q_GET_SPECIALITIES = gql`
  query GetSpecialities {
    specialities_list(order_by: { name: asc }) {
      name
      id
    }
  }
`

export const Q_GET_INSURANCES = gql`
  query GetProfileInsurances {
    insurances(order_by: { name: asc }) {
      name
      id
    }
  }
`

export const Q_FIND_INSURANCES = gql`
  query FindInsurances($search: String!) {
    insurances(
      where: { name: { _ilike: $search } }

      order_by: { name: asc }
    ) {
      name
      id
    }
  }
`

export const U_PROFILE_REFERRAL_INFO = gql`
  mutation UpdateProfileReferralInfo(
    $profileId: Int!
    $referralInfo: jsonb
    $modality: String
  ) {
    update_profiles(
      where: { id: { _eq: $profileId } }
      _set: { referral_information: $referralInfo }
    ) {
      affected_rows
    }
    update_seeing_patients: update_profiles(
      where: { id: { _eq: $profileId } }
      _set: { modality: $modality }
    ) {
      affected_rows
    }
  }
`

export const U_PROFILE_ACCEPTING_CLIENTS = gql`
  mutation UpdateProfileAcceptingClients(
    $profileId: Int!
    $availability: String
  ) {
    update_profiles(
      where: { id: { _eq: $profileId } }
      _set: { availability: $availability }
    ) {
      affected_rows
    }
  }
`

export const Q_GET_USERNAME = gql`
  ${ProfileDataFragment}
  query GetUsername($username: String!) {
    profiles(where: { username: { _eq: $username } }) {
      ...ProfileData
    }
  }
`

export const Q_GET_RECENT_ACTIVITY = gql`
  ${CommonFields}
  ${ProfileFields}
  ${LikedProfiles}
  ${Comments}
  query GetRecentActivity($profileId: Int!) {
    referral_recommendations_out: opportunity_respondants_outsiders(
      limit: 3
      order_by: { created_at: desc }
      where: { recommender_id: { _eq: $profileId } }
    ) {
      created_at
      opportunity {
        id
        author_profile_id
        title
        content
        updated_at
        link_preview
        created_at
        author {
          ...ProfileFields
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
      }
    }
    referral_recommendations_in: opportunity_respondants(
      limit: 3
      order_by: { created_at: desc }
      where: { recommender_id: { _eq: $profileId } }
    ) {
      created_at
      opportunity {
        id
        author_profile_id
        title
        content
        updated_at
        link_preview
        created_at
        author {
          ...ProfileFields
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
      }
    }
    referral_comments: opportunity_comments(
      limit: 3
      order_by: { created_at: desc }
      where: { from_profile_id: { _eq: $profileId } }
    ) {
      created_at
      opportunity {
        id
        author_profile_id
        title
        content
        updated_at
        link_preview
        created_at
        author {
          ...ProfileFields
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
      }
    }
    referrals: opportunities(
      limit: 3
      order_by: { created_at: desc }
      where: {
        _and: [
          { author_profile_id: { _eq: $profileId } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      id
      author_profile_id
      title
      content
      updated_at
      link_preview
      created_at
      author {
        ...ProfileFields
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
    comments: post_comments(
      limit: 3
      order_by: { created_at: desc }
      where: {
        _and: [
          { from_profile_id: { _eq: $profileId } }
          { is_anonymous: { _eq: false } }
        ]
      }
    ) {
      created_at
      post {
        ...CommonFields
        author {
          ...ProfileFields
        }
        liked_profiles {
          ...LikedProfiles
        }
        liked_profiles_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
      }
    }
    likes: liked_posts(
      limit: 3
      order_by: { created_at: desc }
      where: {
        _or: [
          {
            _and: [
              { from_profile_id: { _eq: $profileId } }
              { to_post: { is_anonymous: { _eq: false } } }
            ]
          }
          {
            _and: [
              { from_profile_id: { _eq: $profileId } }
              { to_post: { is_anonymous: { _eq: true } } }
              { to_post: { author_profile_id: { _neq: $profileId } } }
            ]
          }
        ]
      }
    ) {
      created_at
      post: to_post {
        ...CommonFields
        author {
          ...ProfileFields
        }
        liked_profiles {
          ...LikedProfiles
        }
        liked_profiles_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
      }
    }
    posts(
      limit: 3
      order_by: { created_at: desc }
      where: {
        _and: [
          { author_profile_id: { _eq: $profileId } }
          { is_deleted: { _eq: false } }
          { is_anonymous: { _eq: false } }
        ]
      }
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      liked_profiles {
        ...LikedProfiles
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
  }
`

export const Q_FUZZY_FIND_PROFILES = gql`
  query FindProfiles($fullname: String!) {
    totalProfiles: profiles_search_aggregate(args: { search: $fullname }) {
      aggregate {
        count(columns: id)
      }
    }
    profiles: profiles_search(
      args: { search: $fullname }
      order_by: { first_name: asc }
      limit: 10
    ) {
      first_name
      id
      last_name
      username
      degree
      experiences
      educations
      office_info
      avatar
      owner {
        id
        roles
      }
      current_job_new
      experiences_search(order_by: { end_year: desc, end_month: desc }) {
        title
        organization
      }
    }
  }
`

export const Q_GET_CLIENT_PROFILES = gql`
  query FindClientProfiles($fullname: String!) {
    totalProfiles: profiles_search_aggregate(args: { search: $fullname }) {
      aggregate {
        count(columns: id)
      }
    }
    profiles: profiles_search(
      args: { search: $fullname }
      order_by: { first_name: asc }
      limit: 10
      where: { owner: { roles: { _contains: "client" } } }
    ) {
      first_name
      last_name
      id
      created_at
      owner_id
      avatar
      username
      notifications_settings
      contact_info
      owner {
        id
        roles
      }
    }
  }
`
