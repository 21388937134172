import { GetBusinessUsername } from 'operations/GetBusinessUsername'
import {
  GetProfileByOwnerId,
  GetProfileByOwnerId_profiles,
} from 'operations/GetProfileByOwnerId'

import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client'
import { createContext } from 'react'

interface IProfileContext {
  profile: GetProfileByOwnerId_profiles | undefined
  fullName: string
  myAvatar: any
  id: number | undefined
  noProfile: boolean
  ownerError: ApolloError | undefined
  ownerLoading: boolean
  ownerRefecth: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetProfileByOwnerId>>
  businessData: GetBusinessUsername | undefined
  businessLoading: boolean
}

const ProfileContext = createContext<null | IProfileContext>(null)

export default ProfileContext
