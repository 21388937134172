import {
  ArrowLeftIcon,
  CogIcon,
  OfficeBuildingIcon,
  UserCircleIcon,
} from '@heroicons/react/outline'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Avatar, Menu } from 'components/ui'

const ProfileSection = ({
  profileData,
  goToMyProfile,
  goToAccountSettings,
  goToCreateBusinessPage,
  signOut,
  pictureSize = 8,
}: {
  profileData: any
  goToMyProfile: () => void
  goToCreateBusinessPage: () => void
  goToAccountSettings: () => void
  signOut: () => void
  pictureSize?: number
}) => {
  return (
    <Menu
      button={
        <Avatar
          profile={profileData}
          layout="fill"
          className="object-cover rounded-full"
          alt="Profile image"
          size={pictureSize}
          withBorder={false}
        />
      }
      options={[
        {
          label: 'My Profile',
          onClick: goToMyProfile,
          icon: (active: boolean) => (
            <UserCircleIcon
              className={twMerge(active && 'stroke-white', 'h-4 w-4')}
            />
          ),
        },
        {
          label: 'Settings',
          onClick: goToAccountSettings,
          icon: (active: boolean) => (
            <CogIcon className={twMerge(active && 'stroke-white', 'h-4 w-4')} />
          ),
        },
        {
          label: 'Sign Out',
          onClick: signOut,
          icon: (active: boolean) => (
            <ArrowLeftIcon
              className={twMerge(active && 'stroke-white', 'h-4 w-4')}
            />
          ),
        },
      ]}
    />
  )
}

export default ProfileSection
