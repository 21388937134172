import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { FunctionComponent } from 'react'

import CheckCircleIcon from 'components/common/check_circle_icon'
import CircleIcon from 'components/common/circle_icon'
import { InfoLabel, Text } from 'components/ui'

export interface IOptionCard {
  id: any
  title: string
  description: string
  icon: JSX.Element
}

interface IRadioGroupCards {
  label?: string
  options: IOptionCard[]
  horizontal?: boolean
  optionRoundedShape?: boolean
  value: any
  textSize?: 'xs' | 'sm' | 'md' | 'lg'
  tooltip?: string
  onSelect: (option: any) => void
  className?: string
  labelClassName?: string
  defaultValue?: string
  variant?: 'rounded' | 'square'
  colNumber?: number
  radioGroupContainerClassName?: string
}

const RadioGroupCards: FunctionComponent<IRadioGroupCards> = ({
  label,
  className,
  labelClassName,
  options,
  horizontal = false,
  variant = 'rounded',
  value,
  textSize = 'sm',
  tooltip,
  onSelect,
  defaultValue,
  colNumber = 2,
  radioGroupContainerClassName = 'md:grid-cols-2',
  ...props
}: IRadioGroupCards) => {
  return (
    <div
      {...props}
      className={clsx(className, 'flex justify-center items-center mb-0')}
    >
      <div className="flex-1">
        <HeadlessRadioGroup
          value={value}
          onChange={onSelect}
          defaultValue={defaultValue}
        >
          {label && (
            <HeadlessRadioGroup.Label
              className={clsx('mb-4 flex', labelClassName)}
            >
              {label}
            </HeadlessRadioGroup.Label>
          )}
          <div
            className={clsx(
              radioGroupContainerClassName,
              `grid grid-cols-1 gap-4 lg:grid-cols-${colNumber}`,
            )}
          >
            {options.map(({ id, title, description, icon }) => (
              <HeadlessRadioGroup.Option
                value={id}
                key={title}
                className="col-span-1 row-span-1 cursor-pointer"
              >
                {({ checked }) => (
                  <div
                    className={clsx(
                      'flex relative border rounded-md py-5 h-full border-2',
                      checked
                        ? 'bg-[#F1F1FA] border-fourth'
                        : 'bg-white border-gray-300',
                    )}
                  >
                    {checked ? (
                      <CheckCircleIcon className="absolute top-4 right-4 w-5 h-5 fill-blue-badge-check stroke-white" />
                    ) : (
                      <CircleIcon className="absolute top-4 right-4 w-5 h-5 fill-gray-mid" />
                    )}
                    <div className="flex flex-col gap-2 px-9 pb-5">
                      {icon}
                      <div className="flex flex-col gap-3">
                        <Text size={'lg'} weight="bold" className="text-black">
                          {title}
                        </Text>
                        <Text
                          variant={'body'}
                          size={'md'}
                          className="leading-normal text-gray"
                        >
                          {description}
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
              </HeadlessRadioGroup.Option>
            ))}
          </div>
        </HeadlessRadioGroup>
      </div>
      {tooltip && (
        <div className="absolute -right-7">
          <InfoLabel tooltip={tooltip} />
        </div>
      )}
    </div>
  )
}

export default RadioGroupCards
