import { useRouter } from 'next/router'
import { useContext } from 'react'

import useFullSearch from '../../hooks/useFullSearch'
import { SearchBoxContext } from '../../utils/context'
import BusinessProfileCard from './business_profile_card'

const BusinessProfileResults = ({ hit }: { hit: any }) => {
  const router = useRouter()
  const { hideResults } = useContext(SearchBoxContext)
  const goToProfile = () => {
    hideResults()
    router.push(`/page/org/${hit.organization_username}`)
  }
  return (
    <div key={hit.id} onClick={goToProfile}>
      <BusinessProfileCard hit={hit} />
    </div>
  )
}
export default BusinessProfileResults
