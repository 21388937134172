import { FindCities_locations } from 'operations/FindCities'

export const getOptionFormat = (item: string) => {
  return { title: item, value: item }
}

export const removeDuplicatedCities = (cities: FindCities_locations[]) => {
  if (cities.length > 0) {
    const citiesWithouType = [...cities].map((city) => city.city)
    return [...new Set(citiesWithouType)]
  }
  return []
}
