import {
  MessageGroupList,
  MessageGroupList_message_group,
} from 'operations/MessageGroupList'

import { max as Datemax } from 'date-fns'
import { isNil } from 'ramda'

import { hasUnreadMessages } from 'utils/messages/messages'

import { activeProfileOnChat } from '../types/types'

export const getMostRecentUnreadMessage = ({
  conversationListData,
  myProfile,
}: {
  conversationListData: MessageGroupList
  myProfile: activeProfileOnChat
}) => {
  return Datemax(
    conversationListData?.message_group?.map(
      (conversation: MessageGroupList_message_group) => {
        const message = conversation.messages?.[0]
        const chatOwnerProfileId = !isNil(message.business_profile)
          ? message.business_profile?.id
          : message.profile?.id

        const isSentByMe = chatOwnerProfileId == myProfile?.id
        const isUnread =
          !isSentByMe &&
          hasUnreadMessages(
            message?.created_at,
            conversation.profile_seen?.[0]?.last_seen,
          )

        return isUnread ? new Date(message?.created_at) : new Date(0)
      },
    ),
  )
}

export const getUnreadConversationCount = ({
  conversationListData,
  myProfile,
}: {
  conversationListData: MessageGroupList | undefined
  myProfile: activeProfileOnChat
}) => {
  if (isNil(conversationListData)) return 0
  return conversationListData?.message_group?.filter(
    (conversation: MessageGroupList_message_group) => {
      const message = conversation.messages?.[0]
      const chatOwnerProfileId = !isNil(message.business_profile)
        ? message.business_profile?.id
        : message.profile?.id

      const isSentByMe = chatOwnerProfileId == myProfile?.id
      const unreadMessagesCount =
        conversation.unread_messages?.[0]?.unread_messages_count || 0

      const isUnread = !isSentByMe && unreadMessagesCount > 0

      return isUnread
    },
  ).length
}
