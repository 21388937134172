import { Text } from '@/components/ui'

function NewTag() {
  return (
    <div className="flex justify-center items-center py-0.5 px-2 rounded bg-secondary">
      <Text className="text-white text-[10px]">New</Text>
    </div>
  )
}

export default NewTag
