import clsx from 'clsx'
import { FunctionComponent } from 'react'

interface Props {
  className?: string
}

const CloseIcon: FunctionComponent<Props> = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(className, 'h-5 w-5')}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
)

export default CloseIcon
