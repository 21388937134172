import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Text } from '../text'

interface IPostButton extends React.HTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode
  label?: string
  disabled?: boolean
}

const PostButton = ({ icon, label, className, ...props }: IPostButton) => {
  return (
    <button
      {...props}
      className={twMerge(
        className,
        'flex gap-2 py-1 px-3 rounded items-center text-sm hover:cursor-pointer text-primary fill-primary hover:text-primary-hover hover:fill-primary-hover hover:bg-background',
      )}
    >
      {icon && icon}
      <Text variant="small" color="primary" className="hidden sm:flex">
        {label}
      </Text>
    </button>
  )
}

export default PostButton
