import { activeProfileOnChat } from '../types/types'

export const getProfileProperties = (profile: activeProfileOnChat) => {
  if (profile?.__typename == 'profiles') {
    return {
      fullName: `${profile?.first_name} ${profile?.last_name}`,
      firstName: `${profile?.first_name}`,
      avatar: profile?.avatar,
      link: `/be/${profile?.username}`,
    }
  }

  return {
    fullName: profile?.name,
    firstName: profile?.name,
    avatar: profile?.logo,
    link: `/page/org/${profile?.username}`,
  }
}
