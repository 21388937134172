import { InformationCircleIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { FunctionComponent } from 'react'
import ReactTooltip from 'react-tooltip'

import { Text } from 'components/ui'

interface IInfoLabel extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  tooltip?: string
}

const InfoLabel: FunctionComponent<IInfoLabel> = ({
  label,
  tooltip,
  className,
  ...props
}: IInfoLabel) => {
  return (
    <div {...props} className={clsx(className, 'group flex items-center')}>
      <div data-tip data-for={tooltip}>
        <InformationCircleIcon
          className="w-5 h-5"
          fill="#000000"
          stroke="#d2d2d2"
        />
      </div>
      {false && (
        <span className="flex absolute flex-1 py-1 px-2 max-w-3xl text-sm text-gray-100 whitespace-nowrap bg-gray-500 rounded-md opacity-0 transition-opacity translate-x-6 group-hover:opacity-100">
          {tooltip}
        </span>
      )}
      <ReactTooltip id={tooltip} place="right" effect="solid">
        {tooltip}
      </ReactTooltip>
      {label && !tooltip && (
        <Text size="xs" className="self-center ml-1 font-light text-gray-500">
          {label}
        </Text>
      )}
    </div>
  )
}

export default InfoLabel
