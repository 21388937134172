import { gql, useQuery } from '@apollo/client'

const Q_BEEBOT = gql`
  query GetBeebot {
    profiles(where: { username: { _eq: "beebot" } }) {
      id
    }
  }
`

export default function useBeebot() {
  const { data } = useQuery(Q_BEEBOT)

  return {
    id:
      data && data.profiles && data?.profiles.length > 0
        ? Number(data?.profiles[0].id)
        : undefined,
  }
}
