import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

interface IBottomBar extends React.HTMLAttributes<HTMLDivElement> {}

const BottomBar: FunctionComponent<IBottomBar> = ({
  className,
  children,
  ...props
}: IBottomBar) => {
  return (
    <footer
      {...props}
      className={twMerge(
        'fixed bottom-0 inset-x-0 p-4 flex bg-white w-full border-t border-light-border shadow-2xl shadow-black',
        className,
      )}
    >
      {children}
    </footer>
  )
}

export default BottomBar
