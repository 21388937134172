import { Transition } from '@headlessui/react'
import Link from 'next/link'
import { Fragment, useState } from 'react'

import { Box, Button } from 'components/ui'

import NavTab from '../nav_tab'

interface ResourcesLinkProps {
  href: string
  passHref: boolean | undefined
}

function ResourcesLink({ href, passHref }: ResourcesLinkProps) {
  const [isOverResources, setIsOverResources] = useState<boolean>(false)

  return (
    <div
      className="flex relative text-black cursor-pointer"
      onMouseOver={() => setIsOverResources(true)}
      onMouseOut={() => setIsOverResources(false)}
    >
      <NavTab
        isChild
        isLink={false}
        href={href}
        passHref={passHref}
        title="Resources"
        subtitle="For your Practice"
      />

      <Transition
        as={Fragment}
        show={isOverResources}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <span className="absolute left-5 z-10 w-48 bg-white rounded-md divide-y divide-gray-100 ring-black shadow-md focus:outline-none bottom-[-224px] ring-opacit-5">
          <Box className="flex flex-col p-0 -mt-4 text-black">
            <Link href={'/news/category/0'} passHref legacyBehavior>
              <Button className="w-full text-sm font-semibold text-left rounded md:p-3 hover:bg-gray-100 disabled:opacity-85 disabled:bg-gray-mid">
                News
              </Button>
            </Link>
            <Link href={'/resources'} passHref legacyBehavior>
              <Button className="w-full text-sm font-semibold text-left rounded md:p-3 hover:bg-gray-100 disabled:opacity-85 disabled:bg-gray-mid">
                Business Resources
              </Button>
            </Link>
            <a
              href="https://www.belongly.com/mental-health-professional-jobs/"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="w-full text-sm font-semibold text-left rounded md:p-3 hover:bg-gray-100 disabled:opacity-85 disabled:bg-gray-mid">
                Jobs
              </Button>
            </a>
            <Link
              href={'/opportunities/media_opportunities'}
              passHref
              legacyBehavior
            >
              <Button className="w-full text-sm font-semibold text-left rounded md:p-3 hover:bg-gray-100 disabled:opacity-85 disabled:bg-gray-mid">
                Media Opportunities
              </Button>
            </Link>
            <a
              href="https://www.belongly.com/publish/"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="w-full text-sm font-semibold text-left rounded md:p-3 hover:bg-gray-100 disabled:opacity-85 disabled:bg-gray-mid">
                Publish an Article
              </Button>
            </a>
          </Box>
        </span>
      </Transition>
    </div>
  )
}

export default ResourcesLink
