import { GetBusinessId, GetBusinessIdVariables } from 'operations/GetBusinessId'

import { Q_GET_BUSINESS_ID } from 'lib/queries/business_profiles'

import { useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'

const PROFILE_TYPE = 'profileType'

const usePostAs = ({
  profileType,
  username,
}: {
  profileType?: 'business' | 'profile'
  username?: string
}) => {
  const router = useRouter()
  const [getBusinessId] = useLazyQuery<GetBusinessId, GetBusinessIdVariables>(
    Q_GET_BUSINESS_ID,
  )

  const redirect = (url: string) => {
    router.push(url)
    sessionStorage.setItem(
      PROFILE_TYPE,
      JSON.stringify({ profileType, username }),
    )
  }

  const getProfileType = async () => {
    const storage = sessionStorage.getItem(PROFILE_TYPE)

    if (!storage) return {}

    const data = JSON.parse(storage) as {
      profileType: 'business' | 'profile'
      username: string
    }

    const { data: businessData } = await getBusinessId({
      variables: { username: data.username },
    })

    if (!businessData) {
      return { profileType: data.profileType, username: data.username }
    }

    return {
      profileType: data.profileType,
      username: data.username,
      businessId: businessData.business_profiles[0].id,
    }
  }

  const removeProfileType = () => {
    sessionStorage.removeItem(PROFILE_TYPE)
  }

  return {
    redirect,
    getProfileType,
    removeProfileType,
  }
}

export default usePostAs
