import { LinkPreviewData } from 'operations/LinkPreviewData'

import { gql, useQuery } from '@apollo/client'

const Q_GET_PREVIEW = gql`
  query LinkPreviewData($url: String!) {
    link_preview(data: { url: $url }) {
      url
      title
      absolute_image
      description
    }
  }
`

const useLinkPreview = (url?: string) => {
  const { data, loading, error } = useQuery<LinkPreviewData>(Q_GET_PREVIEW, {
    variables: { url },
    skip: !url,
  })

  if (loading || error || !data?.link_preview) {
    return null
  }

  return data.link_preview
}
export default useLinkPreview
