import { algoliaClient, algoliaProfilesIndex } from 'algolia.config'
import { InstantSearch } from 'react-instantsearch'

import SearchBox from './search_box'

interface HeaderSearchProps {
  className?: string
}

const HeaderSearch = ({ className }: HeaderSearchProps) => {
  const searchClient = algoliaClient
  const { indexName } = algoliaProfilesIndex

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <SearchBox className={className} />
    </InstantSearch>
  )
}

export default HeaderSearch
