import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from as aFrom,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { useAuth, useUser } from '@clerk/nextjs'
import { getMainDefinition } from 'apollo-utilities'
import { createClient } from 'graphql-ws'
import { FunctionComponent, useState } from 'react'

export const useApolloClient = (
  getTokenHeaders: () => object | Promise<object>,
) => {
  const uri =
    process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT ||
    'http://localhost:8080/v1/graphql'
  const wsuri = uri.replace(new RegExp('^http'), 'ws')

  const authMiddleware = setContext(async (req, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(await getTokenHeaders()),
      },
    }
  })

  const httpLink = new HttpLink({
    uri: uri, // Server URL (must be absolute)
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 10,
      retryIf: (error, _operation) => !!error,
    },
  })

  let wsLink = process.browser
    ? new GraphQLWsLink(
        createClient({
          url: wsuri, // Server URL (must be absolute)
          connectionParams: async () => {
            return {
              headers: await getTokenHeaders(),
            }
          },
          retryAttempts: 10,
        }),
      )
    : null

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = wsLink
    ? split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query)
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          )
        },
        wsLink,
        aFrom([retryLink, authMiddleware, httpLink]),
      )
    : aFrom([retryLink, authMiddleware, httpLink])

  const apolloClient = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV !== 'production',
  })

  return apolloClient
}

export const ApolloProviderWrapper: FunctionComponent = ({ children }) => {
  const { getToken } = useAuth()
  const { user } = useUser()

  const template =
    (user?.publicMetadata as any)?.roles?.length > 0
      ? 'hasura_with_roles'
      : 'hasura'

  const getTokenHeaders = async () => {
    if (!user) {
      return {}
    }
    const token = await getToken({ template })
    return {
      authorization: `Bearer ${token}`,
    }
  }
  const apolloClient = useApolloClient(getTokenHeaders)

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default ApolloProviderWrapper
