import clsx from 'clsx'
import { FunctionComponent } from 'react'

interface Props {
  className?: string
}

const DropDownArrowIcon: FunctionComponent<Props> = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx('h-5 w-5', className)}
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path d="M8 11L3 6h10z" fillRule="evenodd" />
  </svg>
)

export default DropDownArrowIcon
