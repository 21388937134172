import { newReferralData } from 'lib/reactiveVariables'

import { FunctionComponent, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import SearchIcon from 'components/common/search_icon'
import { Combobox, NewReferralStep, Text } from 'components/ui'
import { Option } from 'components/ui/combobox/combobox'

import { mapValuesAsOptions } from 'utils/opportunities/referrals/referrals'

import useUpsertReferral, {
  StepType,
} from '../../../edit_referral/hooks/useUpsertReferral'
import useDataFetching from '../../hooks/useDataFetching'
import { CreateReferralContext } from '../../step_controller/step_controller'

export interface StepFiveSeekingClientsData {
  _type: 'StepFiveSeekingClients'
  interventions: any[]
  issues: any[]
  languages: any[]
  mainSpecialties: any[]
}
interface IStepFive extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const StepFive: FunctionComponent<IStepFive> = () => {
  const textContent = {
    stepNumber: '5/6',
    stepDescription: 'Skills & Language',
    title:
      'What are the main skills, languages and treatment methods you use in your practice?',
    description:
      "We'll use this section to help match you with referrals withat have identified as needing these skill to help the client.",
  }

  const { goToPreviousStep, goToNextStep } = useContext(CreateReferralContext)

  const {
    getInterventions,
    getIssues,
    getLanguages,
    languages: languagesData,
    specialties,
    treatments,
  } = useDataFetching()

  const [clicked, setClicked] = useState(false)

  const { referralSeekingInfo } = newReferralData()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      languages: referralSeekingInfo.languages.map(mapValuesAsOptions),
      mainSpecialties:
        referralSeekingInfo.mainSpecialties.map(mapValuesAsOptions),
      interventions: referralSeekingInfo.interventions.map(mapValuesAsOptions),
      issues: referralSeekingInfo.issues.map(mapValuesAsOptions),
    },
  })
  const { upsertReferral } = useUpsertReferral()

  const languages =
    languagesData?.map((item) => ({
      title: item.name,
      value: item.name,
    })) || []

  const interventionsOptions =
    treatments?.map((item) => ({
      title: item.name,
      value: item.name,
    })) || []

  const issuesOptions =
    specialties?.map((item) => ({
      title: item.name,
      value: item.name,
    })) || []

  const handleContinue = async ({
    interventions,
    issues,
    languages,
    mainSpecialties,
  }: {
    interventions: Option[]
    issues: Option[]
    languages: Option[]
    mainSpecialties: Option[]
  }) => {
    const stepFiveFormData = {
      interventions: interventions.map((item) => item.value),
      issues: issues.map((item) => item.value),
      languages: languages.map((item) => item.value),
      mainSpecialties: mainSpecialties.map((item) => item.value),
    }

    upsertReferral({
      ...stepFiveFormData,
      _type: StepType.STEP_FIVE_SEEKING_CLIENTS,
    })

    goToNextStep()
  }

  const handleClick = async () => {
    if (!clicked) {
      setClicked(true)
      await handleSubmit(handleContinue)()
    }
  }

  return (
    <NewReferralStep
      stepNumber={textContent.stepNumber}
      stepDescription={textContent.stepDescription}
      title={textContent.title}
      description={textContent.description}
      formTitle="What are the other treatment interventions that you use in your practice?"
      onPositive={handleClick}
      onNegative={goToPreviousStep}
      isPositiveButtonDisabled={clicked}
    >
      <section className="flex flex-col gap-8">
        <div className="flex flex-col order-4 gap-3">
          <Text size="xl" weight="bold">
            What are the other treatment interventions that you use in your
            practice?
          </Text>
          <Controller
            control={control}
            name="interventions"
            render={({ field: { onChange, value } }) => (
              <Combobox
                icon={<SearchIcon />}
                chipVariant="primary"
                options={interventionsOptions}
                selectedItem={value}
                onSelectItem={(selected) => {
                  if (Array.isArray(selected) && selected.length <= 5)
                    onChange(selected)
                }}
                multiple
                background="white"
                hasInnerChips
                onChange={(e) => {
                  getInterventions({
                    variables: {
                      search: `%${e.target.value.replace(/ +/g, '%')}%`,
                    },
                  })
                }}
                placeholder="Select up to 5 treatments you are specialized on"
              />
            )}
          />
        </div>
        <div className="flex flex-col order-3 gap-3">
          <Text size="xl" weight="bold">
            What are the other issues that you treat in your practice?
          </Text>
          <Controller
            control={control}
            name="issues"
            render={({ field: { onChange, value } }) => (
              <Combobox
                icon={<SearchIcon />}
                chipVariant="primary"
                options={issuesOptions}
                selectedItem={value}
                onSelectItem={(selected) => {
                  if (Array.isArray(selected) && selected.length <= 5)
                    onChange(selected)
                }}
                multiple
                hasInnerChips
                background="white"
                onChange={(e) => {
                  getIssues({
                    variables: {
                      search: `%${e.target.value.replace(/ +/g, '%')}%`,
                    },
                  })
                }}
                placeholder="Select up to 5 specialties"
              />
            )}
          />
        </div>
        <div className="flex flex-col order-2 gap-3">
          <Text size="xl" weight="bold">
            What are the issues or interventions that you specialize in?
          </Text>
          <Controller
            control={control}
            name="mainSpecialties"
            render={({ field: { onChange, value } }) => (
              <Combobox
                icon={<SearchIcon />}
                chipVariant="primary"
                options={[...interventionsOptions, ...issuesOptions]}
                selectedItem={value}
                onSelectItem={(selected) => {
                  if (Array.isArray(selected) && selected.length <= 5)
                    onChange(selected)
                }}
                multiple
                hasInnerChips
                background="white"
                onChange={(e) => {
                  getIssues({
                    variables: {
                      search: `%${e.target.value.replace(/ +/g, '%')}%`,
                    },
                  })
                  getInterventions({
                    variables: {
                      search: `%${e.target.value.replace(/ +/g, '%')}%`,
                    },
                  })
                }}
                placeholder="Choose up to 5..."
              />
            )}
          />
        </div>
        <div className="flex flex-col order-1 gap-3">
          <Text size="xl" weight="bold">
            Do you see clients in any other languages besides English?
          </Text>
          <Controller
            control={control}
            name="languages"
            render={({ field: { onChange, value } }) => (
              <Combobox
                icon={<SearchIcon />}
                chipVariant="primary"
                options={languages}
                selectedItem={value}
                onSelectItem={(selected) => {
                  if (Array.isArray(selected)) onChange(selected)
                }}
                multiple
                background="white"
                onChange={(e) =>
                  getLanguages({
                    variables: {
                      search: `%${e.target.value.replace(/ +/g, '%')}%`,
                    },
                  })
                }
                placeholder="Spanish, French..."
              />
            )}
          />
          <Text size="xs" variant="body">
            Sometimes patients feel more comfortable in their native language
          </Text>
        </div>
      </section>
    </NewReferralStep>
  )
}

export default StepFive
