import { GetOutsiders } from 'operations/GetOutsiders'
import { GetReferralList } from 'operations/GetReferralList'
import {
  InsertReferralListSelectedProfiles,
  InsertReferralListSelectedProfilesVariables,
} from 'operations/InsertReferralListSelectedProfiles'
import {
  UpdateReferralListSelectedProfilesOutsiders,
  UpdateReferralListSelectedProfilesOutsidersVariables,
} from 'operations/UpdateReferralListSelectedProfilesOutsiders'

import {
  M_INSERT_REFERRAL_LIST_SELECTED_PROFILES,
  M_UPDATE_REFERRAL_LIST_SELECTED_PROFILES_OUTSIDERS,
  U_REFERRAL_LIST_BODY,
  U_REFERRAL_PROFILES_NOTES,
} from 'lib/mutations/opportunities'
import { Q_GET_OUTSIDERS, Q_GET_REFERRAL_LIST } from 'lib/queries/opportunities'

import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { useReducer, useState } from 'react'

import { referralListReducer } from 'components/opportunities/referrals/reducers/referral_list_reducer'

const initialState = { body: '', profilesNotes: [], respondantsOutsiders: [] }

const useReferralLists = () => {
  const {
    query: { listId, state },
  } = useRouter()
  const [isEditing, setIsEditing] = useState(state === 'new')
  const [listState, dispatch] = useReducer(referralListReducer, initialState)
  const { data: referralListData } = useQuery<GetReferralList>(
    Q_GET_REFERRAL_LIST,
    {
      variables: {
        listId: Number(listId),
      },
      skip: !listId,
    },
  )

  const { data: outsidersData } = useQuery<GetOutsiders>(Q_GET_OUTSIDERS, {
    variables: {
      opportunity_id: referralListData?.referral_list[0].opportunity_id,
    },
  })

  const [changeReferralListBody] = useMutation(U_REFERRAL_LIST_BODY, {
    refetchQueries: [{ query: Q_GET_REFERRAL_LIST }, 'GetReferralList'],
  })

  const [changeReferralProfilesNotes] = useMutation(U_REFERRAL_PROFILES_NOTES, {
    refetchQueries: [{ query: Q_GET_REFERRAL_LIST }, 'GetReferralList'],
  })

  const [selectRespondants] = useMutation<
    InsertReferralListSelectedProfiles,
    InsertReferralListSelectedProfilesVariables
  >(M_INSERT_REFERRAL_LIST_SELECTED_PROFILES, {
    refetchQueries: [{ query: Q_GET_REFERRAL_LIST }, 'GetReferralList'],
  })

  const [updateRespondantStatus] = useMutation<
    UpdateReferralListSelectedProfilesOutsiders,
    UpdateReferralListSelectedProfilesOutsidersVariables
  >(M_UPDATE_REFERRAL_LIST_SELECTED_PROFILES_OUTSIDERS, {
    refetchQueries: [{ query: Q_GET_REFERRAL_LIST }, 'GetReferralList'],
  })

  const profilesOutsiders = referralListData?.referral_list_profile.filter(
    (profile) => profile.respondant_outsider_id !== null,
  )

  const isOutsiderSelected = (id: number) =>
    profilesOutsiders?.some(
      (outsider) => outsider.respondant_outsider_id === id && outsider.status,
    )

  const outsiderHasToBeAdded = () => {
    let response = false
    listState.respondantsOutsiders.map((respondant) => {
      const item = profilesOutsiders?.find(
        ({ respondant_outsider_id }) =>
          respondant_outsider_id === respondant.id,
      )

      if (!item) {
        response = true
      }
    })

    return response
  }

  const outsiderHasToBeActivated = () => {
    let response = false
    listState.respondantsOutsiders.map((respondant) => {
      const item = profilesOutsiders?.find(
        ({ respondant_outsider_id }) =>
          respondant_outsider_id === respondant.id,
      )

      if (item) {
        response = true
      }
    })

    return response
  }

  const handleActivateOutsider = (id: number) => {
    if (outsiderHasToBeActivated()) {
      updateRespondantStatus({
        variables: {
          selectedProfileId: id,
          status: true,
        },
      })
    }
  }

  const handleAddOutsider = (id: number) => {
    if (outsiderHasToBeAdded()) {
      selectRespondants({
        variables: {
          selectedProfiles: [
            {
              referral_list_id: Number(listId),
              respondant_outsider_id: id,
              status: true,
            },
          ],
        },
      })
    }
  }

  const handleDeactivateOutsider = () => {
    profilesOutsiders?.map((outsider) => {
      const item = listState.respondantsOutsiders.find(
        ({ id }) => id === outsider?.respondant_outsider?.id,
      )

      if (!item) {
        updateRespondantStatus({
          variables: {
            selectedProfileId: Number(outsider?.respondant_outsider_id),
            status: false,
          },
        })
      }
    })
  }

  const handleChangeListBody = () => {
    changeReferralListBody({
      variables: {
        bodyText: listState.body,
        listId,
      },
    })
  }

  const handleChangeListNotes = () => {
    listState.profilesNotes.forEach((note) => {
      changeReferralProfilesNotes({
        variables: {
          note: note.value,
          profileId: note.profileId,
        },
      })
    })
  }

  return {
    profilesOutsiders,
    isEditing,
    isOutsiderSelected,
    listState,
    referralListData,
    dispatch,
    setIsEditing,
    outsiders: outsidersData?.opportunity_respondants_outsiders,
    handleChangeListBody,
    handleChangeListNotes,
    handleActivateOutsider,
    handleAddOutsider,
    handleDeactivateOutsider,
  }
}

export default useReferralLists
