import { PermissionWrapper } from '@/components/common/hocs'
import NotificationBellIcon from '@/components/common/notification_bell'
import { Button } from '@/components/ui'
import { ChatIcon, UserAddIcon } from '@heroicons/react/solid'
import { twMerge } from 'tailwind-merge'

interface ActionsProps {
  className?: string
  setOpenInvitationModal: (state: boolean) => void
  goToMessagingPage: () => void
}
function Actions({
  className,
  setOpenInvitationModal,
  goToMessagingPage,
}: ActionsProps) {
  return (
    <div className={twMerge('hidden md:flex md:items-center', className)}>
      <div className="flex justify-items-center items-center h-full text-xs">
        <PermissionWrapper
          behavior="Disable"
          action="InviteColleagues"
          tooltip="You don't have the permissions to invite colleagues yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly."
        >
          <button
            className="inline-block relative"
            onClick={() => setOpenInvitationModal(true)}
          >
            <div className="w-8 h-8 bg-[#D9D9D9] rounded-full flex justify-center items-center">
              <UserAddIcon className="w-[22px] h-[22px]" />
            </div>
          </button>
        </PermissionWrapper>
      </div>
      <div className="flex justify-items-center items-center h-full text-xs">
        <NotificationBellIcon size={8} />
      </div>
      <div className="w-8 h-8 bg-[#D9D9D9] rounded-full flex justify-center items-center">
        <Button onClick={goToMessagingPage}>
          <ChatIcon className="w-[22px] h-[22px]" />
        </Button>
      </div>
    </div>
  )
}

export default Actions
