import { gql } from '@apollo/client'

export const M_MESSAGE_INSERT_MESSAGE_GROUP = gql`
  mutation MessageGroupCreate(
    $messageGroupProfiles: [message_group_profiles_insert_input!]!
    $fromProfileId: Int!
  ) {
    insert_message_group_one(
      object: {
        profiles: { data: $messageGroupProfiles }
        owner_profile_id: $fromProfileId
      }
    ) {
      id
    }
  }
`

export const M_MESSAGE_INSERT_PROFILE_TO_BUSINESS_MESSAGE_GROUP = gql`
  mutation MessageGroupProfileToBusinessCreate(
    $messageGroupProfiles: [message_group_profiles_insert_input!]!
    $businessProfileId: Int!
    $ownerProfileId: Int!
  ) {
    insert_message_group_one(
      object: {
        profiles: { data: $messageGroupProfiles }
        business_profile_id: $businessProfileId
        owner_profile_id: $ownerProfileId
      }
    ) {
      id
    }
  }
`
export const M_UPDATE_LAST_SEEN_MSG_GROUP = gql`
  mutation UpdateMessageGroupProfile(
    $last_seen: timestamptz!
    $from_profile_id: Int!
    $message_group_id: Int!
  ) {
    update_message_group_profiles(
      where: {
        _and: {
          from_profile_id: { _eq: $from_profile_id }
          message_group_id: { _eq: $message_group_id }
        }
      }
      _set: { last_seen: $last_seen }
    ) {
      affected_rows
    }
  }
`
export const M_UPDATE_BUSINESS_LAST_SEEN_MSG_GROUP = gql`
  mutation UpdateBusinessMessageGroupProfile(
    $last_seen: timestamptz!
    $from_profile_id: Int!
    $message_group_id: Int!
  ) {
    update_message_group_profiles(
      where: {
        _and: {
          from_business_profile_id: { _eq: $from_profile_id }
          message_group_id: { _eq: $message_group_id }
        }
      }
      _set: { last_seen: $last_seen }
    ) {
      affected_rows
    }
  }
`

export const M_INSERT_MESSAGE = gql`
  mutation InsertMessage($message: messages_insert_input!) {
    insert_messages_one(object: $message) {
      id
    }
  }
`

export const M_INCREMENT_BUSINESS_MESSAGE_COUNT = gql`
  mutation IncrementBusinessUnreadMessageCount(
    $profileId: Int!
    $messageGroupId: Int!
  ) {
    update_message_group_profiles(
      _inc: { unread_messages_count: 1 }
      where: {
        _and: {
          message_group_id: { _eq: $messageGroupId }
          from_business_profile_id: { _eq: $profileId }
        }
      }
    ) {
      affected_rows
    }
  }
`
export const M_INCREMENT_REGULAR_PROFILE_MESSAGE_COUNT = gql`
  mutation IncrementRegularProfileUnreadMessageCount(
    $profileId: Int!
    $messageGroupId: Int!
  ) {
    update_message_group_profiles(
      _inc: { unread_messages_count: 1 }
      where: {
        _and: {
          message_group_id: { _eq: $messageGroupId }
          from_profile_id: { _eq: $profileId }
        }
      }
    ) {
      affected_rows
    }
  }
`

export const M_RESET_REGULAR_PROFILE_MESSAGE_COUNT = gql`
  mutation ResetRegularProfileUnreadMessageCount(
    $profileId: Int!
    $messageGroupId: Int!
  ) {
    update_message_group_profiles(
      where: {
        _and: {
          message_group_id: { _eq: $messageGroupId }
          from_profile_id: { _eq: $profileId }
        }
      }
      _set: { unread_messages_count: 0 }
    ) {
      affected_rows
    }
  }
`

export const M_RESET_BUSINESS_MESSAGE_COUNT = gql`
  mutation ResetBusinessUnreadMessageCount(
    $profileId: Int!
    $messageGroupId: Int!
  ) {
    update_message_group_profiles(
      where: {
        _and: {
          message_group_id: { _eq: $messageGroupId }
          from_business_profile_id: { _eq: $profileId }
        }
      }
      _set: { unread_messages_count: 0 }
    ) {
      affected_rows
    }
  }
`
