import { GetProfileByUsername_profiles } from 'operations/GetProfileByUsername'

import Image, { ImageProps } from 'next/image'
import { isEmpty } from 'ramda'
import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

import { ImageCrop } from 'components/upload'

export const cropUrl = (data: ImageCrop) => {
  if (!data?.image) {
    return null
  }
  if (!data.crop) {
    return data.image
  }
  let crop = data.crop

  if (!(crop.x && crop.y && crop.width && crop.height)) {
    return data.image
  }
  const params = new URLSearchParams()
  params.append('url', data.image)
  params.append('w', data.crop.width.toString())
  params.append('h', data.crop.height.toString())
  params.append('x', data.crop.x.toString())
  params.append('y', data.crop.y.toString())

  const str = window.location.origin + '/api/crop?' + params.toString()

  return str
}

export interface AvatarProps {
  profile?: Pick<GetProfileByUsername_profiles, 'avatar'>
  avatar?: any
  size?: number
  borderColor?: 'gray' | 'white' | 'light-gray'
  withBorder?: boolean
  alt?: string
}

const Avatar: FunctionComponent<
  AvatarProps & Omit<ImageProps, 'src' | 'alt'>
> = ({
  profile,
  avatar,
  size = 16,
  borderColor = 'light-gray',
  withBorder = true,
  alt = 'Profile image',
  ...props
}) => {
  const profileAvatar = () => {
    if (profile && profile.hasOwnProperty('avatar')) {
      return profile.avatar
    }
  }
  const url = cropUrl(avatar ?? profileAvatar()) || ''

  const borderIsWhite = borderColor === 'white'
  const borderIsGray = borderColor === 'gray'
  const borderIsLightGray = borderColor === 'light-gray'

  return (
    <div
      className={twMerge(
        'relative mx-auto rounded-full bg-white overflow-hidden flex-none',
        `w-${size} h-${size}`,
        withBorder && 'border-4',
        borderIsWhite && 'border-white',
        borderIsGray && 'border-profile-border',
        borderIsLightGray && 'border-gray-200',
      )}
    >
      {isEmpty(url) ? (
        <svg
          height={props?.height || '100%'}
          width={props?.width || '100%'}
          className={props.className || ''}
          strokeWidth="1.5"
          viewBox="1 1 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <Image {...props} src={url} alt="Profile image" />
      )}
    </div>
  )
}

export default Avatar
