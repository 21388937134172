import clsx from 'clsx'
import { FunctionComponent } from 'react'

interface Props {
  className?: string
  size?: number
  fill?: string
}

const ChevronUpIcon: FunctionComponent<Props> = ({
  className = '',
  size = 5,
  fill = 'currentColor',
}) => (
  <svg
    className={clsx(`h-${size} w-${size}`, className)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    data-supported-dps="16x16"
    fill={clsx(fill)}
    width="16"
    height="16"
    focusable="false"
  >
    <path d="M15 11L8 6.39 1 11V8.61L8 4l7 4.61z" />
  </svg>
)

export default ChevronUpIcon
