import {
  BusinessProfile,
  Comments,
  CommonFields,
  LikedProfiles,
  News,
  ProfileFields,
} from 'lib/queries/posts'

import { gql, useMutation } from '@apollo/client'

export const M_POST_LIKE = gql`
  ${ProfileFields}
  ${LikedProfiles}
  ${CommonFields}
  ${News}
  mutation PostLike($postId: Int!, $fromProfileId: Int!) {
    insert_liked_posts_one(
      object: { from_profile_id: $fromProfileId, post_id: $postId }
    ) {
      ...LikedProfiles
      post: to_post {
        ...CommonFields
        author {
          ...ProfileFields
        }
        liked_profiles {
          ...LikedProfiles
        }
        liked_profiles_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        news {
          ...NewsFields
        }
      }
    }
  }
`
export const M_POST_UNLIKE = gql`
  ${ProfileFields}
  ${LikedProfiles}
  ${CommonFields}
  ${News}
  mutation PostUnLike($postId: Int!, $fromProfileId: Int!) {
    delete_liked_posts_by_pk(
      from_profile_id: $fromProfileId
      post_id: $postId
    ) {
      ...LikedProfiles
      post: to_post {
        ...CommonFields
        author {
          ...ProfileFields
        }
        liked_profiles {
          ...LikedProfiles
        }
        liked_profiles_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        news {
          ...NewsFields
        }
      }
    }
  }
`

export const M_DELETE_POST = gql`
  mutation DeletePost($postId: Int!) {
    update_posts(where: { id: { _eq: $postId } }, _set: { is_deleted: true }) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const M_UPDATE_POST = gql`
  ${CommonFields}
  ${News}
  ${ProfileFields}
  ${LikedProfiles}
  ${BusinessProfile}
  mutation UpdatePost($postId: Int!, $set: posts_set_input) {
    update_posts(where: { id: { _eq: $postId } }, _set: $set) {
      returning {
        ...CommonFields
        author {
          ...ProfileFields
        }
        business {
          ...BusinessProfile
        }
        liked_profiles {
          ...LikedProfiles
        }
        news {
          ...NewsFields
        }
        liked_profiles_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
      }
      affected_rows
    }
  }
`

export const M_INSERT_POST_SHARE = gql`
  mutation InsertPostShare($postId: Int!, $profileId: Int!) {
    insert_post_shares_one(
      object: { post_id: $postId, profile_id: $profileId }
    ) {
      id
    }
  }
`

export const M_INSERT_POST = gql`
  ${CommonFields}
  ${News}
  ${ProfileFields}
  ${LikedProfiles}
  ${Comments}
  ${BusinessProfile}
  mutation InsertOnePost(
    $author_profile_id: Int
    $business_id: Int
    $category: post_categories_enum!
    $content: String!
    $title: String!
    $link_preview: jsonb
    $images: jsonb
    $is_anonymous: Boolean!
  ) {
    insert_posts_one(
      object: {
        author_profile_id: $author_profile_id
        business_id: $business_id
        category: $category
        title: $title
        content: $content
        link_preview: $link_preview
        images: $images
        is_anonymous: $is_anonymous
      }
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      business {
        ...BusinessProfile
      }
      liked_profiles {
        ...LikedProfiles
      }
      news {
        ...NewsFields
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
  }
`
