import { UPDATE_USER_STATUS } from '@/lib/mutations/profile_views'
import { useMutation } from '@apollo/client'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import BelonglyLogo from '../header/belongly_logo'
import useClientProfile from '../hooks/useClientProfile'
import ClientSection from './client_section'

const ClientHeader = () => {
  const navRef = useRef<HTMLElement>(null)
  const { pathname, back } = useRouter()
  const { profile } = useClientProfile()

  const [updateStatus] = useMutation(UPDATE_USER_STATUS)

  const setUserStatus = (status: boolean) => {
    updateStatus({
      variables: {
        profileId: profile?.id,
        status,
      },
    })
  }

  useEffect(() => {
    if (profile) {
      window.onfocus = () => setUserStatus(true)
      window.onblur = () => setUserStatus(false)
    }

    return () => {
      window.onfocus = null
      window.onblur = null
    }
    // eslint-disable-next-line
  }, [profile])

  useEffect(() => {
    if (navRef.current) {
      window?.localStorage?.setItem(
        'navbarHeight',
        JSON.stringify(navRef?.current?.offsetHeight),
      )
    }
  }, [])

  const mainPages = ['/therapists-matches']

  const showGoBack = !mainPages.includes(pathname)

  return (
    <div>
      <nav
        className="flex sticky top-0 z-30 items-center w-full h-16 bg-white border-b border-light-border"
        ref={navRef}
      >
        <div className="w-full">
          <div className="mx-auto md:px-5 lg:px-20 max-w-8xl">
            <div className="flex flex-row justify-evenly items-center md:hidden">
              {showGoBack ? (
                <ChevronLeftIcon
                  className="w-6 h-6 stroke-primary"
                  onClick={back}
                />
              ) : (
                <BelonglyLogo />
              )}
              <ClientSection />
            </div>
            <div className="hidden justify-between md:flex md:items-center">
              <div className="flex justify-between items-center space-x-20 w-full h-full">
                <BelonglyLogo />
                <ClientSection />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default ClientHeader
