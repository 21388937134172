import { isBusinessProfile, switchProfileInfo } from 'lib/reactiveVariables'

import { useReactiveVar } from '@apollo/client'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { isNotEmptyArray } from 'utils'

import Conversation from 'components/chat/conversation_window/conversation'
import useHandleActiveConversations from 'components/hooks/useHandleActiveConversations'
import useProfile from 'components/hooks/useProfile'

import { ConversationList } from './conversation_list'
import useBusinessProfileById from './hooks/useBusinessProfileById'
import useChatContext from './hooks/useChatContext'

class MessageEvents {
  messageGroupRequest: Array<{
    messageGroupId: number
  }> = []

  constructor() {
    makeAutoObservable(this)
  }

  openMessageGroup(messageGroupId: number) {
    this.messageGroupRequest.push({ messageGroupId })
  }

  getMessageGroup() {
    return this.messageGroupRequest[0]
  }

  clearMessageGroup() {
    this.messageGroupRequest = []
  }
}

export const myMessageEvents = new MessageEvents()
interface MessengerProps {
  messageEvents: MessageEvents
}

function Chat({ messageEvents }: MessengerProps) {
  const { asPath: url } = useRouter()
  const isOnMessagingPage = url?.includes('/messaging')
  const { activeProfileOnChat: profile, activeConversations = [] } =
    useChatContext()

  const { activateConversation = () => {} } = useHandleActiveConversations()

  if (messageEvents.messageGroupRequest.length) {
    activateConversation({
      conversationId: messageEvents.getMessageGroup().messageGroupId,
    })

    messageEvents.clearMessageGroup()
  }

  if (isOnMessagingPage) return null

  return (
    <aside className="hidden md:flex md:overflow-visible md:fixed md:right-0 md:bottom-0 md:z-50 md:flex-row-reverse md:flex-nowrap md:items-end md:h-0">
      <ConversationList />
      {profile?.id &&
        activeConversations &&
        isNotEmptyArray(activeConversations) &&
        activeConversations.map((conversation, index) => {
          return <Conversation key={index} conversation={conversation} />
        })}
    </aside>
  )
}
export default observer(Chat)
