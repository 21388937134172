import { GetBusinessProfilesSubscription_business_profiles } from 'operations/GetBusinessProfilesSubscription'

import { ArrowSmLeftIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { isEmpty, isNil } from 'ramda'
import { Dispatch, SetStateAction } from 'react'
import { twMerge } from 'tailwind-merge'
import { getFullName } from 'utils'

import ChevronDownIcon from 'components/common/chevron_down_icon'
import ChevronUpIcon from 'components/common/chevron_up_icon'
import DropDownArrowIcon from 'components/common/drop_down_arrow_icon'
import { useProfile } from 'components/hooks'
import { NotificationNumber, Text } from 'components/ui'

import ProfileImage from '../conversation_window/profile_image'
import useChatContext from '../hooks/useChatContext'
import useMyBusinessProfiles from '../hooks/useMyBusinessProfiles'
import { getBusinessProfileUnreadMessagesCount } from '../utils/business_profiles'
import Menu from './menu'

interface HeaderProps {
  title: string
  avatar: any
  isPage?: boolean
  isOnline: boolean
  isExpanded: boolean
  messageGroupId?: number
  unreadConversationCount?: number
  setIsExpanded: Dispatch<SetStateAction<boolean>>
  handleChangeProfile: (businessProfileId?: number) => void
}

function Header({
  title,
  isPage = false,
  avatar,
  isOnline,
  isExpanded,
  messageGroupId,
  unreadConversationCount = 0,
  setIsExpanded,
  handleChangeProfile,
}: HeaderProps) {
  const { profile: ownerProfile } = useProfile()
  const { businessProfiles, hasBusinessProfilesNotifications } =
    useMyBusinessProfiles()
  const { isBusinessProfileActive = false, hasUnreadMessages = false } =
    useChatContext()

  const hasBusinessProfiles =
    !isNil(businessProfiles) && !isEmpty(businessProfiles)

  const profileOptions =
    hasBusinessProfiles && !isBusinessProfileActive
      ? businessProfiles.map((businessProfile) => {
          return {
            label: businessProfile?.name || '',
            onClick: () => handleChangeProfile(businessProfile.id),
            unreadMessagesCount:
              getBusinessProfileUnreadMessagesCount(businessProfile),
          }
        })
      : [
          {
            label: ownerProfile ? getFullName(ownerProfile) : '',
            onClick: () => handleChangeProfile(),
            unreadMessagesCount: 0,
          },
        ]

  return (
    <header
      className={clsx(
        isPage && 'border',
        'flex justify-between items-center py-2 px-2 min-h-[48px] max-w-full relative rounded-t-md bg-clip-padding border-b border-gray-light cursor-pointer',
        hasUnreadMessages ? 'bg-primary' : 'bg-white',
      )}
    >
      <div
        className={`${
          isExpanded ? 'max-w-[75%]' : 'max-w-[70%]'
        } flex flex-grow`}
      >
        <div
          className={twMerge(
            'flex items-center px-1 max-w-full rounded-md hover:bg-gray-light',
            hasBusinessProfilesNotifications && 'bg-primary hover:bg-secondary',
          )}
        >
          {avatar &&
            (hasBusinessProfiles ? (
              <Menu
                options={profileOptions}
                direction="right"
                button={
                  <div className="flex items-center">
                    <ProfileImage avatar={avatar} isOnline={isOnline} />
                    <div>
                      <DropDownArrowIcon
                        className={twMerge(
                          hasBusinessProfilesNotifications &&
                            'animate-bounce fill-white',
                        )}
                      />
                    </div>
                  </div>
                }
              />
            ) : (
              <div className="flex items-center">
                <ProfileImage avatar={avatar} isOnline={isOnline} />
              </div>
            ))}
        </div>
      </div>
      <div
        className="flex gap-1 justify-between items-center w-full"
        onClick={(e) => {
          e.stopPropagation()
          setIsExpanded((prev) => !prev)
        }}
      >
        <div className="flex gap-1 items-center pl-2">
          <Text
            variant="subheading"
            weight="bold"
            className="flex justify-self-start"
            capitalize
          >
            {title}
          </Text>
          {unreadConversationCount > 0 && (
            <NotificationNumber
              notificationCount={unreadConversationCount}
              color="red"
            />
          )}
        </div>

        <div
          className="flex justify-center items-center ml-4 w-12 h-12 rounded-full hover:bg-gray-light"
          style={{
            position: 'relative',
            width: '32px',
            height: '32px',
          }}
        >
          {isExpanded ? (
            <ChevronDownIcon
              className={clsx(
                hasUnreadMessages && 'stroke-white hover:stroke-purple-main',
              )}
            />
          ) : (
            <ChevronUpIcon
              className={clsx(
                hasUnreadMessages && 'fill-white hover:fill-purple-main',
              )}
            />
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
