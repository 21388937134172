import { gql, useMutation } from '@apollo/client'
import { useUser } from '@clerk/clerk-react'
import { SignedIn } from '@clerk/nextjs'
import clsx from 'clsx'
import { ProfileProvider } from 'context'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import Chat, { myMessageEvents } from 'components/chat/chat'
import Intercom from 'components/marketing/intercom'

import { ClientSurveysProvider } from '../context/surveys'
import ApolloProviderWrapper from '../lib/apollo'
import main from '../styles/Main.module.css'
import { ChatProvider } from './chat/context'
import ClientHeader from './client_header'
import Header from './header'
import NotFound from './not_found'

const CLAIM_KEY = 'claim'
export const publicPages = [
  '/',
  '/sign-in/[[...index]]',
  '/sign-up/[[...index]]',
  '/claim/[[...index]]',
  '/register',
  '/client-register',
  '/invitation/[token]',
  '/clients-questionnaire',
  '/matching_algorithm',
  '/clients-questionnaire-email-submit',
  '/resource-registration',
  '/client-survey',
  '/messaging',
  '/be/[username]',
]

export const clientPages = ['/therapists-matches', '/client-survey']

const M_SYNC_USER = gql`
  mutation UserSync($userId: String!) {
    user_sync(userId: $userId) {
      success
    }
  }
`

const M_CLAIM_PROFILE = gql`
  mutation ClaimProfile($claim: String!) {
    claim_profile(claim: $claim) {
      success
    }
  }
`

function SyncUser({ isOnboarding }: { isOnboarding: boolean }) {
  const router = useRouter()
  const { user } = useUser()

  const [syncUser] = useMutation(M_SYNC_USER, {
    errorPolicy: 'ignore',
  })

  const [claimProfile] = useMutation(M_CLAIM_PROFILE, {
    onCompleted: (data) => {
      if (data?.claim_profile?.success) {
        localStorage.removeItem(CLAIM_KEY)
        if (isOnboarding) {
          window.location.pathname = '/onboarding'
          return
        }
        window.location.pathname = '/home'
      }
    },
  })

  useEffect(() => {
    const syncAndClaim = async () => {
      if (user) {
        await syncUser({ variables: { userId: user?.id } })
        const claim = localStorage.getItem(CLAIM_KEY)
        if (claim) {
          await claimProfile({ variables: { claim } })
        }
      }
    }
    syncAndClaim()
  }, [syncUser, user, claimProfile])

  return null
}

function Layout({
  children,
  isWithoutHeader,
  isWithoutBorder,
}: {
  children?: React.ReactNode
  isWithoutHeader?: boolean
  isWithoutBorder?: boolean
}) {
  const { pathname } = useRouter()
  const showIntercom = !pathname.includes('onboarding')

  return (
    <ApolloProviderWrapper>
      {showIntercom && <Intercom />}
      <SyncUser isOnboarding={isWithoutHeader ?? false} />
      <Head>
        <title>Belongly: Better Together</title>
      </Head>
      {isWithoutHeader ? (
        <SignedIn>
          <div className="h-screen lg:mx-auto bg-background">
            <div
              className={clsx(
                !isWithoutBorder && main['layout-container'],
                'h-full',
              )}
            >
              {children}
            </div>
          </div>
        </SignedIn>
      ) : (
        <div className="lg:mx-auto bg-background min-h-[100vh]">
          <SignedIn>
            <Header />
            {/*<!-- blank div under header */}
          </SignedIn>
          <ChatProvider>
            <div
              className={twMerge(
                !isWithoutBorder && main['layout-container'],
                !isWithoutBorder && 'md:px-8 py-12 md:py-20 md:p-20',
                'inset-x-0 min-h-full',
              )}
            >
              {children}
            </div>

            <SignedIn>
              <Chat messageEvents={myMessageEvents} />
            </SignedIn>
          </ChatProvider>
        </div>
      )}
    </ApolloProviderWrapper>
  )
}

export default function Wrapper({
  children,
  isWithoutHeader,
  isWithoutBorder,
}: {
  children?: React.ReactNode
  isWithoutHeader?: boolean
  isWithoutBorder?: boolean
}) {
  const { user, isLoaded } = useUser()
  const router = useRouter()

  if (!publicPages.includes(router.pathname) && !user && isLoaded) {
    router.push(`/sign-in?redirectURL=${router.asPath}`)
    return null
  }

  const isClientRole = (
    user?.publicMetadata?.['roles'] as Array<string>
  )?.includes('client')
  const isATherapistPage = !clientPages.includes(router.asPath)
  const isAPublicPage =
    publicPages.includes(router.asPath) || publicPages.includes(router.pathname)

  if (isClientRole && isAPublicPage) {
    return <ClientLayout>{children}</ClientLayout>
  }

  if (isClientRole && (router.asPath === '/home' || router.asPath === '/')) {
    router.push('/therapists-matches')
    return null
  }

  if (user && isClientRole && isATherapistPage && !isAPublicPage) {
    return <NotFound isClient />
  }

  return (
    <ApolloProviderWrapper>
      <ProfileProvider>
        <ClientSurveysProvider>
          <Layout
            isWithoutBorder={isWithoutBorder}
            isWithoutHeader={isWithoutHeader}
          >
            {children}
          </Layout>
        </ClientSurveysProvider>
      </ProfileProvider>
    </ApolloProviderWrapper>
  )
}

type ClientLayoutType = {
  isWithoutHeader?: boolean
  isWithoutBorder?: boolean
  children: any
}

export function ClientLayout({
  isWithoutHeader,
  isWithoutBorder,
  children,
}: ClientLayoutType) {
  const router = useRouter()
  const { user, isLoaded } = useUser()

  if (!publicPages.includes(router.pathname) && !user && isLoaded) {
    router.push(`/sign-in?redirectURL=${router.asPath}`)
    return null
  }

  const isClientRole = (
    user?.publicMetadata?.['roles'] as Array<string>
  )?.includes('client')
  const isAClientPage = clientPages.includes(router.asPath)
  const isAPublicPage = publicPages.includes(router.asPath)

  if (user && !isClientRole && isAClientPage && !isAPublicPage) {
    return <NotFound />
  }

  return (
    <ApolloProviderWrapper>
      <SyncUser isOnboarding={false} />
      <ProfileProvider>
        <Head>
          <title>Belongly: Better Together</title>
        </Head>
        {isWithoutHeader ? (
          <SignedIn>
            <div className="h-screen lg:mx-auto bg-background">
              <div
                className={clsx(
                  !isWithoutBorder && main['layout-container'],
                  'h-full',
                )}
              >
                {children}
              </div>
            </div>
          </SignedIn>
        ) : (
          <div className="lg:mx-auto bg-background min-h-[100vh]">
            <SignedIn>
              <ClientHeader />
              <ChatProvider>
                <div className="h-screen lg:mx-auto bg-background">
                  <div
                    className={twMerge(
                      !isWithoutBorder && main['layout-container'],
                      !isWithoutBorder && 'md:px-8 py-12 md:py-20 md:p-20',
                      'inset-x-0 min-h-full',
                    )}
                  >
                    {children}
                  </div>
                </div>
              </ChatProvider>
            </SignedIn>
          </div>
        )}
      </ProfileProvider>
    </ApolloProviderWrapper>
  )
}
