import { FindLocations } from 'operations/FindLocations'
import {
  FindSpecialities,
  FindSpecialitiesVariables,
} from 'operations/FindSpecialities'
import { GetAllAgeGroups } from 'operations/GetAllAgeGroups'
import { GetAllTherapyForms } from 'operations/GetAllTherapyForms'
import { GetInsurances } from 'operations/GetInsurances'
import { GetOpportunitiesLanguages } from 'operations/GetOpportunitiesLanguages'
import {
  GetOrientations,
  GetOrientationsVariables,
} from 'operations/GetOrientations'
import { GetTherapyForms } from 'operations/GetTherapyForms'
import { GetTreatments, GetTreatmentsVariables } from 'operations/GetTreatments'

import {
  Q_FIND_SPECIALITIES,
  Q_GET_INSURANCES,
  Q_GET_ORIENTATIONS,
  Q_GET_TREATMENTS,
} from 'lib/queries/onboarding'
import {
  Q_GET_ALL_AGE_GROUPS,
  Q_GET_ALL_THERAPY_FORMS,
  Q_GET_LANGUAGES,
  Q_GET_THERAPY_FORMS,
} from 'lib/queries/opportunities'

import { Q_FUZZY_FIND_LOCATIONS } from '@/lib/queries/locations'
import { Q_GET_OPPORTUNITY_REASONS } from '@/lib/queries/opportunity_reasons'
import { FuzzyFindLocations } from '@/operations/FuzzyFindLocations'
import { GetOpportunityReasons } from '@/operations/GetOpportunityReasons'
import { useLazyQuery, useQuery } from '@apollo/client'

const useDataFetching = () => {
  const [getLanguages, { data: languagesData }] =
    useLazyQuery<GetOpportunitiesLanguages>(Q_GET_LANGUAGES)

  const [getInterventions, { data: interventionsData }] = useLazyQuery<
    GetTreatments,
    GetTreatmentsVariables
  >(Q_GET_TREATMENTS) // treatments

  const [getIssues, { data: issuesData }] = useLazyQuery<
    FindSpecialities,
    FindSpecialitiesVariables
  >(Q_FIND_SPECIALITIES) // specialties

  const [getLocations, { data: locationsData }] =
    useLazyQuery<FuzzyFindLocations>(Q_FUZZY_FIND_LOCATIONS)

  const [getInsurances, { data: insurancesData }] =
    useLazyQuery<GetInsurances>(Q_GET_INSURANCES)

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [getTherapyForms, { data: therapyFormsData }] =
    useLazyQuery<GetTherapyForms>(Q_GET_THERAPY_FORMS)

  const { data: allTherapyFormsData } = useQuery<GetAllTherapyForms>(
    Q_GET_ALL_THERAPY_FORMS,
  )

  const { data: ageGroupsData } =
    useQuery<GetAllAgeGroups>(Q_GET_ALL_AGE_GROUPS)

  const [getOrientations, { data: orientationsData }] = useLazyQuery<
    GetOrientations,
    GetOrientationsVariables
  >(Q_GET_ORIENTATIONS)

  const [getOpportunityReasons, { data: opportunityReasons }] =
    useLazyQuery<GetOpportunityReasons>(Q_GET_OPPORTUNITY_REASONS)

  return {
    getLanguages,
    getInterventions,
    getIssues,
    getInsurances,
    getTherapyForms,
    getOrientations,
    getLocations,
    getOpportunityReasons,
    allTherapyFormsData,
    locationsData,
    insurancesData,
    therapyFormsData,
    ageGroupsData,
    orientationsData,
    languages: languagesData?.languages,
    treatments: interventionsData?.treatments,
    specialties: issuesData?.specialities_list,
    opportunityReasons,
  }
}

export default useDataFetching
