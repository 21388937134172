import { Text } from '@/components/ui'

import NavLink from '../nav_link'

function ResourceMenu({ onClose }: { onClose: () => void }) {
  return (
    <ul className="flex flex-col pl-4 divide-y divide-gray-400">
      <li className="border-l border-gray-400">
        <NavLink
          href="/news/category/0"
          passHref
          isMobile
          isChild
          onClick={onClose}
          title="News"
          regularMenuHeight={false}
        />
      </li>
      <li className="border-l border-gray-400">
        <NavLink
          href="/resources"
          passHref
          isMobile
          isChild
          onClick={onClose}
          title="Business Resources"
          regularMenuHeight={false}
        />
      </li>
      <li className="border-l border-gray-400">
        <NavLink
          href="https://www.belongly.com/mental-health-professional-jobs/"
          passHref
          isMobile
          isChild
          onClick={onClose}
          title="Jobs"
          regularMenuHeight={false}
        />
      </li>
      <li className="border-l border-gray-400">
        <NavLink
          href="/opportunities/media_opportunities"
          passHref
          isMobile
          isChild
          onClick={onClose}
          title="Media Opportunities"
          regularMenuHeight={false}
        />
      </li>
      <li className="border-l border-gray-400">
        <NavLink
          href="https://www.belongly.com/publish/"
          passHref
          isMobile
          isChild
          onClick={onClose}
          title="Publish an Article"
          regularMenuHeight={false}
        />
      </li>
    </ul>
  )
}

export default ResourceMenu
