import { isEmpty, isNil } from 'ramda'

import { activeConversations } from '../types/types'

export const getActiveConversationById = ({
  id,
  activeConversations,
}: {
  id: number
  activeConversations: activeConversations
}) => {
  if (isNil(activeConversations)) return

  return activeConversations.find((conversation) => conversation.id === id)
}

export const removeActiveConversationById = ({
  id,
  activeConversations,
}: {
  id: number
  activeConversations: activeConversations
}) => {
  if (isNil(activeConversations) || isEmpty(activeConversations)) return []

  const foundConversation = activeConversations?.find(
    (conversation) => conversation.id === id,
  )

  if (!isNil(foundConversation)) {
    const activeConversationsCopy = [...activeConversations]
    const conversationIndex = activeConversations.indexOf(foundConversation)
    activeConversationsCopy.splice(conversationIndex, 1)
    return activeConversationsCopy
  }
  return []
}

export const addToActiveConversations = ({
  id,
  activeConversations = [],
  maxActiveConversations = 3,
}: {
  id: number
  activeConversations: activeConversations
  maxActiveConversations?: number
}) => {
  if (isNil(activeConversations)) return []

  const foundConversation = activeConversations.find(
    (conversation) => conversation.id === id,
  )

  if (!isNil(foundConversation)) {
    return activeConversations.map((conversation) => {
      if (conversation.id === id) {
        return {
          ...conversation,
          isMinimized: !conversation.isMinimized,
          hasUnreadMessages: !conversation.hasUnreadMessages,
        }
      }
      return conversation
    })
  }

  //In case there is no conversation found with the id
  const newConversationToBeAdded = {
    id,
    isMinimized: false,
    hasUnreadMessages: false,
  }

  if (activeConversations.length >= maxActiveConversations) {
    return [...activeConversations.slice(1), newConversationToBeAdded]
  }

  return [...activeConversations, newConversationToBeAdded]
}
