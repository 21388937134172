import { Switch } from '@headlessui/react'
import clsx from 'clsx'
import { FunctionComponent } from 'react'

import { Text } from 'components/ui'

interface IToggle extends React.HTMLAttributes<HTMLDivElement> {
  checked?: boolean
  showLabels?: boolean
  positiveLabel?: string
  negativeLabel?: string
  action?: string
  onToggle?: (value: boolean) => void
}

const Toggle: FunctionComponent<IToggle> = ({
  checked = false,
  showLabels = false,
  positiveLabel = 'Yes',
  negativeLabel = 'No',
  action = 'Toggle',
  onToggle = () => {},
  className,
  ...props
}: IToggle) => {
  return (
    <div {...props} className={clsx(className, 'flex items-center')}>
      {showLabels && (
        <Text size="xs" className={clsx(checked && 'invisible')}>
          {negativeLabel}
        </Text>
      )}
      <Switch
        checked={checked}
        onChange={onToggle}
        className={clsx(
          checked ? 'bg-primary' : 'bg-gray',
          showLabels && 'mx-3',
          'relative inline-flex h-8 w-16 items-center rounded-full',
        )}
      >
        <span className="sr-only">{action}</span>
        <span
          className={clsx(
            checked ? 'translate-x-9' : 'translate-x-1',
            'inline-block h-6 w-6 transform rounded-full bg-white transition',
          )}
        />
      </Switch>
      {showLabels && (
        <Text size="xs" className={clsx(!checked && 'invisible')}>
          {positiveLabel}
        </Text>
      )}
    </div>
  )
}

export default Toggle
