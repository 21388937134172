import { UpdateProfilesPk } from 'operations/UpdateProfilesPk'

import {
  Q_GET_FULL_PROFILE,
  Q_GET_PROFILE,
  U_PROFILES_PK,
} from 'lib/queries/profiles'

import { useMutation } from '@apollo/client'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import AcceptingClientsIcon from 'components/common/accept_clients_icon'
import useProfile from 'components/hooks/useProfile'

import { Availabilities } from './availability_badge'

const AvailabilityMenu = ({
  availability,
  showMoreIcons = false,
  iconClassname = '',
}: {
  availability: string
  showMoreIcons?: boolean
  iconClassname?: string
}) => {
  const { profile: ownerProfile } = useProfile()

  const [updateProfilePkMutation] = useMutation<UpdateProfilesPk>(
    U_PROFILES_PK,
    {
      refetchQueries: [
        Q_GET_FULL_PROFILE,
        'ProfileIdGetProfile',
        Q_GET_PROFILE,
        'GetProfileByOwnerId',
      ],
    },
  )

  const updateProfile = (option: Availabilities) => {
    updateProfilePkMutation({
      variables: {
        profileId: ownerProfile?.id,
        _set: {
          availability: option,
        },
      },
    })
  }

  return (
    <Menu>
      <Menu.Button>
        <AcceptingClientsIcon
          availability={availability}
          showExtraIcons={showMoreIcons}
          className={iconClassname}
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 top-3 mt-2 w-56 bg-white rounded-md divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none z-[1000]">
          <div className="py-1 px-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-primary text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => updateProfile(Availabilities.accepting)}
                >
                  Accepting Clients
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-primary text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => updateProfile(Availabilities.notAccepting)}
                >
                  Not Accepting Clients
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-primary text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => updateProfile(Availabilities.waitlist)}
                >
                  Waitlist
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default AvailabilityMenu
