import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { Highlight } from 'react-instantsearch'

import ConnectButton from 'components/common/connect_button'
import CurrentJob from 'components/common/current_job'
import MessageButton from 'components/common/message_button'
import { useProfile } from 'components/hooks'
import { Avatar, Text } from 'components/ui'

import { highlightName, highlightProfilesClassName } from '../../utils'

interface IProfileCardProps {
  hit: any
}

const ProfileCard: FunctionComponent<IProfileCardProps> = ({ hit }) => {
  return (
    <div className="flex w-full">
      <div className="flex gap-3">
        <div>
          <Avatar
            size={10}
            objectFit="cover"
            layout="fill"
            className="inline rounded-full"
            avatar={hit.avatar}
            alt="Profile image"
          />
        </div>
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            <div className="flex gap-1">
              <Text variant="body" weight="semibold" color="black">
                <Highlight
                  attribute="first_name"
                  hit={hit}
                  classNames={highlightName}
                />
              </Text>
              <Text variant="body" weight="semibold" color="black">
                <Highlight
                  attribute="last_name"
                  hit={hit}
                  classNames={highlightName}
                />
              </Text>
            </div>
            <div className="flex gap-1">
              <Text variant="small">
                <Highlight
                  attribute="office_info.state"
                  hit={hit}
                  classNames={highlightProfilesClassName}
                />
              </Text>
              <Text variant="small">
                <Highlight
                  attribute="office_info.city"
                  hit={hit}
                  classNames={highlightProfilesClassName}
                />
              </Text>
            </div>
          </div>
          <div className="overflow-hidden capitalize max-w-fit">
            <CurrentJob
              // className="min-w-0 text-xs font-normal text-left text truncate"
              currentJob={hit.current_job_new}
              experiences={hit.experiences}
            />
          </div>

          <Text variant="small">
            <Highlight
              attribute="referral_information.specialities"
              hit={hit}
              classNames={highlightProfilesClassName}
            />
          </Text>
        </div>
      </div>
    </div>
  )
}

export default ProfileCard
