import type {
  GetHomeFeed,
  GetHomeFeed_belongly_news_posts,
  GetHomeFeed_business_posts,
  GetHomeFeed_followed_news_posts,
  GetHomeFeed_my_connections_posts,
  GetHomeFeed_not_my_connections_commented_posts,
  GetHomeFeed_not_my_connections_liked_posts,
  GetHomeFeed_opportunities,
} from 'operations/GetHomeFeed'

import { makeVar } from '@apollo/client'
import { Queue } from '@datastructures-js/queue'

export interface PostOptionalFields {
  sort_date: number
  commented_profiles_connections?: Omit<
    GetHomeFeed_not_my_connections_commented_posts,
    'post'
  >[]
  liked_profiles_connections?: Omit<
    GetHomeFeed_not_my_connections_liked_posts,
    'post'
  >[]
}

export type ConnectionsPosts = GetHomeFeed_my_connections_posts &
  PostOptionalFields
export type CommentedPosts = GetHomeFeed_not_my_connections_commented_posts &
  PostOptionalFields
export type FeedNews = GetHomeFeed_followed_news_posts & PostOptionalFields
export type BelonglyNews = GetHomeFeed_belongly_news_posts & PostOptionalFields
export type LikedPosts = GetHomeFeed_not_my_connections_liked_posts &
  PostOptionalFields
export type Opportunities = GetHomeFeed_opportunities & PostOptionalFields
export type BusinessPosts = GetHomeFeed_business_posts & PostOptionalFields

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export type HomeFeedType =
  | ConnectionsPosts
  | CommentedPosts
  | FeedNews
  | BelonglyNews
  | LikedPosts
  | Opportunities
  | BusinessPosts

export function isRegularPost(post: HomeFeedType): post is ConnectionsPosts {
  return (
    (post as ConnectionsPosts).__typename === 'posts' &&
    (post as ConnectionsPosts).author !== null
  )
}

export function isBusinessPost(post: HomeFeedType): post is BusinessPosts {
  return (
    (post as BusinessPosts).__typename === 'posts' &&
    (post as BusinessPosts).business !== null
  )
}

export function isPost(
  post: HomeFeedType,
): post is BusinessPosts | ConnectionsPosts {
  return (post as ConnectionsPosts).__typename === 'posts'
}
export function isOpportunity(post: HomeFeedType): post is Opportunities {
  return (post as Opportunities).__typename === 'opportunities'
}

export function isNews(post: HomeFeedType): post is FeedNews | BelonglyNews {
  return (post as FeedNews).news !== null
}

export type FeedPost =
  | Optional<BusinessPosts, 'business'> &
      Optional<ConnectionsPosts, 'author'> &
      PostOptionalFields

export type FeedElement = FeedPost | (Opportunities & PostOptionalFields)

export const homeFeedQueue = new Queue<HomeFeedType>()
export const postsVar = makeVar<HomeFeedType[]>([])
export const postIdList = new Set<number>()

export function clearFeedCache() {
  postsVar([])
}

export function removePostFromCache(index: number, postId?: number) {
  const posts = [...postsVar()]
  posts.splice(index, 1)
  postsVar(posts)

  if (postId) {
    postIdList.delete(postId)
  }
}

export function updatePostFromCache(post: HomeFeedType) {
  const posts = postsVar()
  const cachePostIndex = posts.findIndex((_post) => _post.id === post.id)

  if (cachePostIndex !== -1) {
    posts[cachePostIndex] = parsePost(post)
    postsVar(posts)
  }
}

export function addPostToQueue(post: HomeFeedType) {
  if (!postIdList.has(post.id)) {
    homeFeedQueue.push(post)
    postIdList.add(post.id)
  }
}

export function getNextUniqueNonNewsPost(
  posts: HomeFeedType[],
): HomeFeedType | undefined {
  let post
  do {
    post = posts.shift() as HomeFeedType
    if (post && !postIdList.has(post.id)) {
      return post
    }
  } while (post)
}

export function getNextUniqueNewsPost(
  newsPosts:
    | GetHomeFeed_followed_news_posts[]
    | GetHomeFeed_belongly_news_posts[],
): HomeFeedType | undefined {
  let newsPost
  do {
    newsPost = newsPosts.shift() as HomeFeedType
    if (newsPost && !postIdList.has(newsPost.id)) {
      return newsPost
    }
  } while (newsPost)
}

export const parseDateToNumber = (date: Date) => Number(new Date(date))

export function parsePost(post: HomeFeedType) {
  return {
    ...post,
    sort_date: parseDateToNumber(post.created_at),
  }
}

export function parseLikedPost(post: LikedPosts) {
  const { post: likedPost, ...likeData } = post as LikedPosts
  return {
    ...likedPost,
    liked_profiles_connections: [likeData],
    sort_date: parseDateToNumber(likeData.created_at),
  }
}

export const mergeFeedData = (data: GetHomeFeed) => {
  const regularPosts: HomeFeedType[] = []
  const minDatesList: number[] = []
  const newsPosts = Array.from([
    ...data.followed_news_posts,
    ...data.belongly_news_posts,
  ])

  for (let feedElement in data) {
    let minDate: number = Number.POSITIVE_INFINITY
    let feedElementTyped = feedElement as keyof typeof data

    if (
      feedElementTyped === 'belongly_news_posts' ||
      feedElementTyped === 'followed_news_posts'
    ) {
      continue
    }

    data[feedElementTyped].forEach((post) => {
      if (feedElementTyped === 'not_my_connections_liked_posts') {
        if (minDate > parseDateToNumber(post.created_at)) {
          minDate = parseDateToNumber(post.created_at)
        }

        const newPost = parseLikedPost(post as LikedPosts)

        regularPosts.push(newPost)
        return
      }

      if (feedElementTyped === 'not_my_connections_commented_posts') {
        if (minDate > parseDateToNumber(post.created_at)) {
          minDate = parseDateToNumber(post.created_at)
        }

        const { post: commentPost, ...commentData } = post as CommentedPosts
        const newPost: HomeFeedType = {
          ...commentPost,
          commented_profiles_connections: [commentData],
          sort_date: parseDateToNumber(commentData.created_at),
        }

        regularPosts.push(newPost)
        return
      }

      if (post.__typename === 'opportunities') {
        regularPosts.push({
          ...post,
          sort_date: parseDateToNumber(post.updated_at),
        } as HomeFeedType)
        return
      }

      if (minDate > parseDateToNumber(post.created_at)) {
        minDate = parseDateToNumber(post.created_at)
      }

      regularPosts.push({
        ...post,
        sort_date: parseDateToNumber(post.created_at),
      } as HomeFeedType)

      return
    })

    if (minDate !== Infinity) {
      minDatesList.push(minDate)
    }
  }

  regularPosts.sort((a, b) => b.sort_date - a.sort_date)
  newsPosts.sort((a, b) => b.release_date - a.release_date)
  return {
    posts: regularPosts,
    cutOffDate: Math.max(...minDatesList),
    newsPosts,
  }
}
