import { BusinessProfileConversations } from 'operations/BusinessProfileConversations'
import { MessageGroupList } from 'operations/MessageGroupList'

import {
  S_BEE_MESSAGE_GROUP_LIST,
  S_BUSINESS_MESSAGE_GROUP_LIST,
  S_BUSINESS_PROFILE_CONVERSATIONS,
  S_MESSAGE_GROUP_LIST,
} from 'lib/queries/messages'

import { BeeMessageGroupList } from '@/operations/BeeMessageGroupList'
import { useSubscription } from '@apollo/client'

import useBeebot from './useBeeBot'
import useChatContext from './useChatContext'

const useConversationListData = () => {
  const { isBusinessProfileActive = false, activeProfileOnChat: profile } =
    useChatContext()
  const { id: BEE_PROFILE_ID } = useBeebot()

  const { data: beeConversationData } = useSubscription<BeeMessageGroupList>(
    S_BEE_MESSAGE_GROUP_LIST,
    {
      variables: {
        profileId: profile?.id,
        beeProfileId: BEE_PROFILE_ID,
      },
      skip: !profile,
    },
  )

  const { data: regularProfileConversationListData } =
    useSubscription<MessageGroupList>(S_MESSAGE_GROUP_LIST, {
      variables: {
        profileId: profile?.id,
        beeProfileId: BEE_PROFILE_ID,
      },
      skip: !profile,
    })

  const { data: businessConversationListData } =
    useSubscription<MessageGroupList>(S_BUSINESS_MESSAGE_GROUP_LIST, {
      variables: {
        profileId: profile?.id,
      },
      skip: !profile,
    })

  const beeConversation = beeConversationData?.message_group[0]

  const conversationListData = isBusinessProfileActive
    ? businessConversationListData
    : regularProfileConversationListData

  const { data: businessProfileConversations } =
    useSubscription<BusinessProfileConversations>(
      S_BUSINESS_PROFILE_CONVERSATIONS,
      {
        variables: {
          profileId: profile?.id,
        },
        skip: !profile || isBusinessProfileActive,
      },
    )

  const businessConversations =
    businessProfileConversations?.message_group?.map((conversation) => {
      return {
        conversationId: conversation.id,
        businessProfileId: conversation?.profiles[0]?.from_business_profile_id,
        unreadMessagesCount: conversation?.profiles[0]?.unread_messages_count,
      }
    }) || []

  return {
    beeConversation,
    conversationListData,
    businessConversations,
  }
}

export default useConversationListData
