import { gql } from '@apollo/client'

import { BusinessProfile, ProfileFields } from './posts'

export const Q_GET_USERNAME = gql`
  ${BusinessProfile}
  query GetBusinessUsername($username: String!) {
    business_profiles(where: { organization_username: { _eq: $username } }) {
      ...BusinessProfile
    }
  }
`

export const Q_GET_BUSINESS_ID = gql`
  query GetBusinessId($username: String!) {
    business_profiles(where: { organization_username: { _eq: $username } }) {
      id
    }
  }
`

export const Q_FOLLOW_BUSINESS = gql`
  query FollowingABusiness($business_id: Int!, $profile_id: Int!) {
    followers: business_profile_followers(
      where: { business_id: { _eq: $business_id } }
    ) {
      id
    }

    follow: business_profile_followers(
      where: {
        _and: {
          business_id: { _eq: $business_id }
          profile_id: { _eq: $profile_id }
        }
      }
    ) {
      id
    }

    followers_and_connections: business_profile_followers(
      where: {
        _and: {
          business_id: { _eq: $business_id }
          profile: { connections_to: { from_profile_id: { _eq: $profile_id } } }
        }
      }
    ) {
      id
    }
  }
`

export const Q_GET_BUSINESS_PROFILES = gql`
  ${BusinessProfile}
  query GetBusinessProfiles($profileId: Int!) {
    businessProfilesIAmAdmin: business_profiles(
      where: { administrators: { profile_id: { _eq: $profileId } } }
    ) {
      ...BusinessProfile
    }
    businessProfilesIAmCreator: business_profiles(
      where: { owner_id: { _eq: $profileId } }
    ) {
      ...BusinessProfile
    }
  }
`
export const S_GET_BUSINESS_PROFILES = gql`
  ${BusinessProfile}
  subscription GetBusinessProfilesSubscription($profileId: Int!) {
    business_profiles(
      where: {
        _or: [
          { administrators: { profile_id: { _eq: $profileId } } }
          { owner_id: { _eq: $profileId } }
        ]
      }
    ) {
      ...BusinessProfile
    }
  }
`

export const Q_GET_BUSINESS_PROFILE_ADMINS_AND_OWNER = gql`
  ${ProfileFields}
  query GetBusinessProfileAdmins($businessId: Int!) {
    businessAdmins: business_profile_admins(
      where: { business_id: { _eq: $businessId } }
    ) {
      profile {
        ...ProfileFields
      }
    }
    businessOwner: business_profiles(where: { id: { _eq: $businessId } }) {
      owner {
        ...ProfileFields
      }
    }
  }
`

export const Q_GET_BUSINESS_PROFILE = gql`
  ${BusinessProfile}
  query GetBuisnessProfile($businessProfileId: Int!) {
    business_profiles(where: { id: { _eq: $businessProfileId } }) {
      ...BusinessProfile
    }
  }
`

export const Q_GET_ORGANIZATION_SUBTYPES = gql`
  query GetOrganizationTypes($category: String!) {
    organization_types(where: { category: { _eq: $category } }) {
      sub_category
    }
  }
`

export const Q_SEND_BUSINESS_ADMIN_INVITATION = gql`
  query SendBusinessAdminInvitation(
    $businessName: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $recommenderName: String!
  ) {
    business_admin_invitation_email(
      mail_input: {
        business_name: $businessName
        email: $email
        first_name: $firstName
        last_name: $lastName
        recommender_name: $recommenderName
      }
    ) {
      response_message
    }
  }
`
