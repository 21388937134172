/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_message_group.yaml
// ====================================================

/**
Table name: message_group, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _or:
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: approved
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: client
  - _exists:
      _table:
        name: feature_flags
        schema: public
      _where:
        _and:
          - name:
              _eq: strict_permissions
          - enabled:
              _eq: false

**/
export const check_message_group_insert_permissions = function(feature_flags:any, message_group:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((user?.id === user?.id
				&& user?.roles?.includes("approved"))
			|| (user?.id === user?.id
				&& user?.roles?.includes("client"))))
	|| feature_flags.strict_permissions === false)
}