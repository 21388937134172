import { Messages_messages } from 'operations/Messages'

import clsx from 'clsx'
import { format } from 'date-fns'
import { twMerge } from 'tailwind-merge'

import LinkifyWrapper from 'components/common/hocs/LinkifyWrapper'
import { Text } from 'components/ui'

import PostRender from '../../common/post_render'

export const postInMessageDelimiter = '**'

interface MessageProps {
  isLastMessage: boolean
  isMyMessage: boolean
  isNextMessageFromSameAuthor: boolean
  message: Messages_messages
}

function Message({
  isLastMessage,
  isMyMessage,
  isNextMessageFromSameAuthor,
  message,
}: MessageProps) {
  const messageDate = new Date(message.created_at)
  const messageTime = format(messageDate, 'h:mm a')

  const messageProperties = {
    side: isMyMessage ? 'justify-end' : '',
    color: isMyMessage ? 'bg-gray-200' : 'bg-primary',
    shape: isMyMessage
      ? 'rounded-tl-lg rounded-tr-lg rounded-bl-lg'
      : 'rounded-tl-lg rounded-tr-lg rounded-br-lg',

    isLinkMessage: isMyMessage
      ? 'text-primary underline'
      : 'text-[FFFFFF] underline',
  }

  // if is a shared post
  // index 0: type
  // index 1: title
  // index 2: content
  // index 3: id
  const splittedContent = message.content?.split(postInMessageDelimiter)

  return (
    <div
      className={clsx(
        `${
          isNextMessageFromSameAuthor
            ? 'mb-0.5'
            : isLastMessage
            ? 'mb-8'
            : 'mb-3'
        }`,
      )}
    >
      <div className={`flex ${messageProperties.side}`}>
        <LinkifyWrapper className={messageProperties.isLinkMessage}>
          {splittedContent?.[0] === '__post__' ? (
            <PostRender
              postId={splittedContent[3]}
              bubbleShape={messageProperties.shape}
            />
          ) : (
            <div
              className={twMerge(
                messageProperties.shape,
                messageProperties.color,
                'flex gap-2 items-end whitespace-pre-wrap p-1 max-w-[80%]',
              )}
            >
              <Text
                variant="body"
                color={isMyMessage ? 'gray' : 'white'}
                className={'break-words text-left p-1'}
              >
                {message.content}
              </Text>
              <Text
                size="xs"
                variant="extra-small"
                weight="extralight"
                color={isMyMessage ? 'gray-500' : 'white'}
                className={'flex flex-none text-[10px]'}
              >{`${messageTime}`}</Text>
            </div>
          )}
        </LinkifyWrapper>
      </div>
    </div>
  )
}

export default Message
