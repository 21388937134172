/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_business_profile_followers.yaml
// ====================================================

/**
Table name: business_profile_followers, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _exists:
          _table:
            name: users
            schema: public
          _where:
            _and:
              - id:
                  _eq: X-Hasura-User-Id
              - roles:
                  _contains: approved
  - _exists:
      _table:
        name: feature_flags
        schema: public
      _where:
        _and:
          - name:
              _eq: strict_permissions
          - enabled:
              _eq: false

**/
export const check_business_profile_followers_insert_permissions = function(business_profile_follower:any, feature_flags:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& user?.roles?.includes("approved")))
	|| feature_flags.strict_permissions === false)
}


/**
Table name: business_profile_followers, select permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _exists:
          _table:
            name: users
            schema: public
          _where:
            _and:
              - id:
                  _eq: X-Hasura-User-Id
              - roles:
                  _contains: member
  - _exists:
      _table:
        name: feature_flags
        schema: public
      _where:
        _and:
          - name:
              _eq: strict_permissions
          - enabled:
              _eq: false

**/
export const check_business_profile_followers_select_permissions = function(business_profile_follower:any, feature_flags:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& user?.roles?.includes("member")))
	|| feature_flags.strict_permissions === false)
}


/**
Table name: business_profile_followers, delete permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _exists:
          _table:
            name: users
            schema: public
          _where:
            _and:
              - id:
                  _eq: X-Hasura-User-Id
              - roles:
                  _contains: member
      - profile:
          owner_id:
            _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - profile:
          owner_id:
            _eq: X-Hasura-User-Id

**/
export const check_business_profile_followers_delete_permissions = function(business_profile_follower:any, feature_flags:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& user?.roles?.includes("member"))
		&& business_profile_follower?.profile?.owner_id === user?.id)
	|| (feature_flags.strict_permissions === false
		&& business_profile_follower?.profile?.owner_id === user?.id))
}