/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_posts.yaml
// ====================================================

/**
Table name: posts, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _or:
          - _and:
              - _exists:
                  _table:
                    name: users
                    schema: public
                  _where:
                    _and:
                      - id:
                          _eq: X-Hasura-User-Id
                      - roles:
                          _contains: approved
              - author:
                  owner_id:
                    _eq: X-Hasura-User-Id
          - _or:
              - business:
                  owner:
                    owner_id:
                      _eq: X-Hasura-User-Id
              - business:
                  administrators:
                    profile:
                      owner_id:
                        _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - author:
              owner_id:
                _eq: X-Hasura-User-Id
          - business:
              administrators:
                profile:
                  owner_id:
                    _eq: X-Hasura-User-Id
          - business:
              owner:
                owner_id:
                  _eq: X-Hasura-User-Id

**/
export const check_posts_insert_permissions = function(feature_flags:any, post:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (((user?.id === user?.id
					&& user?.roles?.includes("approved"))
				&& post?.author?.owner_id === user?.id)
			|| (post?.business?.owner?.owner_id === user?.id
				|| post?.business?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id))))
	|| (feature_flags.strict_permissions === false
		&& (post?.author?.owner_id === user?.id
			|| post?.business?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id)
			|| post?.business?.owner?.owner_id === user?.id)))
}


/**
Table name: posts, update permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _or:
          - _and:
              - _exists:
                  _table:
                    name: users
                    schema: public
                  _where:
                    _and:
                      - id:
                          _eq: X-Hasura-User-Id
                      - roles:
                          _contains: approved
              - author:
                  owner_id:
                    _eq: X-Hasura-User-Id
          - _or:
              - business:
                  owner:
                    owner_id:
                      _eq: X-Hasura-User-Id
              - business:
                  administrators:
                    profile:
                      owner_id:
                        _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - author:
              owner_id:
                _eq: X-Hasura-User-Id
          - business:
              administrators:
                profile:
                  owner_id:
                    _eq: X-Hasura-User-Id
          - business:
              owner:
                owner_id:
                  _eq: X-Hasura-User-Id

**/
export const check_posts_update_permissions = function(feature_flags:any, post:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (((user?.id === user?.id
					&& user?.roles?.includes("approved"))
				&& post?.author?.owner_id === user?.id)
			|| (post?.business?.owner?.owner_id === user?.id
				|| post?.business?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id))))
	|| (feature_flags.strict_permissions === false
		&& (post?.author?.owner_id === user?.id
			|| post?.business?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id)
			|| post?.business?.owner?.owner_id === user?.id)))
}


/**
Table name: posts, select permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _or:
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: member
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: approved
  - _exists:
      _table:
        name: feature_flags
        schema: public
      _where:
        _and:
          - name:
              _eq: strict_permissions
          - enabled:
              _eq: false

**/
export const check_posts_select_permissions = function(feature_flags:any, post:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((user?.id === user?.id
				&& user?.roles?.includes("member"))
			|| (user?.id === user?.id
				&& user?.roles?.includes("approved"))))
	|| feature_flags.strict_permissions === false)
}


/**
Table name: posts, delete permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _or:
          - _and:
              - _exists:
                  _table:
                    name: users
                    schema: public
                  _where:
                    _and:
                      - id:
                          _eq: X-Hasura-User-Id
                      - roles:
                          _contains: approved
              - author:
                  owner_id:
                    _eq: X-Hasura-User-Id
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: SYSTEM_ADMIN
          - _or:
              - business:
                  owner:
                    owner_id:
                      _eq: X-Hasura-User-Id
              - business:
                  administrators:
                    profile:
                      owner_id:
                        _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - author:
              owner_id:
                _eq: X-Hasura-User-Id
          - business:
              administrators:
                profile:
                  owner_id:
                    _eq: X-Hasura-User-Id
          - business:
              owner:
                owner_id:
                  _eq: X-Hasura-User-Id

**/
export const check_posts_delete_permissions = function(feature_flags:any, post:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (((user?.id === user?.id
					&& user?.roles?.includes("approved"))
				&& post?.author?.owner_id === user?.id)
			|| (user?.id === user?.id
				&& user?.roles?.includes("SYSTEM_ADMIN"))
			|| (post?.business?.owner?.owner_id === user?.id
				|| post?.business?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id))))
	|| (feature_flags.strict_permissions === false
		&& (post?.author?.owner_id === user?.id
			|| post?.business?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id)
			|| post?.business?.owner?.owner_id === user?.id)))
}