import { ReactElement, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { Text, TextCharsRemaining } from 'components/ui'

interface ITextInput extends React.InputHTMLAttributes<HTMLInputElement> {
  background?: 'white' | 'page'
  variant?: 'regular' | 'phone' | 'maxLength' | 'url'
  urlPath?: string
  phoneCode?: string
  inputStyle?: string
  error?: string | undefined
  maxLimit?: number
  icon?: ReactElement<any>
  footNote?: string
  minLimit?: number
}

const TextInput = forwardRef<HTMLInputElement, ITextInput>(
  (
    {
      className,
      background = 'page',
      type = 'text',
      placeholder,
      variant = 'regular',
      phoneCode = '+1',
      urlPath = 'https://',
      error,
      inputStyle,
      disabled,
      maxLimit,
      value,
      icon,
      footNote,
      minLimit,
      ...props
    }: ITextInput,
    ref,
  ) => {
    const backgroundIsWhite = background === 'white'
    const isPhoneInput = variant === 'phone'
    const isUrlInput = variant === 'url'

    return (
      <div className="flex flex-col gap-1 w-full">
        <div className="flex flex-col gap-2 md:flex-row">
          <div
            className={twMerge(
              'flex w-full rounded border text-body align-center focus-within:outline focus-within:outline-2 focus-within:border-none outline-primary',
              backgroundIsWhite ? 'border-dark-border' : 'border-light-border',
              disabled && 'opacity-50',
              className,
            )}
          >
            {isPhoneInput && (
              <Text
                className={twMerge(
                  'px-6 py-3 bg-white border-r align-middle align-center',
                  backgroundIsWhite
                    ? ' border-dark-border'
                    : ' border-light-border',
                )}
              >
                {phoneCode || '+1'}
              </Text>
            )}
            {isUrlInput && (
              <Text
                detectLink={false}
                className={twMerge('pl-4 py-4 bg-background')}
              >
                {urlPath || 'https://'}
              </Text>
            )}
            <label
              className={twMerge(
                'flex items-center rounded grow',
                backgroundIsWhite ? 'bg-background' : 'bg-white',
              )}
            >
              {icon && <div className="-mr-2">{icon}</div>}
              <input
                {...props}
                value={value}
                disabled={disabled}
                type={type}
                placeholder={placeholder}
                ref={ref}
                maxLength={maxLimit}
                className={twMerge(
                  'w-full px-4 align-center rounded grow outline-none focus:outline-0 focus:border-none py-[14.5px]',
                  backgroundIsWhite ? 'bg-background' : 'bg-white',
                  inputStyle,
                )}
              />
            </label>
          </div>
          {maxLimit && (
            <div className="flex items-center align-middle rounded-r">
              <Text color="gray">
                {value?.toString().length}/{maxLimit}
              </Text>
            </div>
          )}
        </div>
        {footNote && (
          <Text variant="body" detectLink={false}>
            {footNote}
          </Text>
        )}
        {minLimit && typeof value == 'string' && (
          <TextCharsRemaining
            minLimit={minLimit}
            value={value}
            maxLimit={maxLimit}
          />
        )}
        {error && <Text className="mt-2 text-error">{error}</Text>}
      </div>
    )
  },
)

TextInput.displayName = 'TextInput'

export default TextInput
