import { Experience } from 'components/profile/profile.interface'

export const getLatestJob = (
  experiences: Experience[],
): Experience | undefined => {
  if (!experiences) return

  let latestJob: Experience = [...experiences].sort((a, b) => {
    return Number(b.end_year) === Number(a.end_year)
      ? Number(b.end_month) - Number(a.end_month)
      : Number(b.end_year) - Number(a.end_year)
  })[0]

  return latestJob
}
