import { ChevronDownIcon } from '@heroicons/react/solid'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Text } from '../ui'
import NewTag from './links/new_tag'

interface NavTabProps extends LinkProps {
  className?: string
  title: string
  subtitle: string
  isLink?: boolean
  isMenu?: boolean
  isNew?: boolean
  isChild?: boolean
}

function NavTab({
  href,
  as,
  className,
  title,
  subtitle,
  isLink = true,
  isMenu = false,
  isNew = false,
  isChild = false,
  ...props
}: NavTabProps) {
  const { asPath } = useRouter()
  const urlRootPath = asPath.toString().split('/')[1]
  const hrefRootPath = href.toString().split('/')[1]
  const isSelected = urlRootPath === hrefRootPath

  return (
    <>
      {isLink ? (
        <Link href={href} as={as} {...props} legacyBehavior>
          <a className="flex flex-col gap-1 group">
            {isNew ? (
              <div className="flex gap-1 items-center">
                <Text
                  className={twMerge(
                    'leading-none text-black font-semibold group-hover:text-[#7A8FCE] text-sm',
                    isSelected && 'text-[#777777]',
                  )}
                >
                  {title}
                </Text>
                <NewTag />
              </div>
            ) : (
              <Text
                className={twMerge(
                  'leading-none text-black font-semibold group-hover:text-[#7A8FCE] text-sm',
                  isSelected && 'text-[#777777]',
                )}
              >
                {title}
              </Text>
            )}
            <Text className="flex-nowrap text-xs font-extralight leading-none text-gray-500">
              {subtitle}
            </Text>
          </a>
        </Link>
      ) : (
        <a className="flex flex-col gap-1 group">
          <div className="flex gap-1 items-center">
            <Text
              variant="subheading"
              weight="semibold"
              className={twMerge(
                'leading-none text-black font-semibold group-hover:text-[#7A8FCE] text-sm',
                isSelected && 'text-[#777777]',
              )}
            >
              {title}
            </Text>
            <ChevronDownIcon className="w-4" />
          </div>
          <Text className="flex-nowrap text-xs font-extralight leading-none text-gray-500">
            {subtitle}
          </Text>
        </a>
      )}
    </>
  )
}

export default NavTab
