import { gql } from '@apollo/client'

export const Q_GET_PROFILE_OWNER_ID = gql`
  query GetOwnerId($ownerId: bpchar) {
    profiles(where: { owner_id: { _eq: $ownerId } }) {
      id
      username
      first_name
      last_name
    }
  }
`
