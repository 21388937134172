import { OnNewNotifications } from 'operations/OnNewNotifications'

import { S_GET_UNREAD_NOTIFICATIONS } from 'lib/subscriptions/notification'

import { useSubscription } from '@apollo/client'
import { useUser } from '@clerk/clerk-react'

const useNotifications = () => {
  const { user } = useUser()
  const { data, error, loading } = useSubscription<OnNewNotifications>(
    S_GET_UNREAD_NOTIFICATIONS,
    {
      variables: { owner_id: user?.id },
      skip: !user,
    },
  )

  return {
    notifications: data?.notifications.length ?? 0,
    error,
    loading,
  }
}

export default useNotifications
