import { gql, useMutation } from '@apollo/client'

export const M_INVITATION_ACCEPT = gql`
  mutation InvitationAccept($invitationId: Int!, $token: bpchar) {
    invitation_accept(invitationId: $invitationId, token: $token) {
      invitationId
      success
    }
  }
`

export const M_DELETE_INVITATION_BY_ID = gql`
  mutation DeleteInvitationById($id: Int!) {
    delete_invitations_by_pk(id: $id) {
      id
    }
  }
`
export const M_UPDATE_INVITATION_BY_ID = gql`
  mutation UpdateInvitationById($ignored_at: timestamptz!, $id: Int!) {
    update_invitations_by_pk(
      pk_columns: { id: $id }
      _set: { ignored_at: $ignored_at }
    ) {
      id
      ignored_at
    }
  }
`

export const M_MEMBER_INVITATION = gql`
  mutation AddMemberInvitation(
    $ownerProfileId: Int!
    $invitee_email: String!
    $member_email: String!
    $firstName: String
    $lastName: String
    $inviteeId: Int
    $message: String!
  ) {
    insert_member_invitations(
      objects: {
        owner_profile_id: $ownerProfileId
        invitee_email: $invitee_email
        member_email: $member_email
        first_name: $firstName
        last_name: $lastName
        message: $message
        invitee_id: $inviteeId
      }
    ) {
      affected_rows
    }
  }
`
