import { useRouter } from 'next/router'
import { isNil } from 'ramda'

import useChatContext from 'components/chat/hooks/useChatContext'
import {
  addToActiveConversations,
  removeActiveConversationById,
} from 'components/chat/utils/conversation'

const useHandleActiveConversations = () => {
  const { setActiveConversations, activeConversations } = useChatContext()
  const { pathname } = useRouter()
  const isOnMessagingPage = pathname.includes('/messaging')

  const deactivateConversation = ({
    conversationId,
  }: {
    conversationId: number
  }) => {
    if (!isNil(conversationId)) {
      const updatedConversations = removeActiveConversationById({
        id: conversationId,
        activeConversations,
      })
      setActiveConversations(updatedConversations)
    }
  }

  const activateConversation = ({
    conversationId,
  }: {
    conversationId: number
  }) => {
    if (!isNil(conversationId)) {
      const updateActiveConversations = addToActiveConversations({
        id: conversationId,
        activeConversations,
        maxActiveConversations: isOnMessagingPage ? 1 : 3,
      })
      setActiveConversations(updateActiveConversations)
    }
  }

  const removeAllActiveConversations = () => {
    setActiveConversations([])
  }

  return {
    deactivateConversation,
    activateConversation,
    removeAllActiveConversations,
  }
}

export default useHandleActiveConversations
