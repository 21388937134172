export const validEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const hasHandlebars = /(\{\{[\s]*.*?[\s]*\}\})/g

export const parenthesesContent = /\(([^()]*)\)/g

export const markdownLink = /(?:__|[*#])|\[(.*?)\]\(.*?\)/gm

export const onlyNumbers = /^[0-9]*$/g

export const whitespace = / +/g

export const validUrl =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi

export const validUrlProtocolRequired =
  /^((https|http):\/\/)([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}(\/[:\w-]+)*(\?[\w-]+(=[\w-]*)?(&[\w-]+(=[\w-]*)?)*)?$/gi
