import { isNil } from 'ramda'
import { useContext } from 'react'

import { ChatContext } from '../context'
import { activeProfileOnChat } from '../types/types'

const useChatContext = () => {
  const chatContext = useContext(ChatContext)
  const {
    activeProfileOnChat,
    setActiveProfileOnChat = () => {},
    activeConversations = [],
    setActiveConversations = () => {},
    isBusinessProfileActive,
    setIsBusinessProfileActive = () => {},
    hasUnreadMessages,
    setHasUnreadMessages,
  } = chatContext ?? {}

  return {
    activeProfileOnChat,
    activeConversations,
    setActiveConversations,
    setActiveProfileOnChat,
    isBusinessProfileActive,
    setIsBusinessProfileActive,
    hasUnreadMessages,
    setHasUnreadMessages,
  }
}

export default useChatContext
