import { useRouter } from 'next/router'
import { useContext } from 'react'

import { parsePostTitleUrl } from 'utils/posts/helper'

import { SearchBoxContext } from '../../utils/context'
import PostCard from './post_card'

const PostResults = ({ hit }: { hit: any }) => {
  const router = useRouter()
  const { hideResults } = useContext(SearchBoxContext)
  const postLink = `/posts/${hit.id}/${parsePostTitleUrl(hit.title)}`
  const goToPost = () => {
    hideResults()
    router.push(postLink)
  }
  return (
    <div key={hit.id} onClick={goToPost}>
      <PostCard hit={hit} />
    </div>
  )
}

export default PostResults
