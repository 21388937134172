import { GetOwnerId } from 'operations/GetOwnerId'
import { GetProfileConnections } from 'operations/GetProfileConnections'

import { Q_GET_PROFILE_OWNER_ID } from 'lib/queries/owner_id'

import { gql, useQuery } from '@apollo/client'
import { useUser } from '@clerk/clerk-react'
import { useEffect, useState } from 'react'

export const Q_GET_PROFILE_CONNECTIONS = gql`
  query GetProfileConnections($ownerId: Int!, $userId: Int!) {
    ownerConnections: connections(
      where: {
        _and: [
          { from_profile_id: { _eq: $ownerId } }
          { to_profile_id: { _neq: $userId } }
          { status: { _eq: true } }
        ]
      }
    ) {
      from_profile_id
      status
      to_profile_id
    }
    userConnections: connections(
      where: {
        _and: [
          { from_profile_id: { _eq: $userId } }
          { to_profile_id: { _neq: $ownerId } }
          { status: { _eq: true } }
        ]
      }
    ) {
      from_profile_id
      status
      to_profile_id
    }
  }
`

const useMutualConnections = (userId: number) => {
  const [mutualConnections, setMutualConnections] = useState(0)
  const { user } = useUser()
  const { data: ownerProfile } = useQuery<GetOwnerId>(Q_GET_PROFILE_OWNER_ID, {
    variables: {
      ownerId: user?.id,
      skip: !user,
    },
  })
  const { data: totalConnections } = useQuery<GetProfileConnections>(
    Q_GET_PROFILE_CONNECTIONS,
    {
      variables: {
        ownerId: ownerProfile?.profiles[0]?.id,
        userId,
      },
    },
  )

  const getMutualConnections = (totalConnections: GetProfileConnections) => {
    const ownerConnections = [...totalConnections.ownerConnections]
    const userConnections = [...totalConnections.userConnections]
    let mutualConnCounter = 0

    ownerConnections.forEach((ownerConn) => {
      let mutualConn = userConnections.find(
        (el) => el.to_profile_id === ownerConn.to_profile_id,
      )
      if (mutualConn) {
        mutualConnCounter++
      }
    })

    return mutualConnCounter
  }

  useEffect(() => {
    if (!totalConnections) return

    let mutualConn = getMutualConnections(totalConnections)
    setMutualConnections(mutualConn)
  }, [totalConnections])

  return {
    mutualConnections,
    ownerId: ownerProfile?.profiles[0]?.id,
  }
}

export default useMutualConnections
