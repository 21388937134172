import { SurveyDataType } from '@/components/survey/hooks/useMySurveys'
import { useRouter } from 'next/router'
import { ReactNode, useState } from 'react'

import { ClientSurveysContext } from './context'

interface ClientSurveysProviderProps {
  children: ReactNode
}
function ClientSurveysProvider({ children }: ClientSurveysProviderProps) {
  const [selectedSurvey, setSelectedSurvey] = useState<
    SurveyDataType | undefined
  >()

  const [selectedSurveys, setSelectedSurveys] = useState<number[]>([])

  const router = useRouter()

  const createSurvey = () => router.push('/client-surveys/create')
  const editSurvey = () => router.push('/client-surveys/edit')
  const sendSurvey = () => router.push('/client-surveys/send')
  const showSurvey = ({ nativeId }: { nativeId: string }) =>
    router.push({ query: { nativeId } })

  const goToMyWorkspace = () => {
    router.push('/client-surveys')
    setSelectedSurvey(undefined)
  }

  const selectSurvey = (surveyId: number) => {
    setSelectedSurveys((current) => [...current, surveyId])
  }

  const removeSurvey = (surveyId: number) => {
    setSelectedSurveys((current) =>
      [...current].filter((sId) => sId != surveyId),
    )
  }

  return (
    <ClientSurveysContext.Provider
      value={{
        selectedSurvey,
        selectedSurveys,
        setSelectedSurvey,
        goToMyWorkspace,
        createSurvey,
        showSurvey,
        editSurvey,
        sendSurvey,
        selectSurvey,
        removeSurvey,
      }}
    >
      {children}
    </ClientSurveysContext.Provider>
  )
}

export default ClientSurveysProvider
