import { RefreshIcon } from '@heroicons/react/outline'
import { FunctionComponent } from 'react'
import ReactInfiniteScroll from 'react-infinite-scroller'
import { twMerge } from 'tailwind-merge'

interface IInfinteScroll {
  loadMore: () => void
  hasMore: boolean
  threshold?: number
  className?: string
}

const InfiniteScroll: FunctionComponent<IInfinteScroll> = ({
  loadMore,
  hasMore,
  children,
  threshold = 200,
  className,
}) => {
  return (
    <ReactInfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      initialLoad={false}
      loader={
        <div className="flex justify-center items-center p-5 w-full bg-white">
          <RefreshIcon className="flex justify-self-center w-4 h-4" />
        </div>
      }
      threshold={threshold}
      style={{ overflow: 'hidden' }}
      className={twMerge(className)}
    >
      {children}
    </ReactInfiniteScroll>
  )
}

export default InfiniteScroll
