/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_business_profiles.yaml
// ====================================================

/**
Table name: business_profiles, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - owner:
              owner:
                roles:
                  _contains: approved
          - owner:
              owner_id:
                _eq: X-Hasura-User-Id
  - _exists:
      _table:
        name: feature_flags
        schema: public
      _where:
        _and:
          - name:
              _eq: strict_permissions
          - enabled:
              _eq: false

**/
export const check_business_profiles_insert_permissions = function(business_profile:any, feature_flags:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (business_profile?.owner?.owner?.roles?.includes("approved")
			&& business_profile?.owner?.owner_id === user?.id))
	|| feature_flags.strict_permissions === false)
}


/**
Table name: business_profiles, update permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - owner:
              owner:
                roles:
                  _contains: approved
          - _or:
              - owner:
                  owner_id:
                    _eq: X-Hasura-User-Id
              - administrators:
                  profile:
                    owner_id:
                      _eq: X-Hasura-User-Id
  - _exists:
      _table:
        name: feature_flags
        schema: public
      _where:
        _and:
          - name:
              _eq: strict_permissions
          - enabled:
              _eq: false

**/
export const check_business_profiles_update_permissions = function(business_profile:any, feature_flags:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (business_profile?.owner?.owner?.roles?.includes("approved")
			&& (business_profile?.owner?.owner_id === user?.id
				|| business_profile?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id))))
	|| feature_flags.strict_permissions === false)
}


/**
Table name: business_profiles, select permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _exists:
          _table:
            name: users
            schema: public
          _where:
            _and:
              - id:
                  _eq: X-Hasura-User-Id
              - roles:
                  _contains: member
  - _exists:
      _table:
        name: feature_flags
        schema: public
      _where:
        _and:
          - name:
              _eq: strict_permissions
          - enabled:
              _eq: false

**/
export const check_business_profiles_select_permissions = function(business_profile:any, feature_flags:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& user?.roles?.includes("member")))
	|| feature_flags.strict_permissions === false)
}


/**
Table name: business_profiles, delete permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - owner:
              owner:
                roles:
                  _contains: approved
          - _or:
              - owner:
                  owner_id:
                    _eq: X-Hasura-User-Id
              - _and:
                  - administrators:
                      profile:
                        owner_id:
                          _eq: X-Hasura-User-Id
                  - administrators:
                      permissions:
                        _contains: super admin
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - administrators:
              permissions:
                _contains: super admin
          - owner:
              owner_id:
                _eq: X-Hasura-User-Id

**/
export const check_business_profiles_delete_permissions = function(business_profile:any, feature_flags:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (business_profile?.owner?.owner?.roles?.includes("approved")
			&& (business_profile?.owner?.owner_id === user?.id
				|| (business_profile?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id)
					&& business_profile?.administrators?.some((_item:any) => _item?.permissions?.includes("super admin"))))))
	|| (feature_flags.strict_permissions === false
		&& (business_profile?.administrators?.some((_item:any) => _item?.permissions?.includes("super admin"))
			|| business_profile?.owner?.owner_id === user?.id)))
}