import slugify from 'slugify'

export const parsePostTitleUrl = (title: string) => {
  return title?.toLowerCase().split(' ').join('-').replaceAll('/', '-')
}

export const generateUrlFriendlyString = (str?: string) => {
  if (!str) {
    return undefined
  }
  return slugify(str, {
    lower: true,
    strict: true,
  })
}
