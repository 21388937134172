import { M_MEMBER_INVITATION } from 'lib/mutations/invitations'

import { useMutation } from '@apollo/client'
import { useUser } from '@clerk/nextjs'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { FunctionComponent, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useProfile } from 'components/hooks'
import { ERRORS } from 'components/onboarding/shared/enums'
import { validEmail } from 'components/onboarding/shared/regex'
import { Button, Modal, Text, TextArea, TextInput } from 'components/ui'
import { IModal } from 'components/ui/modal/modal'

export const MAIL_MESSAGE = `I’ve been using a new professional network that’s exclusively for psychologists, social workers, and other mental health professionals like us.

It’s called Belongly and it makes it easy to connect with colleagues across the country, share information and best practices, engage in interesting conversations, give and get referrals, and more.`

interface IManualInvitationModal extends IModal {}

const ManualInvitationModal: FunctionComponent<IManualInvitationModal> = ({
  onClose,
  ...props
}) => {
  const { user } = useUser()

  const { profile } = useProfile()

  const [sent, setSent] = useState<boolean>(false)

  const [addMemberInvitation, { loading, error }] = useMutation(
    M_MEMBER_INVITATION,
    {
      onCompleted: () => {
        setSent(true)
      },
    },
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      body: MAIL_MESSAGE,
    },
  })

  const onSend = ({
    firstName,
    lastName,
    email,
    body,
  }: {
    firstName: string
    lastName: string
    email: string
    body: string
  }) => {
    addMemberInvitation({
      variables: {
        ownerProfileId: profile?.id,
        firstName,
        lastName,
        invitee_email: email,
        member_email: user?.emailAddresses[0].emailAddress,
        message: body,
      },
    })
  }

  return (
    <Modal
      title="Invite your Colleagues to Belongly"
      onClose={() => {
        reset()
        onClose()
      }}
      {...props}
    >
      {!sent ? (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4 w-full">
            <Controller
              control={control}
              name="firstName"
              rules={{ required: ERRORS.REQUIRED }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextInput
                  background="white"
                  placeholder="Invitee's first name"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.firstName?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              rules={{ required: ERRORS.REQUIRED }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextInput
                  background="white"
                  placeholder="Invitee's last name"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.lastName?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              rules={{
                required: ERRORS.REQUIRED,
                pattern: { value: validEmail, message: ERRORS.EMAIL },
              }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextInput
                  background="white"
                  placeholder="Invitee's email address"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.email?.message}
                />
              )}
            />
          </div>
          <div className="flex flex-col gap-4 mt-2">
            <Text variant="body" className="whitespace-pre-line">
              {`Hi ${watch('firstName')}`.trim()},
            </Text>
            <Controller
              control={control}
              name="body"
              render={({ field: { value, onChange, onBlur } }) => (
                <TextArea
                  rows={10}
                  background="white"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder="Message"
                  name="message"
                />
              )}
            />
            <Text variant="body">
              I&apos;d love for you to join me. All you have to do is click here
              to get started.
              <br />
              <br />
              Best,
              <br />
              {profile?.first_name} {profile?.last_name}.
            </Text>
          </div>
          <div className="flex flex-col">
            {error && (
              <div>
                <Text className="text-sm text-error">
                  {error?.message.includes('email')
                    ? 'This email already has an invitation'
                    : 'There is an error, please try again'}
                </Text>
              </div>
            )}
            <Button
              disabled={loading}
              variant="primary"
              onClick={handleSubmit(onSend)}
            >
              Send
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4 items-center">
          <CheckCircleIcon className="w-16 h-16 stroke-[green]" />
          <Text>Invitation Sent!</Text>
          <div className="flex gap-4"></div>
          <Button
            variant="primary"
            className="w-full"
            onClick={() => {
              reset()
              onClose()
            }}
          >
            OK
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default ManualInvitationModal
