import { PermissionWrapper } from '@/components/common/hocs'

import NavTab from '../nav_tab'

interface RetentionLinkProps {
  href: string
  passHref: boolean | undefined
}

function RetentionLink({ href, passHref }: RetentionLinkProps) {
  return (
    <PermissionWrapper action="ViewClientPulse" behavior="ShowOrHide">
      <NavTab
        isNew
        href={href}
        passHref={passHref}
        title="Pulse"
        subtitle="Client Surveys"
      />
    </PermissionWrapper>
  )
}

export default RetentionLink
