import { gql, useMutation } from '@apollo/client'

export const INSERT_PROFILE_VIEW_RECORD = gql`
  mutation InsertProfileView($from_profile_id: Int!, $to_profile_id: Int!) {
    insert_profile_views(
      objects: {
        from_profile_id: $from_profile_id
        to_profile_id: $to_profile_id
      }
    ) {
      affected_rows
    }
  }
`
export const UPDATE_USER_STATUS = gql`
  mutation upsert_user($profileId: Int!, $status: Boolean! = false) {
    insert_user_status_one(
      object: { profile_id: $profileId, is_online: $status }
      on_conflict: { constraint: user_status_pkey, update_columns: [is_online] }
    ) {
      profile_id
      is_online
    }
  }
`
