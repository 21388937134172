import { initialReferralData, newReferralData } from 'lib/reactiveVariables'

import {
  ComboboxDemo,
  ComboboxOptionType,
} from '@/components/ui/combobox/newCombobox'
import { mapValuesAsOptions } from '@/utils/opportunities/referrals/referrals'
import { isEmpty, isNil } from 'ramda'
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { CRMIcon } from 'components/common/crm'
import { ValueIcon } from 'components/common/value'
import { Button, NewReferralStep, RadioGroupCards, Text } from 'components/ui'

import { useDataFetching } from '../hooks'
import {
  CreateReferralContext,
  REFERRALTYPE,
} from '../step_controller/step_controller'

interface IStepOne {
  className?: string
}

const StepOne: FunctionComponent<IStepOne> = () => {
  const textContent = {
    stepNumber: '1/6',
    stepDescription: "Let's Get Started",
    title: 'What type of referral are you creating?',
    description:
      'With Belongly Referrals, you can either create a referral for a patient in need or, ' +
      "you can post an opening so that people know you're taking on new clients. " +
      "Whether you're looking for new clients or trying to find help for a patient, our system will suggest matches when appropiate.",
    descriptionStyle: 'text-opacity-70',
  }

  const { referralType, reason, ...allData } = newReferralData()

  const [optionSelected, setOptionSelected] = useState<REFERRALTYPE>(
    referralType as REFERRALTYPE,
  )

  const [reasonSelected, setReasonSelected] = useState<ComboboxOptionType>(
    mapValuesAsOptions(reason),
  )

  const { goToNextStep, cancelCreateReferral, setReferralType } = useContext(
    CreateReferralContext,
  )

  const { getOpportunityReasons, opportunityReasons } = useDataFetching()

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      opportunityReason: reasonSelected,
    },
  })

  const options = [
    {
      id: REFERRALTYPE.SeekingTherapists,
      title: 'Posting for someone who needs help.',
      description:
        "Share a case that you can't serve with the community and find help for a client in need.",
      icon: <ValueIcon size={10} />,
    },
    {
      id: REFERRALTYPE.SeekingClients,
      title: "I'm looking for new clients for my practice.",
      description:
        'Open for business and looking for new clients? Let the community know so they can help you fill your schedule.',
      icon: <CRMIcon size={10} />,
    },
  ]

  const isSeekingTherapist = referralType === REFERRALTYPE.SeekingTherapists

  useEffect(() => {
    reset({ opportunityReason: reasonSelected })
  }, [reset, reasonSelected])

  const reasons = opportunityReasons?.opportunity_reasons?.map((reason) => ({
    title: reason?.new_description ?? reason?.title,
    value: reason?.id,
  }))

  const reasonDescription = opportunityReasons?.opportunity_reasons?.find(
    (reason) => reason.id == reasonSelected?.value,
  )?.description

  const disableContinueAction = useMemo(() => {
    return (
      isEmpty(referralType) ||
      (referralType == REFERRALTYPE.SeekingTherapists &&
        reasonSelected == undefined)
    )
  }, [referralType, reasonSelected])

  const handleCancelCreation = () => {
    cancelCreateReferral()
    newReferralData(initialReferralData)
  }

  const handleChooseReferralType = (option: REFERRALTYPE) => {
    setReferralType(option)
    newReferralData({ ...allData, reason, referralType: option })
    setOptionSelected(option)
  }

  const handleContinue = ({
    opportunityReason,
  }: {
    opportunityReason: ComboboxOptionType | undefined
  }) => {
    if (isSeekingTherapist && !isNil(opportunityReasons)) {
      newReferralData({
        ...newReferralData(),
        referralType,
        reason: opportunityReason?.value,
      })
    }
    newReferralData({
      ...newReferralData(),
      referralType,
    })

    goToNextStep()
  }

  return (
    <NewReferralStep
      stepNumber={textContent.stepNumber}
      stepDescription={textContent.stepDescription}
      title={textContent.title}
      description={textContent.description}
      descriptionStyle={textContent.descriptionStyle}
      extraContent={
        <div>
          <iframe
            src="https://www.loom.com/embed/5797744953a143aba1f742bfa73c8963?sid=8f535dbc-9aaa-4ec2-99f6-ae48e5769dd0"
            className="absolute w-1/4 h-1/3"
          />
        </div>
      }
      customStep
    >
      <div className="flex flex-col gap-2 px-16 pt-[6rem]">
        <Text variant="none" weight="bold" size="2xl">
          Who are you creating this referral for?
        </Text>

        <section className="flex flex-col gap-5 mt-5 w-full">
          <RadioGroupCards
            radioGroupContainerClassName="lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2"
            options={options}
            value={optionSelected}
            onSelect={handleChooseReferralType}
          />

          {referralType == REFERRALTYPE.SeekingTherapists && (
            <div className="flex flex-col gap-3">
              <Text size="md" weight="bold">
                Why are you posting this referral?
              </Text>
              <div className="flex flex-col pb-0 mb-0 w-full">
                <Controller
                  control={control}
                  name="opportunityReason"
                  render={({ field: { onChange, value } }) => (
                    <div className="flex flex-col gap-2">
                      <ComboboxDemo
                        data={reasons}
                        getSelectedOptions={(selected) => {
                          if (!Array.isArray(selected) && selected)
                            onChange(setReasonSelected(selected))
                        }}
                        defaultValues={value}
                        parentBgColor="white"
                        buttonPlaceholder="Please choose from one of the following options."
                        getData={(value) => {
                          getOpportunityReasons({
                            variables: {
                              search: `%${value ?? ''}%`,
                            },
                          })
                        }}
                        lowercase
                      />
                      {reasonDescription && (
                        <Text className="pl-0.5">{reasonDescription}</Text>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        </section>
        <div
          className={twMerge(
            'flex flex-col flex-1 gap-3 w-full md:flex-row md:justify-end bg-white',
          )}
        >
          <Button
            variant="label"
            type="button"
            className="flex-1 order-2 py-2 px-10 my-8 md:flex-none md:order-1 text-md"
            onClick={handleCancelCreation}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="button"
            className="flex-1 order-1 py-3 px-14 my-8 md:flex-none md:order-2 text-md"
            onClick={handleSubmit(handleContinue)}
            disabled={disableContinueAction}
          >
            Continue
          </Button>
        </div>
      </div>
    </NewReferralStep>
  )
}

export default StepOne
