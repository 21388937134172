import {
  GetProfileByOwnerId_profiles,
  GetProfileByOwnerId_profiles_owner,
} from 'operations/GetProfileByOwnerId'

import { Q_GET_FULL_PROFILE } from 'lib/queries/profiles'

import { GetFullProfile } from '@/operations/GetFullProfile'
import { useApolloClient, useQuery } from '@apollo/client'
import { ProfileContext } from 'context'
import { isNil } from 'ramda'
import { useContext } from 'react'

interface Props {
  profileId?: number
}

const useProfile = (options?: Props) => {
  const client = useApolloClient()

  const profileId = options?.profileId
  const isLookingForOwner = isNil(profileId)

  const {
    profile: ownerProfile,
    ownerError,
    ownerLoading,
    ownerRefecth,
    noProfile,
  } = useContext(ProfileContext) ?? {}

  const profileCache = client.readQuery<GetFullProfile>({
    query: Q_GET_FULL_PROFILE,
    variables: { profileId },
  })

  const {
    data: othersData,
    loading: othersLoading,
    error: othersError,
    refetch: othersRefetch,
  } = useQuery<GetFullProfile>(Q_GET_FULL_PROFILE, {
    variables: { profileId },
    skip: !isNil(profileCache) || isLookingForOwner,
  })

  const othersProfile = isNil(profileCache)
    ? othersData?.profiles?.[0]
    : profileCache.profiles?.[0]

  const profile = isLookingForOwner ? ownerProfile : othersProfile

  const fullName = `${profile?.first_name} ${profile?.last_name}`.trim()

  const myAvatar = profile?.avatar

  const id = profile?.id

  const user = profile?.owner

  let isAdmin = false

  if (profile && profile.owner && profile.owner.roles) {
    if (
      Array.isArray(profile.owner.roles) &&
      profile.owner.roles.includes('system_admin')
    ) {
      isAdmin = true
    }
  }

  return {
    loading: isLookingForOwner ? ownerLoading : othersLoading,
    error: isLookingForOwner ? ownerError : othersError,
    profile,
    fullName,
    refetch: isLookingForOwner ? ownerRefecth : othersRefetch,
    myAvatar,
    id,
    noProfile,
    user,
    isAdmin,
  }
}

export type Profile = GetProfileByOwnerId_profiles
export type BelonglyUser = GetProfileByOwnerId_profiles_owner
export default useProfile
