import clsx from 'clsx'

const TelehealthIcon = ({
  className,
  size = 5,
}: {
  className?: string
  size?: number
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(`h-${size} w-${size}`, className)}
    viewBox="0 0 640 512"
  >
    <path d="M592 0H48A48 48 0 0 0 0 48v320a48 48 0 0 0 48 48h240v32H112a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H352v-32h240a48 48 0 0 0 48-48V48a48 48 0 0 0-48-48zm-16 352H64V64h512z" />
  </svg>
)

export default TelehealthIcon
