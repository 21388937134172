/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_post_comments.yaml
// ====================================================

/**
Table name: post_comments, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: approved
          - author:
              owner_id:
                _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - author:
          owner_id:
            _eq: X-Hasura-User-Id

**/
export const check_post_comments_insert_permissions = function(feature_flags:any, post_comment:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((user?.id === user?.id
				&& user?.roles?.includes("approved"))
			&& post_comment?.author?.owner_id === user?.id))
	|| (feature_flags.strict_permissions === false
		&& post_comment?.author?.owner_id === user?.id))
}


/**
Table name: post_comments, update permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: approved
          - author:
              owner_id:
                _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - author:
          owner_id:
            _eq: X-Hasura-User-Id

**/
export const check_post_comments_update_permissions = function(feature_flags:any, post_comment:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((user?.id === user?.id
				&& user?.roles?.includes("approved"))
			&& post_comment?.author?.owner_id === user?.id))
	|| (feature_flags.strict_permissions === false
		&& post_comment?.author?.owner_id === user?.id))
}


/**
Table name: post_comments, select permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                id:
                  _eq: X-Hasura-User-Id
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: member
  - _exists:
      _table:
        name: feature_flags
        schema: public
      _where:
        _and:
          - name:
              _eq: strict_permissions
          - enabled:
              _eq: false

**/
export const check_post_comments_select_permissions = function(feature_flags:any, post_comment:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& (user?.id === user?.id
				&& user?.roles?.includes("member"))))
	|| feature_flags.strict_permissions === false)
}


/**
Table name: post_comments, delete permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: approved
          - author:
              owner_id:
                _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - author:
          owner_id:
            _eq: X-Hasura-User-Id

**/
export const check_post_comments_delete_permissions = function(feature_flags:any, post_comment:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((user?.id === user?.id
				&& user?.roles?.includes("approved"))
			&& post_comment?.author?.owner_id === user?.id))
	|| (feature_flags.strict_permissions === false
		&& post_comment?.author?.owner_id === user?.id))
}