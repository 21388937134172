import { initialReferralData, newReferralData } from 'lib/reactiveVariables'

import { FunctionComponent, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { Button, NewReferralStep, Text, TextInput } from 'components/ui'

import {
  CreateReferralContext,
  ReferralActionType,
} from '../../step_controller/step_controller'

interface IStepTwo extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const StepTwo: FunctionComponent<IStepTwo> = () => {
  const textContent = {
    stepNumber: '2/6',
    stepDescription: 'Add A Title',
    title: 'Give your referral a strong title.',
    description:
      "This helps your referral stand out to the right therapist. It's the first thing they'll see so make it clear and informative about the situation.",
  }

  const exampleTitles = [
    'Older adult Latina with depression and relationship issues',
    'Seeking grief counselor in San Francisco',
    '86 y.o. black female seeking in-person therapists',
    "16yr old Hispanic-American female struggling amidst parent's marital conflict",
  ]

  const {
    goToPreviousStep,
    goToNextStep,
    type: referralActionType,
    cancelCreateReferral,
  } = useContext(CreateReferralContext)

  const isCreatingNewReferral = referralActionType === ReferralActionType.Create

  const { referralTitle } = newReferralData()

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: referralTitle || '',
    },
  })

  useEffect(() => {
    reset({
      title: referralTitle,
    })
  }, [reset, referralTitle])

  const handleBack = () => {
    goToPreviousStep()
    newReferralData(initialReferralData)
  }
  const handleContinue = ({ title }: { title: string }) => {
    const allData = newReferralData()
    newReferralData({
      ...allData,
      referralTitle: title,
    })
    goToNextStep()
  }

  return (
    <NewReferralStep
      stepNumber={textContent.stepNumber}
      stepDescription={textContent.stepDescription}
      title={textContent.title}
      description={textContent.description}
      customStep
    >
      <div className="flex flex-col gap-2 px-16 pt-[6rem]">
        <Text variant="none" weight="bold" size="2xl">
          Write a title for your referral
        </Text>
        <section className="flex flex-col gap-8 mt-5 mb-20">
          <Controller
            control={control}
            name="title"
            rules={{ required: 'You must provide a title.' }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                placeholder="Make it strong so it stands out."
                background="white"
                value={value}
                onChange={onChange}
                error={errors.title?.message}
                maxLimit={80}
              />
            )}
          />
          <div className="flex flex-col gap-3">
            <Text size="sm" weight="bold">
              Example titles:
            </Text>
            <ul className="flex flex-col ml-4 list-none text-gray-700 gap-.5">
              {exampleTitles.map((text) => (
                <li key={text} style={{ position: 'relative' }}>
                  <span
                    style={{
                      content: '""',
                      position: 'absolute',
                      left: '-1rem',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: '0.5rem',
                      height: '0.5rem',
                      borderRadius: '50%',
                      backgroundColor: 'currentColor',
                    }}
                  ></span>
                  <div className="pl-3">
                    <Text variant="body">{text}</Text>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <div
          className={twMerge(
            'flex flex-col flex-1 gap-3 w-full md:flex-row md:justify-end bg-white',
          )}
        >
          <Button
            variant="third"
            type="button"
            className="flex-1 order-2 py-2 px-16 my-8 text-sm md:flex-none md:order-1"
            onClick={isCreatingNewReferral ? handleBack : cancelCreateReferral}
          >
            {isCreatingNewReferral ? 'Back' : 'Cancel'}
          </Button>
          <Button
            variant="primary"
            type="button"
            className="flex-1 order-1 py-2 px-8 my-8 text-sm md:flex-none md:order-2"
            onClick={handleSubmit(handleContinue)}
          >
            Next: Description
          </Button>
        </div>
      </div>
    </NewReferralStep>
  )
}

export default StepTwo
