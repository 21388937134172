import { Menu, Tab, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import {
  Dispatch,
  Fragment,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { MobileSwitch } from '../mobile_switch'
import { Text } from '../text'

interface Tab {
  title: string
  content: React.ReactNode
}

interface ITabs extends React.HTMLAttributes<HTMLDivElement> {
  tabs: Tab[]
  tabsClassName?: string
  contentClassName?: string
  extraContent?: ReactNode
  selectedTabIndex?: number
  setSelectedTabIndex?:
    | Dispatch<SetStateAction<number>>
    | ((tabIndex: number) => void)
}

const Tabs: FunctionComponent<ITabs> = ({
  className,
  tabsClassName,
  contentClassName,
  tabs,
  extraContent,
  selectedTabIndex,
  setSelectedTabIndex,
  ...props
}: ITabs) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  return (
    <div {...props} className={twMerge('w-full', className)}>
      <MobileSwitch
        regularView={
          <div className="flex flex-col w-full">
            <Tab.Group
              selectedIndex={selectedTabIndex}
              onChange={setSelectedTabIndex}
            >
              <Tab.List
                className={twMerge('border-b border-gray-light', tabsClassName)}
              >
                {tabs.map(({ title }) => (
                  <Tab
                    key={title}
                    className={({ selected }) =>
                      clsx(
                        'px-3 md:px-8 py-3 border-b-2',
                        selected ? 'border-secondary' : 'border-gray-light',
                      )
                    }
                  >
                    <Text variant="heading">{title}</Text>
                  </Tab>
                ))}
              </Tab.List>
              {extraContent}
              <Tab.Panels className={contentClassName}>
                {tabs.map(({ title, content }) => (
                  <Tab.Panel key={title}>{content}</Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        }
        mobileView={
          <div className="flex flex-col divide-y divide-light-border">
            <div className="flex justify-between items-center w-full divide-x divide-light-border">
              <Menu as="div" className="inline-block relative w-full">
                <Menu.Button className="flex w-full">
                  <div className="flex justify-between items-center p-4 w-full">
                    <Text weight="semibold" size="sm">
                      {selectedTab.title}
                    </Text>
                    <ChevronDownIcon className="w-5 h-5" />
                  </div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-50 w-full bg-white border-r border-b divide-y origin-bottom focus:outline-none divide-light-border border-light-border">
                    {tabs.map(({ title }, index) => (
                      <Menu.Item key={title}>
                        {({ active }) => (
                          <button
                            className={twMerge(
                              'group flex w-full items-center px-2 py-2 text-sm',
                            )}
                            onClick={() => setSelectedTab(tabs[index])}
                          >
                            {title}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
              <div className="flex-none p-2">{extraContent}</div>
            </div>
            <div>{selectedTab.content}</div>
          </div>
        }
      />
    </div>
  )
}

export default Tabs
