import { MessageGroupList_message_group } from 'operations/MessageGroupList'
import { Messages, Messages_messages } from 'operations/Messages'

import {
  format,
  isBefore,
  isSameDay,
  isThisYear,
  isToday,
  isValid,
  isYesterday,
} from 'date-fns'

import { ProfileChatType, getProfileChatType } from 'utils/messaging'

export const formatMessagesDate = (date: Date) => {
  if (isYesterday(date)) return 'Yesterday'
  if (isToday(date)) return 'Today'
  if (isThisYear(date)) return format(date, 'EEE, MMM dd')
  return format(date, 'EEE, MMM dd, yyyy')
}

export const formatMessagesInListDate = (date: Date) => {
  if (isToday(date)) return format(date, 'hh:mm a')
  if (isThisYear(date)) return format(date, 'MMM dd')
  return format(date, 'MMM dd, yyyy')
}

export const sortListMessagesByDate = (
  messagesGroupList: MessageGroupList_message_group[],
) => {
  let messagesGroupListCopy = [...messagesGroupList]
  messagesGroupListCopy.sort((a, b) => {
    let dateA = Number(new Date(a.messages?.[0]?.created_at))
    let dateB = Number(new Date(b.messages?.[0]?.created_at))
    return dateB - dateA
  })
  return messagesGroupListCopy
}

export const hasUnreadMessages = (lastMessageDate: Date, lastSeenDate: Date) =>
  isBefore(new Date(lastSeenDate), new Date(lastMessageDate))

export const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date as any)
}

export const isSameDayWithFormatChecking = (date1: Date, date2: Date) => {
  if (isValid(date1) && isValid(date2)) return isSameDay(date1, date2)
  return false
}

export const isLastMessage = (messagesData: Messages, index: number) =>
  messagesData.messages[index + 1] === undefined

export const isFirstMessage = (messagesData: Messages, index: number) =>
  messagesData.messages[index - 1] === undefined

export const getNextMessage = (messagesData: Messages, index: number) =>
  messagesData.messages[index + 1]

export const getPrevMessage = (messagesData: Messages, index: number) =>
  messagesData.messages[index - 1]

export const getLasMessage = (messagesData: Messages) =>
  messagesData.messages[messagesData.messages.length - 1]

export const getMessageDate = (date: any) => new Date(date)

/**
 * Its my message (currently logged in user) if the business im operating under or null and my profile id matches the business
 * that the message came from or null and their profile id
 */
export const isMyMessage = ({
  message,
  ownerProfileId,
  originalProfileIdLoggedInApp,
  isBusiness,
}: {
  message: Messages_messages
  ownerProfileId: number
  originalProfileIdLoggedInApp: number
  isBusiness: boolean
}) => {
  const messageBusinessId = message?.business_profile?.id || -1
  const messageProfileId = message?.profile?.id || -1

  const currentSessionBusinessId = isBusiness ? ownerProfileId : -1
  const currentSessionProfileId = originalProfileIdLoggedInApp

  return (
    messageBusinessId == currentSessionBusinessId &&
    messageProfileId === currentSessionProfileId
  )
}

export const isNextMessageFromSameAuthor = (
  message: Messages_messages,
  nextMessage: Messages_messages,
  isBusiness: boolean,
) => {
  const id = isBusiness ? message?.business_profile?.id : message?.profile?.id
  const nextMessageAuthorId = isBusiness
    ? nextMessage?.business_profile?.id
    : nextMessage?.profile?.id
  return id === nextMessageAuthorId
}

export const isValidMessage = (message: string) =>
  message && message.trim().length !== 0

export const getMessageAuthor = ({
  isSentByMe,
  isChatOwnerBusiness,
  isGuestBusiness,
  messageGroup,
}: {
  isSentByMe: boolean
  isChatOwnerBusiness: boolean
  isGuestBusiness: boolean
  messageGroup: MessageGroupList_message_group
}) => {
  if (isSentByMe) return 'You: '
  const name = {
    [ProfileChatType.PROFILE_TO_PROFILE]:
      messageGroup.messages[0].profile?.first_name,
    [ProfileChatType.PROFILE_TO_BUSINESS_PROFILE]:
      messageGroup.messages[0].business_profile?.name,
    [ProfileChatType.BUSINESS_PROFILE_TO_PROFILE]:
      messageGroup.messages[0].profile?.first_name,
    [ProfileChatType.BUSINESS_PROFILE_TO_BUSINESS_PROFILE]:
      messageGroup.messages[0].business_profile?.name,
  }
  const type = getProfileChatType(isChatOwnerBusiness, isGuestBusiness)

  return name[type]
}
