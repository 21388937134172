import Link from 'next/link'

import { Button, Text } from 'components/ui'

const userHomeUrl = '/home'
const clientHomeUrl = '/therapists-matches'

const NotFound = ({ isClient }: { isClient?: boolean }) => {
  return (
    <section className="flex flex-col justify-center items-center w-screen h-screen">
      <Text variant="title" color="primary">
        Oops... Nothing was found.
      </Text>
      <Text>We&apos;ll trying to help find what you&apos;re looking for</Text>
      <br />
      <Link href={isClient ? clientHomeUrl : userHomeUrl} passHref>
        <Button variant="primary">Go to home</Button>
      </Link>
    </section>
  )
}

export default NotFound
