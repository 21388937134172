import { UserSearch_profiles } from 'operations/UserSearch'

import { ISimpleLocation } from 'components/common/hocs/location_select'

import { ComboboxOptionType } from '../ui/combobox/newCombobox'

export enum OrganizationType {
  'BusinessPage' = 'business page',
  'EducationalInstitutions' = 'educational institutions',
  'GovernmentDepartments' = 'government departments & agencies',
}

export enum OrganizationSize {
  'ExtraSmall' = '1',
  'Small' = '2 - 10',
  'Medium' = '11 - 50',
  'Large' = '51 - 200',
  'ExtraLarge' = '201 - 500',
  'MegaLarge' = '500+',
}

export const organizationTypeLabel = {
  ['business page']: 'Business',
  ['educational institutions']: 'Educational institution',
  ['government departments & agencies']: 'Government department or agency',
}

export type OrganizationAddress = {
  address1: string
  address2?: string
  location: {
    city: string
    state: string
    zip?: number
  }
  email: string
  phoneNumber: string
  reference?: string
}

export enum OrganizationRole {
  'Admin' = 'admin',
  'SuperAdmin' = 'super admin',
  'AdminContributor' = 'administrative contributor',
  'Guest' = 'guest',
}

export type OrganizationAdmins = {
  profile: UserSearch_profiles | null
  role: OrganizationRole
  location?: ISimpleLocation
  createdById: number
}

export const ORGANIZATION_WORDING = 'Business Profile'
