import { gql } from '@apollo/client'

import { OpportunitiesFragment, ProfileFields } from './posts'

export const Q_GET_REFERRALS = gql`
  ${OpportunitiesFragment}
  query GetReferrals(
    $limit: Int = 3
    $condition: opportunities_bool_exp
    $order: [opportunities_order_by!]
  ) {
    opportunities(limit: $limit, where: $condition, order_by: $order) {
      ...Opportunity
    }
  }
`

export const Q_GET_LAST_REFERRAL = gql`
  ${OpportunitiesFragment}
  query GetLastReferral($profileId: Int!) {
    opportunities(
      limit: 1
      where: {
        _and: [{ business_id: { _eq: $profileId } }, { live: { _eq: true } }]
      }
      order_by: { created_at: desc }
    ) {
      ...Opportunity
    }
    counter: opportunities_aggregate(
      where: {
        _and: [{ business_id: { _eq: $profileId } }, { live: { _eq: true } }]
      }
    ) {
      total: aggregate {
        count(columns: id)
      }
    }
  }
`

export const Q_GET_REFERRAL = gql`
  ${OpportunitiesFragment}
  query GetReferralById($id: uuid!) {
    opportunities(where: { uuid: { _eq: $id } }) {
      ...Opportunity
    }
  }
`

export const Q_GET_REFERRAL_BY_ID = gql`
  ${OpportunitiesFragment}
  query GetReferralByRegularId($id: Int!) {
    opportunities(where: { id: { _eq: $id } }) {
      ...Opportunity
    }
  }
`

export const Q_GET_OPPORTUNITY_TOTAL_RESPONDANTS = gql`
  query GetTotalRespondants($opportunityId: Int!) {
    respondants: opportunity_respondants_aggregate(
      where: { opportunity_id: { _eq: $opportunityId } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
    respondants_outsider: opportunity_respondants_outsiders_aggregate(
      where: { opportunity_id: { _eq: $opportunityId } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
`

export const Q_GET_OPPORTUNITY_TOTAL_RESPONDANTS_DECLINED = gql`
  query GetTotalRespondantsDeclined($opportunityId: Int!) {
    respondants: opportunity_respondants_aggregate(
      where: {
        _and: {
          opportunity_id: { _eq: $opportunityId }
          response_state: { _eq: "DECLINED" }
        }
      }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
`

export const Q_GET_RESPONDANT = gql`
  query GetRespondant($fromProfileId: Int!, $opportunityId: Int!) {
    opportunity_respondants(
      where: {
        _and: [
          { from_profile_id: { _eq: $fromProfileId } }
          { opportunity_id: { _eq: $opportunityId } }
        ]
      }
    ) {
      id
      response_type
      response_state
    }
  }
`

export const Q_GET_REFERRAL_COMMENTS = gql`
  ${ProfileFields}
  query GetReferralComments($referralId: Int) {
    opportunity_comments(where: { opportunity_id: { _eq: $referralId } }) {
      id
      parent_id
      content
      created_at
      updated_at
      from_profile_id
      author {
        ...ProfileFields
      }
    }
  }
`

export const Q_GET_REFERRAL_LIST = gql`
  ${OpportunitiesFragment}
  ${ProfileFields}
  query GetReferralList($listId: Int!) {
    referral_list(where: { id: { _eq: $listId } }) {
      status
      id
      body_text
      opportunity_id
    }
    referral_list_profile(where: { referral_list_id: { _eq: $listId } }) {
      id
      notes
      status
      respondant_outsider_id
      respondant_outsider {
        id
        first_name
        last_name
        email
      }
      respondant_id
      respondant {
        from_profile {
          ...ProfileFields
          contact_info
          referral_information
        }
      }
    }
  }
`

export const Q_GET_OUTSIDERS = gql`
  ${ProfileFields}
  query GetOutsiders($opportunity_id: Int!) {
    opportunity_respondants_outsiders(
      where: { opportunity_id: { _eq: $opportunity_id } }
    ) {
      id
      first_name
      last_name
      email
      recommender {
        ...ProfileFields
      }
    }
  }
`

export const Q_GET_THERAPY_FORMS = gql`
  query GetTherapyForms($search: String) {
    therapy_forms(where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`

export const Q_GET_ALL_THERAPY_FORMS = gql`
  query GetAllTherapyForms {
    therapy_forms {
      id
      name
    }
  }
`

export const Q_GET_ALL_AGE_GROUPS = gql`
  query GetAllAgeGroups {
    age_groups {
      id
      name
    }
  }
`

export const Q_GET_LANGUAGES = gql`
  query GetOpportunitiesLanguages($search: String) {
    languages(where: { name: { _ilike: $search } }, order_by: { name: asc }) {
      id
      name
    }
  }
`
