import { opportunities_bool_exp } from 'operations/globalTypes'

import { Q_PSYPACT_STATES } from 'lib/queries/psypact_states'
import { ILocation } from 'lib/reactiveVariables'

import { useQuery } from '@apollo/client'
import { useUser } from '@clerk/nextjs'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { ISimpleLocation } from 'components/opportunities/referrals/filter_referrals/location_filter'

import {
  matchActivity,
  matchLocation,
  matchPostDate,
  matchStatus,
} from 'utils/opportunities/referrals/referrals'

export interface IUseFilters {
  field: string
  subField?: string
  inputValue: string | boolean | string[] | ISimpleLocation[]
}

const useReferralFilters = () => {
  const router = useRouter()
  const { user } = useUser()
  const userId = user?.id
  const baseQuery = '/opportunities' + '?filters='

  const { filters: encodedFilters } = router.query

  const { data: psypactStatesData } = useQuery(Q_PSYPACT_STATES)
  const psypactStates = useMemo(
    () =>
      psypactStatesData
        ? [
            ...psypactStatesData?.psypact_states.map(
              (state: any) => state?.name,
            ),
          ]
        : [],
    [psypactStatesData],
  )

  const filters = useMemo(() => {
    let decodedFilters: Record<string, any> = {}
    if (encodedFilters) {
      decodedFilters = JSON.parse(atob(encodedFilters as string))
    }
    return decodedFilters
  }, [encodedFilters])

  const deleteFilter = ({
    field,
    value,
  }: {
    field: string
    value?: string | { id?: number | string }
  }) => {
    const copyFilters = { ...filters }

    Object.keys(copyFilters).map((key: string) => {
      if (key === field) {
        if (!Array.isArray(copyFilters[key])) {
          delete copyFilters[key]
        }
        if (copyFilters[key]?.length === 1) {
          delete copyFilters[key]
        }
        if (
          Array.isArray(copyFilters[key]) &&
          typeof value === 'string' &&
          value
        ) {
          const pos = copyFilters[key]?.indexOf(value)
          copyFilters[key].splice(pos, 1)
        }
        if (
          Array.isArray(copyFilters[key]) &&
          typeof value === 'object' &&
          value &&
          field === 'location'
        ) {
          const newLocations = copyFilters[key].filter(
            (location: ILocation) => location.id !== value.id,
          )

          copyFilters[key] = newLocations
        }
      }
    })

    if (JSON.stringify(copyFilters) == '{}') {
      router.push('/opportunities/referrals')
      return
    }

    const encodedFilters = btoa(JSON.stringify(copyFilters))
    router.push(baseQuery + encodedFilters)
  }

  const addFilter = (propSet: IUseFilters | Array<IUseFilters>) => {
    let copyFilters = { ...filters }
    if (!Array.isArray(propSet)) {
      propSet = [propSet]
    }

    for (const props of propSet) {
      const { field, inputValue } = props
      let assignments: {}
      if (Array.isArray(inputValue)) {
        inputValue.forEach((input) => {
          assignments = { ...assignments, [field]: input }
        })
      }
      assignments = { [field]: inputValue }

      copyFilters = { ...copyFilters, ...assignments }
    }

    const encodedFilters = btoa(JSON.stringify(copyFilters))
    router.push(baseQuery + encodedFilters)
  }

  const query = useMemo(() => {
    const variables: { where: opportunities_bool_exp[]; sort: any } = {
      where: [],
      sort: [],
    }
    Object.entries(filters).forEach(([field, value]: [any, any]) => {
      if (field === 'date posted') {
        variables.where.push(matchPostDate(value))
        return
      } else if (field === 'status') {
        variables.where.push(matchStatus(value))
        return
      } else if (field === 'activity') {
        variables.sort = [...matchActivity(value)]
        return
      } else if (field === 'location') {
        const or: { _or: Array<opportunities_bool_exp> } = { _or: [] }
        value.forEach((item: ISimpleLocation) =>
          or._or.push(matchLocation(item)),
        )
        variables.where.push(or)
        return
      } else if (field === 'PSYPACT') {
        variables.where.push({
          _or: psypactStates.map((state) => ({
            seeking_info: {
              _contains: {
                locations: [{ state }],
              },
            },
          })),
        })
        return
      }
    })

    return { where: { _and: variables.where }, sort: variables.sort }
  }, [filters, psypactStates])

  return {
    deleteFilter,
    addFilter,
    filters,
    query,
  }
}

export default useReferralFilters
