import { useReactiveVar } from '@apollo/client'
import { ArrowSmLeftIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import Link from 'next/link'
import { Dispatch, SetStateAction } from 'react'
import { twMerge } from 'tailwind-merge'

import ChevronDownIcon from 'components/common/chevron_down_icon'
import ChevronUpIcon from 'components/common/chevron_up_icon'
import CloseIcon from 'components/common/close_x_icon'
import { useHandleActiveConversations } from 'components/hooks'
import { Text } from 'components/ui'

import ProfileImage from './profile_image'

interface HeaderProps {
  messageGroupId?: number
  isExpanded: boolean
  handleChangeProfile?: () => void
  setIsExpanded: Dispatch<SetStateAction<boolean>>
  avatar: any
  title: string
  isOnline: boolean
  isPage?: boolean
  link: string
}

function Header({
  messageGroupId,
  isExpanded,
  setIsExpanded,
  avatar,
  title,
  isOnline,
  isPage = false,
  link,
}: HeaderProps) {
  const { deactivateConversation } = useHandleActiveConversations()
  const unreadMessage = false

  return (
    <header
      className={twMerge(
        'flex justify-between items-center py-2 px-2 min-h-[48px] max-w-full relative rounded-md bg-clip-padding border-b border-gray-light',
        unreadMessage ? 'bg-primary animate-pulse' : 'bg-white',
      )}
    >
      <div className="flex items-center max-w-full">
        {avatar && (
          <div className="flex items-center">
            <Link href={link} passHref>
              <ProfileImage avatar={avatar} isOnline={isOnline} />
            </Link>
          </div>
        )}
      </div>

      <div
        className="flex justify-start items-center"
        /* onClick={(e) => {
      e.stopPropagation()
      setIsExpanded((prev) => !prev)
    }} */
      >
        <Link href={link} passHref>
          <Text
            variant="subheading"
            weight="bold"
            className="flex justify-self-start"
            capitalize
          >
            {title}
          </Text>
        </Link>

        <div className="flex flex-none ml-2">
          <div
            className="flex justify-center items-center ml-1 w-12 h-12 rounded-full cursor-pointer hover:bg-gray-light"
            style={{
              position: 'relative',
              width: '32px',
              height: '32px',
            }}
            onClick={(e) => {
              e.stopPropagation()
              setIsExpanded((prev) => !prev)
            }}
          >
            {isExpanded ? (
              <ChevronDownIcon
                className={clsx(
                  unreadMessage && 'stroke-white hover:stroke-purple-main',
                )}
              />
            ) : (
              <ChevronUpIcon
                className={clsx(
                  unreadMessage && 'fill-white hover:fill-purple-main',
                )}
              />
            )}
          </div>

          {messageGroupId && (
            <div
              onClick={(e) => {
                e.stopPropagation()
                deactivateConversation({ conversationId: messageGroupId })
              }}
              className="flex justify-center items-center mr-2 w-12 h-12 rounded cursor-pointer hover:bg-gray-light"
              style={{
                position: 'relative',
                width: '32px',
                height: '32px',
              }}
            >
              <CloseIcon
                className={clsx(
                  unreadMessage && 'stroke-white hover:stroke-purple-main',
                )}
              />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
