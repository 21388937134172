import { FunctionComponent, RefObject } from 'react'
import { useSearchBox } from 'react-instantsearch'

import SearchIcon from 'components/common/search_icon'
import { TextInput } from 'components/ui'

import useFullSearch from '../../hooks/useFullSearch'
import AllCard from './all_card'

interface IAllResults {
  parentRef?: RefObject<HTMLDivElement>
}

const AllResult: FunctionComponent<IAllResults> = ({ parentRef }) => {
  const { categoriesToBeRendered } = useFullSearch()

  return (
    <div ref={parentRef} className="flex z-50 flex-col pl-2">
      {categoriesToBeRendered.map(
        ({ category, indexName, hitsPerPage }, objectID) => {
          return (
            <AllCard
              key={objectID}
              category={category}
              indexName={indexName}
              hitsPerPage={hitsPerPage}
            />
          )
        },
      )}
    </div>
  )
}

export default AllResult
