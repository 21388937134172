import clsx from 'clsx'

import { Experience } from 'components/profile/profile.interface'
import { Text } from 'components/ui'

import { getLatestJob } from 'utils/experiences/getLatestJob'

const CurrentJob = ({
  experiences,
  currentJob,
  className = '',
  textColor = 'text-gray-mid',
  withHeading = true,
}: {
  experiences?: Experience[]
  currentJob?: string | null
  className?: string
  textColor?: string
  withHeading?: boolean
}) => {
  if (!experiences && !currentJob) {
    return null
  }

  const { title: recentJob, organization } = getLatestJob(experiences!) || {
    title: '',
    organization: '',
  }

  return (
    <Text className={clsx('break-words', className)} variant="small">
      {currentJob || `${recentJob} ${organization ? `at ${organization}` : ''}`}
    </Text>
  )
}

export default CurrentJob
