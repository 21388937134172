import { FunctionComponent } from 'react'

import { NewReferralStep } from 'components/ui'

import StepSixForm from './step_six_form'

interface IStepSix {
  className?: string
}

const StepSix: FunctionComponent<IStepSix> = () => {
  const textContent = {
    stepNumber: '6/6',
    stepDescription: 'Exposure',
    title: 'Exposure',
    description:
      "That's it. We've got all the information needed to share your profile with the Belongly community and get you connected with qualified referrals. Just a few more questions and we'll get your referral published.",
  }
  return (
    <NewReferralStep
      stepNumber={textContent.stepNumber}
      stepDescription={textContent.stepDescription}
      title={textContent.title}
      description={textContent.description}
    >
      <StepSixForm />
    </NewReferralStep>
  )
}

export default StepSix
