export const getGeoLocationData = async () => {
  const resp = await fetch(
    `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.NEXT_PUBLIC_ABSTRACT_KEY}`,
  )
  const {
    postal_code: zipCode,
    city,
    region,
    region_iso_code: regionCode,
  } = await resp.json()

  return {
    zipCode,
    city,
    region,
    regionCode,
  }
}
