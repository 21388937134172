/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_member_invitations.yaml
// ====================================================

/**
Table name: member_invitations, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _exists:
          _table:
            name: users
            schema: public
          _where:
            _and:
              - id:
                  _eq: X-Hasura-User-Id
              - roles:
                  _contains: approved
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - owner_profile:
          owner_id:
            _eq: X-Hasura-User-Id

**/
export const check_member_invitations_insert_permissions = function(feature_flags:any, member_invitation:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& user?.roles?.includes("approved")))
	|| (feature_flags.strict_permissions === false
		&& member_invitation?.owner_profile?.owner_id === user?.id))
}