import { gql } from '@apollo/client'

export const Q_GET_INSURANCES = gql`
  query GetInsurances($search: String) {
    insurances(
      where: { name: { _ilike: $search } }
      limit: 80
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

export const Q_GET_LANGUAGES = gql`
  query GetLanguages($search: String) {
    languages(where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`

export const Q_GET_PROFESSIONAL_DESIGNATIONS = gql`
  query GetProfessionalDesignations($search: String) {
    professional_designations(where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`

export const Q_GET_ORIENTATIONS = gql`
  query GetOrientations($search: String) {
    orientations(where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`

export const Q_GET_AGE_GROUPS = gql`
  query GetAgeGroups($search: String) {
    age_groups(where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`

export const Q_GET_JOB_TITLES = gql`
  query GetJobTitles($search: String) {
    job_titles(where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`

export const Q_GET_EMPLOYMENT_TYPES = gql`
  query GetEmploymentTypes($search: String) {
    employment_types(where: { name: { _ilike: $search } }) {
      id
      name
    }
  }
`

export const Q_GET_LOCATION_BY_ID = gql`
  query FindLocationById($search: Int!) {
    locations(where: { id: { _eq: $search } }) {
      id
      city
      state
      state_code
    }
  }
`

export const Q_GET_EXACT_LOCATION = gql`
  query FindExactLocation(
    $city: String!
    $state: String!
    $stateCode: String!
  ) {
    locations(
      where: {
        _and: {
          city: { _eq: $city }
          state_code: { _eq: $stateCode }
          state: { _eq: $state }
        }
      }
    ) {
      id
      city
      state
      state_code
    }
  }
`

export const Q_FIND_SPECIALITIES = gql`
  query FindSpecialities($search: String) {
    specialities_list(limit: 10, where: { name: { _ilike: $search } }) {
      name
      id
    }
  }
`

export const Q_GET_TREATMENTS = gql`
  query GetTreatments($search: String) {
    treatments(limit: 10, where: { name: { _ilike: $search } }) {
      name
      id
    }
  }
`
