import { Combobox as HeadlessCombobox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { isEmpty } from 'ramda'
import { FunctionComponent, ReactElement, useRef, useState } from 'react'
import { removeDuplicatesForObjectsArray } from 'utils'

import { Box } from '../box'
import { Chip } from '../chip'
import { InfoLabel } from '../info_label'
import Line from '../line'
import { Loading } from '../loading'
import { Text } from '../text'

export type Option = { title: string; value: any; type?: optionType }

export enum optionType {
  customized = 'CUSTOMIZED',
}

interface ICombobox extends React.InputHTMLAttributes<HTMLInputElement> {
  background?: 'white' | 'page'
  options?: Option[]
  chipVariant?: 'primary' | 'secondary' | 'gray'
  hasArrowIcon?: boolean
  hasInnerChips?: boolean
  onSelectItem: (option: Option | Option[]) => void
  selectedItem: Option | Option[] | undefined
  loading?: boolean
  error?: string | undefined
  tooltip?: string | undefined
  renderOption?: (option: Option) => JSX.Element
  optionsWidth?: string
  icon?: ReactElement<any>
  maxOptionsSelected?: number
  hasCustomizedOption?: boolean
}

const Combobox: FunctionComponent<ICombobox> = ({
  className,
  background = 'page',
  placeholder,
  options = [],
  multiple = false,
  chipVariant,
  hasArrowIcon = false,
  hasInnerChips = false,
  selectedItem,
  error,
  disabled,
  loading = false,
  tooltip,
  onSelectItem,
  onChange,
  renderOption,
  optionsWidth,
  maxOptionsSelected,
  icon,
  hasCustomizedOption = false,
  ...props
}: ICombobox) => {
  const [query, setQuery] = useState<string>(
    !selectedItem || multiple ? '' : (selectedItem as Option).title,
  )
  const inputRef = useRef<any>(null)

  const filteredOptions =
    query === '' || loading
      ? options
      : options.filter((option) => {
          return option.title.toLowerCase().includes(query?.toLowerCase())
        })

  const onRemoveOption = (optionToBeDeleted: Option) => {
    if (Array.isArray(selectedItem)) {
      const remainingOptions = selectedItem.filter(
        (option) =>
          option.title.toLowerCase() !==
            optionToBeDeleted.title.toLowerCase() &&
          option.value !== optionToBeDeleted.value,
      )
      onSelectItem(remainingOptions)
    }
  }

  const backgroundIsWhite = background === 'white'

  const showChips = !isEmpty(selectedItem)
  const unDuplicateItems = Array.isArray(selectedItem)
    ? removeDuplicatesForObjectsArray(selectedItem as Option[])
    : []

  const handleItemSelection = (option: Option | Option[]) => {
    if (onSelectItem) {
      onSelectItem(option)
    }

    if (!multiple) {
      const singleOption = option as Option
      setQuery(singleOption?.title)
    }
  }

  return (
    <div className="relative z-50 w-full">
      <div className="flex relative justify-center items-center">
        <div className="flex-1">
          <HeadlessCombobox
            {...props}
            value={selectedItem}
            onChange={handleItemSelection}
            // @ts-ignore
            multiple={multiple}
            disabled={disabled}
          >
            {({ open }) => (
              <div>
                <div className={clsx(disabled && 'opacity-50')}>
                  <Box background={background} className={clsx(className)}>
                    {icon && icon}
                    <div
                      onClick={() => inputRef?.current?.focus()}
                      className={clsx(
                        'flex flex-col gap-2 w-full outline-none focus:outline-0',
                      )}
                    >
                      <HeadlessCombobox.Button as="div">
                        <HeadlessCombobox.Input
                          ref={inputRef}
                          placeholder={placeholder}
                          onChange={(event) => {
                            setQuery(event.target.value)
                            if (onChange) {
                              onChange(event)
                            }
                          }}
                          displayValue={() => (multiple ? '' : query || '')}
                          className={clsx(
                            'truncate w-full outline-none focus:outline-0 capitalize',
                            backgroundIsWhite
                              ? 'bg-background border-dark-border'
                              : 'bg-white border-light-border',
                          )}
                        />
                      </HeadlessCombobox.Button>
                      {hasInnerChips && unDuplicateItems.length > 0 && (
                        <div className="flex flex-wrap gap-2 mt-2">
                          {Array.isArray(selectedItem) &&
                            unDuplicateItems.map((option, idx: number) => (
                              <Chip
                                onDelete={() => onRemoveOption(option)}
                                key={idx}
                                label={option.title}
                                variant={chipVariant}
                              />
                            ))}
                        </div>
                      )}
                    </div>
                    {false && <Loading />}
                    {hasArrowIcon && (
                      <HeadlessCombobox.Button
                        className={clsx(open && 'rotate-180')}
                      >
                        <ChevronDownIcon
                          className="w-5 h-5 text-title-light"
                          aria-hidden="true"
                        />
                      </HeadlessCombobox.Button>
                    )}
                  </Box>
                </div>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  {!disabled && (
                    <HeadlessCombobox.Options
                      className={clsx(
                        'absolute top-2',
                        optionsWidth ?? 'w-full',
                      )}
                    >
                      <Box
                        background={background}
                        className="overflow-y-auto flex-col mt-1 max-h-[280px]"
                      >
                        {filteredOptions.length === 0 && !loading && (
                          <>
                            {hasCustomizedOption && !isEmpty(query) ? (
                              <HeadlessCombobox.Option
                                key={query}
                                value={{
                                  title: query,
                                  value: query,
                                  type: optionType.customized,
                                }}
                                className={({ selected, active }) =>
                                  clsx(
                                    'py-2 px-1 select-none hover:cursor-pointer capitalize',
                                    selected
                                      ? 'bg-secondary-100 text-white'
                                      : backgroundIsWhite
                                      ? 'bg-background border-dark-border'
                                      : 'bg-white border-light-border',
                                    active && !selected && 'bg-gray-300/50',
                                    active && selected && 'bg-secondary-hover',
                                  )
                                }
                              >
                                Suggest adding: {query}
                              </HeadlessCombobox.Option>
                            ) : (
                              <Text className="text-center">
                                No results found. Try something else
                              </Text>
                            )}
                          </>
                        )}
                        {loading && (
                          <Text className="text-center">Loading...</Text>
                        )}
                        {filteredOptions.map((option, index) => {
                          const isLast = index === filteredOptions.length - 1
                          return (
                            <>
                              <HeadlessCombobox.Option
                                key={option.value}
                                value={option}
                                className={({ selected, active }) =>
                                  clsx(
                                    'py-2 px-1 select-none hover:cursor-pointer capitalize',
                                    selected
                                      ? 'bg-secondary-100 text-white'
                                      : backgroundIsWhite
                                      ? 'bg-background border-dark-border'
                                      : 'bg-white border-light-border',
                                    active && !selected && 'bg-gray-300/50',
                                    active && selected && 'bg-secondary-hover',
                                  )
                                }
                              >
                                {renderOption
                                  ? renderOption(option)
                                  : option.title}
                              </HeadlessCombobox.Option>
                              {!isLast && <Line background={background} />}
                            </>
                          )
                        })}
                      </Box>
                    </HeadlessCombobox.Options>
                  )}
                </Transition>
                {showChips && !hasInnerChips && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {Array.isArray(selectedItem) &&
                      [...new Set(selectedItem)].map((option, idx: number) => (
                        <Chip
                          onDelete={() => onRemoveOption(option)}
                          key={idx}
                          label={option.title}
                          variant={chipVariant}
                        />
                      ))}
                  </div>
                )}
              </div>
            )}
          </HeadlessCombobox>
        </div>
        {tooltip && (
          <div className="absolute -right-7">
            <InfoLabel tooltip={tooltip} />
          </div>
        )}
      </div>
      {error && <Text className="text-error">{error}</Text>}
    </div>
  )
}

export default Combobox
