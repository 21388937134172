import { isEmpty, isNil } from 'ramda'
import { useEffect, useState } from 'react'
import { getGeoLocationData } from 'services'

const useGeolocation = () => {
  const [city, setCity] = useState<string>('')
  const [zipCode, setZipCode] = useState<string>('')
  const [region, setRegion] = useState<string>('')
  const [regionCode, setRegionCode] = useState<string>('')

  useEffect(() => {
    const getLocationData = async () => {
      const {
        zipCode: zip,
        city,
        regionCode,
        region,
      } = await getGeoLocationData()
      setZipCode(zip)
      setRegion(region)
      setRegionCode(regionCode)
      setCity(city)
    }

    getLocationData()
  }, [])

  const getLocationName = () => {
    if (
      (isNil(city) || isEmpty(city)) &&
      (isNil(regionCode) || isEmpty(regionCode))
    )
      return ''
    if (isNil(city) || isEmpty(city)) return region
    return `${city}, ${regionCode}`
  }

  return {
    zipCode,
    location: getLocationName(),
    region,
    regionCode,
    city,
  }
}

export default useGeolocation
