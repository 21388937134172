import { gql } from '@apollo/client'

export const M_INSERT_POST_COMMENT = gql`
  mutation InsertOnePostComment(
    $content: String!
    $from_profile_id: Int!
    $to_post_id: Int!
    $parent_id: Int
    $is_anonymous: Boolean!
  ) {
    insert_post_comments_one(
      object: {
        content: $content
        from_profile_id: $from_profile_id
        to_post_id: $to_post_id
        parent_id: $parent_id
        is_anonymous: $is_anonymous
      }
    ) {
      content
      from_profile_id
      created_at
      to_post_id
      id
      parent_id
    }
  }
`

export const M_COMMENT_LIKE = gql`
  mutation CommentLike($commentId: Int!, $fromProfileId: Int!) {
    insert_liked_comments_one(
      object: { from_profile_id: $fromProfileId, comment_id: $commentId }
    ) {
      comment_id
      created_at
      from_profile_id
    }
  }
`
export const M_COMMENT_UNLIKE = gql`
  mutation CommentUnLike($commentId: Int!, $fromProfileId: Int!) {
    delete_liked_comments_by_pk(
      comment_id: $commentId
      from_profile_id: $fromProfileId
    ) {
      comment_id
      from_profile_id
    }
  }
`

export const M_UPTADE_COMMENT = gql`
  mutation UpdateComment($content: String!, $id: Int!) {
    update_post_comments_by_pk(
      pk_columns: { id: $id }
      _set: { content: $content }
    ) {
      id
      content
    }
  }
`

export const M_DELETE_COMMENT = gql`
  mutation DeleteComment($id: Int!) {
    delete_post_comments_by_pk(id: $id) {
      id
    }
  }
`
