import { LastSeenByMessageGroupId_message_group_profiles } from 'operations/LastSeenByMessageGroupId'
import { MessageGroupById } from 'operations/MessageGroupById'
import { MessageGroupByIdVariables } from 'operations/MessageGroupById'

import {
  M_RESET_BUSINESS_MESSAGE_COUNT,
  M_RESET_REGULAR_PROFILE_MESSAGE_COUNT,
  M_UPDATE_BUSINESS_LAST_SEEN_MSG_GROUP,
  M_UPDATE_LAST_SEEN_MSG_GROUP,
} from 'lib/mutations/messages'
import {
  Q_BUSINESS_LAST_SEEN,
  Q_LAST_SEEN,
  Q_MESSAGE_GROUP_BY_ID,
} from 'lib/queries/messages'

import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'

import useChatContext from '../hooks/useChatContext'
import { activeConversationProperties } from '../types/types'
import {
  addToActiveConversations,
  getActiveConversationById,
} from '../utils/conversation'
import { getProfileProperties } from '../utils/profile'
import Container from './container'
import Header from './header'
import Input from './input'
import { BusinessProfileSnapshot, ProfileSnapshot } from './snapshot'

interface ConversationProps {
  conversation: activeConversationProperties
  isPage?: boolean
}

function Conversation({ conversation, isPage = false }: ConversationProps) {
  const [isExpanded, setIsExpanded] = useState(true)
  const [isOnline, setIsOnline] = useState(false)
  const [profileSnapshotVisible, setProfileSnapshotVisible] = useState(false)

  const { data: conversationData } = useQuery(Q_MESSAGE_GROUP_BY_ID, {
    variables: {
      messageGroupId: conversation.id,
    },
    skip: !conversation.id,
  })

  const {
    activeProfileOnChat,
    activeConversations,
    setActiveConversations,
    isBusinessProfileActive = false,
  } = useChatContext()

  const { id: conversationId } = conversation

  const ownerProfileId = activeProfileOnChat?.id || -1

  //get the profile the current user is talking to (conversation)
  const otherProfileInConversation =
    conversationData?.message_group[0]?.profiles?.find((profile: any) => {
      const fromProfile =
        profile?.from_business_profile || profile?.from_profile
      return fromProfile?.id !== ownerProfileId
    })

  const profileImTalkingTo =
    otherProfileInConversation?.from_business_profile ||
    otherProfileInConversation?.from_profile

  const {
    fullName: conversationName = '',
    avatar: conversationAvatar = {},
    link,
  } = getProfileProperties(profileImTalkingTo)

  const isProfileImTalkingToBusiness =
    profileImTalkingTo?.__typename == 'business_profiles'

  const commonStyles = isPage
    ? 'w-full h-full'
    : clsx(
        'flex-[0_0_auto] flex flex-col h-[400px] max-h-[calc(100vh-100px)] w-[336px] bg-white rounded-r-md ring-1 ring-gray-light',
      )

  const maximized = 'ease-out duration-150'
  const minimizedConversation = 'ease-in duration-150 w-[270px]'

  const [changeLastSeenMessages] = useMutation(
    isBusinessProfileActive
      ? M_UPDATE_BUSINESS_LAST_SEEN_MSG_GROUP
      : M_UPDATE_LAST_SEEN_MSG_GROUP,
  )

  const [resetUnreadMessagesCount] = useMutation(
    isBusinessProfileActive
      ? M_RESET_BUSINESS_MESSAGE_COUNT
      : M_RESET_REGULAR_PROFILE_MESSAGE_COUNT,
  )
  const [
    getLastSeen,
    { loading: lastSeenLoading, error: lastSeenError, data: lastSeenData },
  ] = useLazyQuery<LastSeenByMessageGroupId_message_group_profiles[]>(
    isBusinessProfileActive ? Q_BUSINESS_LAST_SEEN : Q_LAST_SEEN,
    { fetchPolicy: 'network-only' },
  )

  const getConversationStyles = (style: any) => {
    return isPage ? {} : style
  }

  const getIsOnlineUser = (isUserOnline: boolean) => {
    setIsOnline(isUserOnline)
  }

  const toggleActiveConversationStatus = () => {
    const newActiveConversations = addToActiveConversations({
      id: conversationId,
      activeConversations,
    })
    setActiveConversations(newActiveConversations)
  }

  useEffect(() => {
    if (!isExpanded) {
      setProfileSnapshotVisible(false)
    }
  }, [isExpanded, setProfileSnapshotVisible])

  useEffect(() => {
    const activeConversation = getActiveConversationById({
      id: conversationId,
      activeConversations: activeConversations,
    })
    if (!isNil(activeConversation)) {
      setIsExpanded(!activeConversation.isMinimized)
    }
  }, [activeConversations, conversationId])

  useEffect(() => {
    changeLastSeenMessages({
      variables: {
        last_seen: new Date(),
        from_profile_id: ownerProfileId,
        message_group_id: conversationId,
      },
    })
    resetUnreadMessagesCount({
      variables: {
        profileId: ownerProfileId,
        messageGroupId: conversationId,
      },
    })
  }, [
    ownerProfileId,
    conversationId,
    changeLastSeenMessages,
    isExpanded,
    resetUnreadMessagesCount,
  ])

  return (
    <div className="flex ml-2">
      <div
        style={
          !profileSnapshotVisible
            ? { transform: 'translateX(90%)' }
            : { transform: 'translateX(0) ' }
        }
        className={clsx(
          'w-fit h-[400px]',

          !profileSnapshotVisible
            ? 'ease-in duration-300'
            : 'ease-out duration-300',
        )}
      >
        {isProfileImTalkingToBusiness ? (
          <BusinessProfileSnapshot
            profileId={profileImTalkingTo?.id}
            ownerProfileId={ownerProfileId}
            profileSnapshotVisible={profileSnapshotVisible}
            setProfileSnapshotVisible={setProfileSnapshotVisible}
          />
        ) : (
          <ProfileSnapshot
            profileId={profileImTalkingTo?.id}
            ownerProfileId={ownerProfileId}
            profileSnapshotVisible={profileSnapshotVisible}
            setProfileSnapshotVisible={setProfileSnapshotVisible}
          />
        )}
      </div>

      {!profileSnapshotVisible && isExpanded && (
        <button
          className="w-6 h-20 rounded-tl-md rounded-bl-md bg-primary"
          onClick={() => setProfileSnapshotVisible(true)}
        >
          <ChevronLeftIcon className="stroke-white" />
        </button>
      )}
      <div
        style={getConversationStyles(
          !isExpanded
            ? { transform: 'translateY(100%) translateY(-48px)' }
            : { transform: 'translateY(0)' },
        )}
        className={clsx(
          commonStyles,
          getConversationStyles(isExpanded ? maximized : minimizedConversation),
          profileSnapshotVisible && 'shadow-2xl',
        )}
      >
        <Header
          isOnline={isOnline}
          messageGroupId={conversationId}
          isExpanded={isExpanded}
          setIsExpanded={toggleActiveConversationStatus}
          avatar={conversationAvatar}
          title={conversationName}
          link={link}
        />

        <div className={clsx('flex relative flex-col h-full')}>
          <div
            className={clsx(
              'flex flex-col overflow-hidden flex-[1_0_0] relative mb-auto',
            )}
          >
            <Container
              isBusiness={isBusinessProfileActive}
              lastSeenData={lastSeenData}
              isPage={false}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              getIsOnlineUser={getIsOnlineUser}
              messageGroupId={conversationId}
              ownerProfileId={ownerProfileId}
              getLastSeen={getLastSeen}
            />
          </div>
          <Input
            isBusiness={isBusinessProfileActive}
            fromProfileId={ownerProfileId}
            profileImTalkingTo={profileImTalkingTo}
            messageGroupId={conversationId}
          />
        </div>
      </div>
    </div>
  )
}

export default Conversation
