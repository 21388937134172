import { GetFullBusinessProfile } from 'operations/GetFullBusinessProfile'

import { Q_GET_FULL_BUSINESS_PROFILE } from 'lib/queries/profiles'

import { useQuery } from '@apollo/client'
import {
  GlobeAltIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  MailIcon,
} from '@heroicons/react/solid'
import clsx from 'clsx'
import Link from 'next/link'
import { Dispatch, FunctionComponent, SetStateAction } from 'react'

import ChevronRightIcon from 'components/common/chevron_right_icon'
import PhoneIcon from 'components/common/phone_icon'
import { Avatar, Text } from 'components/ui'

interface BusinessProfileSnapshotProps {
  ownerProfileId: number
  profileId: number
  profileSnapshotVisible?: boolean
  setProfileSnapshotVisible?: Dispatch<SetStateAction<boolean>>
}

function BusinessProfileSnapshot({
  profileId,
  profileSnapshotVisible = false,
  setProfileSnapshotVisible,
}: BusinessProfileSnapshotProps) {
  const { data } = useQuery<GetFullBusinessProfile>(
    Q_GET_FULL_BUSINESS_PROFILE,
    {
      variables: { profileId: profileId || '-1' },
    },
  )

  const profile = data?.business_profiles[0]
  const avatar = profile?.logo
  const website = profile?.website
  const {
    city: location,
    email,
    phoneNumber: phone,
  } = profile?.addresses?.[0] ?? {}

  const maximized = 'ease-out duration-300'
  const minimized = 'ease-in duration-300'
    ? {
        avatar: { w: '70px', h: '70px' },
        nameTextSize: 'text-2xl md:text-2xl',
        degreeTextSize: 'text-base',
        commonTextSize: 'text-sm',
        elementsHeight: 'h-8',
      }
    : {
        avatar: { w: '55px', h: '55px' },
        nameTextSize: 'text-lg md:text-lg',
        degreeTextSize: 'text-md',
        commonTextSize: 'text-xs',
        elementsHeight: 'h-4',
      }

  if (!profile || !profileSnapshotVisible) return null

  return (
    <div
      className={`flex min-h-full${
        profileSnapshotVisible ? minimized : maximized
      }`}
    >
      {profileSnapshotVisible && setProfileSnapshotVisible && (
        <button
          className="ml-2 w-6 h-20 rounded-tl-md rounded-bl-md ring-1 bg-primary ring-gray-light"
          onClick={() => setProfileSnapshotVisible(false)}
        >
          <div>
            <ChevronRightIcon className="mt-1 ml-1 stroke-white" />
          </div>
        </button>
      )}
      <div
        className={clsx(
          'flex flex-col ring-1 gap-3 ring-gray-light rounded-br-xl w-full bg-white px-8 py-2 h-min-full',
        )}
      >
        <div className="flex gap-2 items-center">
          <Link href={`/page/org/${profile?.username}`} passHref>
            <Avatar
              avatar={avatar}
              layout="fill"
              className="object-cover rounded-full"
              alt="Profile image"
              size={12}
            />
          </Link>

          <Text variant="heading">{`${profile.name} `}</Text>
        </div>

        <div className="flex flex-col gap-2 pl-4">
          <Text className="flex gap-2 items-center" size="sm">
            <InformationCircleIcon className="w-4 h-4" /> Private Practice
          </Text>
          {location && (
            <Text className="flex gap-2 items-center" size="sm">
              <LocationMarkerIcon className="w-4 h-4" /> {location}
            </Text>
          )}
          {phone && (
            <Text className="flex gap-2 items-center" size="sm">
              <PhoneIcon className="w-4 h-4" /> {phone}
            </Text>
          )}
          {email && (
            <Text className="flex gap-2 items-center" size="sm">
              <MailIcon className="w-4 h-4" /> {email}
            </Text>
          )}
          {website && (
            <Text className="flex gap-2 items-center" size="sm">
              <GlobeAltIcon className="w-4 h-4" /> {website}
            </Text>
          )}
        </div>
      </div>
    </div>
  )
}

export default BusinessProfileSnapshot
