/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_invitations.yaml
// ====================================================

/**
Table name: invitations, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _exists:
          _table:
            name: users
            schema: public
          _where:
            _and:
              - id:
                  _eq: X-Hasura-User-Id
              - roles:
                  _contains: approved
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - from_profile:
          owner_id:
            _eq: X-Hasura-User-Id

**/
export const check_invitations_insert_permissions = function(feature_flags:any, invitation:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& user?.roles?.includes("approved")))
	|| (feature_flags.strict_permissions === false
		&& invitation?.from_profile?.owner_id === user?.id))
}


/**
Table name: invitations, update permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _exists:
          _table:
            name: users
            schema: public
          _where:
            _and:
              - id:
                  _eq: X-Hasura-User-Id
              - roles:
                  _contains: approved
      - _or:
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
          - to_profile:
              owner_id:
                _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
          - to_profile:
              owner_id:
                _eq: X-Hasura-User-Id

**/
export const check_invitations_update_permissions = function(feature_flags:any, invitation:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& user?.roles?.includes("approved"))
		&& (invitation?.from_profile?.owner_id === user?.id
			|| invitation?.to_profile?.owner_id === user?.id))
	|| (feature_flags.strict_permissions === false
		&& (invitation?.from_profile?.owner_id === user?.id
			|| invitation?.to_profile?.owner_id === user?.id)))
}


/**
Table name: invitations, select permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _exists:
          _table:
            name: users
            schema: public
          _where:
            _and:
              - id:
                  _eq: X-Hasura-User-Id
              - roles:
                  _contains: member
      - _or:
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
          - to_profile:
              owner_id:
                _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
          - to_profile:
              owner_id:
                _eq: X-Hasura-User-Id

**/
export const check_invitations_select_permissions = function(feature_flags:any, invitation:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& user?.roles?.includes("member"))
		&& (invitation?.from_profile?.owner_id === user?.id
			|| invitation?.to_profile?.owner_id === user?.id))
	|| (feature_flags.strict_permissions === false
		&& (invitation?.from_profile?.owner_id === user?.id
			|| invitation?.to_profile?.owner_id === user?.id)))
}


/**
Table name: invitations, delete permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _exists:
          _table:
            name: users
            schema: public
          _where:
            _and:
              - id:
                  _eq: X-Hasura-User-Id
              - roles:
                  _contains: approved
      - _or:
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
          - to_profile:
              owner_id:
                _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
          - to_profile:
              owner_id:
                _eq: X-Hasura-User-Id

**/
export const check_invitations_delete_permissions = function(feature_flags:any, invitation:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id
			&& user?.roles?.includes("approved"))
		&& (invitation?.from_profile?.owner_id === user?.id
			|| invitation?.to_profile?.owner_id === user?.id))
	|| (feature_flags.strict_permissions === false
		&& (invitation?.from_profile?.owner_id === user?.id
			|| invitation?.to_profile?.owner_id === user?.id)))
}