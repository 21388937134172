import { Dispatch, RefObject, SetStateAction, useEffect } from 'react'

interface Props {
  ref: RefObject<HTMLElement>
  setState: Dispatch<SetStateAction<boolean>>
}

const useOutsideClick = ({ ref, setState }: Props) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setState(true)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setState])
}

export default useOutsideClick
