import type { GetYouMayKnowProfiles } from 'operations/GetYouMayKnowProfiles'

import { isDev } from 'lib/env'
import { Q_GET_PROFILE_OWNER_ID } from 'lib/queries/owner_id'

import { useUser } from '@clerk/nextjs'
import { usePostHog } from 'next-use-posthog'

const PostHog = () => {
  const { user } = useUser()

  /*usePostHog('phc_sjFOMCwbUvsIRLc9e80CsFwx2CTCpGAW7uIEUCGHWBZ', {
    api_host: 'https://tracking.belongly.com',
    loaded: (posthog) => {
      if (isDev) {
        posthog.opt_out_capturing()
      } else {
        posthog.identify(user?.id)
        posthog.people.set({
          email: user?.primaryEmailAddress?.emailAddress, // Email address
        })
      }
    },
  })*/
  return null
}

export default PostHog
