import { gql } from '@apollo/client'

export const Q_FEATURE_FLAGS = gql`
  query GetFeatureFlags {
    feature_flags {
      name
      enabled
    }
  }
`
