import { GetReferrals } from 'operations/GetReferrals'

import { Q_GET_REFERRALS } from 'lib/queries/opportunities'

import { useQuery } from '@apollo/client'

import useUnreadReferrals from 'components/hooks/useNewUnreadReferrals'
import useProfile from 'components/hooks/useProfile'

import NavTab from '../nav_tab'

interface ReferralsLinkProps {
  href: string
  passHref: boolean | undefined
}

function ReferralsLink({ href, passHref }: ReferralsLinkProps) {
  const { profile: ownerProfile } = useProfile()

  const {
    checkReadReferrals,
    referralState: { isRead },
  } = useUnreadReferrals()

  useQuery<GetReferrals>(Q_GET_REFERRALS, {
    variables: {
      ownerProfileId: ownerProfile?.id,
    },
    pollInterval: 300000,
    onCompleted: (resp) => {
      checkReadReferrals(resp.opportunities[0])
    },
  })

  return (
    <NavTab
      href={href}
      passHref={passHref}
      title="Referrals"
      subtitle="Post/Take Referrals"
    />
  )
}

export default ReferralsLink
