import Link from 'next/link'

import { useNotifications } from 'components/hooks'

const NotificationBellIcon = ({ size = 5 }: { size?: number }) => {
  const { notifications: totalNotifications } = useNotifications()

  return (
    <Link href="/notifications" passHref legacyBehavior>
      <button className="inline-block relative">
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 563.5 563.5"
          className={`h-${size} w-${size}`}
        >
          <path className="cls-1" fill="#e6e6e6" />
          <circle
            className="cls-1"
            cx="281.75"
            cy="281.75"
            r="281.75"
            fill="#D9D9D9"
          />
          <path d="m285.28,117.03c.73.38,1.44.79,2.19,1.14,5.09,2.4,7.99,6.35,8.18,12.02.15,4.27.12,8.56.03,12.84-.04,1.82.5,2.58,2.41,2.93,39.99,7.27,69.68,36.03,77.94,75.86,1.38,6.64,1.63,13.58,1.81,20.39.37,14.43-.29,28.92.61,43.31,1.53,24.61,12.1,45.15,30.43,61.61,5.28,4.74,9.35,10.04,9.97,17.31,1.25,14.43-8.77,25.8-23.32,26.58-.85.05-1.71.04-2.57.04-73.93,0-147.87-.02-221.8.03-8.53,0-15.75-2.62-21.1-9.48-7.96-10.23-6.3-24.27,4.05-33.34,9.04-7.92,16.39-17.13,21.9-27.81,7-13.57,10.18-28.05,10.21-43.27.02-13.16-.15-26.32.09-39.48.84-45.04,34.69-84.26,79.09-91.65,2.53-.42,3.16-1.34,3.06-3.68-.16-3.63-.07-7.27-.03-10.91.08-6.93,2.99-11.16,9.42-13.79.36-.15.67-.43,1.01-.65,2.14,0,4.28,0,6.42,0Z" />
          <path d="m232.05,404.84h99.93c-.7,19.66-24.77,40.18-47.73,40.97-28.51.98-48.9-20.54-52.19-40.97Z" />
        </svg>
        {totalNotifications > 0 && (
          <span className="flex absolute -top-1 -right-1 justify-center items-center w-4 h-4 leading-none text-white rounded-full text-[8px] bg-fourth">
            <p className="text-white">{totalNotifications}</p>
          </span>
        )}
      </button>
    </Link>
  )
}

export default NotificationBellIcon
