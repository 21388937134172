import { M_INSERT_SUGGESTED_INSURANCE } from 'lib/mutations/insurances'
import { newReferralData } from 'lib/reactiveVariables'

import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import { isEmpty } from 'ramda'
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'

import LocationIcon from 'components/common/location_icon'
import { Modalities } from 'components/profile/user_basic_details/modality_badge'
import {
  Checkbox,
  Combobox,
  ConfirmationModal,
  NewReferralStep,
  RadioGroupButtons,
  Text,
} from 'components/ui'
import { optionType } from 'components/ui/combobox/combobox'

import {
  mapValuesAsCheckboxOptions,
  mapValuesAsOptions,
  parseSelectedCheckboxOptions,
} from 'utils/opportunities/referrals/referrals'

import useDataFetching from '../../hooks/useDataFetching'
import { CreateReferralContext } from '../../step_controller/step_controller'
import { getBoxFormat, getLocationFormat } from '../../utils'

interface ICheckboxOption {
  name: string
  selected: boolean
}

interface PatientInfoState {
  locations: { title: string; value: string }[]
  communities: { title: string; value: string }[]
  insurances: { title: string; value: string }[]
  sessionModality: string
}
interface IStepFour extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const StepFour: FunctionComponent<IStepFour> = () => {
  const textContent = {
    stepNumber: '4/6',
    stepDescription: 'Details',
    title: "Let's add some details about your practice",
    description:
      'Adding these details about your practice will help therapists understand if they can match their referrals with you based on certain criteria such as age group, insurance, location and communities served. Adding these details will also help us match your referral with referrals based on corresponding profile markers.',
  }

  const { goToPreviousStep, goToNextStep } = useContext(CreateReferralContext)

  const {
    referralSeekingInfo: {
      locations: referralSeekingLocation,
      typeOfClients: referralSeekingTypeOfClients,
      ageGroups: referralSeekingAgeGroups,
      communities: referralSeekingCommunities,
      genders: referralSeekingGenders,
      insurances: referralSeekingInsurances,
      sessionModality: referralSeekingSessionModality,
    },
  } = newReferralData()

  const {
    locationsData,
    insurancesData,
    orientationsData,
    getInsurances,
    getOrientations,
    getLocations,
  } = useDataFetching()

  const [insertSuggestedInsurance] = useMutation(M_INSERT_SUGGESTED_INSURANCE)

  const [showInsuranceModal, setShowInsuranceModal] = useState(false)
  const [customizedInsurance, setCustomizedInsurance] = useState('')

  const insurances =
    insurancesData?.insurances.map(({ name }) => mapValuesAsOptions(name)) || []

  const orientations =
    orientationsData?.orientations.map(({ name }) =>
      mapValuesAsOptions(name),
    ) || []

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm({
    defaultValues: useMemo(
      () => ({
        locations: referralSeekingLocation?.map(getLocationFormat) || [],
        typeOfClients: referralSeekingTypeOfClients || [],
        genders: referralSeekingGenders || [],
        ageGroups: referralSeekingAgeGroups || [],
        communities: referralSeekingCommunities?.map(mapValuesAsOptions) || [],
        insurances: referralSeekingInsurances?.map(mapValuesAsOptions) || [],
        sessionModality: referralSeekingSessionModality,
      }),
      [
        referralSeekingAgeGroups,
        referralSeekingCommunities,
        referralSeekingGenders,
        referralSeekingLocation,
        referralSeekingInsurances,
        referralSeekingTypeOfClients,
        referralSeekingSessionModality,
      ],
    ),
  })

  const locationsDesignations = getBoxFormat(
    locationsData?.locations || [],
  ).filter(
    (item) =>
      !getValues('locations')?.some(
        (location) => location.title === item.title,
      ),
  )

  useEffect(() => {
    reset({
      locations: referralSeekingLocation?.map(getLocationFormat) || [],
      typeOfClients: referralSeekingTypeOfClients || [],
      genders: referralSeekingGenders || [],
      ageGroups: referralSeekingAgeGroups || [],
      communities: referralSeekingCommunities?.map(mapValuesAsOptions) || [],
      insurances: referralSeekingInsurances?.map(mapValuesAsOptions) || [],
      sessionModality: referralSeekingSessionModality,
    })
  }, [
    reset,
    referralSeekingAgeGroups,
    referralSeekingCommunities,
    referralSeekingGenders,
    referralSeekingLocation,
    referralSeekingInsurances,
    referralSeekingTypeOfClients,
    referralSeekingSessionModality,
  ])

  const handleCheckboxChange = (data: any, key: any) => {
    const dataToModify = data.find(({ name }: any) => name === key)
    const dataToAdd = {
      name: dataToModify?.name || '',
      selected: !dataToModify?.selected,
    }
    const otherData = data.filter(
      ({ name }: any) => name !== dataToModify?.name,
    )

    return [otherData, dataToAdd]
  }

  const therapyForms = ['Individual', 'Couples', 'Family', 'Group'].map(
    (value) => mapValuesAsCheckboxOptions(value, referralSeekingTypeOfClients),
  )

  const [selectedTypeOfClients, setSelectedTypeOfClients] =
    useState<ICheckboxOption[]>(therapyForms)

  const handleTypeOfClientSelection = ({
    typeOfClient,
    callback,
  }: {
    typeOfClient: string
    callback: (value: any[]) => void
  }) => {
    const [otherTypeOfClients, typeOfClientsToAdd] = handleCheckboxChange(
      selectedTypeOfClients,
      typeOfClient,
    )
    if (otherTypeOfClients) {
      setSelectedTypeOfClients([...otherTypeOfClients, typeOfClientsToAdd])
      callback(
        parseSelectedCheckboxOptions([
          ...otherTypeOfClients,
          typeOfClientsToAdd,
        ]),
      )
    }
  }

  const genderOptions = [
    'Women',
    'Men',
    'Nonbinary / Nonconforming',
    'Transgender',
  ].map((value) => mapValuesAsCheckboxOptions(value, referralSeekingGenders))

  const [selectedGenders, setSelectedGenders] =
    useState<ICheckboxOption[]>(genderOptions)

  const handleGenderSelection = ({
    gender,
    callback,
  }: {
    gender: string
    callback: (value: any[]) => void
  }) => {
    const [otherGenders, genderToAdd] = handleCheckboxChange(
      selectedGenders,
      gender,
    )
    if (otherGenders) {
      setSelectedGenders([...otherGenders, genderToAdd])
      callback(parseSelectedCheckboxOptions([...otherGenders, genderToAdd]))
    }
  }

  const ageGroups = ['Children', 'Adolescents', 'Adult', 'Elderly'].map(
    (value) => mapValuesAsCheckboxOptions(value, referralSeekingAgeGroups),
  )
  const [selectedAgeGroups, setSelectedAgeGroups] =
    useState<ICheckboxOption[]>(ageGroups)
  const handleAgeGroupSelection = ({
    ageGroup,
    callback,
  }: {
    ageGroup: string
    callback: (value: any[]) => void
  }) => {
    const [otherAgeGroups, ageGroupToAdd] = handleCheckboxChange(
      selectedAgeGroups,
      ageGroup,
    )
    if (otherAgeGroups) {
      setSelectedAgeGroups([...otherAgeGroups, ageGroupToAdd])
      callback(parseSelectedCheckboxOptions([...otherAgeGroups, ageGroupToAdd]))
    }
  }

  useEffect(() => {
    if (!isEmpty(selectedAgeGroups)) clearErrors('ageGroups')
  }, [clearErrors, selectedAgeGroups])

  useEffect(() => {
    if (!isEmpty(selectedGenders)) clearErrors('genders')
  }, [clearErrors, selectedGenders])

  useEffect(() => {
    if (!isEmpty(selectedTypeOfClients)) clearErrors('typeOfClients')
  }, [clearErrors, selectedTypeOfClients])

  const handleContinue = ({
    locations,
    communities,
    insurances,
    sessionModality,
  }: PatientInfoState) => {
    const allNewReferralData = newReferralData()
    const {
      referralSeekingInfo: {
        languages,
        mainSpecialties,
        interventions,
        issues,
      },
    } = allNewReferralData
    newReferralData({
      ...allNewReferralData,
      referralSeekingInfo: {
        locations: locations.map((location) => JSON.parse(location.value)),
        typeOfClients: parseSelectedCheckboxOptions(selectedTypeOfClients),
        genders: parseSelectedCheckboxOptions(selectedGenders),
        ageGroups: parseSelectedCheckboxOptions(selectedAgeGroups),
        communities: communities.map((community) => community.value),
        insurances: insurances.map((insurance) => insurance.value),
        sessionModality,
        languages,
        mainSpecialties,
        interventions,
        issues,
      },
    })
    goToNextStep()
  }

  const handleAddNewInsurance = () => {
    insertSuggestedInsurance({
      variables: {
        insurance: customizedInsurance,
      },
    })
    setShowInsuranceModal(false)
  }

  const styles = {
    row: 'flex flex-col xl:flex-row gap-4 w-full xl:items-center',
    label: 'flex-none xl:w-24',
    field: 'w-9/12',
  }

  return (
    <>
      <NewReferralStep
        stepNumber={textContent.stepNumber}
        stepDescription={textContent.stepDescription}
        title={textContent.title}
        description={textContent.description}
        formTitle="Add some details about your practice"
        onPositive={handleSubmit(handleContinue)}
        onNegative={goToPreviousStep}
      >
        <section className="flex flex-col gap-8 w-full">
          <div className={clsx('flex flex-col gap-4', 'order-2')}>
            <Text size="md" weight="bold" className="flex w-full">
              What types of client do you typically see?
            </Text>
            <Controller
              control={control}
              name="typeOfClients"
              rules={{
                validate: {
                  typeOfClients: (list) =>
                    !isEmpty(list) || 'Choose at least one option.',
                },
              }}
              render={({ field: { onChange } }) => (
                <>
                  <div className="flex flex-col gap-8 xl:flex-row">
                    {therapyForms.map((typeOfClient) => (
                      <Checkbox
                        key={typeOfClient.name}
                        label={typeOfClient.name}
                        selected={Boolean(
                          selectedTypeOfClients.find(
                            ({ name }) => name === typeOfClient.name,
                          )?.selected,
                        )}
                        onChange={() => {
                          handleTypeOfClientSelection({
                            typeOfClient: typeOfClient.name,
                            callback: onChange,
                          })
                        }}
                      />
                    ))}
                  </div>
                  {errors.typeOfClients && (
                    <Text className="text-error">
                      Choose at least one option.
                    </Text>
                  )}
                </>
              )}
            />
          </div>
          <div className="flex flex-col order-3 gap-4">
            <Text size="md" weight="bold" className="flex w-full">
              Do you specialize in working with specific Genders?
            </Text>
            <Controller
              control={control}
              name="genders"
              rules={{
                validate: {
                  genders: (list) =>
                    !isEmpty(list) || 'Choose at least one option.',
                },
              }}
              render={({ field: { onChange } }) => (
                <>
                  <div className="flex flex-col gap-8 xl:flex-row">
                    {genderOptions.map((gender) => (
                      <Checkbox
                        key={gender.name}
                        label={gender.name}
                        selected={Boolean(
                          selectedGenders.find(
                            ({ name }) => name === gender.name,
                          )?.selected,
                        )}
                        onChange={() =>
                          handleGenderSelection({
                            gender: gender.name,
                            callback: onChange,
                          })
                        }
                      />
                    ))}
                  </div>
                  {errors.genders && (
                    <Text className="text-error">
                      Choose at least one option.
                    </Text>
                  )}
                </>
              )}
            />
          </div>
          <div className="flex flex-col order-4 gap-4">
            <Text size="md" weight="bold" className="flex w-full">
              Do you specialize in specific age groups?
            </Text>
            <Controller
              control={control}
              name="ageGroups"
              rules={{
                validate: {
                  ageGroups: (list) =>
                    !isEmpty(list) || 'Choose at least one option.',
                },
              }}
              render={({ field: { onChange } }) => (
                <>
                  <div className="flex flex-col gap-8 xl:flex-row">
                    {ageGroups.map((ageGroup) => (
                      <Checkbox
                        key={ageGroup.name}
                        label={ageGroup.name}
                        selected={Boolean(
                          selectedAgeGroups.find(
                            ({ name }) => name === ageGroup.name,
                          )?.selected,
                        )}
                        onChange={() =>
                          handleAgeGroupSelection({
                            ageGroup: ageGroup.name,
                            callback: onChange,
                          })
                        }
                      />
                    ))}
                  </div>
                  {errors.ageGroups && (
                    <Text className="text-error">
                      Choose at least one option.
                    </Text>
                  )}
                </>
              )}
            />
          </div>
          <div className="flex flex-col order-7 gap-4">
            <Text size="md" weight="bold" className="flex w-full">
              What forms of therapy do you provide?
            </Text>
            <Controller
              control={control}
              name="sessionModality"
              render={({ field: { onChange, value } }) => (
                <RadioGroupButtons
                  fullWidth
                  className="w-full"
                  options={[
                    Modalities.inperson,
                    Modalities.online,
                    Modalities.both,
                  ]}
                  value={value}
                  onSelect={onChange}
                />
              )}
            />
          </div>
          <div className={clsx(styles.row, 'order-6')}>
            <Text size="md" weight="bold" className={styles.label}>
              Insurance
            </Text>
            <div className="flex flex-col w-full">
              <Controller
                control={control}
                name="insurances"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Combobox
                      hasCustomizedOption
                      chipVariant="primary"
                      options={insurances}
                      selectedItem={value}
                      onSelectItem={(selected) => {
                        if (Array.isArray(selected)) {
                          const lastIndex = selected.length - 1
                          if (
                            selected[lastIndex]?.type &&
                            selected[lastIndex]?.type === optionType.customized
                          ) {
                            setCustomizedInsurance(selected[lastIndex].value)
                            setShowInsuranceModal(true)
                          }
                          onChange(selected)
                        }
                      }}
                      background="white"
                      placeholder="Which insurance is accepted at this practice?"
                      multiple
                      hasInnerChips
                      onChange={(e) =>
                        getInsurances({
                          variables: {
                            search: `%${e.target.value.replace(/ +/g, '%')}%`,
                          },
                        })
                      }
                    />
                    <Text size="xs" variant="body" className="mt-1">
                      i.e. Cigna, Blue Cross Blue Shield, Out of Pocket
                    </Text>
                  </>
                )}
              />
            </div>
          </div>
          <div className={clsx(styles.row, 'order-5')}>
            <Text size="md" weight="bold" className={styles.label}>
              Communities
            </Text>
            <Controller
              control={control}
              name="communities"
              render={({ field: { onChange, value } }) => (
                <Combobox
                  chipVariant="primary"
                  options={orientations}
                  selectedItem={value}
                  onSelectItem={(selected) => {
                    if (Array.isArray(selected)) onChange(selected)
                  }}
                  placeholder="Select any community the client may be a part of"
                  multiple
                  background="white"
                  hasInnerChips
                  hasArrowIcon
                  onChange={(e) =>
                    getOrientations({
                      variables: {
                        search: `%${e.target.value.replace(/ +/g, '%')}%`,
                      },
                    })
                  }
                />
              )}
            />
          </div>
          <div className={clsx(styles.row, 'order-1')}>
            <Text size="md" weight="bold" className={styles.label}>
              Location
            </Text>
            <Controller
              control={control}
              name="locations"
              rules={{
                validate: {
                  locations: (list) =>
                    !isEmpty(list) || 'Select at least one location.',
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Combobox
                  icon={
                    <LocationIcon className="mr-1 h-5 fill-black stroke-white" />
                  }
                  chipVariant="primary"
                  options={locationsDesignations}
                  selectedItem={value}
                  onSelectItem={(selected) => {
                    // onChange(selected)
                    if (Array.isArray(selected)) onChange(selected)
                  }}
                  background="white"
                  hasInnerChips
                  placeholder="Type the location of the client (i.e. city and state)"
                  onChange={(e) =>
                    getLocations({
                      variables: {
                        search: `%${e.target.value.replace(/ +/g, '%')}%`,
                      },
                    })
                  }
                  error={errors.locations?.message}
                  multiple
                />
              )}
            />
          </div>
        </section>
      </NewReferralStep>
      <ConfirmationModal
        isOpen={showInsuranceModal}
        onClose={() => setShowInsuranceModal(false)}
        onConfirm={handleAddNewInsurance}
        title="Suggest adding an insurance"
        message={`Suggest adding ${customizedInsurance} to the list of insurances available`}
      />
    </>
  )
}

export default StepFour
