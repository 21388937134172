import {
  BUSINESS_INDEX,
  POSTS_INDEX,
  PROFILES_INDEX,
  REFERRALS_INDEX,
} from 'algolia.config'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { SearchCategories } from '../categories/categories'
import BusinessProfileResults from '../results/business_profiles/businnes_profile_results'
import PostResults from '../results/posts/post_results'
import ProfileResults from '../results/profiles/profile_results'
import ReferralResults from '../results/referrals/referral_results'

type placeholder = {
  [key: string]: string
}

type resultComponent = {
  [key: string]: (hit: any) => JSX.Element
}

type Result = { hit: any }

const useFullSearch = () => {
  const router = useRouter()

  const addQueryToRoute = ({ category }: { category: SearchCategories }) => {
    router.push({
      query: {
        category,
      },
    })
  }

  const categoriesToBeRendered = [
    {
      category: SearchCategories.People,
      indexName: PROFILES_INDEX,
      hitsPerPage: 3,
    },
    {
      category: SearchCategories.Referrals,
      indexName: REFERRALS_INDEX,
      hitsPerPage: 3,
    },
    {
      category: SearchCategories.Posts,
      indexName: POSTS_INDEX,
      hitsPerPage: 3,
    },

    {
      category: SearchCategories.Businesses,
      indexName: BUSINESS_INDEX,
      hitsPerPage: 3,
    },
  ]

  const placeholder: placeholder = {
    ['All']: 'Search on belongly',
    ['People']: 'Search for people',
    ['Referrals']: 'Search for referrals',
    ['Posts']: 'Search for posts',
    ['News']: '',
    ['Businesses']: 'Search for businesses pages',
  }

  const resultComponent: resultComponent = {
    ['People']: ({ hit }: Result) => <ProfileResults hit={hit} />,
    ['Referrals']: ({ hit }: Result) => <ReferralResults hit={hit} />,
    ['Posts']: ({ hit }: Result) => <PostResults hit={hit} />,
    ['News']: ({ hit }: Result) => <ReferralResults hit={hit} />,
    ['Businesses']: ({ hit }: Result) => <BusinessProfileResults hit={hit} />, // use the component
  }

  return {
    addQueryToRoute,
    resultComponent,
    categoriesToBeRendered,
    placeholder,
  }
}

export default useFullSearch
