import { gql } from '@apollo/client'

export const CommonFields = gql`
  fragment CommonFields on posts {
    id
    author_profile_id
    business_id
    post_category {
      type
      description
    }
    content
    release_date
    updated_at
    link_preview
    created_at
    release_date
    title
    topic
    images
    is_anonymous
  }
`

export const News = gql`
  fragment NewsFields on news {
    id
    author
    description
    image
    publication_date
    title
    url
    icon
    source_id
    news_source {
      id
      icon
      url
      name
      description
      followed_sources_aggregate {
        aggregate {
          count(columns: created_at)
        }
      }
    }
  }
`

export const ProfileFields = gql`
  fragment ProfileFields on profiles {
    id
    avatar
    first_name
    last_name
    username
    user_status {
      is_online
    }
    owner_id
    degree
    experiences
    current_job_new
    educations
    owner {
      id
      roles
    }
    connections_from_aggregate {
      aggregate {
        count
      }
    }
    connections_to_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const BusinessProfile = gql`
  ${ProfileFields}
  fragment BusinessProfile on business_profiles {
    id
    created_at
    updated_at
    name: organization_name
    size: organization_size
    type: organization_type
    subtype: organization_subtype
    username: organization_username
    logo
    tagline
    year_founded
    description
    has_right_to_act
    scheduling_link
    addresses
    website
    owner_id
    owner {
      ...ProfileFields
    }
    administrators {
      id
      created_at
      business_id
      profile_id
      permissions
      location
      created_by {
        ...ProfileFields
        office_info
        experiences_search {
          title
          organization
        }
      }
      profile {
        ...ProfileFields
        office_info
        experiences_search {
          title
          organization
        }
      }
    }
    message_group_profiles {
      unread_messages_count
      id
      from_business_profile_id
    }
  }
`

export const OpportunitiesFragment = gql`
  ${ProfileFields}
  ${BusinessProfile}
  fragment Opportunity on opportunities {
    title
    content
    created_at
    author_profile_id
    business_id
    category
    is_in_person
    is_telehealth
    seeking_info
    share_referral
    subcategory
    reason
    public_display
    urgent
    assistance_needed
    id
    uuid
    live
    filled
    image
    updated_at
    link_preview
    is_deleted
    author {
      ...ProfileFields
      contact_info
    }
    business {
      ...BusinessProfile
    }
    respondants {
      from_profile_id
      opportunity_id
      id
      recommender_message
      response_type
      response_state
      from_profile {
        ...ProfileFields
        contact_info
      }
      recommender_profile {
        ...ProfileFields
        contact_info
      }
      created_at
    }
    comments {
      id
      parent_id
      content
      created_at
      updated_at
      from_profile_id
      author {
        ...ProfileFields
      }
      from_profile_id
      opportunity_id
      content
      created_at
    }
    opportunity_respondants_outsiders {
      id
      first_name
      last_name
      email
      recommender {
        ...ProfileFields
      }
    }
    opportunity_respondants: respondants_aggregate {
      aggregate {
        count(columns: id)
      }
    }
    respondants_outsider: opportunity_respondants_outsiders_aggregate {
      aggregate {
        count(columns: id)
      }
    }
    respondants_declined: respondants_aggregate(
      where: { response_state: { _eq: "DECLINED" } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
`
const CommentLikedProfiles = gql`
  fragment CommentLikedProfiles on liked_comments {
    from_profile_id
    created_at
    from_profile {
      ...ProfileFields
    }
  }
`

export const LikedProfiles = gql`
  ${ProfileFields}
  fragment LikedProfiles on liked_posts {
    id: from_profile_id
    from_profile_id
    created_at
    from_profile {
      ...ProfileFields
    }
  }
`

export const Comments = gql`
  ${ProfileFields}
  ${CommentLikedProfiles}
  fragment CommentsFields on post_comments {
    id
    parent_id
    content
    created_at
    updated_at
    from_profile_id
    is_anonymous
    author {
      ...ProfileFields
    }
    liked_comments {
      ...CommentLikedProfiles
    }
    liked_comments_aggregate {
      aggregate {
        count(columns: from_profile_id)
      }
    }
  }
`

export const Q_GET_RECENT_POSTS = gql`
  query GetRecentPosts($limit: Int = 3) {
    posts(limit: $limit, order_by: { created_at: desc }) {
      content
      id
      created_at
      title
      topic
      category
    }
  }
`

export const Q_GET_POST = gql`
  ${CommonFields}
  ${ProfileFields}
  ${LikedProfiles}
  ${Comments}
  ${News}
  ${BusinessProfile}
  query GetPost($postId: Int!) {
    my_connections_posts: posts(where: { id: { _eq: $postId } }) {
      ...CommonFields
      business {
        ...BusinessProfile
      }
      author {
        ...ProfileFields
      }
      liked_profiles {
        ...LikedProfiles
      }
      comments(order_by: { created_at: asc }) {
        ...CommentsFields
      }
      news {
        ...NewsFields
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
  }
`

export const Q_GET_HOME_FEED = gql`
  ${CommonFields}
  ${News}
  ${ProfileFields}
  ${LikedProfiles}
  ${Comments}
  ${OpportunitiesFragment}
  ${BusinessProfile}
  query GetHomeFeed(
    $profileId: Int!
    $filter: String_comparison_exp
    $limit: Int
    $offset: Int
    $referralFilter: String_comparison_exp
  ) {
    my_connections_posts: posts(
      where: {
        _and: [
          { is_deleted: { _eq: false } }
          { post_category: { type: $filter } }
          { author_profile_id: { _is_null: false } }
        ]
      }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      business {
        ...BusinessProfile
      }
      liked_profiles {
        ...LikedProfiles
      }
      news {
        ...NewsFields
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
    not_my_connections_commented_posts: post_comments(
      where: {
        _and: [
          {
            _or: [
              {
                author: {
                  connections_to: { from_profile_id: { _eq: $profileId } }
                }
              }
              { author: { id: { _eq: $profileId } } }
            ]
          }
          {
            post: {
              _and: [
                { is_deleted: { _eq: false } }
                { post_category: { type: $filter } }
              ]
            }
          }
        ]
      }

      limit: $limit
      offset: $offset
      order_by: { to_post_id: desc }
      distinct_on: [to_post_id]
    ) {
      ...CommentsFields
      post {
        ...CommonFields
        author {
          ...ProfileFields
        }
        liked_profiles {
          ...LikedProfiles
        }
        liked_profiles_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        news {
          ...NewsFields
        }
      }
    }
    followed_news_posts: posts(
      where: {
        _and: [
          { is_deleted: { _eq: false } }
          { post_category: { type: $filter } }
          {
            _and: [
              {
                _or: [
                  { post_category: { type: { _eq: "NEWS" } } }
                  { post_category: { type: { _eq: "BLOG" } } }
                ]
              }
              {
                news: {
                  news_source: {
                    followed_sources: { from_profile_id: { _eq: $profileId } }
                  }
                }
              }
              { news: { news_source: { url: { _nilike: "%belongly%" } } } }
            ]
          }
        ]
      }
      order_by: { release_date: desc }
      limit: $limit
      offset: $offset
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      liked_profiles {
        ...LikedProfiles
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      news {
        ...NewsFields
      }
    }
    belongly_news_posts: posts(
      where: {
        _and: [
          { is_deleted: { _eq: false } }
          { post_category: { type: $filter } }
          {
            _and: [
              {
                _or: [
                  { post_category: { type: { _eq: "NEWS" } } }
                  { post_category: { type: { _eq: "BLOG" } } }
                ]
              }
              { news: { news_source: { url: { _ilike: "%belongly%" } } } }
            ]
          }
        ]
      }
      order_by: { release_date: desc }
      limit: $limit
      offset: $offset
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      liked_profiles {
        ...LikedProfiles
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      news {
        ...NewsFields
      }
    }
    not_my_connections_liked_posts: liked_posts(
      where: {
        _and: [
          {
            from_profile: {
              connections_to: { from_profile_id: { _eq: $profileId } }
            }
          }
          {
            to_post: {
              _and: [
                { is_deleted: { _eq: false } }
                { post_category: { type: $filter } }
              ]
            }
          }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: { post_id: desc }
      distinct_on: post_id
    ) {
      ...LikedProfiles
      post: to_post {
        ...CommonFields
        author {
          ...ProfileFields
        }
        liked_profiles {
          ...LikedProfiles
        }
        liked_profiles_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        comments_aggregate {
          aggregate {
            count(columns: from_profile_id)
          }
        }
        news {
          ...NewsFields
        }
      }
    }
    opportunities(
      where: {
        _and: [
          { live: { _eq: true } }
          { category: $referralFilter }
          { is_deleted: { _eq: false } }
        ]
      }
      order_by: { updated_at: desc }
      limit: $limit
      offset: $offset
    ) {
      ...Opportunity
    }
    business_posts: posts(
      where: {
        _and: [
          { is_deleted: { _eq: false } }
          { post_category: { type: $filter } }
          { business_id: { _is_null: false } }
        ]
      }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      business {
        ...BusinessProfile
      }
      liked_profiles {
        ...LikedProfiles
      }
      news {
        ...NewsFields
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
  }
`

export const Q_GET_POST_MENTIONS = gql`
  ${ProfileFields}
  query GetPostMentions($postId: Int) {
    mentions(
      where: {
        _and: [
          { post_id: { _eq: $postId } }
          { comment_id: { _is_null: true } }
        ]
      }
    ) {
      id
      post_id
      to_profile {
        ...ProfileFields
      }
    }
  }
`

export const Q_GET_COMMENT_MENTIONS = gql`
  ${ProfileFields}
  query GetCommentMentions($commentId: Int) {
    mentions(where: { comment_id: { _eq: $commentId } }) {
      id
      post_id
      to_profile {
        ...ProfileFields
      }
    }
  }
`

export const Q_GET_POST_COMMENTS = gql`
  ${Comments}
  query GetPostComments($postId: Int) {
    post_comments(where: { to_post_id: { _eq: $postId } }) {
      ...CommentsFields
    }
  }
`

export const Q_GET_ACTIVITY_POSTS = gql`
  ${CommonFields}
  ${ProfileFields}
  ${LikedProfiles}
  ${News}
  ${Comments}
  ${OpportunitiesFragment}
  ${BusinessProfile}
  query GetActivityPosts($profileId: Int!, $limit: Int = 3, $offset: Int!) {
    referrals: opportunities(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        _and: [
          { author_profile_id: { _eq: $profileId } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      ...Opportunity
    }
    posts(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        _or: [
          {
            _and: [
              {
                _or: [
                  { author_profile_id: { _eq: $profileId } }
                  { liked_profiles: { from_profile_id: { _eq: $profileId } } }
                  { comments: { from_profile_id: { _eq: $profileId } } }
                ]
              }
              { is_deleted: { _eq: false } }
              { is_anonymous: { _eq: false } }
            ]
          }
          {
            _and: [
              {
                _or: [
                  {
                    _and: [
                      { author_profile_id: { _neq: $profileId } }
                      {
                        comments: {
                          _and: [
                            { from_profile_id: { _eq: $profileId } }
                            { is_anonymous: { _eq: false } }
                          ]
                        }
                      }
                    ]
                  }
                  { liked_profiles: { from_profile_id: { _eq: $profileId } } }
                ]
              }
              { is_deleted: { _eq: false } }
              { is_anonymous: { _eq: true } }
            ]
          }
        ]
      }
    ) {
      ...CommonFields
      author {
        ...ProfileFields
      }
      business {
        ...BusinessProfile
      }
      news {
        ...NewsFields
      }
      liked_profiles {
        ...LikedProfiles
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments {
        ...CommentsFields
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
  }
`

export const Q_GET_POST_FOR_MESSAGES = gql`
  query GetPostForMessages($postId: Int!) {
    posts(
      where: {
        _and: [{ is_deleted: { _eq: false } }, { id: { _eq: $postId } }]
      }
    ) {
      id
      title
      content
      author {
        id
        first_name
        last_name
        avatar
      }
    }
  }
`

export const Q_SEND_SHARE_POST_EMAIL = gql`
  query SendSharePostMail(
    $content: String!
    $title: String!
    $email: String!
    $first_name: String!
    $last_name: String!
  ) {
    share_post_email(
      mail_input: {
        post_content: $content
        post_title: $title
        receiver_email: $email
        sender_first_name: $first_name
        sender_last_name: $last_name
      }
    ) {
      response_message
    }
  }
`

export const Q_GET_POST_SHARE_COUNTER = gql`
  query GetShareCounter($postId: Int!) {
    post_shares_aggregate(where: { post_id: { _eq: $postId } }) {
      aggregate {
        count(columns: id)
      }
    }
  }
`

export const Q_GET_BUSINESS_POSTS = gql`
  ${BusinessProfile}
  ${CommonFields}
  ${ProfileFields}
  ${LikedProfiles}
  ${News}
  query GetBusinessPosts(
    $offset: Int = 0
    $limit: Int = 2
    $business_id: Int!
  ) {
    posts(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        _and: [
          { business_id: { _eq: $business_id } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      ...CommonFields
      business {
        ...BusinessProfile
      }
      author {
        ...ProfileFields
      }
      news {
        ...NewsFields
      }
      liked_profiles {
        ...LikedProfiles
      }
      liked_profiles_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
      comments_aggregate {
        aggregate {
          count(columns: from_profile_id)
        }
      }
    }
    posts_aggregate(
      where: {
        _and: [
          { business_id: { _eq: $business_id } }
          { is_deleted: { _eq: false } }
        ]
      }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
`
