import { GetBusinessProfilesSubscription } from 'operations/GetBusinessProfilesSubscription'

import { S_GET_BUSINESS_PROFILES } from 'lib/queries/business_profiles'

import { useSubscription } from '@apollo/client'

import { useProfile } from 'components/hooks'

import { getBusinessProfileUnreadMessagesCount } from '../utils/business_profiles'
import useChatContext from './useChatContext'

const useMyBusinessProfiles = () => {
  const { profile } = useProfile()
  const { isBusinessProfileActive } = useChatContext()
  const { data: businessProfilesData } =
    useSubscription<GetBusinessProfilesSubscription>(S_GET_BUSINESS_PROFILES, {
      variables: {
        profileId: profile?.id || -1,
      },
      skip: !profile,
    })

  const hasBusinessProfilesNotifications =
    !isBusinessProfileActive &&
    businessProfilesData?.business_profiles?.filter(
      (profile) => getBusinessProfileUnreadMessagesCount(profile) > 0,
    ).length

  return {
    businessProfiles: businessProfilesData?.business_profiles,
    hasBusinessProfilesNotifications,
  }
}

export default useMyBusinessProfiles
