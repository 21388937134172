import { MessageGroupProfileToProfile_message_group_profiles } from 'operations/MessageGroupProfileToProfile'

export enum ProfileChatType {
  PROFILE_TO_PROFILE = 'PROFILE_TO_PROFILE',
  PROFILE_TO_BUSINESS_PROFILE = 'PROFILE_TO_BUSINESS_PROFILE',
  BUSINESS_PROFILE_TO_PROFILE = 'BUSINESS_PROFILE_TO_PROFILE',
  BUSINESS_PROFILE_TO_BUSINESS_PROFILE = 'BUSINESS_PROFILE_TO_BUSINESS_PROFILE',
}

export const getConversationName = ({
  isBusiness,
  isGuestBusiness,
  guestProfile,
}: {
  isBusiness: boolean
  isGuestBusiness: boolean
  guestProfile: MessageGroupProfileToProfile_message_group_profiles | any | null
}) => {
  const conversationName = {
    [ProfileChatType.PROFILE_TO_PROFILE]:
      'from_profile' in guestProfile
        ? `${guestProfile?.from_profile?.first_name} ${guestProfile?.from_profile?.last_name}`
        : '',
    [ProfileChatType.PROFILE_TO_BUSINESS_PROFILE]: `${guestProfile?.name}`,

    [ProfileChatType.BUSINESS_PROFILE_TO_PROFILE]:
      'from_profile' in guestProfile
        ? `${guestProfile?.from_profile?.first_name} ${guestProfile?.from_profile?.last_name}`
        : '',
    [ProfileChatType.BUSINESS_PROFILE_TO_BUSINESS_PROFILE]: `${guestProfile?.from_business_profile?.name}`,
  }
  const type = getProfileChatType(isBusiness, isGuestBusiness)

  return conversationName[type]
}

export const getProfileChatType = (
  isOwnerBusinessPage: boolean,
  isGuestBusinessPage: boolean,
) => {
  if (isOwnerBusinessPage) {
    if (isGuestBusinessPage) {
      return ProfileChatType.BUSINESS_PROFILE_TO_BUSINESS_PROFILE
    }
    if (!isGuestBusinessPage) {
      return ProfileChatType.BUSINESS_PROFILE_TO_PROFILE
    }
  }
  if (!isOwnerBusinessPage) {
    if (isGuestBusinessPage) {
      return ProfileChatType.PROFILE_TO_BUSINESS_PROFILE
    }
    if (!isGuestBusinessPage) {
      return ProfileChatType.PROFILE_TO_PROFILE
    }
  }
  return ProfileChatType.BUSINESS_PROFILE_TO_PROFILE
}
