import { Text } from '../text'

interface NotificationNumerProps {
  notificationCount: number
  color?: 'secondary' | 'primary' | 'red'
}

function NotificationNumber({
  notificationCount,
  color = 'primary',
}: NotificationNumerProps) {
  return (
    <div
      className={`flex items-center justify-center h-4 w-4 rounded-full bg-${color}`}
    >
      <Text variant="extra-small" className="text-white">
        {notificationCount}
      </Text>
    </div>
  )
}

export default NotificationNumber
