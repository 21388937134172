import { newReferralData } from 'lib/reactiveVariables'

import BriefcaseIcon from '@/components/common/briefcase_opportunity_icon'
import InsuranceIcon from '@/components/common/insurance_icon'
import LanguageIcon from '@/components/common/language_icon'
import PartnershipIcon from '@/components/common/partnership_icon'
import { Modalities } from '@/components/profile/user_basic_details/modality_badge'
import { ComboboxDemo, OptionType } from '@/components/ui/combobox/newCombobox'
import { M_INSERT_SUGGESTED_INSURANCE } from '@/lib/mutations/insurances'
import { useMutation } from '@apollo/client'
import { FunctionComponent, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { SearchIcon } from 'components/common/icons'
import {
  Button,
  Combobox,
  ConfirmationModal,
  NewReferralStep,
  Text,
} from 'components/ui'
import { Option, optionType } from 'components/ui/combobox/combobox'

import { mapValuesAsOptions } from 'utils/opportunities/referrals/referrals'

import { useDataFetching } from '../../hooks'
import { CreateReferralContext } from '../../step_controller/step_controller'

interface IStepFive extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const StepFive: FunctionComponent<IStepFive> = () => {
  const textContent = {
    stepNumber: '5/6',
    stepDescription: 'Additional Details & Skills',
    title:
      'What are the main skills, languages and treatment methods required for this referral.',
    description:
      "We'll use this section to help match your referral with other therapists who identify as having the skills to meet the needs of the client.",
  }

  const { goToPreviousStep, goToNextStep } = useContext(CreateReferralContext)

  const [insertSuggestedInsurance] = useMutation(M_INSERT_SUGGESTED_INSURANCE)

  const [showInsuranceModal, setShowInsuranceModal] = useState(false)
  const [customizedInsurance, setCustomizedInsurance] = useState('')
  const [clicked, setClicked] = useState(false)

  const {
    getIssues,
    getLanguages,
    getInsurances,
    languages: languagesData,
    specialties,
    insurancesData,
    therapyFormsData,
  } = useDataFetching()

  const { referralSeekingInfo } = newReferralData()

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      sessionModality: mapValuesAsOptions(referralSeekingInfo.sessionModality),
      languages: referralSeekingInfo.languages.map(mapValuesAsOptions),
      insurances: referralSeekingInfo.insurances.map(mapValuesAsOptions),
      skills: referralSeekingInfo.issues.map(mapValuesAsOptions),
    },
  })

  const sessionModalitiesValue = watch('sessionModality')
  const languagesValue = watch('languages')
  const insurancesValue = watch('insurances')
  const skillsValue = watch('skills')

  const languages =
    languagesData?.map((item) => ({
      title: item.name,
      value: item.name,
    })) || []

  const issuesOptions =
    specialties?.map((item) => ({
      title: item.name,
      value: item.name,
    })) || []

  const handleContinue = async ({
    sessionModality,
    languages,
    insurances,
    skills,
  }: {
    skills: Option[]
    sessionModality: Option
    insurances: Option[]
    languages: Option[]
  }) => {
    const allNewReferralData = newReferralData()
    const { referralSeekingInfo } = allNewReferralData
    newReferralData({
      ...allNewReferralData,
      referralSeekingInfo: {
        ...referralSeekingInfo,
        sessionModality: sessionModality.value,
        languages: languages.map((item) => item.value),
        insurances: insurances.map((item) => item.value),
        issues: skills.map((item) => item.value),
      },
    })
    goToNextStep()
  }

  const insurances =
    insurancesData?.insurances.map(({ name }) => mapValuesAsOptions(name)) || []
  const therapyForms =
    therapyFormsData?.therapy_forms.map(({ name }) =>
      mapValuesAsOptions(name),
    ) || []

  const sessionModalities = Object.values(Modalities).map(mapValuesAsOptions)

  const handleClick = async () => {
    if (!clicked) {
      setClicked(true)
      await handleSubmit(handleContinue)()
    }
  }

  const handleAddNewInsurance = () => {
    insertSuggestedInsurance({
      variables: {
        insurance: customizedInsurance,
      },
    })
    setShowInsuranceModal(false)
  }

  const styles = {
    row: 'flex flex-col md:flex-row gap-6 w-full md:items-center items-center',
    label: 'flex flex-none md:w-3/12 justify-end place-self-start mt-3',
    icon: 'hidden md:inline place-self-start max-w-[25px]',
  }

  return (
    <>
      <NewReferralStep
        stepNumber={textContent.stepNumber}
        stepDescription={textContent.stepDescription}
        title={textContent.title}
        description={textContent.description}
        customStep
      >
        <div className="flex flex-col gap-2 pr-20 pl-4 pt-[1rem]">
          <Text variant="none" weight="bold" size="2xl" className="pl-12">
            Add some additional details about the client
          </Text>
          <section className="flex flex-col gap-5 pt-16 w-full">
            <div className={twMerge(styles.row)}>
              <Text size="xl" weight="bold" className={styles.label}>
                Session Modality
              </Text>
              <PartnershipIcon className={styles.icon} />
              <Controller
                control={control}
                name="sessionModality"
                rules={{
                  required: 'Select at least one option.',
                }}
                render={({ field: { onChange } }) => (
                  <ComboboxDemo
                    chipVariant="primary"
                    data={sessionModalities}
                    defaultValues={sessionModalitiesValue}
                    getData={() => ''}
                    getSelectedOptions={(selected) => {
                      onChange(selected)
                    }}
                    parentBgColor="white"
                    buttonPlaceholder="How should sessions take place?"
                    searchPlaceholder="Search for a session modality"
                    lowercase
                    regularCombobox
                  />
                )}
              />
            </div>
            <div className={twMerge(styles.row)}>
              <Text size="xl" weight="bold" className={styles.label}>
                Language
              </Text>
              <LanguageIcon className={styles.icon} />
              <div className="flex flex-col w-full">
                <Controller
                  control={control}
                  name="languages"
                  render={({ field: { onChange } }) => (
                    <ComboboxDemo
                      chipVariant="secondary"
                      data={languages}
                      defaultValues={languagesValue}
                      getSelectedOptions={(selected) => {
                        if (Array.isArray(selected)) onChange(selected)
                      }}
                      multiple
                      parentBgColor="white"
                      getData={(value) =>
                        getLanguages({
                          variables: {
                            search: `%${value ?? ''}%`,
                          },
                        })
                      }
                      buttonPlaceholder="Should the therapist speak a different language?"
                      searchPlaceholder="Search for a language"
                      hint="Sometimes patients feel more comfortable in their native language"
                      lowercase
                    />
                  )}
                />
              </div>
            </div>
            <div className={twMerge(styles.row)}>
              <Text size="xl" weight="bold" className={styles.label}>
                Insurance
              </Text>
              <InsuranceIcon className={styles.icon} />
              <div className="flex flex-col w-full">
                <Controller
                  control={control}
                  name="insurances"
                  render={({ field: { onChange } }) => (
                    <ComboboxDemo
                      hasCustomizedOption
                      chipVariant="secondary"
                      data={insurances}
                      defaultValues={insurancesValue}
                      getSelectedOptions={(selected) => {
                        if (Array.isArray(selected)) {
                          const lastIndex = selected.length - 1
                          if (
                            selected[lastIndex]?.type &&
                            selected[lastIndex]?.type === OptionType.customized
                          ) {
                            setCustomizedInsurance(selected[lastIndex].value)
                            setShowInsuranceModal(true)
                          }
                          onChange(selected)
                        }
                      }}
                      parentBgColor="white"
                      buttonPlaceholder="Which insurance is accepted at this practice?"
                      searchPlaceholder="Search for an insurance"
                      lowercase
                      multiple
                      getData={(value) =>
                        getInsurances({
                          variables: {
                            search: `%${value ?? ''}%`,
                          },
                        })
                      }
                    />
                  )}
                />
              </div>
            </div>

            <div className={twMerge(styles.row)}>
              <Text size="xl" weight="bold" className={styles.label}>
                Skills Needed
              </Text>
              <BriefcaseIcon className={twMerge(styles.icon, 'pt-2')} />
              <div className="flex flex-col w-full">
                <Controller
                  control={control}
                  name="skills"
                  render={({ field: { onChange, value } }) => (
                    <ComboboxDemo
                      chipVariant="secondary"
                      data={issuesOptions}
                      defaultValues={skillsValue}
                      getData={(value) => {
                        getIssues({
                          variables: {
                            search: `%${value ?? ''}%`,
                          },
                        })
                      }}
                      getSelectedOptions={(selected) => {
                        if (Array.isArray(selected)) onChange(selected)
                      }}
                      maxSelections={8}
                      multiple
                      parentBgColor="white"
                      buttonPlaceholder="Search or add up to 8 skills"
                      searchPlaceholder="Search for a skill"
                      lowercase
                      hint="For the best matching results, add 3-5 skills"
                    />
                  )}
                />
              </div>
            </div>

            <div
              className={twMerge(
                'flex flex-col flex-1 gap-3 mt-12 w-full md:flex-row md:justify-end bg-white',
              )}
            >
              <Button
                variant="third"
                type="button"
                className="flex-1 order-2 py-2 px-16 my-8 text-sm md:flex-none md:order-1"
                onClick={goToPreviousStep}
              >
                Back
              </Button>
              <Button
                variant="primary"
                type="button"
                className="flex-1 order-1 py-2 px-8 my-8 text-sm md:flex-none md:order-2"
                onClick={handleClick}
                disabled={clicked}
              >
                Final Step: Publishing
              </Button>
            </div>
          </section>
        </div>
      </NewReferralStep>
      <ConfirmationModal
        isOpen={showInsuranceModal}
        onClose={() => setShowInsuranceModal(false)}
        onConfirm={handleAddNewInsurance}
        title="Suggest adding an insurance"
        message={`Suggest adding ${customizedInsurance} to the list of insurances available`}
      />
    </>
  )
}

export default StepFive
