import type { GetNews_sources } from 'operations/GetNews'

import { gql } from '@apollo/client'

import { Source } from 'components/hooks/useFollowNews'

export const Q_GET_CATEGORY = gql`
  query GetCategory($catId: Int!) {
    category: news_categories(where: { id: { _eq: $catId } }) {
      id
      type
      description
    }
  }
`

export const Q_GET_NEWS = gql`
  query GetNews(
    $sourcesCondition: news_sources_bool_exp
    $categoriesCondition: news_categories_bool_exp
    $followedSourcesCondition: followed_sources_bool_exp
    $limit: Int!
  ) {
    categories: news_categories(
      where: $categoriesCondition
      order_by: { type: asc }
      limit: $limit
    ) {
      id
      type
      description
    }
    news_sources_aggregate(where: $sourcesCondition) {
      aggregate {
        count(columns: id)
      }
    }
    sources: news_sources(
      where: $sourcesCondition
      order_by: { name: asc }
      limit: $limit
    ) {
      id
      url
      name
      description
      followed_sources_aggregate(where: $followedSourcesCondition) {
        aggregate {
          count
        }
      }
    }
    followed_sources(
      where: $followedSourcesCondition
      order_by: { news_source: { name: asc } }
      limit: $limit
    ) {
      sources: news_source {
        id
        url
        name
        description
        followed_sources_aggregate(where: $followedSourcesCondition) {
          aggregate {
            count
          }
        }
      }
    }
    total_followed_sources: followed_sources_aggregate(
      where: $followedSourcesCondition
    ) {
      aggregate {
        count
      }
    }
  }
`

export const Q_GET_MY_NEWS = gql`
  query GetMyNews($newsCondition: news_bool_exp = {}, $limit: Int!) {
    news_aggregate(where: $newsCondition) {
      aggregate {
        count(columns: id)
      }
    }
    news(
      where: $newsCondition
      order_by: { publication_date: desc }
      limit: $limit
    ) {
      id
      url
      title
      source
      icon
      news_source {
        id
        name
        news_categories {
          news_category {
            type
          }
        }
        icon
        url
        name
        description
        followed_sources_aggregate {
          aggregate {
            count(columns: created_at)
          }
        }
      }
      publication_date
      image
      id
      force_title
      description
      author
      absolute_image
    }
  }
`

export const Q_GET_FOLLOWED_SOURCE = gql`
  query GetFollowedSource($sourceId: Int!, $profileId: Int!) {
    followed_sources(
      where: {
        _and: [
          { source_id: { _eq: $sourceId } }
          { from_profile_id: { _eq: $profileId } }
        ]
      }
    ) {
      sources: news_source {
        id
        url
        name
        description
      }
    }
  }
`

export const Q_GET_TOP_SOURCES = gql`
  query GetTopSources($limit: Int = 5) {
    most_popular: news_sources(
      order_by: { followed_sources_aggregate: { count: desc } }
      limit: $limit
    ) {
      id
      icon
      url
      name
      description
      followed_sources_aggregate {
        aggregate {
          count(columns: created_at)
        }
      }
    }
    top_journals: news_sources(
      order_by: { followed_sources_aggregate: { count: desc } }
      limit: $limit
      where: {
        news_categories: { news_category: { type: { _eq: "JOURNALS" } } }
      }
    ) {
      id
      icon
      url
      name
      description
      followed_sources_aggregate {
        aggregate {
          count(columns: created_at)
        }
      }
    }
    top_mainstream: news_sources(
      order_by: { followed_sources_aggregate: { count: desc } }
      limit: $limit
      where: {
        news_categories: { news_category: { type: { _eq: "MAINSTREAM" } } }
      }
    ) {
      id
      icon
      url
      name
      description
      followed_sources_aggregate {
        aggregate {
          count(columns: created_at)
        }
      }
    }
  }
`

export const isFollowing = (source: Source) =>
  (source?.followed_sources_aggregate.aggregate?.count || 0) > 0
