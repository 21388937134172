import { newReferralData, referralEditedId } from '@/lib/reactiveVariables'
import { XIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { LoaderIcon } from 'react-hot-toast'
import { twMerge } from 'tailwind-merge'

import { Button, LoadingBar, Text } from 'components/ui'

import useUpsertReferral from '../../edit_referral/hooks/useUpsertReferral'
import { CreateReferralContext } from '../step_controller/step_controller'

const Logo = () => (
  <p className="text-2xl lg:col-span-1 lg:text-4xl font-qanelas-extrabold tracking-[-0.09em] text-purple-main">
    Belongly
  </p>
)
interface IFinalStep extends React.HTMLAttributes<HTMLDivElement> {}

const FinalStep: FunctionComponent<IFinalStep> = ({
  className,
  ...props
}: IFinalStep) => {
  const { cancelCreateReferral, goToNextStep, setStep, referralLink } =
    useContext(CreateReferralContext)

  const { push } = useRouter()

  const textContent = {
    stepNumber: '6/6',
    stepDescription: 'Your referral is now live',
    title: 'Congratulations',
    description:
      "Your referral is now live and published on Belongly. Very soon, you can expect that people will see, review and respond to your post. If this is urgent or your're not receiving the desired response, please feel free to reach out to our referral coordinator and / or our community manager.",
    statusMessage: "You'll be automatically redirected in 20 seconds",
  }

  useEffect(() => {
    let interval: any
    if (referralLink) {
      interval = setInterval(goToNextStep, 20000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [goToNextStep, referralLink])

  if (!referralLink) return <LoaderIcon />
  return (
    <div
      {...props}
      className={twMerge(
        className,
        'flex flex-col bg-background w-full h-full py-10 md:py-10 md:px-24 gap-8 md:gap-0',
      )}
    >
      <div className="flex flex-none justify-between mx-10 md:mx-0">
        <Logo />
        <XIcon
          className={'stroke-gray-mid'}
          width={26}
          height={26}
          onClick={cancelCreateReferral}
        />
      </div>
      <div className="flex justify-center items-center md:h-full">
        <div className="flex flex-col gap-8 items-center p-12 bg-white md:rounded md:border border-x-0 border-y border-gray-mid">
          <span className="flex gap-4">
            <Text size="lg" variant="body">
              {textContent.stepNumber}
            </Text>
            <Text size="lg" variant="body">
              {textContent.stepDescription}
            </Text>
          </span>
          <Text className="text-3xl font-bold md:text-5xl text-title-light">
            {textContent.title}
          </Text>
          <Text size="lg" variant="body" color="black" className="text-center">
            {textContent.description}
          </Text>
          <Text variant="body">{textContent.statusMessage}</Text>
          <div className="flex md:hidden">
            <LoadingBar widthPx={250} />
          </div>
          <div className="hidden md:flex">
            <LoadingBar widthPx={400} />
          </div>
          <div className="flex flex-col gap-2 md:flex-row md:justify-end">
            <Button
              variant="third"
              className="md:w-44"
              type="button"
              onClick={() => {
                push(referralLink)
              }}
            >
              View My Referral
            </Button>
            <Button
              variant="primary"
              type="button"
              className="md:w-44"
              onClick={() => setStep(0)}
            >
              Publish Another
            </Button>
            <a
              href="https://www.belongly.com/referrals-feedback/"
              target="_about"
            >
              <Button variant="secondary" type="button" className="md:w-44">
                Please Take Our Feedback Survey
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinalStep
