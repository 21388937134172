import { newReferralData } from 'lib/reactiveVariables'

import { FunctionComponent, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { NewReferralStep, Text, TextArea } from 'components/ui'

import { CreateReferralContext } from '../../step_controller/step_controller'

interface IStepThree extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const StepThree: FunctionComponent<IStepThree> = () => {
  const textContent = {
    stepNumber: '3/6',
    stepDescription: 'Description',
    title: 'Describe the situation',
    description:
      "This is how therapists figure out what's going on to know if they'd be a good fit. Try to include details about the client situation, if the situation is urgent and what they're looking for in a therapist that would make it a good fit. ",
  }

  const { goToPreviousStep, goToNextStep } = useContext(CreateReferralContext)

  const { referralDescription } = newReferralData()

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      description: referralDescription || '',
    },
  })
  const description = watch('description')
  const descriptionLength = description ? description.length : 0
  const maxDescriptionLength = 5000
  const minDescriptionLength = 250

  useEffect(() => {
    reset({
      description: referralDescription,
    })
  }, [reset, referralDescription])

  const handleContinue = ({ description }: { description: string }) => {
    const allData = newReferralData()
    newReferralData({
      ...allData,
      referralDescription: description,
    })
    goToNextStep()
  }

  return (
    <NewReferralStep
      stepNumber={textContent.stepNumber}
      stepDescription={textContent.stepDescription}
      title={textContent.title}
      description={textContent.description}
      formTitle="Add a detailed description"
      onPositive={handleSubmit(handleContinue)}
      onNegative={goToPreviousStep}
      positiveLabel="Next: About The Client"
    >
      <section className="flex flex-col gap-4">
        <Controller
          control={control}
          name="description"
          rules={{
            required: 'You must provide a description.',
            validate: {
              minLength: (desc) =>
                desc.length >= minDescriptionLength ||
                `The description must have at least ${minDescriptionLength} characters.`,
              maxLength: (desc) =>
                desc.length <= maxDescriptionLength || `Description too long.`,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <TextArea
              placeholder={`Type the details of the situation here. You don't have to write a novel, but please include enough details to help readers understand the situation with clarity. We are HIPAA compliant, but for the privacy of the client, please omit personally identifiable information.
              \nAlready have the description written up? Paste it here.`}
              background="white"
              rows={12}
              className="text-justify"
              onChange={onChange}
              value={value}
              maxLength={maxDescriptionLength}
              error={errors.description?.message}
            />
          )}
        />

        <div className="flex justify-between">
          <Text
            variant="none"
            weight="light"
            size="sm"
            className="text-[#7e7e7e]"
          >{`${minDescriptionLength} minimum characters.`}</Text>
          <Text
            variant="none"
            weight="light"
            size="sm"
            className={twMerge(
              'text-[#7e7e7e]',
              descriptionLength <= minDescriptionLength && 'text-red-light',
              descriptionLength >= maxDescriptionLength && 'text-red-light',
            )}
          >{`${descriptionLength}/${maxDescriptionLength} characters left`}</Text>
        </div>
      </section>
    </NewReferralStep>
  )
}

export default StepThree
