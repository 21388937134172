import { MessageGroupList_message_group } from 'operations/MessageGroupList'

import { isNil } from 'ramda'
import { twMerge } from 'tailwind-merge'
import { capitalize } from 'utils'

import { useHandleActiveConversations } from 'components/hooks'
import { NotificationNumber, Text } from 'components/ui'

import { formatMessagesInListDate } from 'utils/messages/messages'

import { ProfileImage } from '../conversation_window'
import useChatContext from '../hooks/useChatContext'
import { activeProfileOnChat } from '../types/types'
import { getProfileProperties } from '../utils/profile'
import MessageContent from './message_content'

interface ConversationProps {
  conversation: MessageGroupList_message_group
}

function Conversation({ conversation }: ConversationProps) {
  const { activeProfileOnChat: myProfile, isBusinessProfileActive } =
    useChatContext()

  const unreadMessagesCount =
    conversation.unread_messages?.[0]?.unread_messages_count || 0

  const { activateConversation = () => {} } = useHandleActiveConversations()

  const conversationProfiles = conversation.profiles?.[0]

  const profileImTalkingTo =
    conversationProfiles?.from_business_profile ||
    conversationProfiles?.from_profile

  const {
    fullName: talkingToProfileFullName = '',
    firstName: talkingToProfileFirstName = '',
    avatar: talkingToProfileAvatar = {},
  } = getProfileProperties(profileImTalkingTo as activeProfileOnChat)

  const isTalkingToOnline =
    !isNil(profileImTalkingTo) && 'user_status' in profileImTalkingTo
      ? profileImTalkingTo?.user_status[0]?.is_online || false
      : false

  const { created_at: messageCreatedAt, content: messageContent } =
    conversation.messages?.[0]

  //This is to get the owner profile id to compare with my id on isSentByMe
  const chatOwnerProfileId = !isNil(
    conversation?.profiles[0]?.from_business_profile,
  )
    ? conversation?.profiles[0]?.from_business_profile?.id
    : conversation?.profiles[0]?.from_profile?.id

  const isSentByMe =
    conversation.messages.length > 0 && chatOwnerProfileId == myProfile?.id

  const hasUnreadMessages = !isSentByMe && unreadMessagesCount > 0

  const messageAuthor = isSentByMe ? 'You:' : `${talkingToProfileFirstName}:`

  const isMessageASharedPost = (message: string) =>
    message.startsWith('__post__')

  return (
    <div
      onClick={() => activateConversation({ conversationId: conversation.id })}
      className={`flex items-start py-3 px-3 gap-3 w-full z-auto bg-white  border-b-[1px] border-gray-300 cursor-pointer hover:bg-gray-light ${
        hasUnreadMessages && 'bg-primary-50'
      }`}
    >
      <div className="flex items-start w-1/6">
        <ProfileImage
          avatar={talkingToProfileAvatar}
          isOnline={isTalkingToOnline}
          size={12}
        />
      </div>

      <div className="flex overflow-hidden flex-col gap-1 px-1 w-5/6">
        <div className="flex justify-between items-center w-full">
          <Text
            capitalize
            variant="small"
            className="text-black"
            weight={hasUnreadMessages ? 'bold' : 'regular'}
          >
            {talkingToProfileFullName}
          </Text>
          <Text
            variant="extra-small"
            weight="semibold"
            className={`${hasUnreadMessages ? 'text-black' : 'text-gray-mid'}`}
          >
            {formatMessagesInListDate(new Date(messageCreatedAt))}
          </Text>
        </div>

        <div className="flex items-start w-full">
          <Text
            variant="extra-small"
            weight={hasUnreadMessages ? 'semibold' : 'regular'}
            className={twMerge(
              'wrap line-clamp-2 w-full',
              hasUnreadMessages && 'text-black',
            )}
          >
            {isMessageASharedPost(messageContent ?? '') ? (
              ' Shared a post'
            ) : (
              <MessageContent
                messageContent={messageContent || ''}
                hasUnreadMessages={hasUnreadMessages}
                isSentByMe={isSentByMe}
                talkingToProfileFirstName={talkingToProfileFirstName}
              />
            )}
          </Text>
          {hasUnreadMessages && (
            <div className="flex justify-end items-start w-1/6 motion-safe:animate-bounce">
              <NotificationNumber
                notificationCount={unreadMessagesCount}
                color="primary"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Conversation
