const UserCopyIcon = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="57"
    viewBox="0 0 53 57"
    className={className}
  >
    <g>
      <g>
        <image
          width="53"
          height="57"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAA1CAYAAADVn2JsAAAAAXNSR0IArs4c6QAAA8tJREFUaEPV2mnobWMUx/HPNRW5lKl7zUNeGZK8MF+SMpVE13BlzJCpCBmuSLjdDCUuSYaESKRkSIakDKFM78g8jxFeyNSPfW7n/p3/Oc+zz/mf+z/r7Vnrt77Ps/d+9lrr7DlGY1vjAOyF7bAJVsf3eA8v4Vm8OIp0c4YU2RxX49hCnddwBZ4o9O/pNgz0SbgVa7QAuAfHt4j7N6Qt9DW4uG3SJu4V7IffanXaQF+GK2sTTeP/Dnas1aqFzsP2ZG2SAf73VTwT1bfHKvgJa48YOnJ7NCdMkXTNTp+LG4pU653exQ6lYTXQX2B+qXALv53wVklcKfT2yEMzk5YT6dKSBKXQpzdncolmW59XsWtJcCl03mKXlwgO4fMxtiyJL4W+FueXCA7h8zU2xl+DNEqhl+LCQWJD/v5lU2j9PUinFPqSpjAapDfM7+9j2xKBUuiFeLBEcAifx3FISXwp9Ab4tkRwCJ+cULeVxJdCRysF/J4loi19NsR3JbE10HvjhRLRFj5348TSuBroaAY68KO23H5pzYqsFnpd5Ghas0i9zOloPFDm+p9XLXRidsYbNUn6+Ka/XFyr1QY6OXbDY1i/NmGXfzqgq9rEt4VOrjztOaIOq0z8Ac7EU5Vxy92Hge6IHIhTcCjS3UxnryOt1c34oy1wzT2dQmarpgn4GU/3SJoZSG6bLZqrkGHND/gcbyMzj162P9bBV/io8e+7pkE7fRROxj5YrUvpTVyAZ4bYsX1xXfNgd2T+bI7VO3D/dNrTQed+zaXMLvSzXOqL8GsF/FpYgnMGxDyHY5CSdQXrBZ05XC5laT+Y3jEN70P4pA/IZjgC52HTwkV+g13wabd/L+gPSzuIKYlTvKdSS40S+EyOsquBzWDyYKxaCNvtFuA8L8ttKvQynNFCeKZDbsepnSTd0FlN+rTZatsgZ/wKr/G8/4+crcR4BId3Q6cQ+rFlLTLOda4Xzs7tkR2uqrTGSdqVa1HO7w50ivDWQ+4xLiAvnEUd6M+a9n2M+VulyotmXqA36vXWaSU5nqD5gU6Rk3+fJsUWBDoN5Z2TQozTAp3BYgaMk2KLA30TzpoUYiwN9F04YYKgbwx0zr608ZNitwT63hzYk0KMZYEex8B8lHuyJNDpoh8dpeoMay0MdLqLmh5vhpkGys/t1B7XN73bwIiV7JBG+uwOdMYD6cXmrWSofukz1E9D/Ht3u5WvY15uCqjZxp5h++7NVzr/m5pmoJgPSA6aRdSZ+R3X/ffJdMOaBU0/ltVl/jF3jIv4BZml5COWh/H81Nz/AKGOjZWd3oDCAAAAAElFTkSuQmCC"
        />
      </g>
    </g>
  </svg>
)

export default UserCopyIcon
