import type { GetYouMayKnowProfiles } from 'operations/GetYouMayKnowProfiles'

import { isDev } from 'lib/env'
import { Q_GET_PROFILE_OWNER_ID } from 'lib/queries/owner_id'

import { useQuery } from '@apollo/client'
import { useUser } from '@clerk/nextjs'
import Script from 'next/script'

interface IntercomSettings {
  app_id: string
  name?: string
  email?: string
  user_id?: string
  profile_id?: number
  created_at?: number
}

interface IntercomWindow {
  intercomSettings: IntercomSettings
}

const Intercom = () => {
  const { user } = useUser()

  const { data } = useQuery<GetYouMayKnowProfiles>(Q_GET_PROFILE_OWNER_ID, {
    variables: { ownerId: user?.id },
    skip: !user?.id,
  })

  let settings: IntercomSettings

  if (user) {
    settings = {
      app_id: 'qmm4gkk8',
      name: user.firstName + ' ' + user.lastName, // Full name
      email: user.primaryEmailAddress?.emailAddress, // Email address
      user_id: user.id,
      profile_id: data?.profiles?.[0]?.id,
      created_at: user.createdAt ? user.createdAt.getTime() / 1000 : undefined, // Signup date as a Unix timestamp
    }
  } else {
    settings = {
      app_id: 'qmm4gkk8',
    }
  }

  ;(window as any as IntercomWindow).intercomSettings = settings

  return isDev ? null : (
    <Script
      id="intercom-login"
      dangerouslySetInnerHTML={{
        __html: `
      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/qmm4gkk8'
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/qmm4gkk8';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    `,
      }}
    />
  )
}

export default Intercom
