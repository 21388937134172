import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  className?: string
}

const ArrowIcon: FunctionComponent<Props> = ({ className = '' }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1412 1008"
    className={twMerge(
      'w-72 h-72 -m-24 -ml-0 rotate-[12deg] absolute top-2',
      className,
    )}
  >
    <path
      className="fill-[#7C8ECA]"
      d="M934.21,422.15c-1.09,0.79-2.12,1.69-3.29,2.34c-5.13,2.85-10.2,0.68-11.61-4.97
	c-0.66-2.64-1.17-5.32-1.94-8.83c-3.23,5.58-5.99,10.52-8.9,15.38c-23.7,39.57-54.3,73.14-89.94,102.17
	c-45.59,37.14-95.78,66.32-151.76,84.96c-52.96,17.64-107.49,23.77-163.14,20.91c-6.48-0.33-12.94-1.09-19.42-1.23
	c-6.27-0.13-10.48-2.46-11.78-8.9c0.5-1.67,1.01-3.34,1.51-5.02c2.87-2.81,6.36-2.8,10.06-2.44c41.98,4.09,83.7,2.52,125.17-5.31
	c59.21-11.18,113.03-34.97,162.6-68.8c35.63-24.31,67.71-52.57,95.47-85.61c13.75-16.37,24.98-34.45,35.27-53.13
	c0.16-0.29,0.2-0.64,0.44-1.43c-2.98,1.27-5.56,2.47-8.21,3.47c-4.54,1.71-8.58,0.14-10.79-4.04c-2.11-4-0.98-8.17,3.14-10.08
	c9.74-4.52,19.52-8.94,29.39-13.17c5.32-2.28,10.11,0.24,11.46,5.95c2.64,11.14,5.05,22.33,7.56,33.51
	C935.07,419.28,934.64,420.72,934.21,422.15z"
    />
  </svg>
)

export default ArrowIcon
