import { useRouter } from 'next/router'
import { isEmpty } from 'ramda'
import { useState } from 'react'
import { useSearchBox } from 'react-instantsearch'

import SearchIcon from 'components/common/search_icon'
import { useHandleClickOutside } from 'components/hooks'
import { Button, TextInput } from 'components/ui'

import { SearchCategories } from './categories/categories'
import AllResult from './results/all/all_result'
import { SearchBoxContext } from './utils/context'

interface SearchBoxProps {
  className?: string
}

const SearchBox = ({ className = 'w-full' }: SearchBoxProps) => {
  const { refine } = useSearchBox()
  const router = useRouter()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [showResults, setShowResults] = useState<boolean>(false)

  const hideResults = () => {
    setShowResults(false)
  }

  const goToSearchPage = () => {
    hideResults()
    router.push({
      pathname: '/search',
      query: { category: SearchCategories.All, query: searchQuery },
    })
  }
  const { parentRef } = useHandleClickOutside<HTMLDivElement>({
    callback: hideResults,
  })

  return (
    <SearchBoxContext.Provider
      value={{ showResults, setShowResults, hideResults }}
    >
      <div ref={parentRef} className={className}>
        <div className="flex flex-none gap-2 items-center py-2">
          <TextInput
            icon={
              <SearchIcon className="flex self-center ml-2 rounded" size={20} />
            }
            placeholder="Search Belongly"
            background="white"
            className="md:border"
            inputStyle="py-[8.5px]"
            onChange={(event) => {
              setSearchQuery(event.currentTarget.value)
              refine(event.currentTarget.value)
              setShowResults(!isEmpty(event.currentTarget.value))
            }}
          />
        </div>
        {showResults && (
          <div className="overflow-y-scroll fixed left-3.5 w-11/12 bg-white rounded-b shadow-md md:left-56 md:w-1/3 h-fit top-[70px]">
            <div className="flex flex-col">
              <AllResult />
            </div>
            <div className="flex justify-center">
              <Button
                className="font-semibold text-primary"
                onClick={goToSearchPage}
              >
                See more results
              </Button>
            </div>
          </div>
        )}
      </div>
    </SearchBoxContext.Provider>
  )
}

export default SearchBox
