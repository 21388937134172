import { FunctionComponent } from 'react'

import { Button, Modal, Text } from 'components/ui'

interface IConfirmationModal extends React.HTMLAttributes<HTMLDivElement> {
  message: string
  isOpen: boolean
  positiveMessage?: string
  negativeMessage?: string
  onClose: () => void
  onConfirm: () => void
}

const ConfirmationModal: FunctionComponent<IConfirmationModal> = ({
  message,
  isOpen,
  onClose,
  onConfirm,
  positiveMessage = 'OK',
  negativeMessage = 'Cancel',
  className,
  ...props
}: IConfirmationModal) => {
  return (
    <Modal {...props} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-6 justify-center items-center w-full">
        <Text size="md">{message}</Text>
        <div className="grid grid-cols-2 gap-6">
          <Button onClick={onClose}>{negativeMessage}</Button>
          <Button variant="primary" onClick={onConfirm}>
            {positiveMessage}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
