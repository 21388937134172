import { ActionParams, CheckPermissionsAndRoll, Roles } from '../types'

export const checkPermissions = ({
  feature_flags,
  user,
  rol,
}: CheckPermissionsAndRoll) => {
  return (
    (feature_flags?.strict_permissions === true &&
      user?.roles?.includes(rol)) ||
    feature_flags?.strict_permissions === false
  )
}

export function checkClientPulsePermissions({
  user,
  rol,
  profile,
}: Omit<CheckPermissionsAndRoll, 'feature_flags'>) {
  return user?.roles?.includes(rol) || Boolean(profile?.is_ambassor)
}
