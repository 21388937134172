const BriefcaseIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="41"
    viewBox="0 0 46 41"
  >
    <g>
      <g>
        <image
          width="46"
          height="41"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAApCAYAAABKvBuPAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAALqADAAQAAAABAAAAKQAAAADgsvgUAAADSklEQVRYCe2YOYgUQRSG9/I+1wNBPHcVUREjj3Q9EDQQWRQMjERNTMzEVETMTAQFA1cDNRBREANBFgwWNXERE9GBWVE88Fh1wXW9/m+2qimH6e7pmumhlP3hn6p+9d7rv6tfdVdPc1PtmK0U+8VlYov4W4xDswa+i33ipTinRtg7dZK3ImKz8lYjBMad46aHYPcCu+MSp9nb0hxSxtea8aLa3eKPFH+G14g9xm+r2mum37Bmhs40LDKDlzOe9ZuJ680YF7mzmHwxT4HjTfDDjEmeGP9FGeMid7dU6G8U20VWfxJ+anCb47BC/R1iq2OL6xJr/Raov1f8IiZNInqIeyH2ixF2qofRXTih9nulc77YtEEMVWScrgK34I64RQRXxPsiAWnlIpeGAT2sp30iT6USqC8GHo0eBv3bYbSOsCDsAn0VtORRcUNGYxvCR8zBRNOG3Eyx4pIeQdYnyHZMeKNvy38x47xSQ0ek0T4KETyhDqrZMXIXeS+UgxcaT7Cv5QMex7+I+SxyItpxog94q90WB0VeaOQqJ/YP4lHRF7sUiNbSxVvhGM55ZuQ1THw1fOd5DsIK5hyDbqkwcFAk8XGRj4Rq8dJxZJf52Dm23S51JokD1pChXSrfCyItaKbumPFpHDl4rf4D8b3ILOIXB+ptjsjWGJwWj5R6f/881eFy8aN43Qyl5aV02bN3ia4vJVkSjjhmgtqp5nYn+ZxVjkp4LmNSXDVjb5SDvzeG3Of4PRn4kjkv8peDL1iElVDNh3SlOGx86nEX13MgTHZrfKEM1OoB8bDIbWXjxUwkgfHV4kXjtKqCM6U009iLarsr+JSbKA3KkFJ+Zgbb1VI+Jdinyl1r8GinK8a91f063i5iPyZSk3Yc4b7oVKDNE9V4LcIRcsZJapN/KrOxhvaIvuhQoM1dN+GIWSfecJLbk3DLT4lzxVqQm3ArarM6fSIl0iMuEeuB3IVbkVNtp05tJLylTgnj0lDTuSBv4bmIJumY8NymNiYxM84bCvDoCh2RRoTbVyibl9DBrjXCgHpcCcaVkTXMziHJQuswZXJCZD9hUbCdwNpW6VnsamIHyEcDV/Kv8KRdmFwIs75JnCVi5yJCAXrY7xTFq/APXYF7tJ33h9sAAAAASUVORK5CYII="
        />
      </g>
    </g>
  </svg>
)

export default BriefcaseIcon
