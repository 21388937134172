import { LegacyRef, useEffect, useRef } from 'react'

const useHandleClickOutside = <T extends HTMLElement>({
  callback,
}: {
  callback: () => void
}): { parentRef: LegacyRef<T> } => {
  const parentRef = useRef<T>(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (parentRef.current && !parentRef.current.contains(event.target)) {
        parentRef.current.blur()
        callback()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [parentRef, callback])

  return { parentRef }
}

export default useHandleClickOutside
