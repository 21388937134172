import { IncrementBusinessUnreadMessageCount } from 'operations/IncrementBusinessUnreadMessageCount'
import { IncrementRegularProfileUnreadMessageCount } from 'operations/IncrementRegularProfileUnreadMessageCount'
import { InsertMessage } from 'operations/InsertMessage'

import {
  M_INCREMENT_BUSINESS_MESSAGE_COUNT,
  M_INCREMENT_REGULAR_PROFILE_MESSAGE_COUNT,
  M_INSERT_MESSAGE,
} from 'lib/mutations/messages'

import { useMutation } from '@apollo/client'
import { PaperAirplaneIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useProfile } from 'components/hooks'
import { Button, TextArea } from 'components/ui'

import { isValidMessage } from 'utils/messages/messages'

interface InputProps {
  isBusiness: boolean
  messageGroupId: number
  fromProfileId: number
  profileImTalkingTo: any
  onSend?: (content?: string) => void
}

function Input({
  isBusiness,
  onSend,
  messageGroupId,
  profileImTalkingTo,
  fromProfileId,
}: InputProps) {
  const [message, setMessage] = useState<string>('')
  const { profile: ownerProfile } = useProfile()
  const [insertMessage] = useMutation<InsertMessage>(M_INSERT_MESSAGE)

  const isProfileImTalkingToBusiness =
    profileImTalkingTo?.__typename == 'business_profiles'

  const [incrementUnreadMessages] = useMutation<
    | IncrementBusinessUnreadMessageCount
    | IncrementRegularProfileUnreadMessageCount
  >(
    isProfileImTalkingToBusiness
      ? M_INCREMENT_BUSINESS_MESSAGE_COUNT
      : M_INCREMENT_REGULAR_PROFILE_MESSAGE_COUNT,
  )

  const variables = {
    message: isBusiness
      ? {
          message_group_id: messageGroupId,
          profile_id: ownerProfile?.id,
          business_profile_id: fromProfileId,
          content: message,
        }
      : {
          message_group_id: messageGroupId,
          profile_id: fromProfileId,
          content: message,
        },
  }

  const sendMessage = (e: any) => {
    e.preventDefault()
    if (isValidMessage(message)) {
      insertMessage({
        onCompleted: () => setMessage(''),
        variables,
      })
      incrementUnreadMessages({
        variables: {
          profileId: profileImTalkingTo?.id,
          messageGroupId,
        },
      })
      onSend && onSend(message)
    }
  }

  return (
    <div className="flex gap-1 items-center p-3 w-full bg-white border-t border-gray-light">
      <TextArea
        onChange={(event) => setMessage(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.ctrlKey ? setMessage(message + '\n') : sendMessage(event)
          }
        }}
        value={message}
        placeholder="Type your message here"
        className="w-full bg-white"
        cols={100}
        rows={1}
        autoFocus
      />
      <Button variant="label" onClick={sendMessage} className="px-1">
        <PaperAirplaneIcon
          className={twMerge(
            'h-6 w-6 transform rotate-90',
            isValidMessage(message) ? 'fill-primary' : 'fill-gray-400',
          )}
        />
      </Button>
    </div>
  )
}

export default Input
