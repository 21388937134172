import { FindLocations_locations } from 'operations/FindLocations'
import { GetReferrals_opportunities } from 'operations/GetReferrals'

import { ILocation } from 'lib/reactiveVariables'

import { isWithinInterval } from 'date-fns'
import { isEmpty, isNil } from 'ramda'

import { ISimpleLocation } from 'components/opportunities/referrals/filter_referrals/location_filter'

export const isEmptyLocation = (location: ISimpleLocation) => {
  if (isNil(location.city) && isNil(location.state)) return true
  return false
}
export const getAccurateLocation = (
  location: FindLocations_locations | ISimpleLocation,
) => {
  const { city, state } = location
  if (city != '' && state != '') {
    return `${city}, ${state}`
  } else if (state != '' && city == '') {
    return `${state}`
  } else {
    return `${city}`
  }
}

export const getFilteredReferrals = ({
  opportunities,
  PSYPACT,
  psypactStates,
  location,
  datePosted,
  status,
  activity,
  insurances,
}: {
  opportunities: GetReferrals_opportunities[]
  PSYPACT: string
  psypactStates: string[]
  location: string
  datePosted: string
  status: string
  activity: string
  insurances: string[]
}) => {
  let referralList = [...opportunities]

  if (!isNil(PSYPACT)) {
    referralList = referralList.filter((referral) => {
      return referralMatchesPsypact({ referral, psypactStates })
    })
  }
  if (!isNil(location)) {
    referralList = referralList.filter((referral) => {
      return referralMatchesLocation({ referral, location })
    })
  }
  if (!isNil(datePosted) && datePosted !== 'All') {
    referralList = referralList.filter((referral) => {
      return referralMatchesDate({ referral, datePosted })
    })
  }
  if (!isNil(status) && status !== 'All') {
    referralList = referralList.filter((referral) => {
      return referralMatchesStatus({ referral, status })
    })
  }
  // if (!isNil(insurances)) {
  //   referralList = referralList.filter((referral) => {
  //     return referralMatchesInsurances({ referral, insurances })
  //   })
  // }
  if (!isNil(activity) && activity !== 'All') {
    return orderReferralsByActivity({ referralList, activity })
  }

  return referralList
}

export const referralMatchesLocation = ({
  referral,
  location,
}: {
  referral: GetReferrals_opportunities
  location: string
}) => {
  if (referral.seeking_info.location) {
    const { city, state } = referral?.seeking_info?.location

    return (
      `${city}, ${state}` === location ||
      `${city}` === location ||
      `${state}` === location
    )
  }
}

export const referralMatchesPsypact = ({
  referral,
  psypactStates,
}: {
  referral: GetReferrals_opportunities
  psypactStates: string[]
}) => {
  if (referral.seeking_info.location) {
    const { state }: { state: any } = referral?.seeking_info?.location

    return psypactStates.some((psypactState) => psypactState === state)
  }
}

export const orderReferralsByActivity = ({
  referralList,
  activity,
}: {
  referralList: GetReferrals_opportunities[]
  activity: string
}) => {
  return activity === 'Most responses'
    ? referralList.sort((a, b) => b?.respondants.length - a?.respondants.length)
    : referralList.sort((a, b) => b?.comments.length - a?.comments.length)
}

export const referralMatchesStatus = ({
  referral,
  status,
}: {
  referral: GetReferrals_opportunities
  status: string
}) => {
  return status === 'Closed/Filled' ? referral.filled : !referral.filled
}

export const referralMatchesDate = ({
  referral,
  datePosted,
}: {
  referral: GetReferrals_opportunities
  datePosted: string
}) => {
  if (referral.created_at) {
    const now = new Date()
    const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    const lastWeek = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
    const last2Weeks = new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000)
    if (datePosted === 'Last 24 hours') {
      return isWithinInterval(new Date(referral.created_at), {
        start: yesterday,
        end: now,
      })
    }
    if (datePosted === 'Last 7 days') {
      return isWithinInterval(new Date(referral.created_at), {
        start: lastWeek,
        end: now,
      })
    }
    if (datePosted === 'Last 14 days') {
      return isWithinInterval(new Date(referral.created_at), {
        start: last2Weeks,
        end: now,
      })
    }
    return !isWithinInterval(new Date(referral.created_at), {
      start: last2Weeks,
      end: now,
    })
  }
}

export const mapValuesAsOptions = (value: any) => {
  return { title: value, value }
}

export const mapValuesAsCheckboxOptions = (
  value: string,
  reactiveVar: string[],
) => ({
  name: value,
  selected: reactiveVar.includes(value),
})

export const parseSelectedCheckboxOptions = (
  state: {
    name: string
    selected: boolean
  }[],
) => state.filter(({ selected }) => selected).map(({ name }) => name)

export const matchPostDate = (value: string) => {
  const now = new Date().toISOString()
  const yesterday = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000,
  ).toISOString()
  const lastWeek = new Date(
    new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
  ).toISOString()
  const last2Weeks = new Date(
    new Date().getTime() - 14 * 24 * 60 * 60 * 1000,
  ).toISOString()
  if (value === 'Last 24 hours') {
    return {
      _and: [
        { created_at: { _gte: yesterday } },
        { created_at: { _lte: now } },
      ],
    }
  } else if (value === 'Last 7 days') {
    return {
      _and: [{ created_at: { _gte: lastWeek } }, { created_at: { _lte: now } }],
    }
  } else if (value === 'Last 14 days') {
    return {
      _and: [
        { created_at: { _gte: last2Weeks } },
        { created_at: { _lte: now } },
      ],
    }
  } else if (value === 'More than 14 days') {
    return {
      _and: [{ created_at: { _lt: last2Weeks } }],
    }
  } else if (value === 'All') {
    return {}
  }

  return { created_at: { _is_null: false } }
}

export const matchStatus = (value: string) => {
  if (value === 'All') {
    return {}
  }
  if (value === 'Open/Unfilled') {
    return { filled: { _eq: false } }
  }

  return { filled: { _eq: true } }
}

export const matchActivity = (value: string) => {
  if (value === 'All') {
    return []
  }
  if (value === 'Most responses') {
    return [
      {
        respondants_aggregate: { count: 'desc' },
      },
      { opportunity_respondants_outsiders_aggregate: { count: 'desc' } },
    ]
  }

  return [{ comments_aggregate: { count: 'desc' } }]
}

export const matchLocation = (value: ISimpleLocation) => {
  const { city, state } = value
  return {
    seeking_info: {
      _contains: {
        locations: [
          {
            city: city?.trim(),
            state: state?.trim(),
          },
        ],
      },
    },
  }
}
