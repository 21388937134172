import clsx from 'clsx'
import { FunctionComponent } from 'react'

import { Box } from '../box'
import Line from '../line'

type CardColors = 'primary' | 'secondary' | 'gray'

interface ICard extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  actions?: React.ReactElement
  contentClassname?: string
  headerClassName?: string
  topBorderColor?: CardColors
  background?: 'white' | 'page'
}

const Card: FunctionComponent<ICard> = ({
  title,
  actions,
  children,
  contentClassname,
  headerClassName,
  topBorderColor,
  background = 'page',
  className,
  ...props
}) => {
  const borderColor = {
    primary: 'bg-primary',
    secondary: 'bg-secondary',
    gray: 'bg-gray',
  }[topBorderColor ?? 'gray']

  return (
    <div {...props}>
      <div className={clsx('h-[1.1rem] -mb-3 rounded-t', borderColor)} />
      <Box background={background} className={clsx('flex flex-col', className)}>
        <div className="flex flex-1 p-5 w-full">
          {title && (
            <>
              <header
                className={clsx(
                  'flex justify-between w-full mb-1',
                  headerClassName,
                )}
              >
                <p className="text-lg font-semibold">{title}</p>
                <div className="relative">{actions}</div>
              </header>
              <Line />
            </>
          )}
          <section
            className={clsx(contentClassname, title && 'mt-1', 'w-full')}
          >
            {children}
          </section>
        </div>
      </Box>
    </div>
  )
}

export default Card
