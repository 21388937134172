import { FunctionComponent } from 'react'

interface Props {
  hasNotification?: boolean | undefined
}

const HamburgerMenuIcon: FunctionComponent<Props> = ({ hasNotification }) => (
  <div className="realtive">
    {hasNotification && (
      <div className="relative">
        <span className="absolute -top-0.5 left-3 w-3 h-3 rounded-full bg-fourth" />
      </div>
    )}
    <svg
      className="w-6 h-6 text-gray-dark hover:text-purple-mid"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M4 6h16M4 12h16M4 18h16" />
    </svg>
  </div>
)

export default HamburgerMenuIcon
