import { ProfileDataFragment } from '@/lib/queries/profiles'
import { gql } from '@apollo/client'

export const Q_GET_QUESTIONNAIRE_USER = gql`
  query GetQuestionnaireUser($uuid: String!) {
    questionnaire_users(where: { identifier: { _eq: $uuid } }) {
      id
      identifier
      email
    }
  }
`

export const Q_GET_CLIENTS_BY_EMAIL = gql`
  query GetProfileByEmail($email: String) {
    profiles(
      where: { contact_info: { _cast: { String: { _ilike: $email } } } }
    ) {
      id
      first_name
      last_name
      avatar
      owner {
        id
        primary_email_address
        roles
      }
    }
  }
`

export const Q_GET_CLIENT_PROFILE = gql`
  ${ProfileDataFragment}
  query GetClientProfile($ownerId: bpchar!) {
    profiles(where: { owner_id: { _eq: $ownerId } }) {
      ...ProfileData
      research
      biography
      license
      header_url
      press
      contact_info
      office_hours
      services
      module_properties
    }
  }
`

export const Q_GET_MATCHES = gql`
  query GetMatches($client_id: Int!) {
    client_therapist_matches(
      where: { client_profile_id: { _eq: $client_id } }
      order_by: { score: desc }
    ) {
      therapist {
        avatar
        first_name
        id
        last_name
        username
        office_info
        contact_info
        degree
        avatar
        modality
        educations
        experiences_search(order_by: { end_year: desc, end_month: desc }) {
          title
          organization
        }
        current_job_new
      }
    }
  }
`

export const Q_GET_MATCHES_BY_JOB = gql`
  query GetMatchesByJob($job_id: Int!) {
    client_therapist_matches(
      where: { job_id: { _eq: $job_id } }
      order_by: { score: desc }
      limit: 100
    ) {
      therapist {
        avatar
        first_name
        id
        last_name
        username
        office_info
        contact_info
        degree
        avatar
        modality
        educations
        experiences_search(order_by: { end_year: desc, end_month: desc }) {
          title
          organization
        }
        current_job_new
      }
    }
  }
`

export const Q_GET_MATCH_JOBS = gql`
  query GetMatchJobs {
    client_therapist_match_jobs(order_by: { created_at: desc }, limit: 100) {
      id
      questionnaire_user {
        email
      }
    }
  }
`
