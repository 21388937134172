import type { GetMyNews } from 'operations/GetMyNews'
import type {
  GetNews,
  GetNews_followed_sources_sources,
} from 'operations/GetNews'
import type { GetOwnerId } from 'operations/GetOwnerId'

import { Q_GET_MY_NEWS, Q_GET_NEWS } from 'lib/queries/news'
import { Q_GET_PROFILE_OWNER_ID } from 'lib/queries/owner_id'

import { useQuery } from '@apollo/client'
import { useUser } from '@clerk/clerk-react'
import { useMemo } from 'react'

import usePagination from './usePagination'

const useNews = ({
  categoryId,
  sourceId,
  categoryName,
}: { categoryId?: string; sourceId?: string; categoryName?: string } = {}) => {
  const { user } = useUser()
  const LIMIT = 8
  const { data: profilesOwned } = useQuery<GetOwnerId>(Q_GET_PROFILE_OWNER_ID, {
    variables: {
      ownerId: user?.id,
    },
    skip: !user,
  })

  const { optimisticState: myNewsData, setNewLimit: setNewLimitMyNews } =
    usePagination<GetMyNews>({
      limit: LIMIT,
      query: {
        node: Q_GET_MY_NEWS,
        variables: {
          newsCondition: {
            _and: [
              {
                news_source: sourceId
                  ? { id: { _eq: sourceId } }
                  : {
                      followed_sources: {
                        from_profile_id: {
                          _eq: profilesOwned?.profiles[0]?.id,
                        },
                      },
                    },
              },
              { categories: categoryName ? { _contains: categoryName } : {} },
            ],
          },
        },
      },
    })

  const { optimisticState: newsDataFetched, setNewLimit: setNewLimitNews } =
    usePagination<GetNews>({
      limit: LIMIT,
      query: {
        node: Q_GET_NEWS,
        variables: {
          sourcesCondition: {
            _and: [
              Number(categoryId) !== 0 && categoryId
                ? {
                    news_categories: {
                      category_id: {
                        _eq: Number(categoryId),
                      },
                    },
                  }
                : sourceId
                ? { id: { _eq: sourceId } }
                : {},
              { news: { id: { _gt: 0 } } },
            ],
          },
          categoriesCondition: {
            group_id: { _is_null: true },
          },
          followedSourcesCondition: {
            from_profile_id: { _eq: profilesOwned?.profiles[0]?.id },
          },
        },
      },
    })

  const newsData = useMemo(
    () => ({
      ...newsDataFetched,
      news: myNewsData?.news || [],
      followed_sources: newsDataFetched?.followed_sources.map((s) => ({
        ...s.sources,
      })) as GetNews_followed_sources_sources[],
      totalSources: newsDataFetched?.news_sources_aggregate.aggregate?.count,
      totalFollowingSources:
        newsDataFetched?.total_followed_sources.aggregate?.count,
      totalNews: myNewsData?.news_aggregate.aggregate?.count,
    }),
    [myNewsData, newsDataFetched],
  )

  return {
    newsData,
    setNewLimitNews,
    setNewLimitMyNews,
    paginationLimit: LIMIT,
  }
}

export default useNews
