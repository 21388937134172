import { FindCities } from 'operations/FindCities'
import { FindStates } from 'operations/FindStates'

import { Q_FIND_CITIES, Q_FIND_STATES } from 'lib/queries/locations'

import { useLazyQuery } from '@apollo/client'
import clsx from 'clsx'
import { isEmpty } from 'ramda'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Text } from 'components/ui'
import { ComboboxDemo as Combobox } from 'components/ui/combobox/newCombobox'

import { getOptionFormat, removeDuplicatedCities } from 'utils/locations'

export interface ISimpleLocation {
  city: string | null
  state: string | null
}

export const LocationSelect = ({
  location,
  handleChangeLocation,
  horizontal = false,
  className = '',
  hasFieldTitles = false,
}: {
  location: ISimpleLocation
  handleChangeLocation: ({
    field,
    value,
  }: {
    field: string
    value: string
  }) => void
  horizontal?: boolean
  className?: string
  hasFieldTitles?: boolean
}) => {
  const [getCitiesData, { data: citiesData }] =
    useLazyQuery<FindCities>(Q_FIND_CITIES)
  const [getStatesData, { data: statesData }] =
    useLazyQuery<FindStates>(Q_FIND_STATES)

  const { control, reset, watch } = useForm({
    defaultValues: useMemo(
      () => ({
        city: location.city || '',
        state: location.state || '',
      }),
      [location],
    ),
  })

  useEffect(() => {
    reset({
      city: location.city || '',
      state: location.state || '',
    })
  }, [reset, location])

  const selectedState = watch('state')

  const cityOptions = () =>
    removeDuplicatedCities(citiesData?.locations || []).map((city) =>
      getOptionFormat(city || ''),
    ) || []

  const stateOptions = statesData?.locations.map((state) =>
    getOptionFormat(state.state || ''),
  )

  return (
    <form
      className={clsx(
        'flex w-full',
        horizontal ? 'gap-7' : 'flex-col',
        className,
      )}
    >
      <div className="w-full">
        {hasFieldTitles && <Text variant="subheading">State:</Text>}
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, value } }) => (
            <Combobox
              data={stateOptions}
              parentBgColor="white"
              getSelectedOptions={(selected) => {
                if (!Array.isArray(selected)) {
                  handleChangeLocation({
                    field: 'state',
                    value: selected?.value,
                  })
                  onChange(selected?.value)
                }
              }}
              getData={(state) => {
                getStatesData({
                  variables: {
                    searchState: `${state ?? '%'}%`,
                  },
                })
              }}
              buttonPlaceholder={isEmpty(value) ? 'Type in a State' : value}
            />
          )}
        />
      </div>
      <div className="w-full">
        {hasFieldTitles && <Text variant="subheading">City:</Text>}
        <Controller
          control={control}
          name="city"
          render={({ field: { onChange, value } }) => (
            <Combobox
              disabled={isEmpty(selectedState)}
              data={cityOptions()}
              parentBgColor="white"
              getSelectedOptions={(selected) => {
                if (!Array.isArray(selected)) {
                  handleChangeLocation({
                    field: 'city',
                    value: selected?.value,
                  })
                  onChange(selected?.value)
                }
              }}
              getData={(city) => {
                getCitiesData({
                  variables: {
                    searchCity: `${city ?? '%'}%`,
                    searchState: `${selectedState ?? '%'}%`,
                  },
                })
              }}
              buttonPlaceholder={isEmpty(value) ? 'Type in a City' : value}
            />
          )}
        />
      </div>
    </form>
  )
}
