/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_opportunities.yaml
// ====================================================

/**
Table name: opportunities, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _or:
          - author:
              owner:
                roles:
                  _contains: approved
          - business:
              owner:
                owner_id:
                  _eq: X-Hasura-User-Id
          - business:
              administrators:
                profile:
                  owner_id:
                    _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - author:
              owner_id:
                _eq: X-Hasura-User-Id
          - business:
              owner:
                owner_id:
                  _eq: X-Hasura-User-Id
          - business:
              administrators:
                profile:
                  owner_id:
                    _eq: X-Hasura-User-Id

**/
export const check_opportunities_insert_permissions = function(feature_flags:any, opportunity:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (opportunity?.author?.owner?.roles?.includes("approved")
			|| opportunity?.business?.owner?.owner_id === user?.id
			|| opportunity?.business?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id)))
	|| (feature_flags.strict_permissions === false
		&& (opportunity?.author?.owner_id === user?.id
			|| opportunity?.business?.owner?.owner_id === user?.id
			|| opportunity?.business?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id))))
}


/**
Table name: opportunities, update permission rules: 

_or:
  - author:
      owner_id:
        _eq: X-Hasura-User-Id
  - business:
      owner:
        owner_id:
          _eq: X-Hasura-User-Id
  - business:
      administrators:
        profile:
          owner_id:
            _eq: X-Hasura-User-Id

**/
export const check_opportunities_update_permissions = function(opportunity:any, user:any): boolean {
	return (opportunity?.author?.owner_id === user?.id
	|| opportunity?.business?.owner?.owner_id === user?.id
	|| opportunity?.business?.administrators?.some((_item:any) => _item?.profile?.owner_id === user?.id))
}