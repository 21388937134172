import clsx from 'clsx'
import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

interface ILoading extends React.HTMLAttributes<HTMLDivElement> {}

const Loading: FunctionComponent<ILoading> = ({
  className,
  ...props
}: ILoading) => {
  return (
    <div
      className={twMerge(
        'w-5 h-5 rounded-full border-2 border-t-2 border-gray-300 ease-linear animate-spin border-t-primary',
        className,
      )}
    />
  )
}

export default Loading
