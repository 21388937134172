import clsx from 'clsx'
import { FunctionComponent, ReactNode } from 'react'

import { Text } from 'components/ui'

interface IOnboardingStep {
  title: string | ReactNode
  subtitle?: string
  headerStyle?: string
  titleStyle?: string
  subtitleStyle?: string
  className?: string
  childrenStyle?: string
  children?: ReactNode
}

const OnboardingStep: FunctionComponent<IOnboardingStep> = ({
  title,
  subtitle,
  className,
  headerStyle,
  titleStyle,
  subtitleStyle,
  childrenStyle,
  children,
}: IOnboardingStep) => {
  return (
    <section className={clsx(className, 'w-1/2')}>
      <div
        className={clsx(
          headerStyle,
          'flex flex-col gap-2 mb-4 text-center justify-center',
        )}
      >
        <Text variant="title" className={titleStyle}>
          {title}
        </Text>
        <Text size="lg" className={subtitleStyle}>
          {subtitle}
        </Text>
      </div>
      <div className={clsx(childrenStyle, 'mx-auto')}>{children}</div>
    </section>
  )
}

export default OnboardingStep
