import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { Highlight } from 'react-instantsearch'

import ConnectButton from 'components/common/connect_button'
import CurrentJob from 'components/common/current_job'
import MessageButton from 'components/common/message_button'
import { useProfile } from 'components/hooks'
import { Avatar, Text } from 'components/ui'

import { highlightName, highlightProfilesClassName } from '../../utils'

interface IProfileCardProps {
  hit: any
}

const BusinessProfileCard: FunctionComponent<IProfileCardProps> = ({ hit }) => {
  return (
    <div className="flex w-full">
      <div className="flex gap-3">
        <div>
          <Avatar
            size={10}
            objectFit="cover"
            layout="fill"
            className="inline rounded-full"
            avatar={hit.logo}
            alt="Profile image"
          />
        </div>
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            <div className="flex gap-1">
              <Text variant="body" weight="semibold" color="black">
                <Highlight
                  attribute="organization_name"
                  hit={hit}
                  classNames={highlightName}
                />
              </Text>
            </div>
          </div>

          <Text variant="small">
            <Highlight
              attribute="organization_subtype"
              hit={hit}
              classNames={highlightProfilesClassName}
            />
          </Text>
          <Text variant="small">
            <Highlight
              attribute="tagline"
              hit={hit}
              classNames={highlightProfilesClassName}
            />
          </Text>
        </div>
      </div>
    </div>
  )
}

export default BusinessProfileCard
