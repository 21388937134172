import { useQuery } from '@apollo/client'
import type { OperationVariables } from '@apollo/client'
import type { DocumentNode } from 'graphql'
import { useCallback, useEffect, useState } from 'react'

interface IPaginationProps {
  limit: number
  query: {
    node: DocumentNode
    variables: OperationVariables | undefined
  }
}

export default function usePagination<T>(props: IPaginationProps) {
  const { query, limit } = props
  const [paginationLimit, setPaginationLimit] = useState(limit)
  const [optimisticState, setOptimisticState] = useState<T>()
  const { data, loading, refetch } = useQuery<T>(query.node, {
    variables: { ...query.variables, limit: paginationLimit },
  })

  const getNewItems = useCallback(() => {
    refetch()
  }, [refetch])

  const setNewLimit = () => {
    if (!loading) setPaginationLimit((prev) => prev + limit)
  }

  useEffect(getNewItems, [paginationLimit, getNewItems])

  useEffect(() => {
    if (data && !loading) {
      setOptimisticState(data)
    }
  }, [data, loading, setOptimisticState])

  /*
   * We are using optimistic state because
   * there is a blink in the ui while
   * the data is loading, so we keep the
   * previous state until it gets the new
   * data.
   */
  return {
    optimisticState,
    setNewLimit,
    refetch,
    loading,
  }
}
