const HourGlassIcon = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="57"
    viewBox="0 0 53 57"
    className={className}
  >
    <g>
      <g>
        <image
          width="53"
          height="57"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAA1CAYAAADPo4LiAAAAAXNSR0IArs4c6QAABJ9JREFUWEft2HWIplUUBvDf2qJiYBeKHWBhB9iFBcYf9ioWWNjBqogdqNgu5ip251qIgYWBio2Ka2Chgq6J8oz3Lu98O9/MfLPz7fjHHhjmfd977r3Pd+6J59xR/pOTcCDmKu9T49+vuBlHjcLuGDc1dm2zx5EB8Tg2G0EQjwXEFThoBEGMC4gF8SbmHwEgE7FmQETmxAE4F0/h7i4C2hQ74QxciQkVRN3zH5yIs7oIogbCAvgm+zRBLIMPcGHCposgjsZ5WBcvTgPRzhJL48ORPo7/BYhl8T7OwfFddMzDcRE2wPOtjrkEPsGlOLSLIGp0rI7XW0HMhN/xMXI03ZInsQlmQypprzyR96owL75voFgOiyLJLH/9SR2fviSjt1qUM/4Rkpd6pDVjboRncE1J49GpFhqqZebDd2Xy6TgZezbpQyuI6L6BVVJY8GoBmgyaLNepZE42/QPV577Cws2F+gIRs39elFYroPIaRwr5WWEQSLLRPhhfdJfEa4W5rVqq9qRl+gKRwe1xX9EajevKc875KuzXD5D7kTnVp3bFbUV/L9zUOrcdiOithUcwD27HHvizLJBKeAMCqilH4OLGh8txcHnforC4yfD3ByLKs+JG7IxvsR1eKqskuQXIOvisgHyujK1YOEmiKvQxoDO/TxkIRJ10WOMXxklT7qvExPfi5/JhX1xbnk/FaQP50GBBZJ2V8DAWL79uF/zU2GA63Ip8/xFbV74wnCCyViXG6VF+wY54AvH4e0oY3oW9y/hA+/eMd2KJ5oLZ5PryoYbf3KXmpPZ0JEMFkU3OxnHYCo+WCNqto92L8lBBbIk7MAcSJe8hPpEoynF0JEMBEY8/peyS0LsFmyNJapYSRckXg5ZOQCyEO7Ee3i69Q6phlTRPcc6Mv1OctjneFtRgQeSsE36RFKVjGysmFJNZq5yAM8tL2suk+X5lMCCShpOsIjsUs+c56TxJKd9SbXM06VsiG+OBQlwSRfvj73ZI+gOxVMmEKyPpOIUo1TGSLj6+EK5Q5a8CJHUmMnu5f0gx/BLbNipyLzztQORce0hoH21hTB2Tt5OxhRBVhhW+eklRztElnAcEkduaH0oia05arPCJsK+BJJQu7CndfmTtRgpPcvu0uUBflgi9y0bbNBwuXXR4QMhpJ5L0fnWZsEbxneSUXsSoFUQo3cslEcUHIk1zdgKg6oZXjikvlV80HXyy2vFgcaBQvC+wPN4dys4tc0Lxn8bM+K0cU4pejzQtMWMhpBOQ8488WzqlKcUR0hOiG8l1QHwkgEKAe4GobDi3J6Fki+QWZUp3b8wPXXylFL0Uv0mEt2mJavooJASb5Xo4sFSWVXvRDUv+6WWJ2hBfgPSLVXk4AGSNegPU701NBVGVD8Flw4WgXMqFf3QEIn6RkBouqVcO00AMyhIzlA4rN/4pUmHSISnDJcfg/FJT0lCFJH3dmidS83Ml8FCpE6Fs/fWcnYIL/Uv5TxcX7hFiFGY+MXkiZPUFhDdMbUkyXD8gYqJu3uAO9MPGBkS9IhpIuVvj4wNiuPNBp2DH1NoR+pVGtlPS0umGTf3cFIbqjf4Xvs0JLwOpOl4AAAAASUVORK5CYII="
        />
      </g>
    </g>
  </svg>
)

export default HourGlassIcon
