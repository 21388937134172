import { ChevronsUpDown } from '@/components/common/icons'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { CheckIcon as Check, ChevronDownIcon } from '@heroicons/react/solid'
import { CommandLoading } from 'cmdk'
import debounce from 'lodash.debounce'
import { equals, isEmpty } from 'ramda'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from '../button'
import { Chip } from '../chip'
import { Loading } from '../loading'
import { Text } from '../text'

export enum OptionType {
  customized = 'CUSTOMIZED',
}

export type ComboboxOptionType = {
  title: string
  value: any
  type?: OptionType
}

type ComboboxType = {
  parentBgColor?: 'white' | 'page'
  error?: string
  data?: ComboboxOptionType[]
  icon?: React.ReactElement<any>
  isLoading?: boolean
  searchPlaceholder?: string
  buttonPlaceholder?: string
  disabled?: boolean
  multiple?: boolean
  chipVariant?: 'primary' | 'secondary' | 'gray'
  innerChips?: boolean
  maxSelections?: number
  lowercase?: boolean
  hasDropdownIcon?: boolean
  hint?: string
  regularCombobox?: boolean
  hasCustomizedOption?: boolean
  selectAllOption?: boolean
  defaultValues?: ComboboxOptionType | ComboboxOptionType[]
  getData: (value?: string) => void
  customRenderItem?: (option: ComboboxOptionType) => JSX.Element
  getSelectedOptions: (
    options: ComboboxOptionType | ComboboxOptionType[] | undefined,
  ) => void
}

export function ComboboxDemo({
  parentBgColor,
  error,
  data,
  icon,
  hint,
  isLoading,
  searchPlaceholder,
  buttonPlaceholder,
  disabled,
  customRenderItem,
  getData,
  multiple,
  getSelectedOptions,
  chipVariant = 'gray',
  innerChips,
  maxSelections,
  lowercase = false,
  regularCombobox = false,
  hasCustomizedOption = false,
  hasDropdownIcon = false,
  selectAllOption = false,
  defaultValues,
}: ComboboxType) {
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)
  const [hasSelectedMaxItems, setHasSelectedMaxItems] = React.useState(false)
  const [optionObject, setOptionObject] = React.useState<
    ComboboxOptionType | ComboboxOptionType[] | undefined
  >(defaultValues)
  const [lastSelectedTitle, setLastSelectedTitle] = React.useState(
    (defaultValues as ComboboxOptionType)?.title ?? '',
  )
  const backgroundColor =
    parentBgColor === 'white'
      ? 'bg-background border-dark-border'
      : 'bg-white border-light-border'

  const hasCustomRender = !!customRenderItem
  const showSearchbox = !regularCombobox

  const handleClose = (openValue: boolean) => {
    setOpen(openValue)
  }

  const handleTriggerClick = () => {
    getData()
  }

  const unSelectAnItem = (selectedOption: ComboboxOptionType) => {
    if (!Array.isArray(optionObject)) {
      return
    }

    if (maxSelections && optionObject.length <= maxSelections) {
      setHasSelectedMaxItems(false)
    }

    const filteredOptions = optionObject.filter(
      (item) => !equals(item, selectedOption),
    )

    getSelectedOptions(filteredOptions)
    setOptionObject(filteredOptions)
  }

  // This funtions is being trigger when the user selects an option
  // and the multiple parameter is true
  const handleMultipleSelections = (
    currentValue: string,
    type?: OptionType,
  ) => {
    const selectedOption = data?.find(
      (dataItem) =>
        dataItem.title.trim().toLowerCase() ===
        currentValue.trim().toLowerCase(),
    )

    // This part checks if there's a type for the current value,
    // if there is, it sets it as the type
    // This is intended for suggesting a new option
    if (selectedOption && type) {
      selectedOption.type = type
    }

    if (!selectedOption) {
      return
    }

    const isAll = selectedOption.value == ''
    let isAlreadySelected = false

    // Handle duplicated selections
    if (Array.isArray(optionObject)) {
      isAlreadySelected = optionObject.some((option) =>
        equals(option, selectedOption),
      )
      if (isAlreadySelected) {
        unSelectAnItem(selectedOption)
        return
      }

      if (maxSelections && optionObject.length >= maxSelections) {
        setHasSelectedMaxItems(true)
        return
      }
    }

    let newOptionsObject: ComboboxOptionType[] = []
    if (!Array.isArray(optionObject)) {
      newOptionsObject = [selectedOption]
    } else {
      newOptionsObject = [...optionObject, selectedOption]
    }

    if (selectAllOption && data) {
      const allDataSelected =
        data.filter((item) => item.value != '').length ==
        (optionObject as ComboboxOptionType[]).length

      if (isAll && !isAlreadySelected && !allDataSelected) {
        newOptionsObject = data
          .filter((item) => item.value != '')
          .map((item) => {
            return {
              title: item.title,
              value: item.value,
            }
          })
      } else if (isAll && !isAlreadySelected && allDataSelected) {
        newOptionsObject = []
      } else if (!isAll) {
        const isSelectAllAlreadySelected = newOptionsObject.find(
          (item) => item.value == '',
        )
        if (isSelectAllAlreadySelected) {
          newOptionsObject = newOptionsObject.filter((item) => item.value != '')
        }
      }
    }

    getSelectedOptions(newOptionsObject)
    setOptionObject(newOptionsObject)
  }

  const handleSingleSelection = (currentValue: string) => {
    const selectedOption = data?.find(
      (dataItem) => dataItem.title.toLowerCase() === currentValue.toLowerCase(),
    )

    if (equals(selectedOption, optionObject)) {
      setOptionObject(undefined)
      setLastSelectedTitle('')
      handleClose(false)
      return
    }

    getSelectedOptions(selectedOption)
    setOptionObject(selectedOption)
    setLastSelectedTitle(currentValue)
    handleClose(false)
  }

  const debouncedInputChange = debounce((value) => getData(value), 150)

  const isAlreadySelected = (dataItemSelected: ComboboxOptionType) => {
    if (multiple && Array.isArray(optionObject)) {
      const isSelected = !!optionObject.find((option) =>
        equals(option, dataItemSelected),
      )
      return isSelected ? 'opacity-100' : 'opacity-0'
    } else {
      return equals(optionObject, dataItemSelected)
        ? 'opacity-100'
        : 'opacity-0'
    }
  }

  React.useEffect(() => {
    if (defaultValues) {
      setOptionObject(defaultValues)
      setLastSelectedTitle((defaultValues as ComboboxOptionType)?.title ?? '')
    }
  }, [defaultValues])

  return (
    <Popover open={open} onOpenChange={handleClose}>
      <PopoverTrigger disabled={disabled} className="w-full" ref={buttonRef}>
        <Button
          type="button"
          ref={buttonRef}
          disabled={disabled}
          onClick={handleTriggerClick}
          role="combobox"
          aria-disabled={disabled}
          aria-expanded={open}
          className={twMerge(
            'flex w-full flex-col gap-2 border py-0',
            backgroundColor,
          )}
        >
          <div className="flex justify-between items-center w-full text-gray py-[14.5px]">
            <div className="flex items-center">
              {icon && icon}
              {lastSelectedTitle ? (
                <Text className={twMerge(!lowercase && 'capitalize')}>
                  {lastSelectedTitle}
                </Text>
              ) : (
                <Text
                  className={twMerge(' text-gray', !lowercase && 'capitalize')}
                >
                  {buttonPlaceholder}
                </Text>
              )}
            </div>
            {hasDropdownIcon ? (
              <ChevronDownIcon className="ml-2 w-4 h-4 opacity-50 shrink-0" />
            ) : (
              <ChevronsUpDown className="ml-2 w-4 h-4 opacity-50 shrink-0" />
            )}
          </div>
          {multiple &&
            innerChips &&
            Array.isArray(optionObject) &&
            optionObject.length > 0 && (
              <section className="flex flex-wrap gap-2 mt-2">
                {optionObject.map((item, idx) => (
                  <Chip
                    onDelete={() => unSelectAnItem(item)}
                    key={idx}
                    label={item.title}
                    variant={chipVariant}
                  />
                ))}
              </section>
            )}
        </Button>
        {error && <Text className="text-sm text-left text-error">{error}</Text>}
        {hasSelectedMaxItems && (
          <Text className="text-sm text-left text-error">
            You can not select more options.
          </Text>
        )}
        {hint && (
          <Text
            size="xs"
            variant="body"
            className="mt-1 text-left text-[#7a8fce]"
          >
            {hint}
          </Text>
        )}
        {multiple && !innerChips && Array.isArray(optionObject) && (
          <section className="flex flex-wrap gap-2 mt-2">
            {optionObject.map((item, idx) => (
              <Chip
                onDelete={() => unSelectAnItem(item)}
                key={idx}
                label={item.title}
                variant={chipVariant}
              />
            ))}
          </section>
        )}
      </PopoverTrigger>
      <PopoverContent
        className={twMerge('p-0', backgroundColor)}
        style={{ width: buttonRef.current?.clientWidth }}
      >
        <Command shouldFilter={false}>
          {showSearchbox && (
            <CommandInput
              ref={inputRef}
              onValueChange={debouncedInputChange}
              placeholder={searchPlaceholder ?? 'Search for an option...'}
            />
          )}
          <CommandList>
            {isLoading && !data && <CommandLoading></CommandLoading>}
          </CommandList>
          <CommandEmpty>
            {isEmpty(inputRef.current?.value)
              ? 'Start typing to get suggestions...'
              : 'No Results Found'}
          </CommandEmpty>
          <CommandGroup className="overflow-y-auto capitalize max-h-[300px]">
            {data?.length === 0 &&
              !isLoading &&
              !isEmpty(inputRef.current?.value) &&
              hasCustomizedOption && (
                <CommandItem
                  key={inputRef.current?.value}
                  value={inputRef.current?.value}
                  onSelect={(currentValue) => {
                    if (inputRef.current?.value) {
                      data.push({
                        title: inputRef.current?.value,
                        value: inputRef.current?.value,
                      })
                    }
                    if (multiple) {
                      handleMultipleSelections(
                        currentValue,
                        OptionType.customized,
                      )
                    } else {
                      handleSingleSelection(currentValue)
                    }
                  }}
                >
                  <Check
                    className={twMerge(
                      'mr-2 h-4 w-4',
                      isAlreadySelected({
                        title: inputRef.current?.value || '',
                        value: inputRef.current?.value,
                      }),
                    )}
                  />
                  Suggest adding: {inputRef.current?.value}
                </CommandItem>
              )}
            {data?.map((dataItem, index) => (
              <CommandItem
                key={index}
                onSelect={(currentValue) => {
                  if (multiple) {
                    handleMultipleSelections(currentValue)
                  } else {
                    handleSingleSelection(currentValue)
                  }
                }}
              >
                {hasCustomRender ? (
                  customRenderItem(dataItem)
                ) : (
                  <>
                    <Check
                      className={twMerge(
                        'mr-2 h-4 w-4',
                        isAlreadySelected(dataItem),
                      )}
                    />
                    {dataItem.title}
                  </>
                )}
              </CommandItem>
            ))}
            {isLoading && (
              <div className="grid place-items-center py-2">
                <Loading />
              </div>
            )}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
