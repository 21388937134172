import { ProfileFields } from 'lib/queries/posts'

import { gql } from '@apollo/client'

export const S_GET_UNREAD_NOTIFICATIONS = gql`
  ${ProfileFields}
  subscription OnNewNotifications($owner_id: bpchar!) {
    notifications(
      where: {
        _and: [
          { to_profile: { owner_id: { _eq: $owner_id } } }
          { unread: { _eq: true } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      content
      id
      created_at
      category
      unread
      post {
        id
        is_anonymous
        title
        author {
          ...ProfileFields
        }
        liked_profiles(order_by: { created_at: desc }) {
          created_at
          from_profile {
            ...ProfileFields
          }
        }
      }
      post_comment {
        id
        is_anonymous
        author {
          ...ProfileFields
        }
        post {
          id
          title
          is_anonymous
          author {
            ...ProfileFields
          }
        }
      }
      opportunity {
        id
        title
        author {
          ...ProfileFields
        }
      }
      opportunity_comment {
        id
        author {
          ...ProfileFields
        }
        opportunity {
          id
          title
          author {
            ...ProfileFields
          }
        }
      }
      invitation {
        id
        to_profile {
          ...ProfileFields
        }
        from_profile {
          ...ProfileFields
        }
      }
      to_profile {
        ...ProfileFields
      }
      from_profile {
        ...ProfileFields
      }
    }
  }
`
