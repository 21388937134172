import { twMerge } from 'tailwind-merge'
import { capitalize } from 'utils'

import { Text } from 'components/ui'

interface MessageContentProps {
  messageContent: string
  hasUnreadMessages: boolean
  isSentByMe: boolean
  talkingToProfileFirstName: string
}

function MessageContent({
  messageContent,
  hasUnreadMessages,
  isSentByMe,
  talkingToProfileFirstName,
}: MessageContentProps) {
  return (
    <Text
      variant="extra-small"
      weight={hasUnreadMessages ? 'semibold' : 'regular'}
      className={twMerge(hasUnreadMessages && 'text-black')}
    >
      {isSentByMe ? 'You:' : `${capitalize(talkingToProfileFirstName)}:`}{' '}
      {messageContent}
    </Text>
  )
}

export default MessageContent
