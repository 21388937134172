import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'

function BelonglyLogo({
  notResponsive = false,
  color = 'primary',
  size = '4xl',
}: {
  notResponsive?: boolean
  color?: string
  size?: '2xl' | '3xl' | '4xl' | '5xl'
}) {
  return (
    <div className="flex items-center">
      <div
        className={clsx(
          `${notResponsive ? '' : 'hidden'}  md:flex`,
          `font-qanelas-extrabold lg:col-span-1 text-${size} tracking-[-0.09em]`,
          `text-${color}`,
        )}
      >
        <Link href="/home" prefetch={false}>
          Belongly
        </Link>
      </div>
      <div
        className={clsx(
          `${notResponsive ? 'hidden' : 'flex'} md:hidden`,
          'font-qanelas-extrabold lg:col-span-1 text-2xl lg:text-4xl tracking-[-0.09em]',
          `text-${color}`,
        )}
      >
        <Link href="/home" prefetch={false}>
          Be
        </Link>
      </div>
    </div>
  )
}

export default BelonglyLogo
