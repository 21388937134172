import { GetProfileByOwnerId } from 'operations/GetProfileByOwnerId'
import { GetReferralById_opportunities_respondants } from 'operations/GetReferralById'

import { Q_GET_PROFILE } from 'lib/queries/profiles'

import { useQuery } from '@apollo/client'
import { useUser } from '@clerk/nextjs'

interface HookProps {
  recommendations: GetReferralById_opportunities_respondants[]
}

const useRecommendationSortByUser = ({ recommendations }: HookProps) => {
  const { user } = useUser()
  const { data: ownerData } = useQuery<GetProfileByOwnerId>(Q_GET_PROFILE, {
    variables: { ownerId: user?.id },
  })

  const recommendationsCopy = [...recommendations]
  const userInRecommendations = recommendationsCopy.filter((recommended) => {
    return recommended.from_profile_id === ownerData?.profiles[0].id
  })
  const recommendationsWithoutUser = recommendationsCopy.filter(
    (recommended) => {
      return recommended.from_profile_id !== ownerData?.profiles[0].id
    },
  )
  const sortedRecommendations = [
    ...userInRecommendations,
    ...recommendationsWithoutUser,
  ]

  return sortedRecommendations
}

export default useRecommendationSortByUser
