import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  className?: string
}

const CommunicationIcon: FunctionComponent<Props> = ({ className = '' }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    className={twMerge('w-48 h-48 -m-16', className)}
    viewBox="0 0 1412 1008"
  >
    <style type="text/css"></style>
    <path
      d="M776.02,312c4.47,1.05,9.05,1.77,13.39,3.19c31.6,10.31,50.35,41.26,45.12,74.22c-0.16,0.99-0.26,1.98-0.43,3.22
   c0.86,0.34,1.73,0.77,2.65,1.02c19.33,5.38,32.84,22.35,33.5,42.14c0.66,19.84-11.88,37.98-30.97,44.41
   c-2.73,0.92-3.68,2.07-3.63,4.93c0.09,5.99-0.21,11.99-0.4,17.99c-0.23,7.2-6.1,10.41-12.21,6.54
   c-12.96-8.21-25.79-16.63-38.8-24.76c-2.08-1.3-4.81-2.13-7.25-2.15c-18.96-0.17-37.93-0.09-56.89-0.1
   c-25.32,0-41.35-12.19-48.04-36.52c-0.1-0.36-0.24-0.7-0.45-1.34c-5.27,0-10.63-0.13-15.97,0.09c-1.3,0.06-2.69,1.12-3.81,2.01
   c-16.72,13.26-33.34,26.65-50.17,39.78c-2.04,1.59-5.17,2.65-7.72,2.52c-3.76-0.18-5.76-3.48-5.85-7.87
   c-0.25-11.37-0.57-22.74-0.64-34.11c-0.02-2.73-0.78-3.77-3.51-4.46c-29.85-7.51-50.51-34.06-50.44-64.57
   c0.07-30.48,20.93-56.9,50.81-64.35c2.75-0.69,5.54-1.24,8.3-1.85C653.74,312,714.88,312,776.02,312z M671.65,429.81
   c8.15-26.99,22.59-37.69,50.46-37.69c31.05,0,62.1-0.02,93.15,0.04c2.25,0,3.59-0.28,4.18-2.9c6.94-30.77-16.94-61.95-48.46-62.13
   c-57.61-0.34-115.23-0.28-172.84-0.02c-26.52,0.12-48.91,22.81-49.66,49.36c-0.76,26.98,18.43,49.22,45.85,53.14
   c5.61,0.8,7.78,3.2,7.9,8.94c0.16,7.49,0.22,14.99,0.35,22.49c0.03,1.67,0.15,3.33,0.25,5.56c1.61-1.21,2.61-1.92,3.58-2.69
   c12.79-10.18,25.62-20.29,38.31-30.6c3.1-2.52,6.28-3.71,10.26-3.55C660.44,429.97,665.93,429.81,671.65,429.81z M820.89,490.5
   c0-5.24-0.04-9.45,0.01-13.65c0.07-6.41,2.07-8.63,8.51-9.56c14.81-2.13,26.05-15.24,25.91-30.22
   c-0.15-16.1-12.99-29.55-29.06-29.83c-17.83-0.31-35.66-0.13-53.49-0.12c-19.45,0.01-38.91-0.25-58.35,0.17
   c-17.36,0.38-30.85,17.59-27.83,34.68c2.76,15.61,14.7,25.58,31.12,25.67c20.95,0.11,41.9-0.07,62.84,0.14
   c2.92,0.03,6.17,1.03,8.68,2.56C799.54,476.63,809.63,483.28,820.89,490.5z"
    />
    <path
      d="M514,652.5c0.9-3.48,1.62-7.02,2.74-10.42c4.73-14.37,12.86-23.23,29.93-32.73c-7.95-13.25-10.22-27.34-5.34-42.23
   c3.47-10.57,9.98-18.94,19.13-25.18c17.84-12.16,42.25-10.74,58.5,2.96c16.17,13.63,24.86,39.37,9.8,64.6
   c9.9,4.47,18.02,11.08,23.98,20.22c5.75,8.81,8.58,18.52,8.66,29.02c0.08,9-0.16,18,0.1,27c0.13,4.6-1.31,7.97-5.26,10.27
   c-47.41,0-94.83,0-142.24,0C514,681.44,514,666.97,514,652.5z M529.6,680.69c38.78,0,77.74,0,116.78,0c0-8.3,0.32-16.31-0.07-24.28
   c-0.75-15.5-11.3-29.17-25.91-33.84c-1.6-0.51-3.98-0.23-5.4,0.64c-18.28,11.21-36.42,11.16-54.68-0.05
   c-1.35-0.83-3.6-1.18-5.05-0.67c-14.1,4.94-23.34,14.76-25.43,29.58C528.54,661.36,529.6,670.98,529.6,680.69z M588.59,548.64
   c-18.14-0.76-33.95,13.99-34.8,32.45c-0.85,18.46,13.95,34.69,32.26,35.38c19.08,0.72,34.66-13.67,35.56-32.83
   C622.48,565.2,607.6,549.44,588.59,548.64z"
    />
    <path
      d="M755.06,696c-3.97-2.28-5.43-5.63-5.27-10.24c0.39-11.23-0.43-22.56,0.78-33.68c2.08-19.2,12.8-32.84,29.9-41.54
   c0.66-0.34,1.31-0.68,1.98-1.03c-16.41-28.1-4.01-54.38,11.85-66.15c18.12-13.45,43.37-12.82,60.34,1.39
   c17.01,14.25,24.68,40.09,10.03,64.59c1.03,0.57,2.05,1.15,3.08,1.71c18.94,10.28,28.95,26.15,29.51,47.77
   c0.23,8.99-0.15,18,0.12,26.99c0.14,4.62-1.28,7.98-5.32,10.2C846.39,696,800.72,696,755.06,696z M882.2,680.81
   c0-8.67,0.49-17.16-0.11-25.58c-1.08-15.19-11.84-28.38-26.17-32.78c-1.42-0.43-3.5-0.23-4.74,0.54
   c-18.45,11.5-36.79,11.49-55.23-0.03c-1.16-0.73-3.12-1.04-4.39-0.61c-13.9,4.63-23.12,14.17-25.67,28.51
   c-1.72,9.69-1.04,19.81-1.44,29.95C804.04,680.81,842.89,680.81,882.2,680.81z M824.13,548.62c-18.38-0.49-33.91,14.46-34.47,33.19
   c-0.55,18.22,14.44,34.14,32.63,34.67c19.03,0.55,34.72-14.24,35.22-33.19C858,564.66,843.04,549.12,824.13,548.62z"
    />
    <path
      className="fill-[#C7C8F3]"
      d="M671.65,429.81c-5.72,0-11.2,0.16-16.67-0.05c-3.97-0.16-7.16,1.03-10.26,3.55
   c-12.69,10.3-25.52,20.42-38.31,30.6c-0.96,0.77-1.96,1.48-3.58,2.69c-0.1-2.23-0.22-3.89-0.25-5.56
   c-0.13-7.49-0.19-14.99-0.35-22.49c-0.12-5.74-2.29-8.14-7.9-8.94c-27.42-3.92-46.61-26.16-45.85-53.14
   c0.75-26.55,23.14-49.24,49.66-49.36c57.61-0.27,115.23-0.33,172.84,0.02c31.53,0.19,55.4,31.36,48.46,62.13
   c-0.59,2.62-1.94,2.9-4.18,2.9c-31.05-0.06-62.1-0.04-93.15-0.04C694.24,392.12,679.8,402.82,671.65,429.81z"
    />
    <path
      className="fill-[#FFFFFF]"
      d="M820.89,490.5c-11.27-7.22-21.35-13.87-31.66-20.15c-2.51-1.53-5.76-2.53-8.68-2.56
   c-20.95-0.21-41.89-0.03-62.84-0.14c-16.42-0.09-28.36-10.06-31.12-25.67c-3.02-17.1,10.48-34.31,27.83-34.68
   c19.44-0.42,38.9-0.17,58.35-0.17c17.83-0.01,35.66-0.18,53.49,0.12c16.07,0.28,28.9,13.73,29.06,29.83
   c0.14,14.98-11.11,28.09-25.91,30.22c-6.44,0.93-8.44,3.15-8.51,9.56C820.85,481.05,820.89,485.25,820.89,490.5z M813.28,429.85
   c-4.03-0.04-7.36,3.26-7.43,7.35c-0.07,4.18,3.02,7.46,7.13,7.58c4.2,0.12,7.75-3.31,7.72-7.47
   C820.68,433.32,817.26,429.89,813.28,429.85z M735.67,437.52c0.08-4.23-3.25-7.68-7.41-7.67c-3.93,0.01-7.37,3.39-7.47,7.34
   c-0.11,4.1,3.35,7.61,7.51,7.6C732.39,444.77,735.58,441.63,735.67,437.52z M778.22,437.41c0.03-4.12-3.5-7.64-7.59-7.56
   c-3.95,0.08-7.29,3.48-7.32,7.45c-0.03,4.04,3.17,7.37,7.21,7.48C774.72,444.9,778.19,441.58,778.22,437.41z"
    />
    <path
      className="fill-[#CACACA]"
      d="M529.6,680.69c0-9.71-1.07-19.33,0.24-28.62c2.09-14.82,11.32-24.63,25.43-29.58c1.45-0.51,3.71-0.16,5.05,0.67
   c18.25,11.21,36.4,11.26,54.68,0.05c1.43-0.88,3.81-1.15,5.4-0.64c14.61,4.67,25.16,18.34,25.91,33.84
   c0.39,7.97,0.07,15.98,0.07,24.28C607.34,680.69,568.38,680.69,529.6,680.69z"
    />
    <path
      className="fill-[#CACACA]"
      d="M588.59,548.64c19.01,0.79,33.89,16.56,33.02,34.99c-0.9,19.16-16.49,33.55-35.56,32.83
   c-18.31-0.69-33.11-16.91-32.26-35.38C554.64,562.63,570.45,547.88,588.59,548.64z"
    />
    <path
      className="fill-[#CACACA]"
      d="M882.2,680.81c-39.32,0-78.16,0-117.75,0c0.4-10.14-0.28-20.26,1.44-29.95c2.54-14.34,11.77-23.88,25.67-28.51
   c1.28-0.43,3.23-0.12,4.39,0.61c18.44,11.52,36.78,11.53,55.23,0.03c1.24-0.77,3.32-0.97,4.74-0.54
   c14.33,4.4,25.1,17.58,26.17,32.78C882.69,663.65,882.2,672.14,882.2,680.81z"
    />
    <path
      className="fill-[#CACACA]"
      d="M824.13,548.62c18.91,0.5,33.87,16.04,33.38,34.67c-0.5,18.95-16.19,33.74-35.22,33.19
   c-18.19-0.52-33.17-16.44-32.63-34.67C790.22,563.08,805.75,548.13,824.13,548.62z"
    />
    <path
      d="M813.28,429.85c3.98,0.04,7.4,3.48,7.42,7.46c0.02,4.17-3.52,7.59-7.72,7.47c-4.11-0.12-7.2-3.4-7.13-7.58
   C805.92,433.11,809.25,429.81,813.28,429.85z"
    />
    <path
      d="M735.67,437.52c-0.08,4.11-3.27,7.25-7.37,7.26c-4.15,0.01-7.62-3.49-7.51-7.6c0.1-3.94,3.55-7.32,7.47-7.34
   C732.41,429.84,735.75,433.29,735.67,437.52z"
    />
    <path
      d="M778.22,437.41c-0.03,4.18-3.5,7.5-7.7,7.37c-4.04-0.12-7.24-3.44-7.21-7.48c0.03-3.97,3.37-7.37,7.32-7.45
   C774.72,429.77,778.26,433.29,778.22,437.41z"
    />
  </svg>
)

export default CommunicationIcon
