import { FunctionComponent, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface IMobileSwitch extends React.HTMLAttributes<HTMLDivElement> {
  mobileView?: JSX.Element | null
  mobileClassName?: string
  regularView?: JSX.Element | null
  regularClassName?: string
  as?: keyof JSX.IntrinsicElements
}

const MobileSwitch: FunctionComponent<IMobileSwitch> = ({
  mobileView,
  regularView,
  regularClassName,
  mobileClassName,
  as: Wrapper = 'div',
}: IMobileSwitch) => {
  return (
    <>
      {mobileView && (
        <Wrapper className={twMerge('flex md:hidden', mobileClassName)}>
          {mobileView}
        </Wrapper>
      )}
      {regularView && (
        <Wrapper className={twMerge('hidden md:flex', regularClassName)}>
          {regularView}
        </Wrapper>
      )}
    </>
  )
}

export default MobileSwitch
