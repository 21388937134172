import SharePostModal from '@/components/home/post/common/share_post_modal'
import { useRouter } from 'next/router'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'

import { useProfile } from 'components/hooks'
import useHandleActiveConversations from 'components/hooks/useHandleActiveConversations'

import useChatContext from '../hooks/useChatContext'
import useConversationListData from '../hooks/useConversationsListData'
import useMyBusinessProfiles from '../hooks/useMyBusinessProfiles'
import { getUnreadConversationCount } from '../utils/list'
import { getProfileProperties } from '../utils/profile'
import { Header, List } from './index'

function ConversationList() {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isSharePostOpen, setIsSharePostOpen] = useState(false)

  const { profile: myProfile } = useProfile()

  const router = useRouter()
  const {
    activeProfileOnChat,
    setActiveProfileOnChat,
    setIsBusinessProfileActive,
  } = useChatContext()

  const { avatar = {} } = getProfileProperties(activeProfileOnChat)

  const { businessProfiles = [] } = useMyBusinessProfiles()

  const { removeAllActiveConversations } = useHandleActiveConversations()

  const { conversationListData } = useConversationListData()

  const onCloseSharePost = () => {
    const { pathname, query } = router
    const { post_id, share_post, ...rest } = query

    router.push({ pathname, query: { ...rest } }, undefined, {
      shallow: true,
      scroll: false,
    })
  }

  useEffect(() => {
    const { query: routerQuery } = router
    if (routerQuery?.share_post) {
      setIsSharePostOpen(true)
    } else {
      setIsSharePostOpen(false)
    }
  }, [router, router.query])

  const handleChangeProfile = (businessProfileId?: number) => {
    removeAllActiveConversations()
    if (!isNil(businessProfileId)) {
      const foundBusiness = businessProfiles.find(
        (businessProfile) => businessProfile.id == businessProfileId,
      )
      setActiveProfileOnChat(foundBusiness)

      setIsBusinessProfileActive(true)
    } else {
      setActiveProfileOnChat(myProfile)

      setIsBusinessProfileActive(false)
    }
  }

  const unreadConversationCount = getUnreadConversationCount({
    conversationListData,
    myProfile: activeProfileOnChat,
  })

  const { firstName: chatName = '' } = getProfileProperties(activeProfileOnChat)

  const onChange = (hasUnreadMessages: boolean) => {
    if (hasUnreadMessages) {
      setIsExpanded(true)
    }
  }

  return (
    <div
      style={
        isExpanded
          ? { transform: 'translateY(0)' }
          : { transform: 'translateY(100%) translateY(-48px)' }
      }
      className="flex flex-col h-[calc(100vh-400px)] flex-[0_0_288px] w-[288px] min-w-0 bg-white rounded-md shadow-md ring-1 ring-gray-light transition-all mx-5"
    >
      <Header
        unreadConversationCount={unreadConversationCount}
        isExpanded={isExpanded}
        handleChangeProfile={handleChangeProfile}
        setIsExpanded={setIsExpanded}
        avatar={avatar}
        title={chatName}
        isOnline
      />
      {conversationListData && (
        <List onChange={onChange} conversationListData={conversationListData} />
      )}
      <SharePostModal isOpen={isSharePostOpen} onClose={onCloseSharePost} />
    </div>
  )
}

export default ConversationList
