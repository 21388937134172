import type { SurveyDataType } from '@/components/survey/hooks/useMySurveys'
import { Dispatch, SetStateAction, createContext, useContext } from 'react'

interface IClientSurveysContext {
  selectedSurvey: SurveyDataType | undefined
  selectedSurveys: number[]
  setSelectedSurvey: Dispatch<SetStateAction<SurveyDataType | undefined>>
  goToMyWorkspace: () => void
  createSurvey: () => void
  showSurvey: ({ nativeId }: { nativeId: string }) => void
  editSurvey: () => void
  sendSurvey: () => void
  selectSurvey: (value: number) => void
  removeSurvey: (value: number) => void
}

export const ClientSurveysContext = createContext<null | IClientSurveysContext>(
  null,
)

const useClientSurveyContext = () => {
  const context = useContext(ClientSurveysContext)
  if (!context) {
    throw new Error(
      'You need to wrap the client suveys context inside a provider.',
    )
  }

  return context
}

export default useClientSurveyContext
