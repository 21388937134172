const LocationIcon = ({ className = '' }) => (
  <svg
    width="53"
    height="57"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 53 57"
    className={className}
  >
    <g>
      <g>
        <image
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAzCAYAAAAU5BG4AAAAAXNSR0IArs4c6QAAA/VJREFUaEPF2XnIpWMYBvDfkH2nsZQlypY/hmxJtmQpUUZ2QyIiQiRLExpr9imNsjWyla3sJCFNWZItIku2P8i+JLJ1fb1Hx5nvnPd+j/k+1z/f+Xqv+76v85znee7lnWE8rIJdsDu2wIZYDyvgV3yBj/AhnsXz+LprqBkdDbbHiTgQa3aw/QkP4aZGbMm0Km5zXIRDS15Hkx7BBXi1zVdF3Gm4FhVuW7z+5xc2X3ioTVvA2zGnS8SO3MdwAP6YzG6UuOewa8dg49DfxI74ZdB4mLinsNc4kca0eQ3bVMRdh+yz6ca9OKQ/6ODK7YvHp1tVX7zs7zt6//eLWwrfY+X/UVxCr4Yf8qFf3Lm4dAxhXzUZ4JXG6UrYuskeyRpdcSNO6hcXkT8ijqv4GefjZuTzIJbFUc0XXqfqtOFNrF5v5Q7D3R0cvIiD8WnBZnXcg30K3B7lLFzdE/dEB+O3sVWHQD1qCoDdinZvYFbELdP8pMsVDdfH50VuP215fIv8rWBmxG2LbOYKbsHxFeIQThJ+cmoFsyMuJVBOSAWzkCUfF2s3tV7Ffm7EXYlswDZ8hzXaSIXn72KzAm9+xN2KYwvkl7FDgddGuQ8HtZFwW8QtxNEF8gtNaV6gjqTciSMKThZG3HycWiB/go0KvDbKIuzURsKCiDsD1xTIoazbYUNP5nLpJptUrq15EZeCMoVlBafj+gpxCCeN0QNF+zkRlyokebWCtHxZvXGR7LJl0XjjXvp6CWn7KrgBp1SIA5zzcEnR7jNs0BOX6uLiomFo4c7twD8TV3XgL8DJPXGb4IMOxqE+iARNZz8MM3FF8R7t97EzFvUXm2lyF2syWgSnpUtZ/SjeafbuitgU++FwZHTRBSkqUlz8qxLuWtN1CdiFe3aTUhfr4tNDrNrF0xRwc3tMVNaD3VeXC3kKdLkMOdUTmKypTsOy1lREbvH5e9PD/DZKXJJykvN0I93f5f1Bh40j3mtO3HQJ/GayX2uYuO2Q+m26kEnTw4PBRk2Zcn8dOQ3qnh42NBolLl3Zl0jfOVX4qxk/TFp4tA0P98AzU6WsmQgMPXxt4qJrqkZi6SUyNRiKirgYVzum6iLndKYo+HNJiMt7ho+rkQu8Uv9bXbnE2795l1CIPZJyQvM+otVPF3Fxltx3TqvX4YRM54+p2ncVF79PYu9qgD7e681QsWw6jrjY5ICkoKwijVH41UZqwu844mKXqiVlfSaQbchFm9nI+23EwefjioufDBDfKgTMRZ7BYWf8F3EJtieSG4chufmuzqoag/8qLm5m4/5JBJSvjGHil4S4+M4K/fNyo2kZq/OXoQu7pMQlwHGYh0wEqp39yF/8b9m4nHiOAx8kAAAAAElFTkSuQmCC"
          width="53"
          height="57"
          transform="matrix(1,0,0,1,0,0)"
        ></image>
      </g>
    </g>
  </svg>
)

export default LocationIcon
