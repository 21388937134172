import clsx from 'clsx'
import { FunctionComponent, useEffect, useState } from 'react'

interface ILoadingBar {
  className?: string
  widthPx?: number
  duration?: number
}

const LoadingBar: FunctionComponent<ILoadingBar> = ({
  className,
  widthPx = 400,
  duration = 20,
  ...props
}: ILoadingBar) => {
  const advanceRate = Math.trunc(widthPx / duration)
  const [progress, setProgress] = useState(0)

  const increment = () => {
    if (progress <= progress + advanceRate) {
      setProgress((prev) => prev + advanceRate)
    }
  }
  useEffect(() => {
    const interval = setInterval(increment, 1000)
    if (progress >= progress + advanceRate) clearInterval(interval)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div {...props} className={clsx(className)}>
      <div
        className="flex h-1 rounded-xl bg-gray-mid w-"
        style={{ width: `${widthPx}px` }}
      >
        <div
          className="flex h-1 rounded-xl bg-purple-main"
          style={{ width: `${progress}px` }}
        ></div>
      </div>
    </div>
  )
}

export default LoadingBar
