import { newReferralData } from 'lib/reactiveVariables'

import { useRouter } from 'next/router'
import { FunctionComponent, createContext, useState } from 'react'

import { FinalStep, StepOne } from '../index'
import {
  StepFive as StepFiveClients,
  StepFour as StepFourClients,
  StepSix as StepSixClients,
  StepThree as StepThreeClients,
  StepTwo as StepTwoClients,
} from '../seeking_clients_steps'
import {
  StepFive as StepFiveTherapists,
  StepFour as StepFourTherapists,
  StepSix as StepSixTherapists,
  StepThree as StepThreeTherapists,
  StepTwo as StepTwoTherapists,
} from '../seeking_therapists_steps'

export enum ReferralActionType {
  Create = 'CREATE',
  Edit = 'EDIT',
}
interface IStepController {
  type?: ReferralActionType
}

export enum REFERRALTYPE {
  SeekingClients = 'SEEKING_CLIENTS',
  SeekingTherapists = 'SEEKING_THERAPISTS',
}

interface ICreateReferralContext {
  goToPreviousStep: () => void
  cancelCreateReferral: () => void
  goToNextStep: () => void
  setReferralType: () => void
}

export const CreateReferralContext = createContext<
  ICreateReferralContext | any
>({})
const StepController: FunctionComponent<IStepController> = ({
  type = ReferralActionType.Create,
}) => {
  const router = useRouter()

  const [step, setStep] = useState<number>(
    type === ReferralActionType.Create ? 0 : 1,
  )

  const { referralType: editingReferralType } = newReferralData()

  const initialReferralType =
    editingReferralType === REFERRALTYPE.SeekingTherapists
      ? REFERRALTYPE.SeekingTherapists
      : editingReferralType === REFERRALTYPE.SeekingClients
      ? REFERRALTYPE.SeekingClients
      : REFERRALTYPE.SeekingTherapists

  const [referralType, setReferralType] =
    useState<REFERRALTYPE>(initialReferralType)

  const [referralLink, setReferralLink] = useState<string>('')

  const goToNextStep = () =>
    step !== 6 ? setStep(step + 1) : router.push('/opportunities')

  const goToPreviousStep = () => setStep(step - 1)

  const cancelCreateReferral = () => router.back()

  const stepsByType = {
    [REFERRALTYPE.SeekingClients]: {
      0: <StepTwoClients />,
      1: <StepThreeClients />,
      2: <StepFourClients />,
      3: <StepFiveClients />,
      4: <FinalStep />,
    },
    [REFERRALTYPE.SeekingTherapists]: {
      0: <StepTwoTherapists />,
      1: <StepThreeTherapists />,
      2: <StepFourTherapists />,
      3: <StepFiveTherapists />,
      4: <StepSixTherapists />,
    },
  }

  const stepToRender = {
    0: <StepOne />,
    1: referralType ? stepsByType[referralType][0] : null,
    2: referralType ? stepsByType[referralType][1] : null,
    3: referralType ? stepsByType[referralType][2] : null,
    4: referralType ? stepsByType[referralType][3] : null,
    5: referralType ? stepsByType[referralType][4] : null,
    6: <FinalStep />,
  }

  return (
    <CreateReferralContext.Provider
      value={{
        goToPreviousStep,
        cancelCreateReferral,
        goToNextStep,
        setStep,
        setReferralType,
        referralLink,
        setReferralLink,
        type,
      }}
    >
      <div className="h-full">
        {stepToRender[step as keyof typeof stepToRender]}
      </div>
    </CreateReferralContext.Provider>
  )
}

export default StepController
