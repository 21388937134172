import clsx from 'clsx'
import { ReactElement, ReactNode, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { Text } from '../text'

interface IBox {
  background?: 'white' | 'page'
  header?: ReactNode
  headerClassName?: string
  footer?: ReactNode
  footerClassName?: string
  title?: string | ReactNode
  customBorder?: string
  icon?: ReactNode
  isSidebar?: boolean
  className?: string
  adaptToMobile?: boolean
  children: any
}

const Box = forwardRef<HTMLDivElement, IBox>(
  (
    {
      className,
      children,
      customBorder,
      background = 'page',
      header,
      headerClassName,
      footer,
      footerClassName,
      title,
      icon,
      isSidebar,
      adaptToMobile = false,
      ...props
    },
    ref,
  ) => {
    const backgroundIsWhite = background === 'white'

    return (
      <div className="flex flex-col w-full h-full" ref={ref}>
        {header && (
          <div
            className={twMerge(
              'flex w-full rounded-t border border-b-0 outline-none focus:outline-0 p-6',
              backgroundIsWhite
                ? 'bg-background border-dark-border'
                : 'bg-white border-light-border',
              adaptToMobile && 'rounded-none border-x-0 md:rounded-t md:border',
              headerClassName,
            )}
          >
            {header}
          </div>
        )}
        <div
          {...props}
          className={twMerge(
            'flex w-full rounded border text-body outline-none focus:outline-0 p-4',
            backgroundIsWhite
              ? 'bg-background border-dark-border'
              : 'bg-white border-light-border',
            customBorder ?? 'border',
            adaptToMobile && 'rounded-none border-x-0 md:rounded md:border',
            adaptToMobile &&
              header &&
              'rounded-none border-x-0 md:rounded md:rounded-t-none md:border',
            adaptToMobile &&
              footer &&
              'rounded-none border-x-0 md:rounded md:rounded-b-none md:border',
            header && 'md:rounded-t-none md:border-t-0',
            footer && 'md:rounded-b-none md:border-b-0',
            className,
          )}
        >
          {title || icon ? (
            <div className="flex flex-col gap-4 w-full">
              <div
                className={clsx(
                  !title && 'flex-row-reverse',
                  'flex justify-between items-center',
                )}
              >
                {typeof title === 'string' ? (
                  <Text
                    variant={isSidebar ? 'subheading' : 'heading'}
                    className={twMerge(
                      'capitalize',
                      isSidebar && 'font-semibold',
                    )}
                  >
                    {title}
                  </Text>
                ) : (
                  <>{title}</>
                )}
                {icon && <div className="w-5 h-5 cursor-pointer">{icon}</div>}
              </div>
              {children}
            </div>
          ) : (
            children
          )}
        </div>
        {footer && (
          <div
            className={twMerge(
              'flex w-full rounded-b border border-t-0 outline-none focus:outline-0 p-4',
              backgroundIsWhite
                ? 'bg-background border-dark-border'
                : 'bg-white border-light-border',
              adaptToMobile && 'rounded-none border-x-0 md:rounded-b md:border',
              footerClassName,
            )}
          >
            {footer}
          </div>
        )}
      </div>
    )
  },
)

Box.displayName = 'Box'

export default Box
