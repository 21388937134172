import { initialReferralData, newReferralData } from 'lib/reactiveVariables'

import { XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent, ReactNode, useContext } from 'react'
import { twMerge } from 'tailwind-merge'

import BelonglyLogo from 'components/header/belongly_logo'
import { CreateReferralContext } from 'components/opportunities/referrals/create_new_referral/step_controller/step_controller'

import { Button, ButtonVariants } from '../button'
import { Text } from '../text'

interface INewReferralStep {
  title: string | ReactNode
  description?: string
  stepNumber?: string
  stepDescription?: string
  headerStyle?: string
  titleStyle?: string
  descriptionStyle?: string
  className?: string
  childrenStyle?: string
  children?: ReactNode
  formTitle?: string
  onPositive?: () => void
  isPositiveButtonDisabled?: boolean
  positiveLabel?: string
  onNegative?: () => void
  negativeLabel?: string
  negativeVariant?: ButtonVariants
  extraContent?: ReactNode
  formPositionHigh?: boolean
  customStep?: boolean
}

const NewReferralStep: FunctionComponent<INewReferralStep> = ({
  title,
  stepDescription,
  description,
  stepNumber,
  className,
  headerStyle,
  titleStyle,
  descriptionStyle,
  childrenStyle,
  children,
  formTitle,
  formPositionHigh = false,
  onPositive = () => {},
  positiveLabel = 'Continue',
  isPositiveButtonDisabled,
  onNegative = () => {},
  negativeLabel = 'Back',
  negativeVariant = 'third',
  extraContent,
  customStep = false,
}: INewReferralStep) => {
  const createReferralContext = useContext(CreateReferralContext)
  const { cancelCreateReferral } = createReferralContext

  const handleCancelCreation = () => {
    cancelCreateReferral()
    newReferralData(initialReferralData)
  }

  return (
    <section
      className={clsx(
        className,
        'w-full h-full flex flex-col md:flex-row new_referral_step_controller',
      )}
    >
      <div className="flex flex-col p-10 w-full md:py-6 md:px-16 md:w-1/2 bg-primary">
        <div className="flex justify-between w-full">
          <BelonglyLogo color="white" size="3xl" />

          <div className="flex md:hidden">
            <XIcon
              className="stroke-1 stroke-gray-mid"
              width={40}
              height={40}
              onClick={handleCancelCreation}
            />
          </div>
        </div>
        <div
          className={clsx(
            headerStyle,
            'flex flex-col gap-8 md:py-8 md:px-12 text-background',
          )}
        >
          <span className="flex gap-4">
            <Text size="lg" className={clsx(titleStyle, 'text-background')}>
              {stepNumber}
            </Text>
            <Text size="lg" className={clsx(titleStyle, 'text-background')}>
              {stepDescription}
            </Text>
          </span>
          <Text
            variant="title"
            className={clsx(titleStyle, 'text-background text-2xl md:text-5xl')}
          >
            {title}
          </Text>
          <Text size="md" className={clsx(descriptionStyle, 'text-background')}>
            {description}
          </Text>
          {/* <Text size="md" weight="light" color="secondary">
            <Link
              target="_blank"
              href="https://www.belongly.com/how-belongly-referrals-work/"
              prefetch={false}
            >
              Learn More About How Referrals Work &gt;
            </Link>
          </Text> */}
          {extraContent}
        </div>
      </div>
      <>
        {customStep ? (
          <>
            <div
              className={clsx(
                childrenStyle,
                'w-full md:w-1/2 bg-white md:relative md:overflow-y-hidden',
              )}
            >
              <div className="hidden justify-end pt-8 pr-8 w-full md:flex">
                <XIcon
                  className="cursor-pointer stroke-1 stroke-gray-400/60"
                  width={40}
                  height={40}
                  onClick={handleCancelCreation}
                />
              </div>
              {children}
            </div>
          </>
        ) : (
          <>
            <div
              className={clsx(
                childrenStyle,
                'w-full md:w-1/2 bg-white md:relative md:overflow-y-scroll',
              )}
            >
              <div className="hidden justify-end pt-10 pr-10 w-full md:flex">
                <XIcon
                  className="cursor-pointer stroke-1 stroke-gray-400/60"
                  width={40}
                  height={40}
                  onClick={handleCancelCreation}
                />
              </div>
              <form
                className={twMerge(
                  'flex flex-col gap-8 p-10 md:px-20',
                  formPositionHigh ? 'md:py-4' : 'md:py-24',
                )}
              >
                {formTitle && (
                  <Text variant="none" weight="bold" size="2xl">
                    {formTitle}
                  </Text>
                )}
                {children}
                <div
                  className={twMerge(
                    'flex flex-col flex-1 gap-3 w-full md:flex-row md:justify-end bg-white',
                    formPositionHigh && 'pt-8',
                  )}
                >
                  <Button
                    variant={negativeVariant}
                    type="button"
                    className="flex-1 order-2 py-2 px-16 my-8 text-sm md:flex-none md:order-1"
                    onClick={onNegative}
                  >
                    {negativeLabel}
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    className="flex-1 order-1 py-2 px-8 my-8 text-sm md:flex-none md:order-2"
                    onClick={onPositive}
                    disabled={isPositiveButtonDisabled}
                  >
                    {positiveLabel}
                  </Button>
                </div>
              </form>
            </div>
          </>
        )}
      </>
    </section>
  )
}

export default NewReferralStep
