import type { UserSearch_profiles } from 'operations/UserSearch'

import clsx from 'clsx'
import Link from 'next/link'
import { isEmpty } from 'ramda'
import { FunctionComponent } from 'react'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import ConnectButton from 'components/common/connect_button'
import CurrentJob from 'components/common/current_job'
import useMutualConnections from 'components/hooks/useMutualConnections'
import { Avatar, Box, Text } from 'components/ui'

import { useProfile } from '../hooks'

interface IUserCard {
  profile: UserSearch_profiles
}

export const RefreshIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      id="loading"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className, 'mx-auto h-5 w-5')}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
        clipRule="evenodd"
      />
    </svg>
  )
}

const UserCard: FunctionComponent<IUserCard> = ({ profile }) => {
  const { mutualConnections, ownerId } = useMutualConnections(profile.id)

  const sufix = profile?.degree
    ? profile?.degree || ''
    : profile?.educations[0]?.degree || ''

  return (
    <section className="flex flex-col gap-2 h-full">
      <div className="flex flex-1 justify-center items-center pt-6 pb-2 w-full rounded profile-card">
        <Link href={`/be/${profile?.username}`} passHref legacyBehavior>
          <Avatar
            profile={profile}
            layout="fill"
            alt="Profile pic"
            objectFit="cover"
            size={32}
          />
        </Link>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="capitalize bg-white">
          <Link
            href={`/be/${profile?.username}`}
            passHref
            className="flex justify-center cursor-pointer hover:underline"
          >
            <h1 className="text-xl font-bold text-center">
              {profile?.first_name} {profile?.last_name}
              {sufix && <>, &nbsp;{sufix}</>}
            </h1>
          </Link>
          <CurrentJob
            className="text-xs leading-tight text-center break-words"
            textColor="text-gray-main"
            experiences={profile?.experiences}
            currentJob={profile?.current_job_new}
          />
          <br />
        </div>
        <div className="p-2 w-full bg-white">
          <h4 className="mb-2 text-xs font-semibold text-center text-primary">
            {mutualConnections} Mutual Connections
          </h4>
          <ConnectButton fromProfileId={ownerId!} toProfileId={profile?.id} />
        </div>
      </div>
    </section>
  )
}

interface IResults {
  title: string
  profiles: UserSearch_profiles[] | undefined
  totalProfiles: number
  initialPaginationLimit?: number
  setNewLimit: () => void
  profileEducations?: Array<{ school: string }>
}

const Results: FunctionComponent<IResults> = ({
  title,
  profiles,
  setNewLimit,
  totalProfiles,
  initialPaginationLimit = 0,
  profileEducations,
}) => {
  const [hasMore, setHasMore] = useState<boolean>(true)
  const { profile } = useProfile()

  const fetchMoreProfiles = () => {
    if (totalProfiles > (profiles?.length || 0)) {
      setNewLimit()
    } else setHasMore(false)
  }

  if (!profiles) return null

  if (title === 'results') {
    return (
      <InfiniteScroll
        loadMore={fetchMoreProfiles}
        hasMore={totalProfiles >= initialPaginationLimit ? hasMore : false}
        initialLoad={false}
        loader={
          <h4>
            <RefreshIcon />
          </h4>
        }
        threshold={200}
        style={{ overflow: 'hidden' }}
      >
        <Box title={title}>
          <div className="grid grid-cols-3 gap-4">
            {isEmpty(profiles) ? (
              <Text>No results for your search.</Text>
            ) : (
              profiles?.map((profile) => (
                <UserCard profile={profile} key={profile.id} />
              ))
            )}
          </div>
        </Box>
      </InfiniteScroll>
    )
  }

  let resultComponent: JSX.Element = <></>
  const hasProfileEducations = profileEducations && profileEducations.length > 0
  if (isEmpty(profiles) && hasProfileEducations) {
    // This means that there are no matches
    resultComponent = (
      <Text>
        We did not find Belongly members that attended the same school.
      </Text>
    )
  } else if (
    !hasProfileEducations ||
    (hasProfileEducations &&
      (profileEducations[0].school === '' || isEmpty(profileEducations[0])))
  ) {
    // This means there are no schools in the profile
    resultComponent = (
      <Text>
        Provide your{' '}
        <Link href={`/be/${profile?.username}`} className="text-secondary">
          education details
        </Link>{' '}
        so we can let you know of former classmates that are Belongly members!.
      </Text>
    )
  } else {
    resultComponent = (
      <>
        <div className="grid grid-cols-3 gap-4">
          {profiles?.map((profile) => (
            <UserCard profile={profile} key={profile.id} />
          ))}
        </div>

        {totalProfiles > profiles.length && (
          <button
            className="p-2 mt-5 w-full font-semibold rounded-lg text-primary hover:bg-gray-mid"
            onClick={setNewLimit}
          >
            See more
          </button>
        )}
      </>
    )
  }

  return <Box title={title}>{resultComponent}</Box>
}

export default Results
