import { Dialog, Menu as HeadlessMenu, Transition } from '@headlessui/react'
import { Fragment, FunctionComponent, ReactNode, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import DotsIcon from 'components/common/dots_icon'
import { Button, MobileSwitch, NotificationNumber, Text } from 'components/ui'

export interface Option {
  label: string
  unreadMessagesCount: number
  isDestructive?: boolean
  icon?: (active: boolean) => ReactNode
  onClick: () => void
}

interface IMenu extends React.HTMLAttributes<HTMLDivElement> {
  direction?: 'left' | 'right'
  button?: ReactNode
  options: Option[]
}

const Menu: FunctionComponent<IMenu> = ({
  options,
  direction = 'left',
  button,
  className,
  ...props
}: IMenu) => {
  const inverseDirection = direction === 'left' ? 'right' : 'left'

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onOpen = () => {
    setIsOpen(true)
  }
  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <MobileSwitch
      regularView={
        <HeadlessMenu
          {...props}
          as="div"
          className={twMerge('inline-block relative text-left', className)}
        >
          <div className="flex">
            <HeadlessMenu.Button className="rounded">
              {button ?? <DotsIcon />}
            </HeadlessMenu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <HeadlessMenu.Items
              className={twMerge(
                'absolute mt-2 w-44 bg-white rounded divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none z-50',
                `${inverseDirection}-0`,
              )}
            >
              {options.map(
                ({ label, icon, onClick, unreadMessagesCount }, index) => {
                  const isFirst = index === 0
                  const isLast = index === options.length - 1
                  return (
                    <HeadlessMenu.Item key={label}>
                      {({ active }) => (
                        <Button
                          onClick={onClick}
                          className={twMerge(
                            active && 'bg-secondary text-white',
                            isFirst && 'rounded-t rounded-b-none',
                            isLast && 'rounded-b rounded-t-none',
                            'flex w-full gap-2 text-xs p-2 items-center',
                          )}
                        >
                          {icon && <div className="mr-2">{icon(active)}</div>}
                          {<Text capitalize>{label}</Text>}
                          {unreadMessagesCount > 0 && (
                            <NotificationNumber
                              notificationCount={unreadMessagesCount}
                            />
                          )}
                        </Button>
                      )}
                    </HeadlessMenu.Item>
                  )
                },
              )}
            </HeadlessMenu.Items>
          </Transition>
        </HeadlessMenu>
      }
      mobileClassName={className}
      mobileView={
        <>
          <div onClick={onOpen}>{button ?? <DotsIcon />}</div>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" onClose={onClose} className="relative z-50">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>
              <div className="overflow-y-auto fixed inset-0">
                <div className="flex justify-center items-center p-4 min-h-full text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="overflow-hidden w-full max-w-xl text-center align-middle bg-white rounded-xl divide-y shadow-xl transition-all transform divide-light-border">
                      {options.map(
                        ({ label, icon, isDestructive, onClick }) => (
                          <div
                            className="flex relative gap-4 justify-center p-5"
                            key={label}
                            onClick={() => {
                              onClick()
                              onClose()
                            }}
                          >
                            {/* {icon && (
                            <div className="absolute right-5 mr-2">
                              {icon(false)}
                            </div>
                          )} */}
                            <Text
                              variant="extra-small"
                              color={isDestructive ? 'red' : 'fourth'}
                              className=""
                            >
                              {label}
                            </Text>
                          </div>
                        ),
                      )}
                      <div className="p-5" onClick={onClose}>
                        <Text variant="extra-small" color="red">
                          Cancel
                        </Text>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      }
    />
  )
}

export default Menu
