import { gql } from '@apollo/client'

export const Q_FIND_LOCATIONS = gql`
  query FindLocations($search: String!) {
    locations(
      where: {
        _or: [
          { city: { _ilike: $search } }
          { state: { _ilike: $search } }
          { state_code: { _ilike: $search } }
        ]
      }
      order_by: { state: asc, city: asc }
    ) {
      id
      city
      state
      state_code
    }
  }
`

export const Q_FUZZY_FIND_LOCATIONS = gql`
  query FuzzyFindLocations($search: String!) {
    locations: locations_search(
      args: { search: $search }
      order_by: { city: asc, state: asc }
    ) {
      id
      city
      state
      state_code
    }
  }
`

export const Q_FIND_CITIES = gql`
  query FindCities($searchCity: String!, $searchState: String = "%") {
    locations(
      where: {
        _and: { city: { _ilike: $searchCity } }
        state: { _ilike: $searchState }
      }
      order_by: { city: asc }
    ) {
      city
    }
  }
`
export const Q_FIND_STATES = gql`
  query FindStates($searchCity: String = "", $searchState: String!) {
    locations(
      where: {
        _and: { city: { _ilike: $searchCity } }
        state: { _ilike: $searchState }
      }
      order_by: { state: asc }
    ) {
      state
    }
  }
`
