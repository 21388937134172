import Linkify from 'linkify-react'
import React from 'react'
import { whether } from 'utils'

const LINK_MAX_LENGTH = 100

const linkifyOptions = {
  className: 'text-primary break-all',
  target: '_blank',
  format: {
    url: (value: string) =>
      whether(
        value.length > LINK_MAX_LENGTH,
        value.slice(0, LINK_MAX_LENGTH) + '…',
        value,
      ),
  },
}

const LinkifyWrapper = ({
  children,
  options = {},
  className = '',
  ...props
}: {
  children: React.ReactNode
  options?: any
  className?: string
}) => {
  return (
    <Linkify options={{ ...linkifyOptions, ...options, className }} {...props}>
      {children}
    </Linkify>
  )
}

export default LinkifyWrapper
