import { gql } from '@apollo/client'

export const M_INSERT_REFERRAL = gql`
  mutation InsertOneReferral(
    $author_profile_id: Int
    $business_id: Int
    $category: String!
    $subcategory: String!
    $content: String
    $title: String!
    $seeking_info: jsonb
    $link_preview: jsonb
    $urgent: Boolean
    $share_referral: Boolean
    $assistance_needed: Boolean
    $public_display: Boolean
    $reason: Int
  ) {
    insert_opportunities_one(
      object: {
        author_profile_id: $author_profile_id
        business_id: $business_id
        category: $category
        subcategory: $subcategory
        content: $content
        title: $title
        seeking_info: $seeking_info
        link_preview: $link_preview
        urgent: $urgent
        share_referral: $share_referral
        assistance_needed: $assistance_needed
        public_display: $public_display
        reason: $reason
      }
    ) {
      id
      uuid
      author_profile_id
      title
      content
      reason
    }
  }
`

export const M_UPDATE_REFERRAL = gql`
  mutation UpdateReferralById(
    $id: Int!
    $category: String!
    $subcategory: String!
    $content: String
    $title: String!
    $seeking_info: jsonb
    $link_preview: jsonb
    $urgent: Boolean
    $share_referral: Boolean
    $assistance_needed: Boolean
    $public_display: Boolean
  ) {
    update_opportunities_by_pk(
      pk_columns: { id: $id }
      _set: {
        category: $category
        subcategory: $subcategory
        content: $content
        title: $title
        seeking_info: $seeking_info
        link_preview: $link_preview
        urgent: $urgent
        share_referral: $share_referral
        assistance_needed: $assistance_needed
        public_display: $public_display
      }
    ) {
      updated_at
      title
      id
      uuid
    }
  }
`

export const M_INSERT_REFERRAL_COMMENT = gql`
  mutation InsertOneReferralComment(
    $content: String!
    $from_profile_id: Int!
    $opportunity_id: Int!
    $parent_id: Int
  ) {
    insert_opportunity_comments_one(
      object: {
        content: $content
        from_profile_id: $from_profile_id
        opportunity_id: $opportunity_id
        parent_id: $parent_id
      }
    ) {
      content
      created_at
      opportunity_id
      id
      parent_id
      from_profile_id
    }
  }
`

export const M_DELETE_REFERRAL = gql`
  mutation DeleteReferralById($id: Int!) {
    update_opportunities_by_pk(
      pk_columns: { id: $id }
      _set: { is_deleted: true }
    ) {
      updated_at
      title
      id
    }
  }
`

export const M_UPDATE_REFERRAL_STATUS = gql`
  mutation UpdateReferralStausById($id: Int!, $live: Boolean!) {
    update_opportunities_by_pk(pk_columns: { id: $id }, _set: { live: $live }) {
      updated_at
    }
  }
`

export const M_INSERT_RESPONDANT = gql`
  mutation InsertRespondant(
    $from_profile_id: Int!
    $opportunity_id: Int!
    $response_type: String!
    $response_state: String!
    $recommender_id: Int
    $recommender_message: String = ""
    $submit_profile_message: String
  ) {
    insert_opportunity_respondant: insert_opportunity_respondants_one(
      on_conflict: { constraint: opportunity_respondants_pkey }
      object: {
        opportunity_id: $opportunity_id
        from_profile_id: $from_profile_id
        recommender_id: $recommender_id
        recommender_message: $recommender_message
        response_type: $response_type
        response_state: $response_state
        submit_profile_message: $submit_profile_message
      }
    ) {
      id
      opportunity_id
      created_at
      from_profile_id
      response_type
      response_state
    }
  }
`

export const M_UPDATE_OPPORTUNITY_FILLED_STATE = gql`
  mutation UpdateOpportunityFilledStateById($id: Int!, $filled: Boolean!) {
    update_opportunities_by_pk(
      pk_columns: { id: $id }
      _set: { filled: $filled }
    ) {
      updated_at
    }
  }
`

export const M_INSERT_REFERRAL_LIST = gql`
  mutation InsertReferralList($bodyText: String!, $opportunity_id: Int!) {
    insert_referral_list_one(
      object: {
        body_text: $bodyText
        status: true
        opportunity_id: $opportunity_id
      }
    ) {
      id
      status
      body_text
    }
  }
`

export const M_INSERT_REFERRAL_LIST_SELECTED_PROFILES = gql`
  mutation InsertReferralListSelectedProfiles(
    $selectedProfiles: [referral_list_profile_insert_input!]!
  ) {
    insert_referral_list_profile(objects: $selectedProfiles) {
      affected_rows
    }
  }
`

export const M_UPDATE_REFERRAL_LIST_SELECTED_PROFILES_OUTSIDERS = gql`
  mutation UpdateReferralListSelectedProfilesOutsiders(
    $selectedProfileId: Int!
    $status: Boolean!
  ) {
    update_referral_list_profile(
      where: { respondant_outsider_id: { _eq: $selectedProfileId } }
      _set: { status: $status }
    ) {
      affected_rows
    }
  }
`

export const U_REFERRAL_LIST_BODY = gql`
  mutation UpdateReferralListBody($bodyText: String!, $listId: Int!) {
    update_referral_list(
      where: { id: { _eq: $listId } }
      _set: { body_text: $bodyText }
    ) {
      affected_rows
    }
  }
`

export const U_RECOMMENDATION_STATE = gql`
  mutation UpdateRecommendationState(
    $fromProfileId: Int!
    $opportunityId: Int!
    $responseState: String!
  ) {
    update_opportunity_respondants_by_pk(
      pk_columns: {
        from_profile_id: $fromProfileId
        opportunity_id: $opportunityId
      }
      _set: { response_state: $responseState }
    ) {
      response_state
    }
  }
`

export const U_REFERRAL_PROFILES_NOTES = gql`
  mutation UpdateReferralProfilesNotes($note: String!, $profileId: Int!) {
    update_referral_list_profile(
      where: { respondant: { from_profile_id: { _eq: $profileId } } }
      _set: { notes: $note }
    ) {
      affected_rows
    }
  }
`

export const M_DELETE_OPPORTUNITY_RESPONDANTS = gql`
  mutation DeleteOpportunityRespondantsByPk(
    $fromProfileId: Int!
    $opportunityId: Int!
  ) {
    delete_opportunity_respondants_by_pk(
      from_profile_id: $fromProfileId
      opportunity_id: $opportunityId
    ) {
      created_at
      id
    }
  }
`
export const Q_RECOMMENDATION_INVITE = gql`
  query recommendationInvite(
    $email: String!
    $first_name: String!
    $last_name: String!
    $recommender_name: String!
    $referral_title: String!
  ) {
    recommendation_email(
      mail_input: {
        email: $email
        first_name: $first_name
        last_name: $last_name
        recommender_name: $recommender_name
        referral_title: $referral_title
      }
    ) {
      response_message
    }
  }
`
export const M_INSERT_RESPONDANT_OUTSIDER = gql`
  mutation InsertRespondantOutsider(
    $first_name: String!
    $last_name: String!
    $email: String!
    $opportunity_id: Int!
    $recommender_id: Int!
  ) {
    insert_opportunity_respondants_outsiders_one(
      object: {
        opportunity_id: $opportunity_id
        last_name: $last_name
        first_name: $first_name
        email: $email
        recommender_id: $recommender_id
      }
    ) {
      id
    }
  }
`
