import { initialReferralData, newReferralData } from 'lib/reactiveVariables'

import { FunctionComponent, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button, NewReferralStep, Text, TextInput } from 'components/ui'

import {
  CreateReferralContext,
  ReferralActionType,
} from '../../step_controller/step_controller'

interface IStepTwo extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const StepTwo: FunctionComponent<IStepTwo> = () => {
  const textContent = {
    stepNumber: '2/6',
    stepDescription: 'Add A Title',
    title: 'Introduce yourself',
    description:
      "Please tell the Belongly community about yourself in just a few words. You'll be able to add more information about yourself, specialities, modalities, etc., in the next steps.",
  }

  const {
    goToPreviousStep,
    goToNextStep,
    type: referralActionType,
    cancelCreateReferral,
  } = useContext(CreateReferralContext)

  const isCreatingNewReferral = referralActionType === ReferralActionType.Create

  const { referralTitle } = newReferralData()

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: referralTitle || '',
    },
  })

  useEffect(() => {
    reset({
      title: referralTitle,
    })
  }, [reset, referralTitle])

  const handleBack = () => {
    goToPreviousStep()
    newReferralData(initialReferralData)
  }

  const handleContinue = ({ title }: { title: string }) => {
    const allData = newReferralData()
    newReferralData({
      ...allData,
      referralTitle: title,
    })
    goToNextStep()
  }

  return (
    <NewReferralStep
      stepNumber={textContent.stepNumber}
      stepDescription={textContent.stepDescription}
      title={textContent.title}
      description={textContent.description}
      formTitle="Introduce yourself"
      onNegative={isCreatingNewReferral ? handleBack : cancelCreateReferral}
      negativeLabel={isCreatingNewReferral ? 'Back' : 'Cancel'}
      onPositive={handleSubmit(handleContinue)}
    >
      <section className="flex flex-col gap-8">
        <Controller
          control={control}
          name="title"
          rules={{ required: 'You must provide a title.' }}
          render={({ field: { onChange, value } }) => (
            <TextInput
              placeholder="Briefly tell the community about yourself"
              background="white"
              value={value}
              onChange={onChange}
              error={errors.title?.message}
              maxLimit={80}
            />
          )}
        />
        <div className="flex flex-col gap-4">
          <Text size="sm" weight="bold">
            For example...
          </Text>
          <ul className="flex flex-col gap-2 ml-4 list-disc text-gray-700">
            <li>
              <Text variant="body">
                Couples and family therapist, licensed in Florida and New York
              </Text>
            </li>
            <li>
              <Text variant="body">
                Testing specialist for ADHD, ASD, and other learning disorders
              </Text>
            </li>
            <li>
              <Text variant="body">
                Bilingual therapist specializing in DBT, licensed in CA
              </Text>
            </li>
          </ul>
        </div>
      </section>
    </NewReferralStep>
  )
}

export default StepTwo
