import {
  GetPostForMessages,
  GetPostForMessagesVariables,
  GetPostForMessages_posts,
} from 'operations/GetPostForMessages'
import type {
  InsertMessage,
  InsertMessageVariables,
} from 'operations/InsertMessage'
import {
  InsertPostShare,
  InsertPostShareVariables,
} from 'operations/InsertPostShare'
import {
  MessageGroupCreate,
  MessageGroupCreateVariables,
} from 'operations/MessageGroupCreate'
import {
  MessageGroupProfileToProfile,
  MessageGroupProfileToProfileVariables,
} from 'operations/MessageGroupProfileToProfile'
import {
  SendSharePostMail,
  SendSharePostMailVariables,
} from 'operations/SendSharePostMail'

import {
  M_INSERT_MESSAGE,
  M_MESSAGE_INSERT_MESSAGE_GROUP,
} from 'lib/mutations/messages'
import { M_INSERT_POST_SHARE } from 'lib/mutations/posts'
import { Q_MESSAGE_GROUP_PROFILE_TO_PROFILE } from 'lib/queries/messages'
import {
  Q_GET_HOME_FEED,
  Q_GET_POST_FOR_MESSAGES,
  Q_SEND_SHARE_POST_EMAIL,
} from 'lib/queries/posts'

import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { MailIcon, PaperAirplaneIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/router'
import { FunctionComponent, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'

import { myMessageEvents } from 'components/chat/chat'
import { postInMessageDelimiter } from 'components/chat/conversation_window/message'
import PostRender from 'components/common/post_render'
import { useProfile } from 'components/hooks'
import useHandleActiveConversations from 'components/hooks/useHandleActiveConversations'
import { SelectedInvitee } from 'components/opportunities/referrals/recommend_colleague/recommend_colleague'
import RecomendationSelect from 'components/opportunities/referrals/recommend_colleague/recommendation_select'
import { Button, Modal, Text, TextInput } from 'components/ui'

import { isValidMessage } from 'utils/messages/messages'
import { ProfileChatType } from 'utils/messaging'

interface ISharePostModal {
  isOpen: boolean
  onClose: () => void
}

const EmailShareModal: FunctionComponent<{
  handleSendPost: (email: string) => void
  isOpen: boolean
  onClose: () => void
}> = ({ handleSendPost, isOpen, onClose }) => {
  const [value, setValue] = useState('')

  const handleButtonClick = () => {
    if (value === '') {
      toast.error('Plase type an email')
      return
    }

    handleSendPost(value)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Send to a colleague">
      <div className="flex flex-col justify-between h-36">
        <TextInput
          placeholder="Email"
          value={value}
          onChange={({ target }) => setValue(target.value)}
          type="email"
        />

        <Button
          variant="primary"
          disabled={value === ''}
          className="w-full"
          onClick={() => handleButtonClick()}
        >
          Send to the email
        </Button>
      </div>
    </Modal>
  )
}

const BelonglyShareModal: FunctionComponent<{
  handleSendPost: (userSelected: SelectedInvitee) => void
  isOpen: boolean
  onClose: () => void
}> = ({ handleSendPost, isOpen, onClose }) => {
  const [selectedProfile, setSelectedProfile] = useState<SelectedInvitee>()

  const handleAddInvitee = (userSelected: SelectedInvitee) => {
    setSelectedProfile(userSelected)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Send to a colleague">
      <div className="flex flex-col justify-between h-36">
        <RecomendationSelect
          addInvitee={handleAddInvitee}
          selectedValue={null}
          maxOptions={2}
        />
        {selectedProfile && (
          <Button
            variant="primary"
            onClick={() => handleSendPost(selectedProfile)}
            className="flex gap-1 justify-center items-center"
          >
            <PaperAirplaneIcon className="mr-2 w-4 h-4 rotate-90 fill-white" />
            <Text className="text-white">
              Send to {selectedProfile?.first_name} {selectedProfile?.last_name}
            </Text>
          </Button>
        )}
      </div>
    </Modal>
  )
}

const SharePostModal: FunctionComponent<ISharePostModal> = ({
  isOpen,
  onClose,
}) => {
  const { profile } = useProfile()
  const router = useRouter()
  const [postInfo, setPostInfo] = useState<GetPostForMessages_posts>()
  const [isBelonglyShareOpen, setIsBelonglyShareOpen] = useState(false)
  const [isEmailShareOpen, setIsEmailShareOpen] = useState(false)
  const { activateConversation } = useHandleActiveConversations()
  const postId = router.query.post_id as string

  const { data: _ } = useQuery<GetPostForMessages, GetPostForMessagesVariables>(
    Q_GET_POST_FOR_MESSAGES,
    {
      variables: {
        postId: Number(postId),
      },
      onCompleted(data) {
        setPostInfo(data.posts[0])
      },
      skip: !postId,
    },
  )

  const [sendSharePostEmail] = useLazyQuery<
    SendSharePostMail,
    SendSharePostMailVariables
  >(Q_SEND_SHARE_POST_EMAIL)

  const [getMessageGroup] = useLazyQuery<
    MessageGroupProfileToProfile,
    MessageGroupProfileToProfileVariables
  >(Q_MESSAGE_GROUP_PROFILE_TO_PROFILE)

  const [insertMessage] = useMutation<InsertMessage, InsertMessageVariables>(
    M_INSERT_MESSAGE,
  )

  const [insertMessageGroup] = useMutation<
    MessageGroupCreate,
    MessageGroupCreateVariables
  >(M_MESSAGE_INSERT_MESSAGE_GROUP)

  const [insertPostShare] = useMutation<
    InsertPostShare,
    InsertPostShareVariables
  >(M_INSERT_POST_SHARE, {
    refetchQueries: [Q_GET_HOME_FEED],
  })

  const checkMessageGroup = async (userSelected: SelectedInvitee) => {
    let existMessageGroup = false

    if (!profile?.id || !userSelected?.id || !userSelected || !profile) {
      return { existMessageGroup: false, messageGroupData: undefined }
    }

    const { data: messageGroupData } = await getMessageGroup({
      variables: {
        fromProfileId: profile.id,
        toProfileId: userSelected.id,
      },
    })

    if (messageGroupData && messageGroupData?.message_group.length > 0) {
      existMessageGroup = true
    }

    return {
      existMessageGroup,
      messageGroupData: messageGroupData?.message_group[0],
    }
  }

  const createMessageGroup = async (userSelected: SelectedInvitee) => {
    if (!profile?.id || !userSelected?.id || !userSelected || !profile) {
      return
    }

    const { data } = await insertMessageGroup({
      variables: {
        messageGroupProfiles: [
          { from_profile_id: profile?.id },
          { from_profile_id: userSelected?.id },
        ],
        fromProfileId: profile?.id,
      },
    })

    return data?.insert_message_group_one?.id
  }

  const updateShareCounter = () => {
    insertPostShare({
      variables: {
        postId: Number(postId),
        profileId: profile?.id ?? 0,
      },
    })
  }

  const handleSendMessage = async (userSelected: SelectedInvitee) => {
    const prefix = '__post__'
    const postTitle = postInfo?.title
    const postContent = postInfo?.content

    const message = [prefix, postTitle, postContent, postId].join(
      postInMessageDelimiter,
    )

    const { existMessageGroup, messageGroupData } = await checkMessageGroup(
      userSelected,
    )
    let messageGroupId

    if (!existMessageGroup) {
      messageGroupId = await createMessageGroup(userSelected)
    } else {
      messageGroupId = messageGroupData?.id
    }

    if (!messageGroupId || messageGroupId === 0) {
      toast.error('Please try again')
      return
    }

    if (isValidMessage(message)) {
      toast.promise(
        insertMessage({
          variables: {
            message: {
              message_group_id: messageGroupId,
              profile_id: profile?.id,
              content: message,
            },
          },
        }),
        {
          success: 'Post sent',
          loading: 'Loading...',
          error: 'Oops, Please try again',
        },
      )
    }

    updateShareCounter()

    myMessageEvents.openMessageGroup(messageGroupData?.id || -1)

    const { share_post, post_id, ...rest } = router.query

    router.push({ pathname: router.pathname, query: { ...rest } }, undefined, {
      shallow: true,
      scroll: false,
    })
  }

  const handleSendPostEmail = async (email: string) => {
    const { networkStatus, error } = await sendSharePostEmail({
      variables: {
        content: postInfo?.content ?? '',
        title: postInfo?.title ?? '',
        email,
        first_name: profile?.first_name ?? '',
        last_name: profile?.last_name ?? '',
      },
    })

    if (networkStatus === NetworkStatus.error && error) {
      toast.error(`Sorry an error ocurred: ${error.message}`)
      return
    }

    toast.success('Email sent')
    updateShareCounter()

    if (networkStatus !== NetworkStatus.loading) {
      setIsEmailShareOpen(false)
      setTimeout(() => {
        const { share_post, post_id, ...rest } = router.query

        router.push(
          { pathname: router.pathname, query: { ...rest } },
          undefined,
          {
            shallow: true,
            scroll: false,
          },
        )
      }, 800)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Share this post with your colleagues"
    >
      <Toaster toastOptions={{ duration: 1200 }} />
      <section className="h-[350px]">
        <div className="flex flex-col gap-10 h-full grow">
          <div className="flex flex-col gap-4">
            <Text>Click the icon below to send:</Text>
            <div className="flex gap-4 items-center">
              <div className="flex flex-col gap-1">
                <button
                  className="flex justify-center items-center p-2 mx-auto rounded-full hover:bg-gray-200 bg-background w-[42px] min-h-[42px]"
                  onClick={() => setIsEmailShareOpen(true)}
                >
                  <MailIcon className="w-5 h-5" />
                </button>
                <span className="text-xs">Via email to a colleague</span>
              </div>
              <div className="flex flex-col gap-1">
                <button
                  className="p-2 mx-auto font-bold rounded-full hover:bg-gray-200 bg-background w-[42px] min-h-[42px]"
                  onClick={() => setIsBelonglyShareOpen(true)}
                >
                  Be
                </button>
                <span className="text-xs">To a Belongly member</span>
              </div>
            </div>
          </div>
          {postInfo && (
            <div className="flex justify-center mx-auto w-full">
              <PostRender postId={postInfo.id.toString()} />
            </div>
          )}
        </div>
      </section>
      <BelonglyShareModal
        handleSendPost={handleSendMessage}
        isOpen={isBelonglyShareOpen}
        onClose={() => setIsBelonglyShareOpen(false)}
      />
      <EmailShareModal
        handleSendPost={handleSendPostEmail}
        isOpen={isEmailShareOpen}
        onClose={() => setIsEmailShareOpen(false)}
      />
    </Modal>
  )
}

export default SharePostModal
