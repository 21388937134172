import { newReferralData } from 'lib/reactiveVariables'

import GenderNeutralIcon from '@/components/common/gender_neutral_icon'
import HourGlassIcon from '@/components/common/hourglass_icon'
import TeamWorkIcon from '@/components/common/teamwork_icon'
import UserCopyIcon from '@/components/common/user_copy_icon'
import { getOptionFormat } from '@/utils/locations'
import clsx from 'clsx'
import { isEmpty } from 'ramda'
import { FunctionComponent, useContext, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import LocationIcon from 'components/common/location_icon'
import { Button, NewReferralStep, Text } from 'components/ui'
import { ComboboxDemo as Combobox } from 'components/ui/combobox/newCombobox'
import type { ComboboxOptionType } from 'components/ui/combobox/newCombobox'

import { mapValuesAsOptions } from 'utils/opportunities/referrals/referrals'

import useDataFetching from '../../hooks/useDataFetching'
import { CreateReferralContext } from '../../step_controller/step_controller'
import { getBoxFormat, getLocationFormat } from '../../utils'

interface PatientInfoState {
  locations: ComboboxOptionType[]
  typeOfClient: ComboboxOptionType
  gender: ComboboxOptionType | ComboboxOptionType[]
  ageGroup: ComboboxOptionType | ComboboxOptionType[]
  communities: ComboboxOptionType[]
  insurances: { title: string; value: string; type?: ComboboxOptionType }[]
}
interface IStepFour extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const StepFour: FunctionComponent<IStepFour> = () => {
  const textContent = {
    stepNumber: '4/6',
    stepDescription: 'Client Details',
    title: "Let's add some details about the client.",
    description:
      'Adding these details about the client will help therapists understand if they can help based on certain criteria such as age group, insurance, location and communities. Adding these details will also help us match your referral with therapists based on corresponding profile markers.',
  }

  const { goToPreviousStep, goToNextStep } = useContext(CreateReferralContext)

  const {
    referralSeekingInfo: {
      locations: referralSeekingLocation,
      typeOfClients: referralSeekingTypeOfClients,
      ageGroups: referralSeekingAgeGroups,
      genders: referralSeekingGenders,
      communities: referralSeekingCommunities,
      insurances: referralSeekingInsuraces,
      sessionModality: referralSeekingSessionModality,
    },
  } = newReferralData()

  const {
    locationsData,
    ageGroupsData,
    therapyFormsData,
    orientationsData,
    getTherapyForms,
    getOrientations,
    getLocations,
  } = useDataFetching()

  const therapyForms =
    therapyFormsData?.therapy_forms
      .filter(({ name }) => name.toLowerCase() !== 'group')
      .map(({ name }) => mapValuesAsOptions(name)) || []

  const orientations =
    orientationsData?.orientations.map(({ name }) =>
      mapValuesAsOptions(name),
    ) || []

  const ageGroups =
    ageGroupsData?.age_groups.map(({ name }) => ({
      title: name,
      value: name,
    })) || []

  const {
    control,
    reset,
    formState: { errors },
    watch,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(
      () => ({
        locations: referralSeekingLocation?.map(getLocationFormat) || [],
        typeOfClient: {
          title: referralSeekingTypeOfClients[0],
          value: referralSeekingTypeOfClients[0],
        },
        gender: referralSeekingGenders?.map(getOptionFormat),
        ageGroup: referralSeekingAgeGroups?.map(getOptionFormat),
        communities: referralSeekingCommunities.map(mapValuesAsOptions) || [],
        insurances: referralSeekingInsuraces.map(mapValuesAsOptions) || [],
      }),
      [
        referralSeekingLocation,
        referralSeekingTypeOfClients,
        referralSeekingGenders,
        referralSeekingAgeGroups,
        referralSeekingCommunities,
        referralSeekingInsuraces,
      ],
    ),
  })

  const typeOfClientValue = watch('typeOfClient')
  const locationsValue = watch('locations')
  const gendersValue = watch('gender')
  const ageGroupsValue = watch('ageGroup')
  const communitiesValue = watch('communities')
  const isIndividualClient =
    (typeOfClientValue?.value ?? '').toLowerCase() === 'individual'

  useEffect(() => {
    reset({
      locations: referralSeekingLocation?.map(getLocationFormat) || [],
      typeOfClient: {
        title: referralSeekingTypeOfClients[0],
        value: referralSeekingTypeOfClients[0],
      },
      gender: referralSeekingGenders?.map(getOptionFormat),
      ageGroup: referralSeekingAgeGroups?.map(getOptionFormat),
      communities: referralSeekingCommunities.map(mapValuesAsOptions) || [],
      insurances: referralSeekingInsuraces.map(mapValuesAsOptions) || [],
    })
  }, [
    reset,
    referralSeekingAgeGroups,
    referralSeekingCommunities,
    referralSeekingGenders,
    referralSeekingLocation,
    referralSeekingInsuraces,
    referralSeekingTypeOfClients,
    referralSeekingSessionModality,
  ])

  const locationsDesignations = getBoxFormat(
    locationsData?.locations || [],
  ).filter(
    (item) =>
      !getValues('locations')?.some(
        (location) => location.title === item.title,
      ),
  )

  const genderOptions = [
    { title: 'Woman', value: 'Woman' },
    { title: 'Man', value: 'Man' },
    { title: 'Nonbinary / Nonconforming', value: 'Nonbinary / Nonconforming' },
    { title: 'Transgender', value: 'Transgender' },
  ]

  const handleContinue = ({
    locations,
    typeOfClient,
    ageGroup,
    communities,
    gender,
    insurances,
  }: PatientInfoState) => {
    const allNewReferralData = newReferralData()
    const {
      referralSeekingInfo: {
        languages,
        mainSpecialties,
        interventions,
        issues,
        sessionModality,
      },
    } = allNewReferralData
    newReferralData({
      ...allNewReferralData,
      referralSeekingInfo: {
        locations: locations?.map((location) => JSON.parse(location.value)),
        typeOfClients: [typeOfClient.value],
        genders: Array.isArray(gender)
          ? gender?.map((gen) => gen.value)
          : [gender?.value],
        ageGroups: Array.isArray(ageGroup)
          ? ageGroup?.map((age) => age.value)
          : [ageGroup?.value],
        communities: communities?.map((community) => community.value),
        insurances: insurances?.map((insurance) => insurance.value),
        sessionModality,
        languages,
        mainSpecialties,
        interventions,
        issues,
      },
    })
    goToNextStep()
  }

  const styles = {
    row: 'flex flex-col md:flex-row gap-6 w-full md:items-center items-center',
    label: 'flex flex-none md:w-3/12 justify-end place-self-start mt-3',
    icon: 'hidden md:inline place-self-start max-w-[25px]',
  }

  return (
    <>
      <NewReferralStep
        stepNumber={textContent.stepNumber}
        formPositionHigh
        stepDescription={textContent.stepDescription}
        title={textContent.title}
        description={textContent.description}
        customStep
      >
        <div className="flex flex-col gap-2 pr-20 pl-4 pt-[1rem]">
          <Text variant="none" weight="bold" size="2xl" className="pl-16">
            Add some details about the client
          </Text>
          <section className="flex flex-col gap-2 mt-14 w-full xl:gap-2.5">
            <div className={clsx(styles.row)}>
              <Text size="lg" weight="bold" className={styles.label}>
                Location
              </Text>
              <LocationIcon className={styles.icon} />
              <Controller
                control={control}
                name="locations"
                rules={{
                  validate: {
                    locations: (list) =>
                      !isEmpty(list) || 'Select at least one location.',
                  },
                }}
                render={({ field: { onChange } }) => (
                  <Combobox
                    chipVariant="secondary"
                    data={locationsDesignations}
                    multiple
                    lowercase
                    defaultValues={locationsValue}
                    getSelectedOptions={(selected) => {
                      onChange(selected)
                    }}
                    parentBgColor="white"
                    buttonPlaceholder="Type the location of the patient"
                    getData={(selected) =>
                      getLocations({
                        variables: {
                          search: `%${selected ?? ''}%`,
                        },
                      })
                    }
                    error={errors.locations?.message}
                  />
                )}
              />
            </div>
            <div className={clsx(styles.row)}>
              <Text size="lg" weight="bold" className={styles.label}>
                Type of Client
              </Text>
              <UserCopyIcon className={styles.icon} />
              <Controller
                control={control}
                name="typeOfClient"
                rules={{
                  validate: {
                    selectedOne: (selected) =>
                      selected.title !== undefined ||
                      'Choose a type of client.',
                  },
                }}
                render={({ field: { onChange } }) => (
                  <Combobox
                    chipVariant="secondary"
                    data={therapyForms}
                    getSelectedOptions={(selected) => {
                      onChange(selected)
                    }}
                    parentBgColor="white"
                    defaultValues={typeOfClientValue}
                    regularCombobox
                    hasDropdownIcon
                    lowercase
                    buttonPlaceholder="What type of client is seeking therapy?"
                    getData={(selected) =>
                      getTherapyForms({
                        variables: {
                          search: `%${selected ?? '%'}%`,
                        },
                      })
                    }
                    error={errors.typeOfClient?.message}
                  />
                )}
              />
            </div>
            <div className={clsx(styles.row)}>
              <Text size="lg" weight="bold" className={styles.label}>
                Gender
              </Text>
              <GenderNeutralIcon className={twMerge(styles.icon)} />
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange } }) => (
                  <Combobox
                    chipVariant="secondary"
                    data={genderOptions}
                    multiple={!isIndividualClient}
                    lowercase
                    hasDropdownIcon
                    defaultValues={
                      !isIndividualClient ? gendersValue : gendersValue[0]
                    }
                    regularCombobox
                    getSelectedOptions={(selected) => {
                      onChange(selected)
                    }}
                    parentBgColor="white"
                    buttonPlaceholder={
                      !isIndividualClient
                        ? 'What are the genders of all members?'
                        : 'What is the gender of the client?'
                    }
                    getData={() => null}
                    error={errors.gender?.message}
                  />
                )}
              />
            </div>
            <div className={clsx(styles.row)}>
              <Text size="lg" weight="bold" className={styles.label}>
                Age Group
              </Text>
              <HourGlassIcon className={styles.icon} />
              <Controller
                control={control}
                name="ageGroup"
                rules={{
                  required: 'Select at least one option.',
                }}
                render={({ field: { onChange } }) => (
                  <Combobox
                    chipVariant="secondary"
                    data={ageGroups}
                    multiple={!isIndividualClient}
                    lowercase
                    hasDropdownIcon
                    defaultValues={
                      !isIndividualClient ? ageGroupsValue : ageGroupsValue[0]
                    }
                    regularCombobox
                    getSelectedOptions={(selected) => {
                      onChange(selected)
                    }}
                    parentBgColor="white"
                    buttonPlaceholder={
                      !isIndividualClient
                        ? 'What are the age groups of all members?'
                        : 'What age group does the client belong to?'
                    }
                    getData={() => null}
                    error={errors.ageGroup?.message}
                  />
                )}
              />
            </div>
            <div className={clsx(styles.row)}>
              <Text size="lg" weight="bold" className={styles.label}>
                Communities
              </Text>
              <TeamWorkIcon className={twMerge(styles.icon)} />
              <Controller
                control={control}
                name="communities"
                defaultValue={communitiesValue}
                render={({ field: { onChange } }) => (
                  <Combobox
                    chipVariant="secondary"
                    data={orientations}
                    multiple
                    lowercase
                    defaultValues={communitiesValue}
                    getSelectedOptions={(selected) => {
                      onChange(selected)
                    }}
                    parentBgColor="white"
                    buttonPlaceholder="Add any communities the client may be a part of."
                    getData={(selected) => {
                      getOrientations({
                        variables: { search: selected ? `%${selected}%` : '%' },
                      })
                    }}
                  />
                )}
              />
            </div>
            <div
              className={twMerge(
                'flex flex-col flex-1 gap-3 mt-14 w-full md:flex-row md:justify-end bg-white',
              )}
            >
              <Button
                variant="third"
                type="button"
                className="flex-1 order-2 py-2 px-16 my-8 text-sm md:flex-none md:order-1"
                onClick={goToPreviousStep}
              >
                Back
              </Button>
              <Button
                variant="primary"
                type="button"
                className="flex-1 order-1 py-2 px-8 my-8 text-sm md:flex-none md:order-2"
                onClick={handleSubmit(handleContinue)}
              >
                Next: Aditional Details
              </Button>
            </div>
          </section>
        </div>
      </NewReferralStep>
    </>
  )
}

export default StepFour
