import { GetFullProfile } from 'operations/GetFullProfile'

import { Q_GET_FULL_PROFILE } from 'lib/queries/profiles'

import { useQuery } from '@apollo/client'
import { IdentificationIcon, MailIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { isEmpty, isNil } from 'ramda'
import { Dispatch, ReactNode, SetStateAction } from 'react'

import AcceptingClientsIcon from 'components/common/accept_clients_icon'
import ChevronRightIcon from 'components/common/chevron_right_icon'
import ConnectButton from 'components/common/connect_button'
import CurrentJob from 'components/common/current_job'
import LocationIcon from 'components/common/location_icon'
import MobileIcon from 'components/common/mobile_icon'
import PhoneIcon from 'components/common/phone_icon'
import TelehealthIcon from 'components/common/telehealth_icon'
import useConnectionState from 'components/hooks/useConnectionState'
import useMutualConnections from 'components/hooks/useMutualConnections'
import { OfficeInfo } from 'components/profile/profile.interface'
import { Availabilities } from 'components/profile/user_basic_details/availability_badge'
import { Modalities } from 'components/profile/user_basic_details/modality_badge'
import { Avatar, Button, Text } from 'components/ui'

interface ProfileSnapshotProps {
  ownerProfileId: number
  profileId: number
  profileSnapshotVisible?: boolean
  setProfileSnapshotVisible?: Dispatch<SetStateAction<boolean>>
}

function ProfileSnapshot({
  ownerProfileId,
  profileId,
  profileSnapshotVisible = false,
  setProfileSnapshotVisible,
}: ProfileSnapshotProps) {
  const { loading, error, data } = useQuery<GetFullProfile>(
    Q_GET_FULL_PROFILE,
    {
      variables: { profileId: profileId || '-1' },
    },
  )
  const router = useRouter()
  const connectionState = useConnectionState(ownerProfileId, profileId)
  const { mutualConnections } = useMutualConnections(profileId)

  const maximized = 'ease-out duration-300'
  const minimized = 'ease-in duration-300'

  const profile = data?.profiles[0]

  const officeInfoIsEmpty = (info: OfficeInfo | undefined) => {
    if (isNil(info)) return true
    return Object.values(info).every(isEmpty)
  }

  const handleViewProfile = () => {
    router.push(`/be/${profile?.username}`)
  }

  if (loading && !data) {
    return <div>Loading...</div>
  }

  if (!profile || error || !profileSnapshotVisible) return null

  function ItemContainer({ children }: { children: ReactNode }) {
    return <div className="flex gap-7 items-center">{children}</div>
  }

  return (
    <div
      className={`flex h-full ${
        profileSnapshotVisible ? minimized : maximized
      }`}
    >
      {profileSnapshotVisible && setProfileSnapshotVisible && (
        <button
          className="ml-2 w-6 h-20 rounded-tl-md rounded-bl-md ring-1 bg-primary ring-gray-light"
          onClick={() => setProfileSnapshotVisible(false)}
        >
          <div>
            <ChevronRightIcon className="mt-1 ml-1 stroke-white" />
          </div>
        </button>
      )}
      <div className="flex flex-col gap-6 items-center py-4 px-12 bg-white">
        <div className="flex flex-col gap-2">
          <Link href={`/be/${profile?.username}`} passHref>
            <Avatar
              profile={profile}
              layout="fill"
              className="object-cover rounded-full"
              alt="Profile image"
              size={16}
            />
          </Link>
          <div className="flex flex-col gap-2 items-center">
            <div className="flex">
              <Text size="sm" variant="heading" capitalize>
                {profile.first_name} {profile.last_name}
              </Text>
              {profile.degree && (
                <Text
                  size="sm"
                  variant="heading"
                >{`,  ${profile.degree}`}</Text>
              )}
            </div>

            <CurrentJob
              textColor="black"
              currentJob={profile?.current_job_new}
              experiences={profile?.experiences}
            />

            <Text size="xs" variant="small" color="secondary">
              {`${mutualConnections > 0 ? mutualConnections : 'No'}`} Mutual
              Connections
            </Text>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {profile.license[0]?.licenseState && (
            <ItemContainer>
              <IdentificationIcon className="w-5 h-5" />
              <div className="flex gap-1">
                <Text variant="extra-small">License State(s):</Text>
                {profile?.license?.map(
                  (license: { licenseState: string }, index: number) => (
                    <Text variant="extra-small" key={index}>
                      {index > 0 ? ', ' : ' '}
                      {license?.licenseState}
                    </Text>
                  ),
                )}
              </div>
            </ItemContainer>
          )}

          {!officeInfoIsEmpty(profile?.office_info?.city) && (
            <ItemContainer>
              <LocationIcon className="w-5 h-5 fill-black stroke-white" />
              <Text variant="extra-small">
                {profile?.office_info?.city}
                {profile?.office_info?.state && ','}{' '}
                {profile?.office_info?.state}
              </Text>
            </ItemContainer>
          )}
          {profile.contact_info?.phoneNo && (
            <ItemContainer>
              <PhoneIcon className="fill-black" size={5} />
              <Text variant="extra-small">
                {profile.contact_info?.phoneNo || '-'}
              </Text>
            </ItemContainer>
          )}

          {profile.contact_info?.mobileNo && (
            <ItemContainer>
              <MobileIcon className="stroke-black" />
              <Text variant="extra-small">
                {profile.contact_info?.mobileNo || '-'}
              </Text>
            </ItemContainer>
          )}

          {profile.contact_info?.emailId && (
            <ItemContainer>
              <MailIcon className="flex-none w-5 h-5 stroke-white fill-black" />
              <Text
                detectLink={false}
                variant="extra-small"
                color="secondary"
                className="cursor-pointer truncate"
              >
                {profile.contact_info?.emailId || '-'}
              </Text>
            </ItemContainer>
          )}

          {profile.availability && (
            <ItemContainer>
              <AcceptingClientsIcon
                className="stroke-black"
                availability={profile?.availability}
              />
              <Text variant="extra-small">
                {profile.availability === Availabilities.accepting &&
                  'Accepting Clients'}
                {profile.availability === Availabilities.notAccepting &&
                  'Not Accepting Clients'}
                {profile.availability === Availabilities.waitlist && 'Waitlist'}
              </Text>
            </ItemContainer>
          )}
          {profile.modality && (
            <ItemContainer>
              <TelehealthIcon size={5} />
              <Text variant="extra-small">
                {profile.modality === Modalities.online && 'Online'}
                {profile.modality === Modalities.inperson && 'In-Person'}
                {profile.modality === Modalities.both && 'Online & In-Person'}
              </Text>
            </ItemContainer>
          )}
        </div>
        {/* <div className="flex flex-col gap-2 w-full">
          {connectionState === 'connect' && (
            <ConnectButton
              fromProfileId={ownerProfileId}
              toProfileId={profile?.id}
            />
          )}

          <Button
            variant="secondary"
            className="w-full"
            onClick={handleViewProfile}
          >
            <Text variant="extra-small" color="white">
              View My Profile
            </Text>
          </Button>
        </div> */}
      </div>
    </div>
  )
}

export default ProfileSnapshot
