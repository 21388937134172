import { FunctionComponent, useContext } from 'react'

import { Button, Text } from 'components/ui'

import { CreateReferralContext } from '../../step_controller/step_controller'

interface IStepSixForm {
  className?: string
}

const StepSixForm: FunctionComponent<IStepSixForm> = () => {
  const { goToPreviousStep, goToNextStep } = useContext(CreateReferralContext)

  return (
    <>
      <Text size="xl" weight="bold">
        Add some details about the client
      </Text>
      <section className="flex flex-col gap-10">
        <div className="flex flex-1 gap-2 self-end w-1/2">
          <Button
            variant="third"
            type="button"
            className="flex-1"
            onClick={goToPreviousStep}
          >
            Back
          </Button>
          <Button
            variant="primary"
            type="button"
            className="flex-1"
            onClick={goToNextStep}
          >
            Continue
          </Button>
        </div>
      </section>
    </>
  )
}

export default StepSixForm
