import { FunctionComponent } from 'react'
import { Configure, Hits, Index } from 'react-instantsearch'

import { Text } from 'components/ui'

import { SearchCategories } from '../../categories/categories'
import ResultRendering from '../result_rendering'

interface IAllCard {
  category: SearchCategories
  indexName: string
  hitsPerPage: number
}

const AllCard: FunctionComponent<IAllCard> = ({
  category,
  indexName,
  hitsPerPage,
}) => {
  return (
    <div>
      <Index indexName={indexName}>
        <Configure hitsPerPage={hitsPerPage} />

        <div className="flex flex-col gap-1 mb-2">
          <div className="pl-2">
            <Text>{category}</Text>
          </div>

          <div className="p-0">
            <Hits
              className="w-full"
              classNames={{
                list: 'flex flex-col',
              }}
              hitComponent={(hit) => (
                <div className="p-1 w-full hover:cursor-pointer hover:bg-[#f2f0fb]">
                  <ResultRendering hit={hit.hit} indexCategory={category} />
                </div>
              )}
            />
          </div>
        </div>
      </Index>
    </div>
  )
}

export default AllCard
