import { Dispatch, SetStateAction, createContext } from 'react'

import {
  activeConversationProperties,
  activeConversations,
  activeProfileOnChat,
} from '../types/types'

interface IChatContext {
  isBusinessProfileActive: boolean
  setIsBusinessProfileActive: Dispatch<SetStateAction<boolean>>
  activeProfileOnChat: activeProfileOnChat
  setActiveProfileOnChat: Dispatch<SetStateAction<activeProfileOnChat>>
  activeConversations: activeConversationProperties[]
  setActiveConversations: Dispatch<SetStateAction<activeConversations>>
  hasUnreadMessages: boolean
  setHasUnreadMessages: Dispatch<SetStateAction<boolean>>
}

const ChatContext = createContext<null | IChatContext>(null)

export default ChatContext
