import {
  GetHomeFeed,
  GetHomeFeed_belongly_news_posts,
  GetHomeFeed_followed_news_posts,
} from 'operations/GetHomeFeed'
import type { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'

import { Q_GET_HOME_FEED } from 'lib/queries/posts'

import { NetworkStatus, useQuery, useReactiveVar } from '@apollo/client'
import { useState } from 'react'

import { PostCategory } from 'components/post.interface'

import {
  HomeFeedType,
  addPostToQueue,
  clearFeedCache,
  getNextUniqueNewsPost,
  getNextUniqueNonNewsPost,
  homeFeedQueue,
  mergeFeedData,
  postIdList,
  postsVar,
} from 'utils/posts/feed'

interface HookProps {
  readerProfile: Pick<GetProfileByOwnerId_profiles, 'id' | 'avatar'>
}

enum AdditionalFilter {
  MOST_RECENT = 'Most Recent',
}

type FilterTypes = PostCategory | AdditionalFilter

const filtersList = {
  [AdditionalFilter.MOST_RECENT]: { _is_null: false },
  [PostCategory.REFERRAL]: { _is_null: true },
  [PostCategory.POST]: { _eq: PostCategory.POST },
  [PostCategory.NEWS]: { _eq: PostCategory.NEWS },
}

const referralFiltersList = {
  [AdditionalFilter.MOST_RECENT]: { _eq: 'Referral' },
  [PostCategory.REFERRAL]: { _eq: 'Referral' },
  [PostCategory.POST]: { _eq: '' },
  [PostCategory.NEWS]: { _eq: '' },
}

const NEWS_POSTS_PERIODICITY = 3

const usePosts = ({ readerProfile }: HookProps) => {
  const QUERY_LIMIT = 5
  const [filter, setFilter] = useState<FilterTypes>(
    AdditionalFilter.MOST_RECENT,
  )
  const [queryOffset, setQueryOffset] = useState(0)
  const posts = useReactiveVar(postsVar)

  const {
    error: errorPostData,
    refetch,
    networkStatus,
  } = useQuery<GetHomeFeed>(Q_GET_HOME_FEED, {
    variables: {
      profileId: readerProfile.id,
      filter: filtersList[filter],
      referralFilter: referralFiltersList[filter],
      limit: QUERY_LIMIT,
      offset: queryOffset,
    },
    onCompleted: (data) => {
      if (!data) return
      const { posts: regularPosts, cutOffDate, newsPosts } = mergeFeedData(data)

      if (AdditionalFilter.MOST_RECENT === filter) {
        queuePostsWithNewsItems(regularPosts, newsPosts)
      } else if (PostCategory.NEWS === filter) {
        queueNewsPosts(newsPosts)
      } else {
        queueNonNewsPosts(regularPosts)
      }

      const updatedPosts = updatePosts(cutOffDate)
      postsVar(updatedPosts)
    },
  })

  function queueNonNewsPosts(nonNewsPosts: HomeFeedType[]) {
    while (nonNewsPosts.length > 0) {
      let post = getNextUniqueNonNewsPost(nonNewsPosts)
      if (post) {
        addPostToQueue(post)
      }
    }
  }

  function queueNewsPosts(
    newsPosts:
      | GetHomeFeed_followed_news_posts[]
      | GetHomeFeed_belongly_news_posts[],
  ) {
    while (newsPosts.length > 0) {
      let newsPost = getNextUniqueNewsPost(newsPosts)
      if (newsPost) {
        addPostToQueue(newsPost)
      }
    }
  }

  /**
   * Enqueue repeating sequences of regular posts, separated
   * by single individual news posts. For example:
   * [Discussion, Referral, Discussion, News, Referral, Referral, Discussion, News...]
   * @param regularPosts
   * @param newsPosts
   */
  function queuePostsWithNewsItems(
    regularPosts: HomeFeedType[],
    newsPosts:
      | GetHomeFeed_followed_news_posts[]
      | GetHomeFeed_belongly_news_posts[],
  ) {
    // Special case: Queue all news posts if no regular posts provided
    if (regularPosts.length === 0) {
      queueNewsPosts(newsPosts)
    }

    let nonNewsPostSeriesCount = 0
    let nonNewsPosts = [...regularPosts]

    // Create multiple repeating sequences until out of data
    do {
      nonNewsPostSeriesCount = 0
      // Add sequence of NEWS_POSTS_PERIODICITY regular posts
      do {
        let nonNewsPost = getNextUniqueNonNewsPost(nonNewsPosts)
        if (nonNewsPost) {
          addPostToQueue(nonNewsPost)
        }
        nonNewsPostSeriesCount++
      } while (
        nonNewsPosts.length > 0 &&
        nonNewsPostSeriesCount < NEWS_POSTS_PERIODICITY
      )

      // Add a unique news post after the sequence of regular posts
      let newsPost = getNextUniqueNewsPost(newsPosts)
      if (newsPost) {
        addPostToQueue(newsPost)
      }
    } while (nonNewsPosts.length > 0 && newsPosts.length > 0)
  }

  function getNextDataChunk() {
    if (networkStatus === NetworkStatus.ready) {
      const newOffset = queryOffset + QUERY_LIMIT
      setQueryOffset(newOffset)
    }
  }

  // This is grabbing the most recent posts from the queue we have
  // With all the data we have get from database
  function updatePosts(cutOff: number) {
    if (!cutOff) return

    let isBelowTimeCutOff = true
    const postsTemp = [...postsVar()]
    let itemsAddedCount = 0

    // We are dequeueing items until we reach the time of the most
    // recent post between the latest posts
    while (isBelowTimeCutOff) {
      const dequeueElement = homeFeedQueue.dequeue()

      if (dequeueElement === null) {
        return postsTemp
      }

      postsTemp.push(dequeueElement)
      postIdList.add(dequeueElement.id)
      itemsAddedCount++

      if (dequeueElement.sort_date <= cutOff) {
        isBelowTimeCutOff = false
      }
    }

    if (itemsAddedCount < 5) {
      getNextDataChunk()
    }

    return postsTemp
  }

  const changeFilter = (filter: FilterTypes) => {
    setFilter(filter)
    homeFeedQueue.clear()
    postIdList.clear()
    clearFeedCache()
    setQueryOffset(0)
  }

  return {
    posts,
    errorPostData,
    setFilter: changeFilter,
    filter,
    isLoading: networkStatus === NetworkStatus.loading,
    refetch,
    getNextDataChunk,
    offset: queryOffset,
  }
}

export default usePosts
