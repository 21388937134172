import algoliasearch from 'algoliasearch'

const applicationId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY

export const algoliaClient = algoliasearch(applicationId, apiKey)

export const PROFILES_INDEX = 'prod_PROFILES'
export const REFERRALS_INDEX = 'prod_REFERRALS'
export const POSTS_INDEX = 'prod_POSTS'
export const BUSINESS_INDEX = 'prod_BUSINESS_PROFILES'
export const INTERNAL_SEARCH_INDEX = 'prod_PROFILES'

export const algoliaProfilesIndex = algoliaClient.initIndex(PROFILES_INDEX)
export const algoliaReferralsIndex = algoliaClient.initIndex(REFERRALS_INDEX)
export const algoliaPostsIndex = algoliaClient.initIndex(POSTS_INDEX)
export const algoliaBusinessProfilesIndex =
  algoliaClient.initIndex(BUSINESS_INDEX)
export const algoliaInternalSearchIndex = algoliaClient.initIndex(
  INTERNAL_SEARCH_INDEX,
)
