import { ProfileFields } from 'operations/ProfileFields'

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  intlFormat,
} from 'date-fns'
import {
  compose,
  filter,
  isEmpty,
  isNil,
  map,
  match,
  propOr,
  split,
  test,
} from 'ramda'
import { v4 as uuidv4 } from 'uuid'

import { BusinessProfile } from 'components/hooks/useBusinessProfile'
import { Profile } from 'components/hooks/useProfile'

export const whether = (
  condition: any,
  trueValue: any,
  falseValue: any = null,
) => (!!condition ? trueValue : falseValue)

export const generateKey = (pre: any) => `${pre}_${uuidv4()}`

export const getFullName = (profile: ProfileFields) =>
  `${profile.first_name} ${profile.last_name}`.trim()

export const today = new Date()

export const getFirstItem = propOr({}, 0 as any)

export const removeSymbols = (pre: string) => pre.replace(/[^0-9 ]/g, '')

export const getId = compose(
  (id) => +id,
  removeSymbols,
  getFirstItem,
  match(/(\{\{[\s]*.*?[\s]*\}\})/g),
)

export const isTag = test(/(\{\{[\s]*.*?[\s]*\}\})/g)
export const isAnonTag = test(/(\{\{[\s]*.*?[\s]*\}\}--anonymous)/g)
export const isMention = test(/(@\[[a-zA-Z]+\s[a-zA-Z]+\]\([0-9]\))/g)

export const mapMention = compose(
  (id) => `{{${id}}}`, // => {{1}}
  removeSymbols, // 1
  getFirstItem, // => 1.
  match(/\(([^()]*)\)/g), //[carlos](1) => [1.]
)

export const replaceMentions = (text: string) => {
  let out = text
  const mentions = out.match(/\[(.*?)\]\(.*?\)/gm) // [carlos](1.)
  mentions?.forEach((mention) => {
    out = out.replace(`@${mention}`, mapMention(mention))
  })
  return out
}

export const getMentionIdsFromText = compose(
  map(getId), // [1, 2]
  filter(isTag), // ['@1', '@2']
  split(' '),
  replaceMentions,
)

export const mapListToOptions = ({
  list,
  titlePropName,
  valuePropName,
}: {
  list: any[]
  titlePropName: ''
  valuePropName: ''
}) =>
  list.map((item) => ({
    title: item[titlePropName],
    value: item[valuePropName],
  }))

export const capitalizeWord = (word: string) => {
  word[0]?.toUpperCase()
  return word
}

export const capitalize = (word: string) =>
  word?.charAt(0).toUpperCase() + word?.slice(1)

export const getSufix = ({
  degree,
  educations,
}: {
  degree: string | null
  educations: any
}) => {
  if (degree) return degree || ''
  return educations[0]?.degree || ''
}

export const formatFullDate = (date: string) =>
  intlFormat(new Date(date), {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

export const getDistanceLabel = (date: string) => {
  const minutes = differenceInMinutes(new Date(), new Date(date))
  if (minutes < 1) return 'now'
  if (minutes < 60) return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`

  const hours = differenceInHours(new Date(), new Date(date))
  if (hours < 24) return `${hours} ${hours === 1 ? 'hour' : 'hours'}`

  const days = differenceInDays(new Date(), new Date(date))
  if (days < 7) return `${days} ${days === 1 ? 'day' : 'days'}`

  const weeks = differenceInWeeks(new Date(), new Date(date))
  const months = differenceInMonths(new Date(), new Date(date))

  if (months < 7) return `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`

  return `${months} ${months === 1 ? 'month' : 'months'}`
}

export const removeDuplicatesForObjectsArray = (arr: Array<unknown>) => {
  let strItems = []
  strItems = arr.map((item) => JSON.stringify(item))
  let uniqueItems = new Set(strItems)
  return Array.from(uniqueItems).map((item) => JSON.parse(item))
}

export const profilesLink = (author?: {
  __typename: BusinessProfile['__typename'] | Profile['__typename']
  username: string | null
}) => {
  if (!author) {
    throw new Error('You need to pass an author in order to create the link')
  }

  if (author.__typename === 'business_profiles') {
    return `/page/org/${author.username}`
  }

  return `/be/${author.username}`
}

export const isNotEmptyArray = (array: any[] | undefined) => {
  return !isNil(array) && array.length > 0
}
export const exists = (pre: any) => !isNil(pre) && !isEmpty(pre)

export const parseReferralTitle = (title?: string) =>
  title?.toLowerCase().split(' ').join('-').replaceAll('/', '-')
