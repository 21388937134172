import { useRouter } from 'next/router'
import { useContext } from 'react'

import useFullSearch from '../../hooks/useFullSearch'
import { SearchBoxContext } from '../../utils/context'
import ProfileCard from './profile_card'

const ProfileResults = ({ hit }: { hit: any }) => {
  const router = useRouter()
  const { hideResults } = useContext(SearchBoxContext)
  const goToProfile = () => {
    hideResults()
    router.push(`/be/${hit.username}`)
  }
  return (
    <div key={hit.id} onClick={goToProfile}>
      <ProfileCard hit={hit} />
    </div>
  )
}

export default ProfileResults
