import {
  GetBusinessUsername,
  GetBusinessUsernameVariables,
  GetBusinessUsername_business_profiles,
} from 'operations/GetBusinessUsername'

import { Q_GET_USERNAME } from 'lib/queries/business_profiles'

import { useQuery } from '@apollo/client'
import { ProfileContext } from 'context'
import { useRouter } from 'next/router'
import { isNil } from 'ramda'
import { useContext, useMemo } from 'react'

interface UseBusinessProfile {
  name?: string
}

const useBusinessProfile = (options?: UseBusinessProfile) => {
  const router = useRouter()
  const username =
    options?.name || (router.query['business_username'] as string)

  const { data, loading } = useQuery<
    GetBusinessUsername,
    GetBusinessUsernameVariables
  >(Q_GET_USERNAME, {
    variables: {
      username,
    },
  })

  return {
    businessProfile: data?.business_profiles[0],
    loading,
  }
}

export type BusinessProfile = GetBusinessUsername_business_profiles
export default useBusinessProfile
