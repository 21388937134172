import { useClerk } from '@clerk/nextjs'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Avatar, Menu } from 'components/ui'

const ClientSection = ({ pictureSize = 8 }: { pictureSize?: number }) => {
  const { signOut } = useClerk()

  return (
    <Menu
      button={
        <Avatar
          layout="fill"
          className="object-cover rounded-full"
          alt="Profile image"
          size={pictureSize}
          withBorder={false}
        />
      }
      options={[
        {
          label: 'Sign Out',
          onClick: signOut,
          icon: (active: boolean) => (
            <ArrowLeftIcon
              className={twMerge(active && 'stroke-white', 'h-4 w-4')}
            />
          ),
        },
      ]}
    />
  )
}

export default ClientSection
