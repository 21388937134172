import { MessageGroupList } from 'operations/MessageGroupList'

import { useEffect, useState } from 'react'

import useChatContext from '../hooks/useChatContext'
import { getMostRecentUnreadMessage } from '../utils/list'
import Conversation from './conversation'

interface ListProps {
  conversationListData: MessageGroupList
  onChange?: (hasUnread: boolean) => void
}

function List({ onChange, conversationListData }: ListProps) {
  const { activeProfileOnChat: myProfile } = useChatContext()

  const [previousMostRecentUnreadMessage, setPreviousMostRecentUnreadMessage] =
    useState<Date>(new Date(0))

  const mostRecentUnreadMessage = getMostRecentUnreadMessage({
    conversationListData,
    myProfile,
  })

  useEffect(() => {
    if (
      mostRecentUnreadMessage.getTime() >
      previousMostRecentUnreadMessage.getTime()
    ) {
      onChange?.(true)
      setPreviousMostRecentUnreadMessage(mostRecentUnreadMessage)
    }
  }, [
    onChange,
    mostRecentUnreadMessage,
    previousMostRecentUnreadMessage,
    setPreviousMostRecentUnreadMessage,
  ])

  return (
    <div className="flex overflow-y-auto flex-col">
      {conversationListData?.message_group.map((conversation) => (
        <Conversation key={conversation.id} conversation={conversation} />
      ))}
    </div>
  )
}

export default List
