import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { FunctionComponent } from 'react'

import { InfoLabel, Text } from 'components/ui'

interface IRadioGroupButtons {
  label?: string
  options: string[]
  vertical?: boolean
  optionRoundedShape?: boolean
  value?: string
  error?: string | undefined
  fullWidth?: boolean
  textSize?: 'xs' | 'sm' | 'md' | 'lg'
  tooltip?: string
  onSelect: (option: string) => void
  className?: string
  labelClassName?: string
  defaultValue?: string
  variant?: 'rounded' | 'square'
}

const RadioGroupButtons: FunctionComponent<IRadioGroupButtons> = ({
  label,
  className,
  labelClassName,
  options,
  error,
  vertical = false,
  variant = 'rounded',
  value,
  fullWidth = false,
  textSize = 'sm',
  tooltip,
  onSelect,
  defaultValue,
  ...props
}: IRadioGroupButtons) => {
  const optionContainerStyle = clsx(
    'flex mt-1 text-md rounded cursor-pointer items-center gap-2',
    fullWidth ? 'w-full' : 'max-w-fit',
    vertical ? 'flex-col w-full' : 'max-h-10 bg-background ',
  )
  const radiobuttonStyle = {
    unselected:
      'hover:text-white hover:bg-primary/[0.35] px-2 flex justify-self-center self-center select-none bg-background ',
    selected:
      'bg-primary text-white font-medium flex justify-self-center select-none',
    disabled: 'flex justify-self-center self-center',
  }

  return (
    <div
      {...props}
      className={clsx(
        className,
        'flex flex-col relative justify-center items-center w-full',
      )}
    >
      <div className="flex w-full">
        <HeadlessRadioGroup
          value={value}
          onChange={onSelect}
          defaultValue={defaultValue}
          className="w-full"
        >
          {label && (
            <HeadlessRadioGroup.Label
              className={clsx('mb-4 flex', labelClassName)}
            >
              {label}
            </HeadlessRadioGroup.Label>
          )}
          <div className={optionContainerStyle}>
            {options.map((option) => (
              <HeadlessRadioGroup.Option
                value={option}
                key={option}
                className={clsx(vertical && 'flex w-full text-end')}
              >
                {({ checked }) => (
                  <div
                    className={clsx(
                      'flex cursor-pointer rounded',
                      vertical ? 'w-full' : 'px-4 py-2',
                      checked
                        ? radiobuttonStyle.selected
                        : radiobuttonStyle.unselected,
                    )}
                  >
                    <Text
                      variant="body"
                      className={clsx(
                        'capitalize justify-self-center text-center hover:text-white w-full h-full',
                        checked && 'text-white',
                        vertical && 'w-full text-center py-3',
                      )}
                    >
                      {option}
                    </Text>
                  </div>
                )}
              </HeadlessRadioGroup.Option>
            ))}
          </div>
        </HeadlessRadioGroup>
      </div>
      {tooltip && (
        <div className="absolute -right-7">
          <InfoLabel tooltip={tooltip} />
        </div>
      )}
      {error && (
        <div className="flex mt-2 w-full">
          <Text className="text-error">{error}</Text>
        </div>
      )}
    </div>
  )
}

export default RadioGroupButtons
