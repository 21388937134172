import { PopoverArrow } from '@radix-ui/react-popover'
import clsx from 'clsx'
import { FunctionComponent, JSXElementConstructor, ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { Popover, PopoverContent, PopoverTrigger } from '../popover'
import { Text } from '../text'

interface ITooltip extends React.HTMLAttributes<HTMLDivElement> {
  content: string
  children: ReactElement<any, string | JSXElementConstructor<any>>
  width?: 'xs' | 'sm' | 'md'
  className?: string
}

const Tooltip: FunctionComponent<ITooltip> = ({
  content,
  children,
  width = 'sm',
  className = '',
}: ITooltip) => {
  const tooltipWidth =
    width == 'xs' ? 'max-w-xs' : width == 'sm' ? 'max-w-sm' : 'max-w-md'
  return (
    <Popover>
      <PopoverTrigger className={children.props.className}>
        {children}
      </PopoverTrigger>
      <PopoverContent
        sideOffset={4}
        className={twMerge(
          'radix-side-top:animate-slide-down-fade',
          'radix-side-right:animate-slide-left-fade',
          'radix-side-bottom:animate-slide-up-fade',
          'radix-side-left:animate-slide-right-fade',
          'inline-flex items-center rounded-md px-4 py-2.5',
          'bg-gray-800',
          className,
        )}
      >
        <PopoverArrow className="text-white text-gray-800 fill-current" />
        <Text
          className={twMerge(
            'text-justify text-xs leading-snug text-gray-700 text-gray-100',
            tooltipWidth,
          )}
        >
          {content}
        </Text>
      </PopoverContent>
    </Popover>
  )
}

export default Tooltip
