import {
  GetBusinessUsername,
  GetBusinessUsernameVariables,
} from 'operations/GetBusinessUsername'
import { GetProfileByOwnerId } from 'operations/GetProfileByOwnerId'

import { Q_GET_USERNAME } from 'lib/queries/business_profiles'
import { Q_GET_PROFILE } from 'lib/queries/profiles'

import { useApolloClient, useQuery } from '@apollo/client'
import { useUser } from '@clerk/nextjs'
import { useRouter } from 'next/router'
import { isEmpty, isNil } from 'ramda'
import { ReactNode, useMemo } from 'react'

import ProfileContext from './profile_context'

interface IProfileProvider {
  children: ReactNode
}

function ProfileProvider({ children }: IProfileProvider) {
  const { user } = useUser()
  const client = useApolloClient()
  const router = useRouter()

  const ownerCache = client.readQuery<GetProfileByOwnerId>({
    query: Q_GET_PROFILE,
    variables: { ownerId: user?.id },
  })

  const username = router.query['business_username'] as string

  const { data: businessData, loading: businessLoading } = useQuery<
    GetBusinessUsername,
    GetBusinessUsernameVariables
  >(Q_GET_USERNAME, {
    variables: {
      username,
    },
    skip: isNil(username),
  })

  const {
    data: ownerData,
    loading: ownerLoading,
    error: ownerError,
    refetch: ownerRefecth,
  } = useQuery<GetProfileByOwnerId>(Q_GET_PROFILE, {
    variables: { ownerId: user?.id },
    skip: !isNil(ownerCache),
  })

  const ownerProfile = isNil(ownerCache)
    ? ownerData?.profiles?.[0]
    : ownerCache.profiles?.[0]

  const profile = ownerProfile

  const fullName = `${profile?.first_name} ${profile?.last_name}`.trim()

  const myAvatar = profile?.avatar

  const id = profile?.id

  const noProfile = isEmpty(ownerData?.profiles)

  const memoizedValue = useMemo(
    () => ({
      profile,
      fullName,
      myAvatar,
      id,
      noProfile,
      ownerError,
      ownerLoading,
      ownerRefecth,
      businessData,
      businessLoading,
    }),
    [
      businessData,
      businessLoading,
      fullName,
      id,
      myAvatar,
      noProfile,
      ownerError,
      ownerLoading,
      ownerRefecth,
      profile,
    ],
  )

  return (
    <ProfileContext.Provider value={memoizedValue}>
      {children}
    </ProfileContext.Provider>
  )
}

export default ProfileProvider
