import { LastSeenByMessageGroupId_message_group_profiles } from 'operations/LastSeenByMessageGroupId'
import type { Messages, Messages_messages } from 'operations/Messages'

import { S_MESSAGES_BY_GROUP_ID } from 'lib/queries/messages'

import {
  LazyQueryExecFunction,
  OperationVariables,
  useSubscription,
} from '@apollo/client'
import clsx from 'clsx'
import { isNil } from 'ramda'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'

import { RefreshIcon } from 'components/colleagues/results'
import { useProfile } from 'components/hooks'

import {
  formatMessagesDate,
  getLasMessage,
  getMessageDate,
  getNextMessage,
  getPrevMessage,
  hasUnreadMessages,
  isLastMessage,
  isMyMessage,
  isNextMessageFromSameAuthor,
  isSameDayWithFormatChecking,
} from 'utils/messages/messages'

import Message from './message'

interface ContainerProps {
  isBusiness: boolean
  lastSeenData: LastSeenByMessageGroupId_message_group_profiles[] | any
  isPage: boolean
  messageGroupId: number
  ownerProfileId: number
  getIsOnlineUser: (isOnlineUser: boolean) => void
  getLastSeen: LazyQueryExecFunction<
    LastSeenByMessageGroupId_message_group_profiles[],
    OperationVariables
  >
  isExpanded: boolean
  setIsExpanded: Dispatch<SetStateAction<boolean>>
}
function Container({
  isBusiness,
  lastSeenData,
  isPage,
  messageGroupId,
  ownerProfileId,
  getIsOnlineUser,
  getLastSeen,
  isExpanded,
  setIsExpanded,
}: ContainerProps) {
  const { profile } = useProfile()
  const currentSessionId = profile?.id || -1
  const { data: messagesData, loading } = useSubscription<Messages>(
    S_MESSAGES_BY_GROUP_ID,
    {
      skip: !messageGroupId,
      variables: { messageGroupId: messageGroupId, ownerProfileId },
    },
  )
  const messageClass = clsx(
    ' py-0 px-2 pt-8 z-1 list-none w-full',
    isPage ? 'h-[548px]' : 'min-h-auto',
  )
  const bottomRef = useRef<null | HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (bottomRef.current) bottomRef.current.scrollIntoView()
  }

  useEffect(() => {
    getLastSeen({
      variables: {
        messageGroupId: messageGroupId,
        fromProfileId: ownerProfileId,
      },
    })

    if (messagesData && messagesData.messages.length) {
      getIsOnlineUser(
        messagesData?.messages[0].message_group?.profiles[0]?.from_profile
          ?.user_status[0]?.is_online || false,
      )
    }
    scrollToBottom()

    // eslint-disable-next-line
  }, [messagesData])

  return (
    <div
      className={clsx(
        `flex overflow-y-auto flex-col flex-none justify-start w-full border-l min-h-auto grow border-gray-light`,
        isPage ? 'h-full' : 'h-full',
      )}
    >
      {!messagesData && loading && <RefreshIcon className="spin" />}
      <ul className={messageClass}>
        {messagesData?.messages?.map(
          (message: Messages_messages, index: number) => {
            return (
              <span key={message.id}>
                {!isSameDayWithFormatChecking(
                  getMessageDate(message.created_at),
                  getMessageDate(
                    getPrevMessage(messagesData, index)?.created_at,
                  ),
                ) && (
                  <div className="py-3 text-xs text-center text-gray-mid">
                    {formatMessagesDate(getMessageDate(message.created_at))}
                  </div>
                )}

                <Message
                  key={message.id}
                  isMyMessage={isMyMessage({
                    message,
                    ownerProfileId,
                    originalProfileIdLoggedInApp: currentSessionId,
                    isBusiness,
                  })}
                  isNextMessageFromSameAuthor={isNextMessageFromSameAuthor(
                    message,
                    getNextMessage(messagesData, index),
                    isBusiness,
                  )}
                  message={message}
                  isLastMessage={isLastMessage(messagesData, index)}
                />
              </span>
            )
          },
        )}
        <div className="pb-1" ref={bottomRef}></div>
      </ul>
    </div>
  )
}

export default Container
