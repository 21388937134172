import { useUser } from '@clerk/nextjs'
import { useRouter } from 'next/router'
import { isEmpty } from 'ramda'
import { useMemo } from 'react'

import { ISimpleLocation } from 'components/common/hocs/location_select'

import { getAccurateLocation } from 'utils/opportunities/referrals/referrals'

export interface IUseFilters {
  field: string
  subField?: string
  inputValue: string | boolean | ISimpleLocation
}

const useFilters = () => {
  const router = useRouter()
  const { user } = useUser()
  const userId = user?.id
  const baseQuery = '/colleagues' + '?filters='

  const { filters: encodedFilters } = router.query

  const filters = useMemo(() => {
    let decodedFilters: Record<string, any> = {}
    if (encodedFilters) {
      decodedFilters = JSON.parse(atob(encodedFilters as string))
    }
    return decodedFilters
  }, [encodedFilters])

  const deleteFilter = (field: string, subfield: string) => {
    const copyFilters = { ...filters }

    if (field === 'search') {
      delete copyFilters['search']
    } else if (field === 'office_info') {
      copyFilters['office_info'] = undefined
    } else {
      Object.keys(copyFilters).map((key: string) => {
        if (key == field) {
          const { [subfield]: sf, ...restProperties } = copyFilters[key]
          if (JSON.stringify(restProperties) == '{}') {
            copyFilters[key] = undefined
          } else {
            copyFilters[key] = { ...restProperties }
          }
        }
      })
    }

    if (JSON.stringify(copyFilters) == '{}') {
      router.push('/colleagues')
      return
    }

    const encodedFilters = btoa(JSON.stringify(copyFilters))
    router.push(baseQuery + encodedFilters)
  }
  const addFilter = (propSet: IUseFilters | Array<IUseFilters>) => {
    let copyFilters = { ...filters }
    if (!Array.isArray(propSet)) {
      propSet = [propSet]
    }

    for (const props of propSet) {
      const { field, subField, inputValue } = props
      let assignments
      if (subField) {
        assignments = {
          [field]: { ...copyFilters[field], [subField]: inputValue },
        }
      } else {
        assignments = { [field]: inputValue }
      }

      copyFilters = { ...copyFilters, ...assignments }
    }

    const encodedFilters = btoa(JSON.stringify(copyFilters))
    router.push(baseQuery + encodedFilters)
  }
  const query = useMemo(() => {
    const queryObject = Object.entries(filters).map(
      ([field, value]: [any, any]) => {
        let searchField: any
        if (typeof value == 'string') {
          if (field == 'search') {
            searchField = {
              _or: [
                { first_name: { _ilike: `%${value}%` } },
                { last_name: { _ilike: `%${value}%` } },
              ],
            }
          } else if (field == 'office_info') {
            const { state, city } = JSON.parse(filters[field])
            return {
              office_info_search: {
                city: { _ilike: !isEmpty(city) ? `%${city}%` : '%%' },
                state: { _ilike: !isEmpty(state) ? `%${state}%` : '%%' },
              },
            }
          } else {
            searchField = { [field]: { _ilike: `%${value}%` } }
          }
          return searchField
        } else if (typeof value == 'boolean') {
          if (!value) {
            return {
              _or: [
                {
                  [field]: { _is_null: true },
                },
                {
                  [field]: { _eq: false },
                },
              ],
            }
          }

          return {
            [field]: { _eq: true },
          }
        } else {
          if (JSON.stringify(value) == '{}') return

          const [subProperty, searchValue] = Object.entries(value)[0]
          searchField = { [subProperty]: searchValue }
          if (['educations' /*, 'research', 'experiences'*/].includes(field)) {
            return {
              educations: {
                _contains: [{ [subProperty]: searchValue }],
              },
            }
            //searchField = [searchField]
          } else if (['license'].includes(field)) {
            if (
              filters[field].hasOwnProperty('licensestate') &&
              filters[field]['licensestate']
            ) {
              const stateName = filters[field]['licensestate'].trim()
              return {
                licenses_search: { licensestate: { _ilike: `%${stateName}%` } },
              }
            }
            return {
              licenses_search: {
                [subProperty]: { _ilike: `%${searchValue}%` },
              },
            }
          } else if (['insurance_accepted'].includes(field)) {
            if (
              filters[field].hasOwnProperty('insurance') &&
              filters[field]['insurance']
            ) {
              const insuranceName = filters[field]['insurance'].trim()
              return {
                referral_information: {
                  _cast: { String: { _iregex: insuranceName } },
                },
              }
            }
            return {
              licenses_search: {
                [subProperty]: { _ilike: `%${searchValue}%` },
              },
            }
          } else if (['services'].includes(field)) {
            const services = Object.entries(filters[field]).map(
              ([serviceField, serviceValue]: [any, any]) => ({
                [serviceField]: { _ilike: `%${serviceValue}%` },
              }),
            )
            return {
              services_search: { _and: services },
            }
          } else if (['people'].includes(field)) {
            if (searchValue == 'everyone') {
              return {}
            }
            return {
              connections_from: {
                to_profile: { owner_id: { _eq: userId } },
              },
            }
          } else {
            return { [field]: { _contains: searchField } }
          }
        }
      },
    )
    return { _and: queryObject }
  }, [filters, userId])

  return {
    deleteFilter,
    addFilter,
    filters,
    query,
  }
}

export default useFilters
