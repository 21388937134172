import useFullSearch from '../hooks/useFullSearch'

const ResultRendering = ({
  hit,
  indexCategory,
}: {
  hit: any
  indexCategory?: string
}) => {
  const { resultComponent } = useFullSearch()
  const resultCategory = indexCategory || ''
  return resultComponent[resultCategory]({ hit })
}

export default ResultRendering
