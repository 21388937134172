const TeamWorkIcon = ({ className = '' }) => (
  <svg
    width={53}
    height={53}
    viewBox="0 -12 53 53"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g
      transform="matrix(0.01042, 0, 0, -0.01, -2, 40)"
      fill="#000000"
      stroke="none"
    >
      <path d="M2414 5100 c-68 -24 -120 -58 -177 -116 -44 -44 -117 -169 -117 -200 0 -8 -9 -14 -20 -14 -41 0 -291 -70 -403 -114 -310 -120 -585 -297 -819 -529 -158 -155 -333 -378 -312 -396 5 -4 124 -74 264 -156 l255 -147 20 28 c195 271 491 500 803 623 l52 21 0 -395 0 -395 600 0 600 0 2 395 3 394 90 -39 c311 -133 523 -303 763 -609 l19 -25 244 142 c134 77 253 147 263 155 19 14 19 16 -15 68 -148 232 -421 492 -701 665 -204 126 -523 256 -740 301 -49 9 -88 23 -88 29 0 29 -74 155 -117 198 -97 99 -188 136 -327 135 -61 0 -108 -6 -142 -19z" />
      <path d="M389 3438 c-40 -78 -107 -256 -138 -364 -68 -235 -91 -406 -91 -666 0 -265 29 -465 101 -704 l31 -100 -32 -47 c-156 -232 -68 -550 187 -670 60 -28 81 -32 171 -35 l104 -4 141 -142 c329 -329 714 -548 1143 -650 121 -29 295 -56 362 -56 l42 0 0 309 c0 351 11 314 -95 326 -215 23 -523 137 -716 265 -79 52 -188 133 -189 140 0 3 149 91 331 195 182 105 333 195 336 202 2 6 -129 244 -293 528 -222 386 -302 515 -313 512 -9 -2 -164 -90 -346 -195 l-330 -191 -8 37 c-15 73 -20 397 -7 499 17 136 60 302 114 435 42 105 44 114 28 126 -25 19 -498 292 -505 292 -4 0 -16 -19 -28 -42z" />
      <path d="M4680 3463 c-47 -17 -500 -288 -496 -297 139 -335 173 -506 163 -819 -4 -100 -10 -199 -14 -219 l-8 -37 -330 191 c-181 105 -337 193 -346 195 -11 3 -92 -129 -313 -512 -163 -284 -295 -522 -293 -528 3 -7 154 -97 336 -202 182 -104 331 -192 331 -195 0 -3 -29 -26 -63 -52 -251 -188 -530 -308 -812 -348 -44 -6 -90 -13 -102 -16 l-23 -4 0 -310 0 -310 43 0 c66 0 240 27 361 56 429 102 816 322 1142 650 l141 142 104 4 c91 3 112 7 172 35 255 120 343 438 187 670 l-32 46 36 121 c42 143 70 281 87 425 15 138 7 514 -15 641 -37 214 -110 451 -184 600 -37 75 -45 83 -72 73z" />
    </g>
  </svg>
)

export default TeamWorkIcon
