import { GetPostComments_post_comments } from 'operations/GetPostComments'
import { GetReferralComments_opportunity_comments } from 'operations/GetReferralComments'
import { InsertMention } from 'operations/InsertMention'
import { InsertOnePostComment } from 'operations/InsertOnePostComment'
import { InsertOneReferralComment } from 'operations/InsertOneReferralComment'

import { M_INSERT_MENTION } from 'lib/mutations/mentions'
import { M_INSERT_REFERRAL_COMMENT } from 'lib/mutations/opportunities'
import { M_INSERT_POST_COMMENT } from 'lib/mutations/post_comment'

import { useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import { isNil, pathOr } from 'ramda'
import { useState } from 'react'
import { getMentionIdsFromText, replaceMentions } from 'utils'

import useProfile from './useProfile'

interface Props {
  postType: 'post' | 'opportunity'
}

const useSubmitComment = ({ postType }: Props) => {
  const { profile } = useProfile()
  const myId = profile?.id

  const router = useRouter()

  const [replyingToName, setReplyingToName] = useState<string | null>(null)
  const [replyingToCommentId, setReplyingToCommentId] = useState<number | null>(
    null,
  )

  const [replyingToAuthorId, setReplyingToAuthorId] = useState<number | null>(
    null,
  )

  const [showReplyingTo, setShowReplyingTo] = useState<boolean>(false)

  const [isReplyingToReply, setIsReplyingToReply] = useState<boolean>(false)

  const [insertMention] = useMutation<InsertMention>(M_INSERT_MENTION)

  const [insertRegularComment] = useMutation<InsertOnePostComment>(
    M_INSERT_POST_COMMENT,
    {
      refetchQueries: [
        'GetPostComments',
        'GetReferrals',
        'GetReferralById',
        'GetHomeFeed',
        'GetPost',
      ],
    },
  )

  const [insertReferralComment] = useMutation<InsertOneReferralComment>(
    M_INSERT_REFERRAL_COMMENT,
    {
      refetchQueries: [
        'GetPostComments',
        'GetReferrals',
        'GetReferralById',
        'GetHomeFeed',
        'GetPost',
      ],
    },
  )

  const isReplyingToSelf = profile?.id === replyingToAuthorId

  const insertMentions = ({
    idList,
    fromProfileId,
    commentId,
    opportunityCommentId,
  }: {
    idList: number[]
    fromProfileId: number
    commentId?: number
    opportunityCommentId?: number
  }) => {
    const mentions = idList.map((id: number) => ({
      from_profile_id: fromProfileId,
      to_profile_id: id,
      comment_id: commentId,
      opportunity_comment_id: opportunityCommentId,
      post_id: null,
    }))

    insertMention({ variables: { data: mentions } })
  }

  const postRegularComment = async ({
    comment,
    postId,
    isAnonymous,
    isReplyToAnonComment,
    onCompleted = () => {},
  }: {
    comment: string
    postId: number
    isAnonymous?: boolean
    isReplyToAnonComment?: boolean
    onCompleted?: () => void
  }) => {
    const parsedComment =
      isReplyingToReply && !isReplyingToSelf
        ? replaceMentions(
            isReplyToAnonComment
              ? `{{${replyingToAuthorId}}}--anonymous ${comment}`
              : `{{${replyingToAuthorId}}} ${comment}`,
          )
        : replaceMentions(comment)
    const response = await insertRegularComment({
      variables: {
        from_profile_id: myId,
        to_post_id: postId,
        content: parsedComment,
        parent_id: replyingToCommentId,
        is_anonymous: isAnonymous,
      },
      refetchQueries: [
        'GetPostComments_post_comments',
        'GetCommentMentions',
        'GetPostComments',
      ],
      onCompleted: () => {
        setShowReplyingTo(false)
        onCompleted()
      },
    })

    const idList = getMentionIdsFromText(parsedComment)
    const { from_profile_id: fromProfileId, id: commentId } = pathOr(
      {},
      ['data', 'insert_post_comments_one'],
      response,
    ) as GetPostComments_post_comments

    insertMentions({ idList, fromProfileId, commentId })
  }

  const postReferralComment = async ({
    comment,
    postId,
    onCompleted = () => {},
  }: {
    comment: string
    postId: number
    onCompleted?: () => void
  }) => {
    const parsedComment =
      isReplyingToReply && !isReplyingToSelf
        ? replaceMentions(`{{${replyingToAuthorId}}} ${comment}`)
        : replaceMentions(comment)
    const response = await insertReferralComment({
      variables: {
        content: parsedComment,
        from_profile_id: myId,
        opportunity_id: postId,
        parent_id: replyingToCommentId,
      },
      refetchQueries: [
        'GetReferralComments',
        'GetReferrals',
        'GetCommentMentions',
        'GetReferralById',
      ],
      onCompleted: () => {
        setShowReplyingTo(false)
        onCompleted()
      },
    })

    const idList = getMentionIdsFromText(parsedComment)
    const { from_profile_id: fromProfileId, id: commentId } = pathOr(
      {},
      ['data', 'insert_opportunity_comments_one'],
      response,
    ) as GetReferralComments_opportunity_comments

    insertMentions({ idList, fromProfileId, opportunityCommentId: commentId })
  }

  const onOpenReply = ({
    replyingToId,
    authorId,
    authorName,
    replyingToReply = false,
    callback,
  }: {
    replyingToId: number
    replyingToReply?: boolean
    authorId?: number
    authorName: string
    callback?: () => void
  }) => {
    setReplyingToCommentId(replyingToId)
    setReplyingToName(authorName)
    setShowReplyingTo(true)
    setIsReplyingToReply(replyingToReply)
    setReplyingToAuthorId(replyingToReply && authorId ? authorId : null)
    if (!isNil(callback)) callback()
  }

  const closeReply = () => setShowReplyingTo(false)

  const goToMainPost = ({ postId }: { postId: number }) =>
    postType === 'post'
      ? router.push(`/posts/${postId}`)
      : router.push(`/opportunities/referrals/${postId}`)

  const isReplying = showReplyingTo

  return {
    isReplying,
    replyingToName,
    onPost:
      postType == 'opportunity' ? postReferralComment : postRegularComment,
    onOpenReply,
    closeReply,
    replyingToAuthorId,
    goToMainPost,
  }
}

export default useSubmitComment
