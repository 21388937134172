import {
  ModalProps,
  PermissionWrapperProps,
} from '@/components/permission_wrapper/types'
import { Actions } from '@/components/permission_wrapper/utils'
import { Tooltip } from '@/components/ui/tooltip'
import { propOr } from 'ramda'
import { ReactNode, cloneElement, useEffect, useMemo, useState } from 'react'

import useFeatureFlags from 'components/feature_flags/hooks/useFeatureFlags'
import { useProfile } from 'components/hooks'
import { Loading, Modal } from 'components/ui'

function PermissionWrapper({
  children,
  className,
  behavior,
  variables,
  ...props
}: PermissionWrapperProps) {
  const [showWarning, toggleWarning] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { user, profile } = useProfile()

  const {
    featureFlags: { instant_access: instantAccessIsOn, strict_permissions },
  } = useFeatureFlags()

  const hasPermissions = Array.isArray(props.action)
    ? !props.action
        .map((action) =>
          Actions[action]({
            user,
            feature_flags: { strict_permissions },
            variables,
            profile,
          }),
        )
        .includes(false)
    : Actions[props.action]({
        user,
        feature_flags: { strict_permissions },
        variables,
        profile,
      })

  const shouldRestrict = instantAccessIsOn && !hasPermissions

  useEffect(() => {
    setIsLoading(false)
  }, [shouldRestrict, hasPermissions])

  if (behavior === 'ShowModal') {
    const { title, content }: ModalProps = propOr(
      { title: 'Warning', content: 'You are not a member.' },
      'modal',
      props,
    )

    if (shouldRestrict) {
      return (
        <>
          {cloneElement(children, {
            onClick: () => toggleWarning(true),
          })}
          <Modal
            isOpen={showWarning}
            onClose={() => toggleWarning(false)}
            title={title}
          >
            {content}
          </Modal>
        </>
      )
    }

    return <>{children}</>
  }

  if (behavior === 'Disable') {
    const tooltip: string = propOr('Become a member', 'tooltip', props)

    if (shouldRestrict) {
      return (
        <>
          <Tooltip content={tooltip}>
            {cloneElement(children, {
              onClick: () => {},
              'data-tip': true,
              'data-for': tooltip,
            })}
          </Tooltip>
        </>
      )
    }
  }

  const alternativeComponent: ReactNode = propOr(
    <></>,
    'alternativeComponent',
    props,
  )

  if (shouldRestrict) {
    return <>{alternativeComponent}</>
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <Loading />
      </div>
    )
  }

  return <>{children}</>
}

export default PermissionWrapper
