import { FunctionComponent } from 'react'
import { Configure, Highlight, Snippet } from 'react-instantsearch'

import { Text } from 'components/ui'

import { highlightContentClassName, highlightTitle } from '../../utils'

interface IPostCardProps {
  hit: any
}

const PostCard: FunctionComponent<IPostCardProps> = ({ hit }) => {
  return (
    <div className="flex gap-3 p-1">
      <Configure attributesToSnippet={['content']} />
      <div className="flex flex-none justify-center items-center w-8 h-8 rounded-full border-2 border-gray-400 bg-primary">
        <Text className="text-white" variant="small" weight="bold">
          D
        </Text>
      </div>
      <div className="flex flex-col truncate">
        <Text
          variant="body"
          weight="semibold"
          color="black"
          className="truncate"
        >
          <Highlight attribute="title" hit={hit} classNames={highlightTitle} />
        </Text>
        <Text variant="small" className="truncate">
          <Snippet
            attribute="content"
            hit={hit}
            classNames={highlightContentClassName}
          />
        </Text>
      </div>
    </div>
  )
}

export default PostCard
