import { GetNameById } from 'operations/GetNameById'

import { Q_GET_NAME_BY_ID } from 'lib/queries/profiles'

import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { isNil } from 'ramda'
import { generateKey, getId, isAnonTag, isTag } from 'utils'

import { Text } from 'components/ui'

function Tag({
  id,
  onClick,
}: {
  id: number
  onClick: (username: string | undefined) => void
}) {
  const { data } = useQuery<GetNameById>(Q_GET_NAME_BY_ID, {
    variables: {
      profileId: id,
    },
  })
  const firstName = data?.profiles?.[0]?.first_name
  const lastName = data?.profiles?.[0]?.last_name

  if (isNil(firstName) && isNil(lastName)) return <p>@{id}&nbsp;</p>

  const fullName = `${firstName} ${lastName}`.trim()

  return (
    <Text
      onClick={() => onClick(data?.profiles[0].username!)}
      variant="body"
      className="inline-block font-semibold hover:cursor-pointer text-primary"
      weight="light"
      size="md"
    >
      {fullName}&nbsp;
    </Text>
  )
}

const TagHandler = ({
  word,
  onClick,
  textSize,
}: {
  word: string
  onClick: (username?: string) => void
  textSize?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl'
}) => {
  if (isTag(word) && !isAnonTag(word)) {
    return <Tag id={getId(word)} onClick={onClick} />
  }

  if (word === '\n') {
    return <br />
  }

  if (isAnonTag(word)) {
    return (
      <div className="inline-block font-semibold text-primary">
        Anonymous Member&nbsp;
      </div>
    )
  }

  if (word !== ' ') {
    return (
      <Text
        variant="body"
        className="inline-block"
        weight="light"
        size={textSize}
        key={generateKey(word)}
      >
        {`${word}`}&nbsp;
      </Text>
    )
  }

  return null
}

function MentionsDetector({
  content,
  textSize,
}: {
  content: string
  textSize?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl'
}) {
  const router = useRouter()
  const words = content?.split(/(?=\s)|(?=^)/gm)

  return (
    <div>
      {words?.map((word) => (
        <TagHandler
          word={word}
          textSize={textSize}
          onClick={(username: string | undefined) =>
            router.push(`/be/${username}`)
          }
          key={generateKey(word)}
        />
      ))}
    </div>
  )
}

export default MentionsDetector
