import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

import Text from '../text/text'

interface ITextCharsRemaining extends React.HTMLAttributes<HTMLDivElement> {
  minLimit: number
  maxLimit?: number
  value: string
}

const TextCharsRemaining: FunctionComponent<ITextCharsRemaining> = ({
  className,
  minLimit,
  maxLimit,
  value,
  ...props
}: ITextCharsRemaining) => {
  return (
    <div {...props} className={twMerge(className)}>
      <Text variant="body" detectLink={false}>
        {minLimit - value.length > 0
          ? `You need at least ${minLimit - value.length} more characters`
          : maxLimit && value.length < maxLimit
          ? `You have ${
              maxLimit - value.length
            } chars remaining out of ${maxLimit}`
          : ''}
      </Text>
    </div>
  )
}

export default TextCharsRemaining
