import { UserSearch_profiles } from 'operations/UserSearch'

import { Q_FUZZY_FIND_PROFILES } from '@/lib/queries/profiles'
import { FindProfiles } from '@/operations/FindProfiles'
import { useLazyQuery } from '@apollo/client'
import { FunctionComponent } from 'react'

import { Avatar } from 'components/ui'
import { ComboboxDemo as Combobox } from 'components/ui/combobox/newCombobox'

import { SelectedInvitee } from './recommend_colleague'

interface IOption {
  profile: UserSearch_profiles
}

const Option: FunctionComponent<IOption> = ({ profile }) => {
  return (
    <div className="flex gap-4 justify-start">
      <Avatar
        objectFit="cover"
        layout="fill"
        className="inline flex-none mr-2 rounded-full"
        avatar={profile.avatar}
        borderColor="gray"
        alt="Profile image"
        size={8}
      />
      <p className="grow">
        {profile.first_name} {profile.last_name}
      </p>
    </div>
  )
}

interface IRecommendationSelect {
  addInvitee: (invitee: SelectedInvitee) => void
  selectedValue: SelectedInvitee | null
  placeholder?: string
  background?: 'white' | 'page'
  maxOptions?: number
  disable?: boolean
}

const RecomendationSelect: FunctionComponent<IRecommendationSelect> = ({
  addInvitee,
  selectedValue,
  background = 'page',
  placeholder,
  maxOptions = 8,
  disable = false,
}) => {
  const [getSuggestions, { data: suggestionsData }] =
    useLazyQuery<FindProfiles>(Q_FUZZY_FIND_PROFILES, {
      fetchPolicy: 'network-only',
    })

  const handleOnChange = (value: SelectedInvitee) => {
    addInvitee(value)
  }

  const profiles = suggestionsData?.profiles

  return (
    <Combobox
      disabled={disable}
      parentBgColor={background}
      buttonPlaceholder={
        selectedValue?.first_name && selectedValue?.last_name
          ? `${selectedValue?.first_name} ${selectedValue?.last_name}`
          : placeholder
      }
      getSelectedOptions={(selected) => {
        if (!Array.isArray(selected)) {
          const profileSelected = selected?.value as SelectedInvitee
          handleOnChange(profileSelected)
        }
      }}
      data={profiles?.slice(0, maxOptions).map((profile) => ({
        title: `${profile.first_name} ${profile.last_name}`,
        value: {
          id: profile.id,
          first_name: profile.first_name,
          last_name: profile.last_name,
          avatar: profile.avatar,
          owner: profile.owner,
        },
      }))}
      getData={(selection) =>
        getSuggestions({
          variables: {
            fullname: selection ?? '',
          },
        })
      }
      customRenderItem={(option) => (
        <Option key={option.value.id} profile={option.value} />
      )}
    />
  )
}

export default RecomendationSelect
