import clsx from 'clsx'

import { Avatar } from 'components/ui'

interface ProfileImageProps {
  avatar: any
  isOnline: boolean
  size?: number
}
function ProfileImage({ avatar, isOnline, size = 6 }: ProfileImageProps) {
  return (
    <div className="flex flex-wrap my-1">
      <div className="relative mr-2">
        <Avatar
          size={size}
          avatar={avatar}
          layout="fill"
          className="object-cover rounded-full"
          alt="Profile image"
        />
        <div
          className={clsx(
            'h-1.5 w-1.5 rounded-full absolute bottom-0 -right-1 ring-2 ring-white',
            isOnline ? 'bg-[#5BD468]' : 'bg-gray-mid',
          )}
        />
      </div>
    </div>
  )
}

export default ProfileImage
