import { parseReferralTitle } from '@/utils/index'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import { SearchBoxContext } from '../../utils/context'
import ReferralCard from './referral_card'

const ReferralResults = ({ hit }: { hit: any }) => {
  const router = useRouter()
  const { hideResults } = useContext(SearchBoxContext)
  const referralLink = `/opportunities/referrals/${
    hit.uuid
  }/${parseReferralTitle(hit?.title)}`

  const goToCompleteReferral = () => {
    hideResults()
    router.push(referralLink)
  }
  return (
    <div key={hit.id} onClick={goToCompleteReferral}>
      <ReferralCard hit={hit} />
    </div>
  )
}

export default ReferralResults
