import { GetProfileByOwnerId } from 'operations/GetProfileByOwnerId'

import { UPDATE_USER_STATUS } from 'lib/mutations/profile_views'
import { Q_GET_PROFILE } from 'lib/queries/profiles'

import { useMutation, useQuery } from '@apollo/client'
import { useClerk, useUser } from '@clerk/clerk-react'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import { ChatIcon, UserAddIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { whether } from 'utils'

import ManualInvitationModal from 'components/common/manual_invitation_modal'
import NotificationBellIcon from 'components/common/notification_bell'
import useFeatureFlags from 'components/feature_flags/hooks/useFeatureFlags'
import { useNotifications } from 'components/hooks'
import useProfile from 'components/hooks/useProfile'
import HeaderSearch from 'components/search/header_search_realoded/header_search'
import { Button, MobileSwitch } from 'components/ui'

import { PermissionWrapper } from '../common/hocs'
import { HamburgerMenuIcon } from '../common/icons'
import Actions from './actions/actions'
import BelonglyLogo from './belongly_logo'
import CloseButton from './close_button'
import MobileMenu from './mobile/mobile_menu'
import ProfileSection from './profile_section'
import RegularMenu from './regular_menu'

const Header: FunctionComponent = () => {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)
  const [showUserDropdownMenu, setShowUserDropdownMenu] =
    useState<boolean>(false)

  const [openInvitationModal, setOpenInvitationModal] = useState<boolean>(false)

  const { push, pathname, back } = useRouter()
  const { profile } = useProfile()

  const { user } = useUser()
  const { signOut } = useClerk()

  const { data: ownerProfileData } = useQuery<GetProfileByOwnerId>(
    Q_GET_PROFILE,
    {
      variables: { ownerId: user?.id || '' },
    },
  )
  const [updateStatus] = useMutation(UPDATE_USER_STATUS)

  const setUserStatus = (status: boolean) => {
    updateStatus({
      variables: {
        profileId: profile?.id,
        status,
      },
    })
  }

  const { featureFlags } = useFeatureFlags()

  const { notifications } = useNotifications()

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const goToMyProfile = () => {
    if (
      ownerProfileData &&
      ownerProfileData.profiles &&
      ownerProfileData.profiles.length > 0
    ) {
      push(`/be/${ownerProfileData?.profiles[0].username ?? ''}`)
      setShowUserDropdownMenu(false)
    }
  }

  const goToAccountSettings = () => {
    push('/user/')
    setShowUserDropdownMenu(false)
  }

  const goToCreateBusinessPage = () => {
    push('/page/create')
    setShowUserDropdownMenu(false)
  }

  const goToMessagingPage = () => {
    push('/messaging')
  }

  const closeMobileMenu = () => setShowMobileMenu(false)

  const MenuButton = () => (
    <div className="flex items-center">
      <button
        className="w-5 outline-none mobile-menu-button"
        onClick={toggleMenu}
      >
        {whether(
          showMobileMenu,
          <CloseButton onClick={toggleMenu} />,
          <HamburgerMenuIcon
            hasNotification={!!notifications && notifications > 0}
          />,
        )}
      </button>
    </div>
  )

  useEffect(() => {
    if (profile) {
      window.onfocus = () => setUserStatus(true)
      window.onblur = () => setUserStatus(false)
    }

    return () => {
      window.onfocus = null
      window.onblur = null
    }
    // eslint-disable-next-line
  }, [profile])

  const navRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (navRef.current) {
      window?.localStorage?.setItem(
        'navbarHeight',
        JSON.stringify(navRef?.current?.offsetHeight),
      )
    }
  }, [])

  const mainPages = [
    '/home',
    '/opportunities/referrals',
    '/opportunities/media_opportunities',
    '/notifications',
    '/news/category/0',
    '/colleagues',
    '/resources',
    '/be/[username]',
  ]

  const showGoBack = !mainPages.includes(pathname)

  return (
    <div>
      <nav
        className="sticky top-0 z-30 items-center w-full h-16 bg-white border-b border-light-border"
        ref={navRef}
      >
        <div className="mx-auto md:px-5 lg:px-20 max-w-8xl">
          <MobileSwitch
            mobileClassName="flex mx-2 md:flex lg:hidden"
            mobileView={
              <div className="flex items-center w-full">
                <div className="flex w-[75%] md:w-[65%]">
                  <div className="flex gap-3 items-center w-full">
                    {showGoBack ? (
                      <ChevronLeftIcon
                        className="w-6 h-6 stroke-primary"
                        onClick={back}
                      />
                    ) : (
                      <BelonglyLogo />
                    )}
                    <HeaderSearch className="w-[80%]" />
                  </div>
                </div>
                <div className="flex w-[25%] md:w-[35%] justify-end gap-5">
                  <Actions
                    className="gap-3 md:flex"
                    setOpenInvitationModal={setOpenInvitationModal}
                    goToMessagingPage={goToMessagingPage}
                  />
                  <MenuButton />
                  <ProfileSection
                    profileData={ownerProfileData?.profiles[0]}
                    goToMyProfile={goToMyProfile}
                    goToCreateBusinessPage={goToCreateBusinessPage}
                    goToAccountSettings={goToAccountSettings}
                    signOut={signOut}
                  />
                </div>
              </div>
            }
            regularClassName="hidden justify-between md:hidden lg:flex lg:items-center"
            regularView={
              <>
                <div className="flex items-center w-full">
                  <div className="flex w-[35%]">
                    <div className="flex gap-3 items-center w-[90%]">
                      <BelonglyLogo />
                      <HeaderSearch />
                    </div>
                  </div>
                  <div className="flex w-[65%]">
                    <RegularMenu />
                  </div>
                </div>
                <div className="relative items-center space-x-3 md:flex">
                  <Actions
                    className="gap-3"
                    setOpenInvitationModal={setOpenInvitationModal}
                    goToMessagingPage={goToMessagingPage}
                  />
                  <ProfileSection
                    pictureSize={8}
                    profileData={ownerProfileData?.profiles[0]}
                    goToMyProfile={goToMyProfile}
                    goToCreateBusinessPage={goToCreateBusinessPage}
                    goToAccountSettings={goToAccountSettings}
                    signOut={signOut}
                  />
                </div>
              </>
            }
          />
        </div>
        {showMobileMenu && (
          <MobileMenu
            onClose={closeMobileMenu}
            hasNotification={!!notifications && notifications > 0}
            openInvitationModal={() => setOpenInvitationModal(true)}
          />
        )}
      </nav>

      <ManualInvitationModal
        isOpen={openInvitationModal}
        onClose={() => setOpenInvitationModal(false)}
      />
    </div>
  )
}

export default Header
