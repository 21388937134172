let isDev = false

if (process && process.env.NODE_ENV === 'development') {
  isDev = true
}

const VALID_ENVS = ['development', 'production']

function normalizeEnvName() {
  if (!VALID_ENVS.includes(process.env.NODE_ENV)) {
    throw 'Invalid process.env.NODE_ENV: ' + process.env.NODE_ENV
  } else return process.env.NODE_ENV
}

export const envName = normalizeEnvName()

export { isDev }
