import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from '../button'

interface IChip extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  thumbnail?: JSX.Element
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onDelete?: (value: unknown) => void
  onThumbnailClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  variant?: 'primary' | 'secondary' | 'gray'
  clickable?: boolean
}

const variantColors = {
  primary: 'bg-primary hover:bg-primary-hover',
  secondary: 'bg-secondary-100 hover:bg-secondary-hover',
  gray: 'bg-gray hover:bg-gray-hover',
}

const variantTextColor = {
  primary: 'text-white stroke-white',
  secondary: 'text-blue-badge-check stroke-blue-badge-check',
  gray: 'text-white stroke-white',
}

const variantNotClickable = {
  primary: 'bg-primary/70',
  secondary: 'bg-secondary-50',
  gray: 'bg-gray/70',
}

const Chip: FunctionComponent<IChip> = ({ className, ...props }: IChip) => {
  const {
    label,
    thumbnail,
    onClick,
    onDelete,
    onThumbnailClick,
    variant,
    children,
    clickable = false,
  } = props

  const hasThumbnail = thumbnail !== undefined
  const hasDeleteIcon = onDelete !== undefined
  const variantClassname = clickable
    ? variantColors[variant || 'gray']
    : variantNotClickable[variant || 'gray']
  const variantTextClassname = variantTextColor[variant || 'gray']

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    if (onClick) {
      onClick(e)
    }
  }

  const handleDeleteIconClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: unknown,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    if (onDelete) {
      onDelete(value)
    }
  }

  const handleThumbnailClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    if (onThumbnailClick) {
      onThumbnailClick(e)
    }
  }

  return (
    <div
      {...props}
      className={twMerge(
        'flex items-center rounded-buttons p-1 max-w-max h-[32px]',
        clickable ? 'hover:cursor-ponter' : '',
        className,
        variantClassname,
      )}
      onClick={handleClick}
    >
      {hasThumbnail && (
        <Button className="flex-1 py-0 px-0" onClick={handleThumbnailClick}>
          {thumbnail}
        </Button>
      )}
      <p
        className={twMerge(
          'px-2 text-xs text-center whitespace-nowrap select-none flex-2',
          variant == 'secondary'
            ? 'text-[#7a8fce] font-semibold'
            : 'text-white',
        )}
      >
        {variant == 'secondary' ? label?.toUpperCase() : label}
        {children}
      </p>
      {hasDeleteIcon && (
        <Button
          className="flex-1 py-0 px-0 pr-0.5"
          onClick={(e) => handleDeleteIconClick(e, label)}
        >
          <XIcon
            className={twMerge(
              'w-3 h-3',
              variant == 'secondary' ? 'stroke-[#7a8fce]' : 'stroke-white',
            )}
          />
        </Button>
      )}
    </div>
  )
}

export default Chip
