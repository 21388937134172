import { LightBulbIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { FunctionComponent } from 'react'

import { Text } from 'components/ui'

interface ISideNote extends React.HTMLAttributes<HTMLDivElement> {
  text: string
  icon?: boolean
}

const SideNote: FunctionComponent<ISideNote> = ({
  className,
  text,
  icon = true,
  ...props
}: ISideNote) => {
  return (
    <div
      {...props}
      className={clsx(
        'absolute w-56 -right-[18rem] top-1 flex flex-row',
        className,
      )}
    >
      {icon && <LightBulbIcon className="mr-2 w-10 h-10" aria-hidden="true" />}
      <Text size="xs" className="self-end">
        {text}
      </Text>
    </div>
  )
}

export default SideNote
