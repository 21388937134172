export const highlightName = {
  nonHighlighted: 'capitalize hover:cursor-pointer',
  highlighted: 'capitalize bg-[#AFBFF5] hover:cursor-pointer',
}
export const highlightProfilesClassName = {
  nonHighlighted: 'capitalize text-text-small font-light',
  separator: 'text-text-small font-light',
  highlighted: 'capitalize bg-[#AFBFF5]',
}
export const highlightContentClassName = {
  nonHighlighted: 'text-text-small font-light',
  separator: 'text-text-small font-light',
  highlighted: 'bg-[#AFBFF5]',
}
export const highlightTitle = {
  nonHighlighted: 'text-sm text-black',
  highlighted: 'text-sm text-black bg-[#AFBFF5]',
}
export const highlightContent = {
  nonHighlighted: 'text-sm',
  highlighted: 'text-sm bg-[#AFBFF5]',
}
export const highlightReferralsClassName = {
  nonHighlighted: 'text-sm',
  highlighted: 'bg-[#AFBFF5]',
}
