const InformationIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="47"
    viewBox="0 0 47 47"
    className={className}
  >
    <g>
      <g>
        <image
          width="14"
          height="14"
          transform="translate(5 5)"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAlCAYAAADFniADAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAJaADAAQAAAABAAAAJQAAAACQyAEPAAAEM0lEQVRYCbVXXYhVVRRea59z70yZMzkaiY0mZiKDmk2hYxE04t9LEEUiWCg+hfXig9BTwsD0GPUw9EOOTOHDYDREDP2I0QhKPxgKvVgMJMo0SiPOKPPTuWcvv329Z+4555477X2vd8Ph7LX2+tZee+21116byb35PvnbNVEXMz3DRGuFaBnUPIBvFvQE6FGM/S5afgkp/BH8GfdprBD5Dl/5H3rsX8UnDt+4p7yPcpR72moaS6FHoPRTByOqG6xyA5hzpeW82WIeeS/DmH/vi0Fl796B3v3ZM97jetUGFfnHmPljjD9YTaZGfp6YX/GUatGif8jSgbisbNiuPhI+XDlynznMJ0MdvJ7WWuGpooeIj6YFG0Rvgsfa4LHv4voTRuGo7yamz+ICDv0hFjqsiPuQLq4hJXRbYrcC86eQ/JElvxgBPV1jUA+nFXqUO+SiC/jlkQ4VdTyV60PfJEDnBg99kAaFFPSDN5vmV6OxgBPRWGTUKhJ5I2K6/jXxmgzMQ+AlwiNDJs7ak6f8BsMoGoXT9k581LXPLD1E+Y44Div/AnQuzvu/fqjkXSNjUgJDwS38WwyjrsZynIknSegl3H9P1qArDKXQyuZyFaYzNShoDERkr48NfBErq6ddA/g6HI5KQR6vR1ERC3uUCHW6KsKe/yXCb8PV7fhW4Xs2lGA1TqHJTTCwjibcyT77l+GodQ5qZkLxUIbMXc7CoETZrFkuYKx4iLJkFubxFQWDTIHm0rTicLCUGGdgRFccHFBwEdv4d5zn1pcWs5omNxAtwvY9VcI0h0RbMvD1RGnOGPVfhlJrliK5lBJuQ9DXU8gVYJRMpJS6kLcLVDgXB6CAewF0Ps5z6/MUjOJRN1BCegRUIcFRtCtBOxMyrsyrwxkXAYSHou78X2jPfL+WDstFRZrO1oI1GFQC3yax+fXw/Jokz5HSNKIQE2cAm3aEmkvAePifOA5BvyNO19LHO3EYMUUBsQy6KkBl8HMaw4qeT/Mc6W8gP2mMolD77zmCSTRfTWNEpFgPpfm2tBLuMbKlIiy8qVgZhYmaaCFlStFvKPjN1s831NrjeD49Cs//RKxM/jJJFrnWqo0glCqcsxRXhy5dH9VfueVH5RimQqLMbnji9zroQog2r440JVaBxPcqVvplNGjxH0O10At3/wrZQJNewYp3ovJ4DXS7Bb4oAh1vago+ieQTRhkmVvg+lB6JBBr+Zx7Ag/RgfJ5STJVZiJPvlfLWgbOxzG1Ybxi12N609gqjjICI/gov14fRTZQlaXBdNEt/KOE+Zx2IsQMI1jmXgLWRVZR7y9mYJKDpCUx0ymYyC5mvo7ddco4aKbx6tuEV/TkmnrSYPJ5SpoEbBL7bduqK02cBXIJt3Ymj/xxOKWp1eQz5sRU4U8GiYJQp0GNIoJdw2Z/HXXYa/BsWeudF7gLfcpm9nXOihAAAAABJRU5ErkJggg=="
        />
      </g>
    </g>
  </svg>
)

export default InformationIcon
