import clsx from 'clsx'
import { FunctionComponent } from 'react'

interface Props {
  className?: string
}

const DotsIcon: FunctionComponent<Props> = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx('h-5 w-5', className)}
    viewBox="0 0 20 20"
    fill="#000"
  >
    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
  </svg>
)

export default DotsIcon
