import NavTab from '../nav_tab'

interface ColleaguesLinkProps {
  href: string
  passHref: boolean | undefined
}

function ColleaguesLink({ href, passHref }: ColleaguesLinkProps) {
  return (
    <NavTab
      href={href}
      passHref={passHref}
      title="Network"
      subtitle="Connect With Others"
    />
  )
}

export default ColleaguesLink
