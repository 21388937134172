import { Q_GET_CLIENT_PROFILE } from '@/lib/queries/clients'
import { GetClientProfile } from '@/operations/GetClientProfile'
import { gql, useQuery } from '@apollo/client'
import { useUser } from '@clerk/nextjs'

const useClientProfile = () => {
  const { user } = useUser()
  const ownerId = user?.id

  const { data } = useQuery<GetClientProfile>(Q_GET_CLIENT_PROFILE, {
    variables: {
      ownerId,
    },
    context: {},
  })

  const isClient: boolean = data?.profiles[0].owner?.roles.includes('client')

  return {
    profile: data?.profiles[0],
    isClient,
  }
}

export default useClientProfile
