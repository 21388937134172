import { GetFeatureFlags } from 'operations/GetFeatureFlags'

import { Q_FEATURE_FLAGS } from 'lib/queries/feature_flags'

import { useQuery } from '@apollo/client'

export type FeatureFlags = {
  strict_permissions?: boolean
  instant_access?: boolean
}

const feature_flags: FeatureFlags = {
  strict_permissions: false,
  instant_access: false,
}

const useFeatureFlags = () => {
  const { data, error, loading } = useQuery<GetFeatureFlags>(Q_FEATURE_FLAGS)
  if (data) {
    data.feature_flags?.forEach((feature_flag) => {
      if (feature_flags.hasOwnProperty(feature_flag.name)) {
        ;(feature_flags as any)[feature_flag.name] = feature_flag.enabled
      }
    })
  }

  return {
    loading,
    error,
    featureFlags: feature_flags,
  }
}

export default useFeatureFlags
