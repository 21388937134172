import React from 'react'
import { twMerge } from 'tailwind-merge'

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'third'
  | 'label'
  | 'outlined'
  | 'white'
  | 'rounded'
  | 'icon'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants
  outlined?: boolean
  hasHover?: boolean
  rounded?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, IButton>(
  (
    { className, children, variant = 'label', outlined, hasHover, ...props },
    ref,
  ) => {
    const isPrimary = variant === 'primary'
    const isRounded = variant === 'rounded'
    const isSecondary = variant === 'secondary'
    const isOutlined = variant === 'outlined'
    const isWhite = variant === 'white'
    const isThird = variant === 'third'
    const isLabel = variant === 'label'
    const isIcon = variant === 'icon'

    return (
      <button
        ref={ref}
        {...props}
        className={twMerge(
          isIcon
            ? ''
            : 'disabled:opacity-50 disabled:cursor-not-allowed px-4 py-2 rounded-buttons',
          isPrimary &&
            'bg-primary enabled:hover:bg-primary-hover transition-colors text-white',
          isSecondary &&
            'bg-secondary  enabled:hover:bg-secondary-hover transition-colors text-white',
          isOutlined && 'border border-secondary text-secondary',
          isWhite &&
            'bg-white  border border-gray-mid  focus:bg-primary focus:text-white enabled:focus:hover:bg-primary-hover',
          isThird && 'bg-third text-primary enabled:hover:bg-third-hover',
          isRounded && 'rounded-full bg-primary',
          isLabel && 'text-[#798dcf]',
          hasHover && 'hover:bg-background',
          className,
        )}
      >
        {children}
      </button>
    )
  },
)

Button.displayName = 'Button'

export default Button
