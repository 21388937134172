import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Fragment, FunctionComponent, ReactNode } from 'react'

import { Text } from 'components/ui'

export interface IModal {
  children?: ReactNode
  title?: ReactNode | string
  isOpen: boolean
  hasCloseIcon?: boolean
  onClose: () => void
}

const Modal: FunctionComponent<IModal> = ({
  children,
  title,
  isOpen,
  hasCloseIcon = true,
  onClose,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="overflow-y-auto fixed inset-0">
          <div className="flex justify-center items-center p-4 min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="overflow-hidden p-6 w-full max-w-xl text-left align-middle bg-white rounded shadow-xl transition-all transform md:max-w-2xl">
                {title ? (
                  <Dialog.Title
                    as="div"
                    className="flex justify-between items-center pb-4 mb-5 border-b border-gray-light"
                  >
                    <Text className="text-black">{title}</Text>
                    {hasCloseIcon && (
                      <button
                        onClick={onClose}
                        className="outline-none focus:outline-0"
                      >
                        <XIcon className="w-4 h-4 stroke-black" />
                      </button>
                    )}
                  </Dialog.Title>
                ) : (
                  hasCloseIcon && (
                    <div className="flex justify-end mb-2">
                      <XIcon
                        className="w-4 h-4 cursor-pointer"
                        onClick={onClose}
                      />
                    </div>
                  )
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
