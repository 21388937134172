/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_liked_comments.yaml
// ====================================================

/**
Table name: liked_comments, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: approved
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - from_profile:
          owner_id:
            _eq: X-Hasura-User-Id

**/
export const check_liked_comments_insert_permissions = function(feature_flags:any, liked_comment:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((user?.id === user?.id
				&& user?.roles?.includes("approved"))
			&& liked_comment?.from_profile?.owner_id === user?.id))
	|| (feature_flags.strict_permissions === false
		&& liked_comment?.from_profile?.owner_id === user?.id))
}


/**
Table name: liked_comments, select permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                id:
                  _eq: X-Hasura-User-Id
  - _exists:
      _table:
        name: feature_flags
        schema: public
      _where:
        _and:
          - name:
              _eq: strict_permissions
          - enabled:
              _eq: false

**/
export const check_liked_comments_select_permissions = function(feature_flags:any, liked_comment:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& (user?.id === user?.id))
	|| feature_flags.strict_permissions === false)
}


/**
Table name: liked_comments, delete permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _and:
          - _exists:
              _table:
                name: users
                schema: public
              _where:
                _and:
                  - id:
                      _eq: X-Hasura-User-Id
                  - roles:
                      _contains: approved
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - from_profile:
          owner_id:
            _eq: X-Hasura-User-Id

**/
export const check_liked_comments_delete_permissions = function(feature_flags:any, liked_comment:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((user?.id === user?.id
				&& user?.roles?.includes("approved"))
			&& liked_comment?.from_profile?.owner_id === user?.id))
	|| (feature_flags.strict_permissions === false
		&& liked_comment?.from_profile?.owner_id === user?.id))
}