/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// Source file: tables/public_opportunity_respondants.yaml
// ====================================================

/**
Table name: opportunity_respondants, insert permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _or:
          - _and:
              - response_type:
                  _eq: SUBMIT_PROFILE
              - from_profile:
                  owner:
                    roles:
                      _contains: approved
          - _and:
              - response_type:
                  _eq: RECOMMEND
              - from_profile:
                  owner:
                    roles:
                      _contains: approved
              - recommender_profile:
                  owner:
                    roles:
                      _contains: approved
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
          - recommender_profile:
              owner_id:
                _eq: X-Hasura-User-Id

**/
export const check_opportunity_respondants_insert_permissions = function(feature_flags:any, opportunity_respondant:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((opportunity_respondant?.response_type === "SUBMIT_PROFILE"
				&& opportunity_respondant?.from_profile?.owner?.roles?.includes("approved"))
			|| (opportunity_respondant?.response_type === "RECOMMEND"
				&& opportunity_respondant?.from_profile?.owner?.roles?.includes("approved")
				&& opportunity_respondant?.recommender_profile?.owner?.roles?.includes("approved"))))
	|| (feature_flags.strict_permissions === false
		&& (opportunity_respondant?.from_profile?.owner_id === user?.id
			|| opportunity_respondant?.recommender_profile?.owner_id === user?.id)))
}


/**
Table name: opportunity_respondants, update permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _or:
          - _and:
              - response_type:
                  _eq: SUBMIT_PROFILE
              - from_profile:
                  owner_id:
                    _eq: X-Hasura-User-Id
          - _and:
              - response_type:
                  _eq: RECOMMEND
              - recommender_profile:
                  owner_id:
                    _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
          - recommender_profile:
              owner_id:
                _eq: X-Hasura-User-Id

**/
export const check_opportunity_respondants_update_permissions = function(feature_flags:any, opportunity_respondant:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((opportunity_respondant?.response_type === "SUBMIT_PROFILE"
				&& opportunity_respondant?.from_profile?.owner_id === user?.id)
			|| (opportunity_respondant?.response_type === "RECOMMEND"
				&& opportunity_respondant?.recommender_profile?.owner_id === user?.id)))
	|| (feature_flags.strict_permissions === false
		&& (opportunity_respondant?.from_profile?.owner_id === user?.id
			|| opportunity_respondant?.recommender_profile?.owner_id === user?.id)))
}


/**
Table name: opportunity_respondants, delete permission rules: 

_or:
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: true
      - _or:
          - _and:
              - response_type:
                  _eq: SUBMIT_PROFILE
              - from_profile:
                  owner_id:
                    _eq: X-Hasura-User-Id
          - _and:
              - response_type:
                  _eq: RECOMMEND
              - recommender_profile:
                  owner_id:
                    _eq: X-Hasura-User-Id
  - _and:
      - _exists:
          _table:
            name: feature_flags
            schema: public
          _where:
            _and:
              - name:
                  _eq: strict_permissions
              - enabled:
                  _eq: false
      - _or:
          - from_profile:
              owner_id:
                _eq: X-Hasura-User-Id
          - recommender_profile:
              owner_id:
                _eq: X-Hasura-User-Id

**/
export const check_opportunity_respondants_delete_permissions = function(feature_flags:any, opportunity_respondant:any, user:any): boolean {
	return ((feature_flags.strict_permissions === true
		&& ((opportunity_respondant?.response_type === "SUBMIT_PROFILE"
				&& opportunity_respondant?.from_profile?.owner_id === user?.id)
			|| (opportunity_respondant?.response_type === "RECOMMEND"
				&& opportunity_respondant?.recommender_profile?.owner_id === user?.id)))
	|| (feature_flags.strict_permissions === false
		&& (opportunity_respondant?.from_profile?.owner_id === user?.id
			|| opportunity_respondant?.recommender_profile?.owner_id === user?.id)))
}