import AcceptingClientsIcon from 'components/common/accept_clients_icon'

import AvailabilityMenu from './availability_menu'

export enum Availabilities {
  accepting = 'accepting',
  notAccepting = 'not-accepting',
  waitlist = 'waitlist',
}

const AvailabilityBadge = ({
  availability,
  canEdit,
}: {
  availability: string
  canEdit: boolean
}) => {
  return (
    <div className="flex relative gap-2 justify-end">
      <span className="text-white">
        {availability === Availabilities.accepting && 'Accepting Clients'}
        {availability === Availabilities.notAccepting &&
          'Not Accepting Clients'}
        {availability === Availabilities.waitlist && 'Waitlist'}
      </span>
      {!canEdit && <AcceptingClientsIcon className="stroke-white" />}
      {canEdit && (
        <AvailabilityMenu
          availability={availability}
          iconClassname="stroke-white"
        />
      )}
    </div>
  )
}

export default AvailabilityBadge
