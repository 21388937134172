import React from 'react'

import ColleaguesLink from './links/colleagues_link'
import ReferralLink from './links/referrals_link'
import ResourcesLink from './links/resources_link'
import RetentionLink from './links/retention_link'

const RegularMenu: React.FC = () => {
  return (
    <div className="flex justify-between items-end w-[90%]">
      <ReferralLink href="/opportunities" passHref />
      <RetentionLink href="/client-surveys" passHref />
      <ResourcesLink href="/news" passHref />
      <ColleaguesLink href="/colleagues" passHref />
    </div>
  )
}

export default RegularMenu
