import clsx from 'clsx'
import { FunctionComponent, MouseEventHandler } from 'react'
import { twMerge } from 'tailwind-merge'

import CheckboxIcon from 'components/common/checkbox_icon'
import SquareIcon from 'components/common/square_icon'

import { Text } from '../text'

interface ICheckbox {
  className?: string
  label: string | JSX.Element
  size?: 'sm' | 'md' | 'lg'
  selected: boolean
  uncheckedClassName?: string
  onChange: MouseEventHandler<HTMLDivElement>
  disabled?: boolean
}

const Checkbox: FunctionComponent<ICheckbox> = ({
  className,
  label,
  size = 'sm',
  selected,
  uncheckedClassName = '',
  onChange,
  disabled = false,
  ...props
}: ICheckbox) => {
  const checkboxSize = size == 'sm' ? 5 : size == 'md' ? 8 : 9

  const handleChange = (e: any) => {
    if (onChange && !disabled) {
      onChange(e)
    }
  }

  return (
    <div
      {...props}
      onClick={handleChange}
      className={twMerge('flex gap-2 items-start cursor-pointer', className)}
    >
      <div>
        {selected ? (
          <CheckboxIcon
            size={checkboxSize}
            className="fill-primary-hover stroke-primary"
          />
        ) : (
          <SquareIcon
            size={checkboxSize}
            className={twMerge(
              'fill-gray-mid',
              uncheckedClassName,
              disabled && 'fill-gray-light',
            )}
          />
        )}
      </div>
      <Text className="select-none">{label}</Text>
    </div>
  )
}

export default Checkbox
