import { FindExactLocation } from 'operations/FindExactLocation'
import { FindLocationById } from 'operations/FindLocationById'

import {
  Q_GET_EXACT_LOCATION,
  Q_GET_LOCATION_BY_ID,
} from 'lib/queries/onboarding'
import { onboardingData } from 'lib/reactiveVariables'

import { useLazyQuery } from '@apollo/client'

const useFindPreciseLocation = () => {
  const [getLocationById, { loading: getLocationLoading }] =
    useLazyQuery<FindLocationById>(Q_GET_LOCATION_BY_ID)
  const [getExactLocation, { loading: getExactLocationLoading }] =
    useLazyQuery<FindExactLocation>(Q_GET_EXACT_LOCATION)

  const findLocation = async ({
    locationId,
    city,
    region,
    regionCode,
  }: {
    locationId: number | string
    city: string
    region: string
    regionCode: string
  }) => {
    try {
      if (locationId === -1) {
        const { data: foundLocation } = await getExactLocation({
          variables: {
            city,
            stateCode: regionCode,
            state: region,
          },
        })
        return foundLocation?.locations[0] ?? onboardingData().location
      } else {
        const { data: foundLocation } = await getLocationById({
          variables: {
            search: locationId,
          },
        })
        return foundLocation?.locations[0] ?? onboardingData().location
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error occurred while finding precise location:', error)

      return onboardingData().location
    }
  }

  const loading = getLocationLoading || getExactLocationLoading

  return { findLocation, loading }
}

export default useFindPreciseLocation
