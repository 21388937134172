import { UpdateProfilesPk } from 'operations/UpdateProfilesPk'

import { Q_GET_FULL_PROFILE, U_PROFILES_PK } from 'lib/queries/profiles'

import { useMutation } from '@apollo/client'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import TelehealthIcon from 'components/common/telehealth_icon'
import useProfile from 'components/hooks/useProfile'

export enum Modalities {
  online = 'online',
  inperson = 'in-person',
  both = 'both',
}

const ModalityBadge = ({
  modality,
  canEdit,
}: {
  modality: string
  canEdit: boolean
}) => {
  const { profile: ownerProfile } = useProfile()

  const [updateProfilePkMutation] = useMutation<UpdateProfilesPk>(
    U_PROFILES_PK,
    {
      refetchQueries: [Q_GET_FULL_PROFILE],
    },
  )

  const updateProfile = (option: Modalities) => {
    updateProfilePkMutation({
      variables: {
        profileId: ownerProfile?.id,
        _set: {
          modality: option,
        },
      },
    })
  }

  return (
    <div className="flex relative gap-2 justify-end">
      <span className="text-white">
        {modality === Modalities.online && 'Online'}
        {modality === Modalities.inperson && 'In-Person'}
        {modality === Modalities.both && 'Online & In-Person'}
      </span>
      {!canEdit && <TelehealthIcon className="fill-white" />}
      {canEdit && (
        <Menu>
          <Menu.Button>
            <TelehealthIcon className="fill-white" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 top-3 mt-2 w-56 bg-white rounded-md divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none z-[1000]">
              <div className="py-1 px-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-primary text-white' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => updateProfile(Modalities.online)}
                    >
                      Online
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-primary text-white' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => updateProfile(Modalities.inperson)}
                    >
                      In-Person
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-primary text-white' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => updateProfile(Modalities.both)}
                    >
                      Online & In-Person
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  )
}

export default ModalityBadge
