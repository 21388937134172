import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { Configure, Highlight, Snippet } from 'react-instantsearch'

import BadgeCheckIcon from 'components/common/badge_check_icon'
import { Text } from 'components/ui'

import { highlightContentClassName, highlightTitle } from '../../utils'

interface IReferralCardProps {
  hit: any
}

const ReferralCard: FunctionComponent<IReferralCardProps> = ({ hit }) => {
  return (
    <div className="flex gap-3 p-1">
      <Configure attributesToSnippet={['content']} />
      <div className="flex flex-none justify-center items-center w-8 h-8 rounded-full border-2 border-gray-400 bg-fourth">
        <Text className="text-white" variant="small" weight="bold">
          R
        </Text>
      </div>

      <div className="flex flex-col w-full truncate">
        <Text
          variant="body"
          weight="semibold"
          color="black"
          className="truncate"
        >
          <Highlight attribute="title" hit={hit} classNames={highlightTitle} />
        </Text>

        {hit.filled && (
          <div className="flex gap-2 items-center self-baseline">
            <BadgeCheckIcon className="w-4 h-4 fill-blue-badge-check" />
            <Text size="xs" weight="bold">
              Filled
            </Text>
          </div>
        )}
        <Text variant="small" className="truncate">
          <Snippet
            attribute="content"
            hit={hit}
            classNames={highlightContentClassName}
          />
        </Text>
      </div>
    </div>
  )
}

export default ReferralCard
