import React, { useState } from 'react'

import { useHandleClickOutside } from 'components/hooks'

import { Text } from '../../ui'
import NavLink from './nav_link'
import ResourceMenu from './resource/resource_menu'

interface IMobileMenu {
  onClose: () => void
  hasNotification?: boolean
  openInvitationModal?: () => void
}

const MobileMenu: React.FC<IMobileMenu> = ({
  onClose,
  hasNotification = false,
  openInvitationModal = () => {},
}) => {
  const [resourcesIsOpen, setOpportunitiesIsOpen] = useState<boolean>(false)

  const toggleResources = () => setOpportunitiesIsOpen(!resourcesIsOpen)

  const { parentRef } = useHandleClickOutside<HTMLLIElement>({
    callback: () => setOpportunitiesIsOpen(false),
  })

  return (
    <div className="z-50 bg-white border-t border-gray-400 shadow-lg lg:hidden">
      <ul className="flex flex-col divide-y divide-gray-400">
        <NavLink
          href="/home"
          passHref
          isMobile
          onClick={onClose}
          title="Home"
        />

        <NavLink
          href="/opportunities/referrals"
          passHref
          isMobile
          onClick={onClose}
          title="Referrals"
          subtitle=" Post/Take Referrals"
        />

        <NavLink
          href="/client-surveys"
          passHref
          isMobile
          isNew
          onClick={onClose}
          title="Pulse"
          subtitle="Client Surveys"
        />

        <NavLink
          href="/opportunities"
          parentRef={parentRef}
          onClick={toggleResources}
          passHref
          isMobile
          isLink={false}
          title="Resources"
          subtitle="For your Practice"
        />

        {resourcesIsOpen && <ResourceMenu onClose={onClose} />}

        <NavLink
          href="/colleagues"
          passHref
          isMobile
          onClick={onClose}
          title="Network"
          subtitle="Connect With Others"
        />

        <NavLink
          onClick={() => {
            openInvitationModal()
            onClose()
          }}
          href="none"
          passHref
          isMobile
          isLink={false}
          title="Invite Colleagues"
        />

        <NavLink
          href="/notifications"
          passHref
          isMobile
          onClick={onClose}
          hasNotification={hasNotification}
          title="Notifications"
          isHidden
        />

        <NavLink
          href="/messaging"
          passHref
          isMobile
          title="Messaging"
          onClick={onClose}
        />
      </ul>
    </div>
  )
}

export default MobileMenu
