import clsx from 'clsx'
import { FunctionComponent } from 'react'

interface Props {
  className?: string
}

const ChevronDownIcon: FunctionComponent<Props> = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(className, 'h-5 w-5')}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
)

export default ChevronDownIcon
