import {
  GetPostForMessages,
  GetPostForMessagesVariables,
} from 'operations/GetPostForMessages'

import { Q_GET_POST_FOR_MESSAGES } from 'lib/queries/posts'

import { useQuery } from '@apollo/client'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

import { MentionsDetector } from 'components/common/hocs'
import { Avatar } from 'components/ui'

import { parsePostTitleUrl } from 'utils/posts/helper'

const PostRender = ({
  postId,
  bubbleShape,
}: {
  postId?: string
  bubbleShape?: string
}) => {
  const {
    data: postData,
    networkStatus,
    error,
  } = useQuery<GetPostForMessages, GetPostForMessagesVariables>(
    Q_GET_POST_FOR_MESSAGES,
    {
      variables: {
        postId: Number(postId),
      },
    },
  )

  if (!postData && networkStatus === 1) {
    return <p>Loading...</p>
  }

  if (!postData && networkStatus !== 1 && error) {
    return <p>Oops... try again please</p>
  }

  if (!postData) {
    return null
  }

  const { content, title, id, author } = postData.posts[0]
  const contentMaxLength = 20
  const contentLimited =
    content.length > contentMaxLength
      ? content.split(' ').slice(0, contentMaxLength).join(' ') + '...'
      : content

  return (
    <article
      className={twMerge(
        'w-2/3 text-xs bg-white shadow-lg my-2 p-2 border border-gray-100 pl-3 pr-1.5 pb-3 pt-2',
        bubbleShape,
      )}
    >
      <div className="flex gap-2 mb-5">
        <div>
          <Avatar
            objectFit="cover"
            layout="fill"
            className="inline mr-2 rounded-full"
            profile={author || undefined}
            alt="Profile image"
            size={8}
          />
        </div>

        <h2 className="mb-2 text-sm font-bold line-clamp-3">
          <Link
            href={`/posts/${id}/${parsePostTitleUrl(title)}`}
            legacyBehavior
          >
            {title}
          </Link>
        </h2>
      </div>
      <MentionsDetector content={contentLimited} textSize="xs" />
    </article>
  )
}

export default PostRender
