export type ProfilesNotes = {
  value: string
  profileId: number
}

export type RespondantOutsider = {
  id: number
  first_name: string
  last_name: string
  email: string
}

export type ReferralListState = {
  body: string
  profilesNotes: Array<ProfilesNotes>
  respondantsOutsiders: RespondantOutsider[]
}

export type ReferralListActionType =
  | 'CHANGE_BODY_TEXT'
  | 'CHANGE_PROFILES_NOTES'
  | 'UPDATE_RESPONDANTS_OUTSIDERS'

export type ReferralListAction = {
  type: ReferralListActionType
  value: any
}

export const referralListReducer = (
  state: ReferralListState,
  action: ReferralListAction,
) => {
  const newState = { ...state }
  switch (action.type) {
    case 'CHANGE_BODY_TEXT':
      newState.body = action.value
      return newState
    case 'CHANGE_PROFILES_NOTES':
      const noteIndex = newState.profilesNotes.findIndex(
        (note) => note.profileId === action.value.profileId,
      )
      // if exists
      if (noteIndex !== -1) {
        newState.profilesNotes[noteIndex] = action.value
      } else {
        newState.profilesNotes.push(action.value)
      }
      return newState
    case 'UPDATE_RESPONDANTS_OUTSIDERS':
      const respondantExists =
        newState.respondantsOutsiders.filter(
          (item) => item.id === action.value.id,
        ).length > 0

      if (respondantExists) {
        const selection = newState.respondantsOutsiders.filter(
          (item) => item.id !== action.value.id,
        )

        newState.respondantsOutsiders = selection
      } else {
        newState.respondantsOutsiders.push(action.value)
      }

      return newState
    default:
      return state
  }
}
