import { FollowSource } from 'operations/FollowSource'
import { GetBusinessPosts_posts_news_news_source } from 'operations/GetBusinessPosts'
import {
  GetHomeFeed_business_posts_news_news_source,
  GetHomeFeed_my_connections_posts_news_news_source,
} from 'operations/GetHomeFeed'
import { GetMyNews_news_news_source } from 'operations/GetMyNews'
import { GetNews_sources } from 'operations/GetNews'
import { UnFollowSource } from 'operations/UnFollowSource'

import { M_FOLLOW_SOURCE, M_UNFOLLOW_SOURCE } from 'lib/mutations/news'
import {
  Q_GET_FOLLOWED_SOURCE,
  Q_GET_NEWS,
  isFollowing,
} from 'lib/queries/news'

import { useMutation } from '@apollo/client'
import { isNil } from 'ramda'

import { useProfile } from 'components/hooks'

import { PostOptionalFields } from 'utils/posts/feed'

export type Source =
  | (PostOptionalFields['liked_profiles_connections'] &
      PostOptionalFields['commented_profiles_connections'] &
      GetHomeFeed_business_posts_news_news_source &
      GetHomeFeed_my_connections_posts_news_news_source)
  | GetBusinessPosts_posts_news_news_source
  | GetNews_sources
  | GetMyNews_news_news_source
  | undefined

const useFollowNews = () => {
  const { id: myId } = useProfile()

  const [followSource] = useMutation<FollowSource>(M_FOLLOW_SOURCE, {
    refetchQueries: [Q_GET_NEWS, Q_GET_FOLLOWED_SOURCE],
  })
  const [unfollowSource] = useMutation<UnFollowSource>(M_UNFOLLOW_SOURCE, {
    refetchQueries: [Q_GET_NEWS, Q_GET_FOLLOWED_SOURCE],
  })

  const onFollowClick = (sourceId: number, isFollowing: boolean) => {
    if (isFollowing) {
      unfollowSource({
        variables: {
          sourceId,
          profileId: myId,
        },
      })
    } else {
      followSource({
        variables: {
          sourceId,
          profileId: myId,
        },
      })
    }
  }

  const onFollow = (source: Source) => {
    if (isNil(source)) return

    const action = isFollowing(source) ? unfollowSource : followSource
    return action({
      variables: {
        sourceId: source.id,
        profileId: myId,
      },
    })
  }

  return {
    onFollowClick,
    onFollow,
  }
}

export default useFollowNews
